
import { showCustomSelectConfirm } from '../components/dropdowns/regionPopup/index';
import { gConst } from "./gConst"; 
import { CDN } from '../lib/gCdn'

let parrentHost = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
const crypto = require('crypto');

// MOBIE APP - IN REACT NATIVE APP -> DEFAULT ANGENT IS 'Mobile'
const MBAGENT = 'Mobile';



function doCalculateScaleRatio(pScreenWidth, pScreenHeight, pCallBoxWidth = 300, pCallBoxHeight = 510) {

  let scaleX = pScreenWidth / pCallBoxWidth
  let scaleY = pScreenHeight / pCallBoxHeight

  return {scaleX, scaleY}

}
// ---------------------------------------------------------------------------------------------

// Mobile Agent check work only when React Native App sent message to Webview -> check onMessageListenerFromMobile()
function isMobile(pWindow) {

  let isMobile = false

  if (
    pWindow.navigator.userAgent.includes(MBAGENT) ||
    pWindow.navigator.userAgent.match(/Android/i) ||
    pWindow.navigator.userAgent.match(/webOS/i) ||
    pWindow.navigator.userAgent.match(/iPhone/i) ||
    pWindow.navigator.userAgent.match(/iPad/i) ||
    pWindow.navigator.userAgent.match(/iPod/i) ||
    pWindow.navigator.userAgent.match(/BlackBerry/i) ||
    pWindow.navigator.userAgent.match(/Windows Phone/i) 
    
    ) {
    isMobile = true
  }

  return isMobile
}

// ---------------------------------------------------------------------------------------------

function doValidatePhoneNumber(pPhoneNumber) {

  // 1. Check if pPhoneNumber is empty
  if (pPhoneNumber.length === 0) {
    return false;
  }
  // 2. Check if pPhoneNumber is number
  if (isNaN(pPhoneNumber)) {
    return false;
  }

  if (pPhoneNumber.length < 3 || pPhoneNumber.length > 16) {
    return false;
  }
  // 5 to 8 is invalid
  if (pPhoneNumber.length > 4 && pPhoneNumber.length < 8) {
    return false;
  }

  return true
}

// ---------------------------------------------------------------------------------------------
const getCurrentDateTime = () => {
  const currentDate = new Date();

  // Get current day
  const day = currentDate.toLocaleDateString(undefined, { weekday: 'long' });

  // Get current time
  const time = currentDate.toLocaleTimeString();

  return { day, time };
};

// ---------------------------------------------------------------------------------------------

function doFormatPhoneNumber(pPhoneNumber) {  

  // remove all space
  pPhoneNumber = pPhoneNumber.replace(/\s/g, '')

  // INDO ------------------------------
  if(pPhoneNumber.startsWith('62')){
    return pPhoneNumber
  }

  if(pPhoneNumber.startsWith('+62')){
    pPhoneNumber = pPhoneNumber.replace('+62', '62')
    return pPhoneNumber
  }

  // -----------------------------------------

  // LAO
  if(pPhoneNumber.startsWith('856')){
    return pPhoneNumber
  }

  if(pPhoneNumber.startsWith('+856')){
    pPhoneNumber = pPhoneNumber.replace('+856', '856')
    return pPhoneNumber
  }
  // -----------------------------------------

  // Malaysia
  if(pPhoneNumber.startsWith('60')){
    return pPhoneNumber
  }

  if(pPhoneNumber.startsWith('+60')){
    pPhoneNumber = pPhoneNumber.replace('+60', '60')
    return pPhoneNumber
  }
  // -----------------------------------------

  // replace +84 to 0
  if (pPhoneNumber.startsWith('+84')) {
    pPhoneNumber = pPhoneNumber.replace('+84', '0')
  }

  if (pPhoneNumber.startsWith('84')) {
    pPhoneNumber = pPhoneNumber.replace('84', '0')
  }

  // remove duplicate 0 at the beginning
  if (pPhoneNumber.startsWith('00')) {
    pPhoneNumber = pPhoneNumber.replace('00', '0')
  }

  return pPhoneNumber
}

// ---------------------------------------------------------------------------------------------

// Default Css for all component
function callBoxCss(pIsMobile,pClassName) {

  let mobileCss = ""
  let destopCss = ""
  // pIsMobile = true

  if(pClassName === "container") {
    mobileCss = "flex flex-col justify-center w-full h-screen"
    destopCss ="flex flex-col items-center w-full rounded-3xl h-full"
  }

  return pIsMobile ? mobileCss: destopCss
}

// ---------------------------------------------------------------------------------------------

function infoTableCss( pClassName,pIsMobile = false) {

  let mobileCss = ""
  let destopCss = ""

  if(pClassName === "infoTbContainer") {
    mobileCss = "transition duration-700 ease-in-out  bg-white  w-full h-full "
    destopCss ="transition duration-700 ease-in-out  bg-white  w-full h-500px rounded-lg"
  }

  return pIsMobile ? mobileCss: destopCss

}


// ---------------------------------------------------------------------------------------------


// ============Hanle CSS for KeyPad and Infotable when user click show hide info table ================

const isShowInfotableCss = (pIsShow) => {
  let className = 'hidden w-0px'
  if (pIsShow) {
    className = 'w-full h-full shadow-2xl'
  }
  return className

}


// ---------------------------------------------------------------------------------------------

const isShowKeyPadCss = (pIsShow, pIsMobile = false) => {


  let className = 'w-full h-full items-center'

  if(pIsMobile){
    return className
  }

  if(pIsShow){

    className = 'w-300px h-500px  justify-center items-center'
  }else{
    className = 'w-full h-500px  justify-center items-center'
  }
  
  return className
}

// ---------------------------------------------------------------------------------------------

const isShowCrmInfoTable = (pIsShow, pCRMType) => {

  let integration =[gConst.HUBSPOT, gConst.SALESFORCE, gConst.NHANH_V1]

  if (!integration.includes(pCRMType)) {
    pIsShow = false
  }

  return pIsShow
}
// ---------------------------------------------------------------------------------------------


function isValidJson(str) {
  try {
    const obj = JSON.parse(str);
    return typeof obj === 'object' && obj !== null;
  } catch (error) {
    return false;
  }
}

// ---------------------------------------------------------------------------------------------
function extractNumbers(inputString) {
  return inputString.replace(/\D/g, ''); // \D matches non-digit characters, g flag for global replacement
}

// ---------------------------------------------------------------------------------------------
// Hash localstorage info

function encrypt(data) {
  const cipher = crypto.createCipher('aes-256-cbc', gConst.LOCAL_STORAGE_SECRET_KEY);
  let encryptedData = cipher.update(data, 'utf8', 'hex');
  encryptedData += cipher.final('hex');
  return encryptedData;
}


// ---------------------------------------------------------------------------------------------------

function decrypt(encryptedData) {
  try {
    if(!encryptedData) return ''
    const decipher = crypto.createDecipher('aes-256-cbc', gConst.LOCAL_STORAGE_SECRET_KEY);
    let decryptedData = decipher.update(encryptedData, 'hex', 'utf8');
    decryptedData += decipher.final('utf8');
    return decryptedData;
    
  } catch (error) {
    console.log(error)
    return ''
  }
}



// ---------------------------------------------------------------------------------------------

function doCapitalizeFirstLetter(pText) {
  if (!pText) {
    return ''; 
  }

  const words = pText.split(' ');

  const formattedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  

  return formattedWords.join(' ');
}
// ---------------------------------------------------------------------------------------------

function getIntergrator(){
  let parrentHost= localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
  let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
  gUserData = JSON.parse(gUserData);
  let integrator = gUserData?.integrator[parrentHost] || null
  return integrator
}

// ---------------------------------------------------------------------------------------------
function getGUserData(){
  let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
  gUserData = JSON.parse(gUserData);
  return gUserData
}

// ---------------------------------------------------------------------------------------------
function getRegionCode(){
  let regionCode = null
  regionCode = localStorage.getItem(gConst.STORAGE_SIP_REGION_CODE);
  return regionCode

}
// ---------------------------------------------------------------------------------------------
async function setRegionCode(pTitle ,pMessage , pOptions, pDefaultOption){

  let result = await showCustomSelectConfirm(
    pTitle, 
    pMessage,
    pOptions, 
    pDefaultOption
  );
  

  const codeType = isTextOrNumber(result);

  if(codeType == gConst.TEXT){
    localStorage.setItem(gConst.STORAGE_SIP_REGION_CODE, result)
    localStorage.removeItem(gConst.STORAGE_SIP_HOTLINE_CALLOUT)
    return result
  }

  if(codeType == gConst.NUMBER){
    localStorage.setItem(gConst.STORAGE_SIP_HOTLINE_CALLOUT, result)
    localStorage.removeItem(gConst.STORAGE_SIP_REGION_CODE)
    return result


  }


  if(codeType == gConst.DEFAULT){
    localStorage.setItem(gConst.STORAGE_SIP_REGION_CODE, codeType)
    localStorage.removeItem(gConst.STORAGE_SIP_HOTLINE_CALLOUT)
    return result
  }

  return result

}

// ---------------------------------------------------------------------------------------------
const handleAddSipHeader = (pCalllogSdk) => {
  let regionCode = getRegionCode()
  let integratorId = getIntergrator()?._id
  let gUserData = JSON.parse(localStorage.getItem(gConst.STORAGE_GCALLS_USER));
  let hotlineCallout = localStorage.getItem(gConst.STORAGE_SIP_HOTLINE_CALLOUT)


  // send to tel4
  let sipHeader = {}
  if(regionCode){
    sipHeader.regionCode =  regionCode
  }
  // hotline calllout
  if(hotlineCallout){
    sipHeader.hotlineCallout =  hotlineCallout
  }

  if(integratorId){
    sipHeader.integratorId =  integratorId
  }

  if(gUserData?.email){
    sipHeader.email =  gUserData?.email
  }


  if(gUserData?._id){
    sipHeader.userId =  gUserData?._id
  }


  // console.log(sipHeader,'sipHeader')
  pCalllogSdk?.setSipHeaders(sipHeader)

}

// ---------------------------------------------------------------------------------------------
const  handleChangeRegionCode = async (pHandleRegionCode) => {

    let brandText = await CDN.brandText()

    let defaultValue= null;
    // ---------------------------------------------------------------------
    const regionCode = localStorage.getItem(gConst.STORAGE_SIP_REGION_CODE) 
    const hotlineCallout = localStorage.getItem(gConst.STORAGE_SIP_HOTLINE_CALLOUT) 

    // Check if regionCode is text or number
    const regionCodeType = isTextOrNumber(regionCode);
    const hotlineCalloutType = isTextOrNumber(hotlineCallout);


    if(regionCodeType == gConst.TEXT){
      defaultValue = regionCode
    }

    if(hotlineCalloutType == gConst.NUMBER){
      defaultValue= hotlineCallout
    }

    // Check if hotlineCallout is text or number
    
    // ----------------------------------------------------------------------
    let defaultOption = pHandleRegionCode?.regionCode.find((option) => option.value === defaultValue);
    let selectedOption = await setRegionCode(brandText,pHandleRegionCode?.label || "Label", pHandleRegionCode?.regionCode || [], defaultOption || pHandleRegionCode?.regionCode[0])

    return selectedOption
}

// ---------------------------------------------------------------------------------------------

// input let obj {example :{id: 1 , property_a : value_a} }   findPropertyInNestedStructure(obj,property_a)

function findPropertyInNestedStructure(obj, propertyName) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object") {
        const foundObject = findPropertyInNestedStructure(obj[key], propertyName);
        if (foundObject) {
          return { name: key, value: foundObject.value };
        }
      } else if (key === propertyName) {
        return { name: key, value: obj[key] };
      }
    }
  }
  return undefined; 
}
// --------------------------------------------------------------------------------------------
function isTextOrNumber(value) {
  if (typeof value === 'string') {
    if (!isNaN(parseFloat(value))) {
      return gConst.NUMBER;
    } else {
      return gConst.TEXT;
    }
  } else if (typeof value === 'number' && !isNaN(value)) {
    return gConst.NUMBER;
  } else {
    return gConst.DEFAULT;
  }
}
// ---------------------------------------------------------------------------------------------

function getFieldFromSipMessage(fieldname, sipData) {

  const pattern = new RegExp(`${fieldname}:\\s*([^\n]+)`, 'i');
  const match = sipData.match(pattern);

  if (match) {
    const fieldValue = match[1].trim();
    return fieldValue;
  } else {
    return null;
  }
}


// --------------------------------------------------------------------------------------------
const isCouchDBUAT = () => {

  let gUserData = getGUserData();


  let idCallcenters = null
  let idCallcenter = null
  if(gUserData && gUserData.idCallcenter){
    idCallcenter = gUserData.idCallcenter;
  }

  idCallcenters = process.env[gConst.REACT_APP_CALLCENTERS_UAT];



  if( idCallcenter && idCallcenters?.includes(idCallcenter)){
    return true
  }

  return false

} 
// --------------------------------------------------------------------------------------------

function handleConfig(pConfig) {
  
  let config = pConfig;
  let integratior = getIntergrator();



  // Remove sau khi chuyen sang luongcouchDB thanh cong
  let isUAT = isCouchDBUAT();

  if( isUAT){
    config.databaseConfigurations.isUAT = true; 
    config.databaseConfigurations.host = process.env[gConst.REACT_APP_COUCHDB_UAT_HOST];
    config.databaseConfigurations.password = process.env[gConst.REACT_APP_COUCHDB_UAT_PASSWORD];
    config.databaseConfigurations.username = process.env[gConst.REACT_APP_COUCHDB_UAT_USERNAME];
  }

  
  if(integratior?.info?.modules){
    config.modules = integratior.info.modules
    config.modules.userInfo = getGUserData()
  }

  return config
  
}



// ---------------------------------------------------------------------------------------------
export { 
  callBoxCss,
  doCalculateScaleRatio,
  doValidatePhoneNumber,
  doFormatPhoneNumber,
  doCapitalizeFirstLetter,
  decrypt,
  isCouchDBUAT,
  infoTableCss,
  isMobile,
  isShowInfotableCss,
  isShowKeyPadCss,
  isShowCrmInfoTable,
  isValidJson,
  isTextOrNumber,
  extractNumbers,
  encrypt,
  getCurrentDateTime,
  getIntergrator,
  getGUserData,
  getRegionCode,
  getFieldFromSipMessage,
  setRegionCode,
  handleAddSipHeader,
  handleChangeRegionCode,
  handleConfig,
  findPropertyInNestedStructure
};