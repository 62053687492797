import { join } from 'path';
import { apiIntegration, apiIntegration2, createContact, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from '../../services/gcalls.service';
import { gConst } from '../../lib/gConst';

let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

let agentName = gUserData?.fullName || '';
let dataFunction = gUserData?.integrator[parrentHostname]?.info?.dataFunction;

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function handleTimeBitrix(time) {
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let seconds = addZero(createdAt.getSeconds());
  let month_vi = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let vi_month = month_vi[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  return `${year}-${vi_month}-${date}T${hour}:${minutes}:${seconds}+07:00`;
}

const interaction = 'bitrixselfhost', iframeDatas = {};

export async function getDataIframe(display_name, setIframeData, setName, direction) {
 
  iframeDatas.phone = display_name;
  iframeDatas.domain = parrentHostname
  try {
    await apiIntegration({ filter: { PHONE: display_name } }, interaction, gConst.BITRIX_SEARCH_CONTACT, async (contact, errorContact) => {
      
      //reset data iframe
      iframeDatas.idContact = ""
      iframeDatas.contactName = ""
      iframeDatas.assignedId = ""
      iframeDatas.dataCreate = ""
      iframeDatas.assignedContact = ""
      iframeDatas.dateCreate = ""
      iframeDatas.comment = ""
      iframeDatas.idDeal = ""
      iframeDatas.dealName = ""
      iframeDatas.dealValue = ""
      iframeDatas.dealDateCreate = ""
      iframeDatas.company = ""
      if (!errorContact && contact.success && contact.data.result.length > 0) {
        iframeDatas.idContact = contact.data.result[0].ID;
        iframeDatas.contactName = contact.data.result[0].NAME + " " + contact.data.result[0].LAST_NAME;        
        iframeDatas.assignedId = contact.data.result[0].ASSIGNED_BY_ID;
        iframeDatas.dataCreate = contact.data.result[0].DATE_CREATE;
  
        setIframeData(null);
        setIframeData(iframeDatas);        
        // await apiIntegration({id:contact.data.result[0].ID}, interaction, 'get_contact_by_id', async (contactOne, errorOneContact) => {
        //   if (!errorOneContact && contactOne.data.result.length > 0) {            
        //   }
        // });
        await apiIntegration({id: contact.data.result[0].ASSIGNED_BY_ID}, interaction, gConst.BITRIX_GET_USER_BY_ID, async (user, errorUser) => {          
          if (!errorUser && user.success && user.data.result.length > 0) {
            //error permission -> add permission user for token webhook
            iframeDatas.assignedContact = user.data.result[0].NAME +  " " + user.data.result[0].LAST_NAME
          }
        });
        await apiIntegration({ filter: { ENTITY_ID: contact.data.result[0].ID,  ENTITY_TYPE: "contact"}, order: {CREATED: "DESC"}}, interaction, gConst.BITRIX_SEARCH_COMMENT, async (comment, errorComment) => {
          if (!errorComment && comment.success && comment.data.result.length > 0) {
            iframeDatas.dateCreate = comment.data.result[0].CREATED
            iframeDatas.comment = comment.data.result[0].COMMENT
          }
          setIframeData(null);
          setIframeData(iframeDatas);          
        });
        await apiIntegration({ filter: { CONTACT_ID: contact.data.result[0].ID}}, interaction, gConst.BITRIX_GET_LIST_DEAL, async (deal, errorDeal) => {
          if (!errorDeal && deal.success && deal.data.result.length > 0) {
            iframeDatas.idDeal = deal.data.result[0]?.ID
            iframeDatas.dealName = deal.data.result[0]?.TITLE
            iframeDatas.dealValue = deal.data.result[0]?.OPPORTUNITY
            iframeDatas.dealDateCreate = deal.data.result[0]?.DATE_CREATE
          }
          setIframeData(null);
          setIframeData(iframeDatas);          
        });
        await apiIntegration({id:contact.data.result[0].COMPANY_ID}, interaction, gConst.BITRIX_GET_COMPANY_BY_ID, async (company, errorCompany) => {
          if (!errorCompany && company.success) {
            iframeDatas.company = company.data.result.TITLE;
          }          
          setIframeData(null);
          setIframeData(iframeDatas);          
        });
      }
    })
  
    await apiIntegration({ filter: { PHONE: display_name } }, interaction, gConst.BITRIX_SEARCH_LEAD, async (lead, errorLead) => {
      iframeDatas.idLead = ""
      iframeDatas.lead = ""
      iframeDatas.companyLead = ""
      iframeDatas.assignedLead = ""
      if (!errorLead && lead.success && lead.data.result.length > 0) {
        iframeDatas.idLead = lead.data.result[0].ID
        // iframeDatas.lead = lead.data.result.map((item) => item?.TITLE + ', ');  
        // iframeDatas.companyLead = lead.data.result.map((item) => item?.COMPANY_TITLE === '' ? false : item?.COMPANY_TITLE + ', ');  	
        iframeDatas.lead = lead.data.result[0].TITLE
        iframeDatas.companyLead  = lead.data.result[0].COMPANY_TITLE

        //search company when company title null
        if(!lead.data.result[0].COMPANY_TITLE && !!lead.data.result[0].COMPANY_ID){
          await apiIntegration({id:lead.data.result[0].COMPANY_ID}, interaction, gConst.BITRIX_GET_COMPANY_BY_ID, async (company, errorCompany) => {
            if (!errorCompany && company.success) {
              iframeDatas.companyLead = company.data.result.TITLE;
            }          
            setIframeData(null);
            setIframeData(iframeDatas);
          });
        }

        await apiIntegration({id: lead.data.result[0].ASSIGNED_BY_ID}, interaction, gConst.BITRIX_GET_USER_BY_ID, async (user, errorUser) => {
          if (!errorUser && user.success && user.data.result.length > 0) {
            // if error permission -> add permission user for token webhook
            iframeDatas.assignedLead = user.data.result[0].NAME +  " " + user.data.result[0].LAST_NAME
            setIframeData(null);
            setIframeData(iframeDatas);
          }
        });
      }
    })
    setIframeData(null);
    setIframeData(iframeDatas);    
  
    let gcallsContact = await searchContact(display_name);
    if (!!gcallsContact.length) {
        let name = gcallsContact[0].firstName + " " + gcallsContact[0].lastName;
        setName(name);
        // searchGroup(gcallsContact[0]._id, async function (res, err) {
        //     if (!err) {
        //         console.log(res)
        //     }
        // })
        // await getCalllogs(gcallsContact[0]._id, async function (res, err) {
        //   iframeDatas.latestCall = {}  
        //   if (!err) {
        //         // console.log(res)
        //         iframeDatas.latestCall = res[0]
        //     }
        // })
    }
    //Get Calllog By Phone
    await getCalllogs(display_name, async function (res, err) {
      iframeDatas.latestCall = {}  
      if (!err) {
            // console.log(res)
            iframeDatas.latestCall = res[0]
        }
    })
  
    setIframeData(null)
    setIframeData(iframeDatas)
    
  } catch (error) {
    console.log("error get data iframe")
  }
}


    // setIframeData(iframeDatas);
    // setIframeData(null);
    // setName(iframeDatas.firstname);

    // if (!err) {
    //     // console.log(res)
    //     let list = []
    //     if (!!res.data && !!res.data.contacts)

    //         for (const contact of res.data.contacts) {
    //             const owner_id = contact.properties.hubspot_owner_id ? contact.properties.hubspot_owner_id.value : '';
    //             let owner = {}
    //             if (owner_id) {
    //                 let dataSearch2 = {
    //                     "id": owner_id
    //                 }
    //                 let resp = await apiIntegration2(dataSearch2, gConst.HUBSPOT, 'get_owner');
    //                 owner = resp.data.firstName ? Object.assign({}, {
    //                     firstname: resp.data.firstName,
    //                     lastname: resp.data.lastName,
    //                     email: resp.data.email
    //                 }) : undefined;
    //             }
    //             // console.log(owner)
    //             let item = Object.assign({}, {
    //                 vid: contact.vid,
    //                 firstname: contact.properties.firstname ? contact.properties.firstname.value : '',
    //                 lastname: contact.properties.lastname ? contact.properties.lastname.value : '',
    //                 email: contact.properties.email ? contact.properties.email.value : '',
    //                 phone: contact.properties.phone ? contact.properties.phone.value : '',
    //                 company: contact.properties.company ? contact.properties.company.value : '',
    //                 mobilephone: contact.properties.mobilephone ? contact.properties.mobilephone.value : '',
    //                 lifecyclestage: contact.properties.lifecyclestage ? contact.properties.lifecyclestage.value : '',
    //                 website: contact.properties.website ? contact.properties.website.value : '',
    //                 createdate: contact.properties.createdate ? contact.properties.createdate.value : '',
    //                 owner
    //             });
    //             await list.push(item);
    //         }
    //     // console.log(list)
    //     if (!!list && !!list.length) {
    //         iframeDatas.hubspotContact = list;
    //         setName(list[0].firstname + " " + list[0].lastname)
    //         if (!!localStorage.getItem("auto_copy_hubspot_gcalls")) {
    //             searchContact2(display_name, async function (res, err) {
    //                 if (!err) {
    //                     if (res.length) {
    //                         let contactData = {
    //                             firstName: list[0].firstname,
    //                             lastName: list[0].lastname || '',
    //                             email: list[0].email
    //                         }
    //                         updateContact(res[0]._id, contactData)
    //                     }else {
    //                         let contactData = {
    //                             firstName: list[0].firstname,
    //                             lastName: list[0].lastname || '',
    //                             phone: display_name,
    //                             email: list[0].email,
    //                             gender: 'unknown',
    //                             avatar: ''
    //                         }
    //                         createContact(contactData)
    //                     }
    //                 }
    //             })
    //         }
    //         let getAssociationData = {
    //             objectId: list[0].vid
    //         }
    //         await apiIntegration(getAssociationData, gConst.HUBSPOT, 'get_associations_deals', async function (res, err) {
    //             if (!err) {
    //                 let getDealData = {
    //                     offset: res.data.offset
    //                 }
    //                 await apiIntegration(getDealData, gConst.HUBSPOT, 'get_recent_deals', async function (res, err) {
    //                     if (!err) {
    //                         let dealResponse = res;
    //                         if (dealResponse.success && dealResponse.data) {
    //                             if (dealResponse.data.properties.amount) {
    //                                 let amount = dealResponse.data.properties.amount.value;
    //                                 let value = amount ? numberWithCommas(amount) : '0'
    //                                 iframeDatas.hubspotContact[0].deal = value;
    //                             } else {
    //                                 iframeDatas.hubspotContact[0].deal = '0';
    //                             }
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //         let filterTickets = {
    //             "filters": [
    //                 {
    //                     "propertyName": "associations.contact",
    //                     "operator": "EQ",
    //                     "value": list[0].vid
    //                 }
    //             ]
    //         }
    //         await apiIntegration(filterTickets, gConst.HUBSPOT, 'search_tickets', async function (res, err) {
    //             if (!err) {
    //                 let ticketsResponse = res;
    //                 if (ticketsResponse.success && ticketsResponse.data.results.length > 0) {
    //                     iframeDatas.hubspotTicket = ticketsResponse.data.results;
    //                     iframeDatas.hubspotTicket.forEach(async (e, i) => {
    //                         if (!e.properties.hubspot_owner_name) {
    //                             let idData = {
    //                                 id: e.properties.hubspot_owner_id
    //                             }
    //                             if (idData.id) {
    //                                 await apiIntegration(idData, gConst.HUBSPOT, 'get_owner', async function (res, err) {
    //                                     if (!err) {
    //                                         e.properties.hubspot_owner_name = res.data.firstName + ' ' + res.data.lastName;
    //                                     }
    //                                 })
    //                                 setIframeData(null)
    //                                 setIframeData(iframeDatas)
    //                             }
    //                         }
    //                     })
    //                 }

    //             }
    //         })

    //         setIframeData(null)
    //         setIframeData(iframeDatas)
    //     }
    // }
  // });
  // await apiIntegration({id: item.leadId}, 'bitrixselfhost', 'get_lead_by_id', async function (leads, errorLead) {
  //     if(!errorLead) {
  //         console.log("🚀 ~ file: function.js ~ line 39 ~ leads", leads)

  //     }
  // })

  // let gcallsContact = await searchContact(display_name);
  // if (!!gcallsContact.length) {
  //     // searchGroup(gcallsContact[0]._id, async function (res, err) {
  //     //     if (!err) {
  //     //         console.log(res)
  //     //     }
  //     // })
  //     await getCalllogs(gcallsContact[0]._id, async function (res, err) {
  //         if (!err) {
  //             // console.log(res)
  //             iframeDatas.latestCall = res[0]
  //         }
  //     })
  // }
  // setIframeData(null)
  // setIframeData(iframeDatas)
// }

function handleDuration(time) {
  var minutes = "0" + Math.floor(time / 60);
  var seconds = "0" + (time - minutes * 60);
  return minutes.substr(-2) + ":" + seconds.substr(-2);
}
function handleTimeCreate(time) {
  let today = new Date();
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let month_vi = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let month_en = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let vi_month = month_vi[createdAt.getMonth()];
  let en_month = month_en[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  return date + "/" + vi_month + "/" + year + " - " + hour + ":" + minutes;
}

export async function saveLogCRM(calllog) {
console.log("🚀 ~ file: function.js ~ line 240 ~ saveLogCRM ~ calllog", calllog)

	const calllog_info = { ...calllog };
	const { status, duration, start, direction, recordings_url, playing_recordings_url, phone, end } = calllog_info;

  let record_url = playing_recordings_url //|| recordings_url; //fix only add playing record
  let callDuration = handleDuration(duration);    
  
	const directionCustom = direction === gConst.OUTGOING ? "Outbound" : "Missed";  
	const Subject = `[Gcalls] ${agentName} - ${directionCustom} - ${status} - ${callDuration}`;
	//const descriptionCall = `Details: ${'quan'} - ${directionCustom} - ${status} - ${handleTimeCreate(start)} ${calllog.recordings_url ? " - " + calllog.recordings_url : ""}`;
	const descriptionCall = record_url ? `Link record: ${record_url}` : "";
  
  const directionEnum = direction === gConst.OUTGOING ? 2 : 1;
  
  // type 3: contact, 1: lead
  const typeObjectEnum = iframeDatas.idContact ? 3 : 1
	
	const dataCall = {
		// OWNER_TYPE_ID: 3,
    OWNER_TYPE_ID: typeObjectEnum,
		OWNER_ID: iframeDatas.idContact || iframeDatas.idLead || "",
		TYPE_ID: 2,
		SUBJECT: Subject,
		START_TIME: handleTimeBitrix(start),
		END_TIME: handleTimeBitrix(end),
		COMPLETED: "N",
		PRIORITY: 3,
		RESPONSIBLE_ID: 1,
		DESCRIPTION: descriptionCall,
		DESCRIPTION_TYPE: 3,
		DIRECTION: directionEnum,    
		COMMUNICATIONS: [
			{
				VALUE:  iframeDatas.phone,
				ENTITY_ID: iframeDatas.idContact || iframeDatas.idLead || "",
        // ENTITY_TYPE_ID: 3,
				ENTITY_TYPE_ID: typeObjectEnum,        
			},
		],
	};
	await apiIntegration({ fields: dataCall }, interaction, gConst.BITRIX_CREATE_CALLLOG, async function (res, err) {
    if (!err) {
			console.log("🚀 ~ file: function.js ~ line 300 ~ res", res)
    }
	})
}

export async function createSMSNote(data) {
  console.log(data);
}
