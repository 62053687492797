import { gConst } from "../../lib/gConst";

declare const window: Window &
    typeof globalThis & {
        sforce: any;
    };

export const gimasysDefaultObject: any = {
    account: [
        {
            name: '',
            phone: '',
            website: '',
            owner: '',
            id: '',
        },
    ],
    contact: [
        {
            name: '',
            accountName: '',
            email: '',
            phone: '',
            mobile: '',
            id: '',
            owner: '',
        },
    ],
    cases: [
        {
            caseNumber: '',
            status: '',
            contactName: '',
            contactPhone: '',
            contactMobile: '',
            subject: '',
            description: '',
            owner: '',
            id: '',
        },
    ],
}

export const gimasysDefaultAccount: any = {
    name: '',
    phone: '',
    website: '',
    owner: '',
    id: '',
}

export const gimasysDefaultContact: any = {
    name: '',
    accountName: '',
    email: '',
    phone: '',
    mobile: '',
    id: '',
    owner: '',
}

export const gimasysDefaultCases: any = {
    caseNumber: '',
    status: '',
    contactName: '',
    contactPhone: '',
    contactMobile: '',
    subject: '',
    description: '',
    owner: '',
    id: '',
}

const defaultObject = gimasysDefaultObject;
const defaultAccount = gimasysDefaultAccount;
const defaultContact = gimasysDefaultContact;
const defaultCases = gimasysDefaultCases;

// export async function runApexGimasys(sdt: string, setAccount: any, setCases: any, student: any, setStudent: any, currentObject?: any) {
//     //TODO: get account by phone number
//     console.log('runApexxx', sdt, currentObject);
//     let getAccountParams = "select Id, Name, Phone, Website, OwnerId from Account where Phone like\'%" + sdt + "%\' order by CreatedDate desc";
//     const accountParams: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getAccountParams}`,
//     };
//     accountParams.callback = async function (response: any) {
//         if (response.success) {
//             console.log(JSON.parse(response.returnValue.runApex));
//             const x = JSON.parse(response.returnValue.runApex);
//             const x1: any = [];
//             try {
//                 for (let i: number = 0; i < x.length; i++) {
//                     let value = x[i];
//                     const param: any = {
//                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                         methodName: 'searchUser',
//                         methodParams: `userId=${value.OwnerId}`,
//                         callback: (response: any) => {
//                             if (response.success) {
//                                 const userIf = JSON.parse(response.returnValue.runApex);
//                                 console.log('aaa', response, userIf, userIf[0].Name);
//                                 const x2: any = {
//                                     name: value.Name,
//                                     phone: value.Phone,
//                                     website: value.Website,
//                                     owner: userIf[0].Name,
//                                     id: value.Id,
//                                 };
//                                 if (
//                                     currentObject &&
//                                     value.Id === currentObject.idObject &&
//                                     currentObject.typeObject === gConst.ACCOUNT &&
//                                     i > 0
//                                 ) {
//                                     x1.push(x2);
//                                     let b = x1[0];
//                                     x1[0] = x2;
//                                     let leg = x1.length - 1;
//                                     x1[leg] = b;
//                                 } else {
//                                     x1.push(x2);
//                                 }
//                                 if (x1.length > 0) {
//                                     setAccount(x1);
//                                 } else {
//                                     setAccount([
//                                         defaultAccount
//                                     ]);
//                                 }
//                             }
//                         },
//                     };
//                     await salesforce.opencti.runApex(param);
//                 }
//                 if (x.length === 0) {
//                     setAccount([
//                         defaultAccount
//                     ]);
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setAccount([
//                     defaultAccount
//                 ]);
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setAccount([
//                 defaultAccount
//             ]);
//         }
//     };
//     await salesforce.opencti.runApex(accountParams);
//     // TODO: get contact by phone number
//     let getContactParams = "select Id, Name, AccountId, Email, Phone, MobilePhone, LastActivityDate, OwnerId from Contact where Phone like\'%" + sdt + "%\' order by CreatedDate desc";
//     const contactParams: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getContactParams}`,
//     };
//     contactParams.callback = async function (response: any) {
//         if (response.success) {
//             const x = JSON.parse(response.returnValue.runApex);
//             console.log(x);
//             const x1: any[] = [];
//             try {
//                 x.forEach(function (value: any, i: any) {
//                     const x2: any = {
//                         name: value.Name,
//                         accountName: value.AccountId,
//                         email: value.Email,
//                         phone: value.Phone,
//                         mobile: value.MobilePhone,
//                         id: value.Id,
//                         owner: value.OwnerId,
//                     };
//                     if (
//                         currentObject &&
//                         value.Id === currentObject.idObject &&
//                         currentObject.typeObject === gConst.CONTACT &&
//                         i > 0
//                     ) {
//                         x1.push(x2);
//                         let b = x1[0];
//                         x1[0] = x2;
//                         let leg = x1.length - 1;
//                         x1[leg] = b;
//                     } else {
//                         x1.push(x2);
//                     }
//                 });
//                 if (x1.length > 0) {
//                     const param: any = {
//                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                         methodName: 'searchUser',
//                         methodParams: `userId=${x1[0].owner}`,
//                         callback: (response: any) => {
//                             if (response.success) {
//                                 const userIf = JSON.parse(response.returnValue.runApex);
//                                 x1[0].owner = userIf[0].Name;
//                             }
//                             let getAccountByIdParams = "select Name from Account where Id = \'" + x1[0].accountName + "\'";
//                             const param2: any = {
//                                 apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                                 methodName: 'getObjectData',
//                                 methodParams: `Params=${getAccountByIdParams}`,
//                                 callback: (response: any) => {
//                                     if (response.success) {
//                                         const accountIf = JSON.parse(response.returnValue.runApex);
//                                         x1[0].accountName = accountIf[0].Name;
//                                     }
//                                     setStudent(x1);
//                                 },
//                             };
//                             salesforce.opencti.runApex(param2);
//                         },
//                     };
//                     await salesforce.opencti.runApex(param);
//                     // TODO: get case by contact id
//                     let getCaseParams = "select Id, CaseNumber, Status, ContactId, ContactPhone, ContactMobile, Subject, Description, OwnerId from Case where ContactId = \'" + x1[0].id + "\' order by CreatedDate desc";
//                     const caseParams: any = {
//                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                         methodName: 'getObjectData',
//                         methodParams: `Params=${getCaseParams}`,
//                     };
//                     caseParams.callback = async function (response: any) {
//                         if (response.success) {
//                             const x = JSON.parse(response.returnValue.runApex);
//                             console.log(x);
//                             const x1: any = [];
//                             try {
//                                 x.forEach(function (value: any, i: any) {
//                                     const x2: any = {
//                                         caseNumber: value.CaseNumber,
//                                         status: value.Status,
//                                         contactName: value.ContactId,
//                                         contactPhone: value.ContactPhone,
//                                         contactMobile: value.ContactMobile,
//                                         subject: value.Subject,
//                                         description: value.Description,
//                                         id: value.Id,
//                                         owner: value.OwnerId,
//                                     };
//                                     if (
//                                         currentObject &&
//                                         value.Id === currentObject.idObject &&
//                                         currentObject.typeObject === gConst.CONTACT &&
//                                         i > 0
//                                     ) {
//                                         x1.push(x2);
//                                         let b = x1[0];
//                                         x1[0] = x2;
//                                         let leg = x1.length - 1;
//                                         x1[leg] = b;
//                                     } else {
//                                         x1.push(x2);
//                                     }
//                                 });
//                                 if (x1.length > 0) {
//                                     let getContactByIdParams = "select Name from Contact where Id = \'" + x1[0].contactName + "\'";
//                                     const param: any = {
//                                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                                         methodName: 'getObjectData',
//                                         methodParams: `Params=${getContactByIdParams}`,
//                                         callback: (response: any) => {
//                                             if (response.success) {
//                                                 const contactIf = JSON.parse(response.returnValue.runApex);
//                                                 x1[0].contactName = contactIf[0].Name;
//                                             }
//                                             console.log('set case', x1)
//                                             setCases(x1);
//                                         },
//                                     };
//                                     await salesforce.opencti.runApex(param);
//                                 } else {
//                                     setCases([
//                                         defaultCases
//                                     ]);
//                                 }
//                             } catch (error) {
//                                 console.log(error);
//                                 setCases([
//                                     defaultCases
//                                 ]);
//                             }
//                         } else {
//                             console.error('Something went wrong! Errors:', response);
//                             setCases([
//                                 defaultCases
//                             ]);
//                         }
//                     };
//                     await salesforce.opencti.runApex(caseParams);
//                 } else {
//                     setStudent([
//                         defaultContact
//                     ]);
//                     setCases([
//                         defaultCases
//                     ]);
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setStudent([
//                     defaultContact
//                 ]);
//                 setCases([
//                     defaultCases
//                 ]);
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setStudent([
//                 defaultContact
//             ]);
//             setCases([
//                 defaultCases
//             ]);
//         }
//     };
//     await salesforce.opencti.runApex(contactParams);
// }

export async function setIframeGimasys(setIframeData: any, userInfo: any, objectData: any, currentObject: any) {
    let a: any = defaultObject;
    a.account = [objectData.account[0]];
    a.contact = [objectData.contact[0]];
    a.cases = objectData.cases;
    setIframeData(a);
}

export async function setIdGimasys(currentObject: any, number: any, logData: any, objectData: any, userInfo: any) {
    if (
        currentObject &&
        currentObject.idObject &&
        (currentObject.typeObject === gConst.CONTACT ||
            currentObject.typeObject === gConst.CASE ||
            currentObject.typeObject === gConst.ACCOUNT) &&
        number === currentObject.number
    ) {
        if (currentObject.typeObject === gConst.CONTACT) {
            logData.whoId = currentObject.idObject;
            if (
                currentObject.typeObject === gConst.CONTACT &&
                objectData.account.length > 0 &&
                objectData.account[0].id
            ) {
                logData.whatId = objectData.account[0].id;
            } else if (
                currentObject.typeObject === gConst.CONTACT &&
                objectData.cases.length > 0 &&
                objectData.cases[0].id
            ) {
                logData.whatId = objectData.cases[0].id;
            }
        } else {
            logData.whatId = currentObject.idObject;
            if (
                currentObject.typeObject === gConst.ACCOUNT &&
                objectData.contact.length > 0 &&
                objectData.contact[0].id
            ) {
                for (let i: number = 0; i < objectData.contact.length; i++) {
                    if (objectData.contact[i].owner === userInfo.OwnerId) {
                        logData.whoId = objectData.contact[i].id;
                        break;
                    }
                }
            }
        }
    } else {
        if (objectData.contact.length > 0 && objectData.contact[0].id) {
            logData.whoId = objectData.contact[0].id;
        }
        if (objectData.cases.length > 0 && objectData.cases[0].id) {
            logData.whatId = objectData.cases[0].id;
        }
        if (objectData.account.length > 0 && objectData.account[0].id) {
            logData.whatId = objectData.account[0].id;
        }
    }

    return logData;
}

export async function setNameGimasys(currentObject: any, number: any, setName: any, userInfo: any, account: any, cases: any, student: any) {
    if (currentObject && currentObject.name && number === currentObject.number) {
        setName(currentObject.name);
    } else {
        if (student.length > 0 && student[0].id) {
            setName(student[0].name);
        }
        if (cases.length > 0 && cases[0].id) {
            setName(cases[0].contactName);
        }
        if (account.length > 0 && account[0].id) {
            setName(account[0].name);
        }
    }
}

export async function setObjectDataGimasys(setObjectData: any, account: any, cases: any, student: any) {
    setObjectData({
        account:
            account !== undefined
                ? account
                : [
                    defaultAccount
                ],
        contact:
            student !== undefined
                ? student
                : [
                    defaultContact
                ],
        cases:
            cases !== undefined
                ? cases
                : [
                    defaultCases
                ],
    });
}