import React, { useState } from 'react';
import { ReactComponent as Delete } from '../../../icons/delete.svg';
import {ReactComponent as Close} from '../../../icons/close.svg';
type props = {
  phone: any;
  onCloseClick: any;
};
function TransferCall({ phone, onCloseClick }: props) {
  const buttonNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  const [number, setNumber] = useState<string>('');
  const onSubmit123 = () => {
    phone(number);
  };
  const onChange = (event: any) => {
    const value = event.target.value;
    setNumber(number + value);
  };
  const handleDelete = () => {
    setNumber(number.slice(0, number.length - 1));
  };
  const handleChangeNumberPhone = (event: any) => {
    setNumber(event.target.value);
  };
  return (
    <div
      id="transfer"
      className="w-11/12 h-5/6 bg-purple-200 absolute transform left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 z-50 rounded-3xl p-8"
    >
        <button id="close" className="w-12 h-12 rounded-full flex justify-center items-center block ml-auto hover:bg-gray-200 active:bg-gray-500" onClick={()=>{onCloseClick()}}>
            <Close className="w-8 h-8" />
        </button>
      <div className="h-16 w-11/12 bg-transparent flex justify-around items-center px-2 mx-auto mb-20 border-b border-black">
        <input
          type="text"
          className="h-12 w-10/12 text-3xl p-2 bg-transparent focus:outline-none text-center"
          value={number}
        onChange={handleChangeNumberPhone}
        />

        <button className="text-lg text-blue-200 mr-4" onClick={handleDelete}>
          <Delete width="20" height="20" />
        </button>
      </div>
      <div className="w-11/12 mx-auto flex justify-center items-center flex-wrap">
        {buttonNumber.map((item, index) => {
          return (
            <input
              key={index}
              readOnly
              className="w-3/12 h-full p-2 bg-white bg-transparent hover:bg-gray-800 opacity-75 pointer my-2 mx-2 text-3xl rounded-full text-center cursor-pointer select-none focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
              onClick={onChange}
              value={item}
            />
          );
        })}
      </div>
      <input
        onClick={onSubmit123}
        type="submit"
        value="Forward"
        className=" cursor-pointer text-3xl p-2 rounded-2xl block w-40 mx-auto my-10 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
        readOnly
      />
    </div>
  );
}

export default TransferCall;
