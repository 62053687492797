const translationsVn = {
    Ended: "Kết thúc cuộc gọi",
    Canceled: "Kết thúc cuộc gọi",
    Calling: "Đang gọi điện",
    Confirm: "Xác nhận",
    Busy: "Máy bận",
    busy: "Máy bận",
    Unavailable: "Không liên lạc được",
    Rejected: "Từ chối cuộc gọi",
    rejected: "Từ chối cuộc gọi",
    Missed: "Cuộc gọi nhỡ",
    missed: "Cuộc gọi nhỡ",
};

export default translationsVn;