import axios from 'axios';
import { gdebug } from '../../../lib/gdebug';
import { apiIntegration, apiIntegration2, createContact, createSMSLogs, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from '../../../services/gcalls.service';
import { calcTime, formatDate, handleDuration, handleStartTimeZoho, handleTimeCreate, timeout } from '../../../services/sharedFunction';
import { gConst } from '../../../lib/gConst';
const salesforce = window.sforce;

let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';
let parrentUrl = localStorage.getItem(gConst.STORAGE_PARRENT_URL) || '';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

let integrator = gUserData?.integrator[parrentHostname];

let agentName = gUserData?.fullName || '';
let dataFunction = gUserData?.integrator[parrentHostname]?.info?.dataFunction;
let logFunction = gUserData?.integrator[parrentHostname]?.info?.logFunction;
let preLogFunction = gUserData?.integrator[parrentHostname]?.info?.preLogFunction;
let mode = gUserData?.integrator[parrentHostname]?.info?.mode;
mode = "1";

export async function getDataIframe(currentObject, setIframeData, setName, direction) {
  let iframeDatas = {};
  iframeDatas.objectType = currentObject.typeObject
  console.log("currentObject", currentObject)
  if(typeof currentObject === 'object'){
    iframeDatas.phone = currentObject?.number;
  }else {
    iframeDatas.phone = currentObject;
  }
  iframeDatas.isEmpty = true;
  // dataFunction = [
  //     {
  //       "objectName":gConst.APPLICATION,
  //       "endpointName":"getObjectData",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          },
  //          {
  //             "Applicant_Full_Name__c":"[0].Applicant_Full_Name__c"
  //          },
  //          {
  //             "Mobile__c":"[0].Mobile__c"
  //          },
  //          {
  //             "Application_Status__c":"[0].Application_Status__c"
  //          },
  //          {
  //             "F1st_Choice__c":"[0].F1st_Choice__c"
  //          },
  //          {
  //             "High_School_Name__c":"[0].High_School_Name__c"
  //          },
  //          {
  //             "Id":"[0].Id"
  //          },
  //          {
  //             "OwnerId":"[0].OwnerId"
  //          },
  //          {
  //             "CreatedDate":"[0].CreatedDate"
  //          }
  //       ],
  //       "data":"Params=select Name,Applicant_Full_Name__c,Mobile__c,Application_Status__c,F1st_Choice__c,High_School_Name__c,Id,OwnerId,CreatedDate from Application__c where Mobile__c like'%:phone%' order by CreatedDate desc",
  //       "replaceData":[
  //          {
  //             ":phone":"iframeDatas?.phone"
  //          }
  //       ],
  //     },
  //     {
  //       "objectName":gConst.LEAD,
  //       "endpointName":"getObjectData",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          },
  //          {
  //             "Status":"[0].Status"
  //          },
  //          {
  //             "MobilePhone":"[0].MobilePhone"
  //          },
  //          {
  //             "LeadSource":"[0].LeadSource"
  //          },
  //          {
  //             "OwnerId":"[0].OwnerId"
  //          },
  //          {
  //             "Id":"[0].Id"
  //          }
  //       ],
  //       "data":"Params=select Name, Status, MobilePhone, LeadSource, OwnerId, Id from Lead where MobilePhone like'%:phone%' order by CreatedDate desc",
  //       "replaceData":[
  //          {
  //             ":phone":"iframeDatas?.phone"
  //          }
  //       ],
  //     },
  //     {
  //       "objectName":gConst.CONTACT,
  //       "endpointName":"getObjectData",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          },
  //          {
  //             "Email":"[0].Email"
  //          },
  //          {
  //             "MobilePhone":"[0].MobilePhone"
  //          },
  //          {
  //             "OwnerId":"[0].OwnerId"
  //          },
  //          {
  //             "Id":"[0].Id"
  //          }
  //       ],
  //       "data":"Params=select Name, MobilePhone, Email, OwnerId, Id from Contact where MobilePhone like'%:phone%' order by CreatedDate desc",
  //       "replaceData":[
  //          {
  //             ":phone":"iframeDatas?.phone"
  //          }
  //       ],
  //       "delay":2000,
  //     },
  //     {
  //       "objectName":gConst.CASE,
  //       "endpointName":"getObjectData",
  //       "arrayResponse":"none",
  //       "responseData":[
  //          {
  //             "CaseNumber":"CaseNumber"
  //          },
  //          {
  //             "Subject":"Subject"
  //          },
  //          {
  //             "Status":"Status"
  //          },
  //          {
  //             "Priority":"Priority"
  //          },
  //          {
  //             "OwnerId":"OwnerId"
  //          },
  //          {
  //             "Id":"Id"
  //          }
  //       ],
  //       "data":"Params=select CaseNumber, Subject, Status, Priority, Id, OwnerId from Case where ContactId = ':ctID' order by CreatedDate desc",
  //       "replaceData":[
  //          {
  //             ":ctID":"iframeDatas?.Contact[0].Id"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"ApplicationUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Application[0].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"LeadUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Lead[0].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"ContactUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"",
  //       "responseData":[
  //          {
  //             "Name":"[0].Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Contact[0].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"CaseUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"none",
  //       "responseData":[
  //          {
  //             "Name":"Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Case[0].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"CaseUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"none",
  //       "responseData":[
  //          {
  //             "Name":"Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Case[1].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     },
  //     {
  //       "objectName":"CaseUser",
  //       "endpointName":"searchUser",
  //       "arrayResponse":"none",
  //       "responseData":[
  //          {
  //             "Name":"Name"
  //          }
  //       ],
  //       "data":"userId=:OwnerId",
  //       "replaceData":[
  //          {
  //             ":OwnerId":"iframeDatas?.Case[2].OwnerId"
  //          }
  //       ],
  //       "delay":1000,
  //     }
  // ]
  try {
    if (dataFunction && dataFunction.length) {
      let dFLength = dataFunction.length;
      let i = 0;
      (async () => {
        while (i < dFLength) {
          let e = dataFunction[i];
          let dataSearch = e?.data || '';
          if (!!dataSearch) {
            dataSearch = JSON.stringify(dataSearch);

            let replaceData = e.replaceData;

            if (!!replaceData && !!replaceData.length) {
              for (let i = 0; i < replaceData.length; i++) {
                try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                  dataSearch = dataSearch.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              }
            }
            dataSearch = JSON.parse(dataSearch);
          }

          let objectName = e.objectName;
          let templateName = e.templateName;
          let endpointName = e.endpointName;
          let arrayResponse = e.arrayResponse;
          let responseData = e.responseData;
          let delay = e.delay || 0;

          const param = {
            apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
            methodName: endpointName,
            methodParams: dataSearch,
          };
          param.callback = async function (response) {
            if (response.success && response.returnValue) {
                if (!!arrayResponse) {
                  try {
                      let data = JSON.parse(response.returnValue.runApex);
                    if (arrayResponse === 'none') {
                      arrayResponse = 'data';
                    } else {
                      arrayResponse = 'data?.' + arrayResponse;
                    }
                    eval(arrayResponse).forEach((item, i) => {
                      if (responseData) {
                        let ObjectItem = {};
                        if (Array.isArray(responseData) && responseData.length) {
                        //   let ObjectItem1 = {};
                          for (const item3 of responseData) {
                            let key = Object.keys(item3)[0];
                            let value = item3[key];
                            value = 'item?.' + value;

                            ObjectItem[key] = eval(value);
                          }
                        //   ObjectItem.push(ObjectItem1);
                        }
                        if (i == 0 && !iframeDatas[objectName]) {
                          iframeDatas[objectName] = [];
                          iframeDatas[objectName].push(ObjectItem);
                        } else {
                          iframeDatas[objectName].push(ObjectItem);
                        }
                      } else {
                        if (i == 0 && !iframeDatas[objectName]) {
                          iframeDatas[objectName] = [];
                          iframeDatas[objectName].push(item);
                        } else {
                          iframeDatas[objectName].push(item);
                        }
                      }
                      if (iframeDatas[objectName]?.length) {
                        iframeDatas.isEmpty = false;
                      }
                      setIframeData(null);
                      setIframeData(iframeDatas);
                    });
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                } else {
                  try {
                    let item = JSON.parse(response.returnValue.runApex);
                    if (responseData) {
                      let ObjectItem = {};
                      if (Array.isArray(responseData) && responseData.length) {
                        // let ObjectItem1 = {};
                        for (const item3 of responseData) {
                          let key = Object.keys(item3)[0];
                          let value = item3[key];
                          value = 'item?.' + value;

                          ObjectItem[key] = eval(value);
                        }
                        // ObjectItem.push(ObjectItem1);
                      }
                      if(!iframeDatas[objectName]){
                        iframeDatas[objectName] = [];
                      }
                      iframeDatas[objectName].push(ObjectItem);
                    } else {
                      if(!iframeDatas[objectName]){
                        iframeDatas[objectName] = [];
                      }
                      iframeDatas[objectName].push(item);
                    }
                    if (iframeDatas[objectName]?.length) {
                      iframeDatas.isEmpty = false;
                    }
                    setIframeData(null);
                    setIframeData(iframeDatas);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                }
            }
          };

          await salesforce.opencti.runApex(param);
          i++;
          // Set Name
          try {
            if (typeof currentObject !== 'object') {
              if (iframeDatas?.Investor?.length > 0) {
                let name = iframeDatas?.Investor[0]?.Name || '';
                setName(name);
              } else {
                if (iframeDatas?.Lead?.length > 0) {
                  let name = iframeDatas?.Lead[0]?.Name || '';
                  setName(name);
                } else if (iframeDatas?.RM?.length > 0) {
                  let name = iframeDatas?.RM[0]?.Name || '';
                  setName(name);
                } else if (iframeDatas?.Team?.length > 0) {
                  let name = iframeDatas?.Team[0]?.Name || '';
                  setName(name);
                }
              }
            }
          } catch (error) {
            gdebug(1,"Error: ", error);
          }
          await timeout(delay);
        }
      })();
    }
  } catch (error) {
    gdebug(1,"Error: ", error);
  }

    // let gcallsContact = await searchContact(currentObject?.number);
    // if (!!gcallsContact.length) {
    //   let name = gcallsContact[0].firstName + ' ' + gcallsContact[0].lastName;
    //   setName(name);
    //   await getCalllogs(gcallsContact[0]._id, async function (res, err) {
    //     if (!err) {
    //       // console.log(res)
    //       iframeDatas.latestCall = res[0];
    //     }
    //   });
    // }
  gdebug(3,"iframeDatas ",iframeDatas)
  
  setIframeData(null);
  setIframeData(iframeDatas);
}

export async function saveLogCRM(calllog, iframeData) {
  gdebug(3,"Calllog ",calllog,iframeData)
  const handleTimeCreate2 = handleTimeCreate;
  const handleDuration2 = handleDuration;
  const formatDate2 = formatDate;

  const calllog_info = { ...calllog };
  const { status, duration, start, direction, recordings_url, playing_recordings_url, phone, end } = calllog_info;

  let record_url = playing_recordings_url || recordings_url;
  let callDuration = handleDuration(duration);
  let startTimeZoho = handleStartTimeZoho(start);
  let se_module = '$se_module';
  let callStatus = status === 'connected' || status === 'success' ? 'Answered' : 'Unanswered';
  let callStatusText = callStatus === 'Answered' ? 'successful' : 'missed';
  let empty = '';
  let start1 = new Date(start);
  let end1 = new Date(end);

  const directionCustom = direction === gConst.OUTGOING ? 'Outbound' : 'Inbound';
  const subjectTitle = direction === gConst.OUTGOING ? 'Call to ' + phone : 'Call from ' + phone;
  const subjectTitle2 = `[Gcalls] ${agentName} - ${directionCustom} - ${status} - Time: ${handleTimeCreate(start, false)}`;
  const phoneTitle = direction === gConst.OUTGOING ? 'made a phone call to ' + phone : 'received a call from ' + phone;
  const TextContent = `[${agentName}] ${phoneTitle} - Time: ${handleTimeCreate(start, false)} - Direction: ${directionCustom} - Status: ${status} - Duration: ${handleDuration(duration)} - Record: ${record_url}`;
  const HtmlContent = `<b>[${agentName}] ${phoneTitle}</b><br>Time: ${handleTimeCreate(start, false)} <br>Direction: ${directionCustom} <br>Status : ${status} <br>Duration : ${handleDuration(duration)} <br>Record : <a href=${record_url}>${record_url}</a>`;

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  // logFunction = [
  //   {
  //      "data":{
  //         "CallDurationInSeconds":":callDuration",
  //         "CallDisposition":":callStatus",
  //         "Gcalls_Call_Type__c":":directionCustom",
  //         "Gcalls_Customer_Phone__c":":phone",
  //         "Gcalls_URL_Recorder__c":":recordings_url",
  //         "Gcalls_Call_Flowid__c":":flowid",
  //         "Gcalls_ActivityId__c":":call_id",
  //         "Gcalls_Call_Duration__c":":callDuration",
  //         "Gcalls_Call_Status__c":":callStatus",
  //         "Subject":"[Gcalls] :OwnerName (:GcallsExtension) has a :statusText :directionCustom call at :Time",
  //         "Type":"Call",
  //         "Status":"Completed",
  //         "OwnerId":":OwnerId",
  //         "ActivityDate":":ActivityDate",
  //         "WhoId":"",
  //         "WhatId":""
  //      },
  //      "replaceData":[
  //         {
  //            ":callDuration":"callDuration"
  //         },
  //         {
  //            ":callStatus":"callStatus"
  //         },
  //         {
  //            ":directionCustom":"directionCustom"
  //         },
  //         {
  //            ":phone":"phone"
  //         },
  //         {
  //            ":recordings_url":"record_url"
  //         },
  //         {
  //            ":flowid":"checkValue('eval','ie','calllog_info?.flowid','calllog_info?.flowid','empty')"
  //         },
  //         {
  //            ":call_id":"checkValue('eval','ie','calllog_info?.call_id','calllog_info?.call_id','empty')"
  //         },
  //         {
  //            ":OwnerName":"checkValue('eval','ie','iframeData?.userInfo?.OwnerName','iframeData?.userInfo?.OwnerName','empty')"
  //         },
  //         {
  //            ":OwnerId":"checkValue('eval','ie','iframeData?.userInfo?.OwnerId','iframeData?.userInfo?.OwnerId','empty')"
  //         },
  //         {
  //            ":GcallsExtension":"checkValue('eval','ie','iframeData?.userInfo?.GcallsExtension','iframeData?.userInfo?.GcallsExtension','empty')"
  //         },
  //         {
  //            ":statusText":"callStatusText"
  //         },
  //         {
  //            ":Time":"handleTimeCreate2(calllog_info?.start, true)"
  //         },
  //         {
  //            ":ActivityDate":"formatDate2(new Date(start))"
  //         }
  //      ]
  //   }
  // ];

  if (logFunction && logFunction.length) {
    try {
      logFunction.forEach(async (e, i) => {
        setTimeout(async () => {
          let data = JSON.stringify(e.data);
          let replaceData = e.replaceData;

          if (!!replaceData && !!replaceData.length) {
            for (let i = 0; i < replaceData.length; i++) {
              try {
                let key = Object.keys(replaceData[i])[0];
                let value = replaceData[i][key];
                let replaceVal = '';
                try {
                  replaceVal = eval(value);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
                data = data.replaceAll(key, replaceVal);
              } catch (error) {
                gdebug(1,"Error: ", error);
              }
            }
          }
          data = JSON.parse(data);
          data = {...data,
            Gcalls_End_Time__c:new Date(end),
            Gcalls_Start_Time__c:new Date(start),
          }

          let templateName = e.templateName;
          let endpointName = e.endpointName;
          gdebug(3,"Create task ",data)

          const saveLogCallback = (response) => {
            if (response.success) {
              const { returnValue } = response;
              const { recordId } = returnValue;
              gdebug(3,"Save log data: ",response)
              //   let isOpenTask = window.localStorage.getItem(gConst.STORAGE_OPEN_TASK);;
              //   console.log('isOpenTask', isOpenTask);
              //   if (isOpenTask === 'true') {
              // window.sforce.opencti.screenPop({
              //   type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT,
              //   params: { recordId },
              //   callback: (res) => console.log(res),
              // });
              //   }
            } else {
              gdebug(1,"Save log error: ", response)
            }
          };

          let value = {
            entityApiName: gConst.TASK,
            ...data,
          };

          value = await setId(iframeData.currentObject,value,iframeData)
          gdebug(3,"vl: ",value)
          window.sforce.opencti.saveLog({ value, callback: saveLogCallback });
        });
      });
    } catch (error) {
      gdebug(1,"Error: ", error);
    }
  }
}

export async function createSMSNote(data) {
  gdebug(3,"createSMSNote: ",data)
  let { number, valueNote, OwnerName, OwnerId, origin_phone, status_sms, currentObject, objectData } = data;
  let phonenumber = number;
  let idContact = '';
  let creator = localStorage.getItem(gConst.STORAGE_GCALLS_ID_AGENT);
  let idCallcenter = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER);
  let contact = await searchContact(phonenumber);

  if (contact.length) {
    idContact = contact[contact.length - 1]._id;
  } else {
    let contactData = {
      firstName: phonenumber,
      lastName: '',
      phone: phonenumber,
      email: '',
      gender: 'unknown',
      avatar: ''
    }
    let newcontact = await createContact(contactData);

    if (newcontact) {
      idContact = newcontact._id;
    }
  }

  const TextArray = valueNote.split('\n');
  let title = TextArray[0].replace('Trạng thái:', '').trim();
  let content = TextArray[1].replace('Nội dung SMS:', '').trim();
  let time = calcTime(Date.now(), '+7');
  let minuate = time.getMinutes();
  if (minuate < 10) {
    minuate = '0' + minuate;
  }

  let SubjectError = `[Gcalls] ${OwnerName} có lỗi ${title} - vào lúc ${time.getHours() + ':' + minuate}`;
  let SubjectSuccess = `[Gcalls] ${OwnerName} Gửi tin nhắn thành công - vào lúc ${time.getHours() + ':' + minuate}`;
  let Subject = status_sms === true ? SubjectSuccess : SubjectError;
  let logTask = {
    Subject,
    OwnerId,
    Status: 'Completed',
    ActivityDate: formatDate(new Date()),
    Type: gConst.SMS,
    Gcalls_Customer_Phone__c: origin_phone,
    Gcalls_SMS_Content__c: content,
  };
  logTask = await setId(currentObject, logTask, objectData);
  gdebug(3,"logTask: ",logTask)
  let logData = {
    text: valueNote,
    type: gConst.NOTE,
    body: null,
    creator,
    isSMS: true,
    idContact,
    phone: origin_phone,
  };

  let saveSMSCallback = (response) => {
    if (response.success) {
      let { returnValue } = response;
      let { recordId } = returnValue;
      gdebug(3,"Save log data: ",logData,response)
      logTask.idTask = recordId;
      //   let isOpenTask = window.localStorage.getItem(gConst.STORAGE_OPEN_TASK);;
      //   console.log('isOpenTask', isOpenTask);
      //   if (isOpenTask === 'true') {
      window.sforce.opencti.screenPop({
        type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT,
        params: { recordId },
        callback: (res) => {
          gdebug(3,"screenPop: ",res)
        },
      });
      //   }
    } else {
      gdebug(1,"Save log error: ", response)
    }
  };
  let value = {
    entityApiName: gConst.TASK,
    ...logTask,
  };
  gdebug(3,"vl: ", value)
  window.sforce.opencti.saveLog({ value, callback: saveSMSCallback });
  createSMSLogs(logData)
}

export async function savePreLogCRM(calllog, iframeData, setIframeData) {}

export async function setId(currentObject, logData, objectData) {
  let ListObject = [
    gConst.ACCOUNT, //Account
    gConst.LEAD, //Lead
    gConst.RM__c, //RM__c
    gConst.TEAM__C //Team__c
  ]
  console.log("objectData in setId", objectData)
  if (typeof currentObject === 'object' && ListObject.includes(currentObject?.typeObject)) {
    console.log("first if")
    if (currentObject?.typeObject === gConst.LEAD || currentObject?.typeObject === gConst.TEAM__C) {
      logData.WhoId = currentObject?.idObject;
      logData.WhatId = '';
    } else {
        logData.WhatId = currentObject?.idObject;
        logData.WhoId = '';
      }
  } else {
    console.log("second if")
    if (objectData?.Investor[0]?.Id) {
      logData.WhatId = objectData?.Investor[0]?.Id;
      logData.WhoId = '';
      console.log("setId at objectData")
    } else {
      if (objectData?.Lead[0]?.Id) {
        logData.WhoId = objectData?.Lead[0]?.Id;
        logData.WhatId = '';
        console.log("setId at Lead")
      } else if (objectData?.RM[0]?.Id) {
        logData.WhatId = objectData?.RM[0]?.Id;
        logData.WhoId = '';
        console.log("setId at RM")
      } else if (objectData?.Team[0]?.Id > 0) {
        logData.WhoId = objectData?.Team[0]?.Id;
        logData.WhatId = '';
        console.log("setId at Team")
      }
    }
    
  }
  console.log("logData", logData)
  return logData;
}