// Integration type
const INTEGRATION_TYPE = {
    HUBSPOT : 'hubspot',
    SALESFORCE : 'salesforce',
    NHANH_V1 : 'nhanhv1',
    DEFAULT : 'default',
    SAPO : 'sapo',
    BITRIX_SELF_HOST: 'bitrixselfhost',
};

const OS_TYPE = {
    ANDROID : 'android',
    WEB : 'web',
    IOS : 'ios',

};

// SMS
const SMS = {
    SMS_VIET_GUY : 'vietguy',
    SMS_FPT : 'fpt',
    SMS_NEXMO : 'nexmo',
    SMS_IRIS : 'iris',
    SMS_ESMS : 'esms',
    SMS_GAPIT: 'gapit',
};


// env
const ENV  = {
    REACT_APP_GCALLS_MOBILE : 'REACT_APP_GCALLS_MOBILE',
    REACT_APP_GCALLS_WEBHOOK_URL : 'REACT_APP_GCALLS_WEBHOOK_URL',
    REACT_APP_SF_USERNAME : 'REACT_APP_SF_USERNAME'
};


// action type
const CALL_STATUS = {
    ANSWERED : 'Answered',
    SUCCESSFUL : 'successful',
    MISSED : 'missed',
    SUCCESS : 'success',
    CONNECTED : 'connected',
    UNANSWERED : 'Unanswered',
    CALLING : 'Calling',
    FORWARD : 'Forward...'
};

const CSS = {
    RINGRING : 'ringing',
    AUDIO : 'audio',
    CSS_INFO_CONTACT : 'infoContact',
    CSS_BLOCK : 'BLOCK',
    CSS_NONE : 'NONE',
    CSS_INFO_TABLE_CONTAINER : 'infoTbContainer'
}



const CALLBOX_SOCKET = {
    SOCKET_HIDE_CALLBOX : 'hideCallbox',
    SOCKET_INCOMING_CALL : 'incomingCall',
    SOCKET_CHANGE_WIDTH_CALLBOX : 'changeWidthCallbox',
    SOCKET_SET_CALLBOX_STYLE : 'setCallboxStyle',
    SOCKET_CHANGE_SERVER : 'changeServer',
    SOCKET_GET_SESSION : 'getSession',

    SOCKET_WEBSOCKET : 'websocket',
    SOCKET_CONNECT : 'connect',
    SOCKET_DISCONNECT : 'disconnect',
    SOCKET_GET_INFO : 'getInfo',
    SOCKET_CLICK_TO_CALL : 'clickToCall',
    SOCKET_SHOW_SMS : 'showSMS',
    SOCKET_AGENT_UPDATE : 'agent-updated',
    SOCKET_GET_CURRENT_USER : 'getCurrentUser',
    SOCKET_CHANGE_CALLBOX : 'changeCallbox',
    SOCKET_SESSION : 'session',
    SOCKET_MESSAGE : 'message',
    SOCKET_STORAGE : 'storage',
    SOCKET_ROOM : 'room',
    SOCKET_EXTENSION_TAB_ROOM : 'extensionTabRoom',
    SOCKET_CALL_BUTTON_INFO : 'callButtonInfo',

    // IMPORTANT
    SOCKET_GCALLS : 'gcalls',
};

// ----------------- SALESFORCE -----------------
const SF = {
    // 
    CALL : 'CALL',
    CALL_LOG : 'calllog',
    COMPLETED : 'Completed',
    SMS : 'SMS',
    NOTE : 'note',

    // Object
    LEAD : 'Lead',
    CASE : 'Case',
    ACCOUNT : 'Account',
    CONTACT : 'Contact',
    TASK : 'Task',
    STUDENT: 'Student',
    APPLICATION : 'Application',
    OPPORTUNITY : 'Opportunity',
    ORDER: 'Order',
    ACCOUNT_CONTACT_RELATION:'AccountContactRelation',
    ACCOUNT_IN_CONTACT : 'AccountInContact',
    REGISTRATION_FORM_C : 'Registration_Form__c',
    TEAM__C : 'Team__c',
    RM__c : 'RM__c',

    // Task
    SF_TASK_SUBTYPE_CALL : 'Call',

};



// Code trong RCPhoneHelper.cls, config trong SF (Doc 'Lần đầu tích hợp SF')
const SF_APEX = {
    SF_CLASS_RC_PHONE_HELPER : 'RCPhoneHelper',
    SF_METHOD_GET_CURRENT_USER : 'getCurrentUser',
    SF_METHOD_GET_OBJECT_DATA : 'getObjectData',
    SF_METHOD_UPDATE_TASK : 'updateTask',
}

// ----------------------------------



const REGION_CODE = {
    DEFAULT : 'default',
    VI_NORTH : 'vi_north',
    VI_SOUTH : 'vi_south'
};

const COMMON= {
    WORK : 'WORK', // BITRIX
    OBJECT : 'object',
    ARRAY : 'array',
    RELOAD : 'RELOAD'

};


// Control React Native function from Omnibox
const REACT_NATIVE = {
    REACT_NATIVE_ALERT : 'reactNativeAlert',
    REACT_NATIVE_LOCAL_PUSH : 'reactNativeLocalPush',
    HANDLE_CHANGE_AGENT_STATUS : 'handleChangeAgentStatus',
    MOBILE_INTEGRATOR : 'MOBILE_INTEGRATOR',
    KEEP_APP_AWAKE : 'KEEP_APP_AWAKE',
    DEACTIVE_KEEP_APP_AWAKE : 'DEACTIVE_KEEP_APP_AWAKE',
    SAVE_MOBILE_LOCAL_STORAGE: 'SAVE_MOBILE_LOCAL_STORAGE',
    PHONE_NUMBER : 'phoneNumber',
    SETUP_ANDROID_SPEAKER_OFF: 'SETUP_ANDROID_SPEAKER_OFF',
    SETUP_ANDROID_SPEAKER_ON: 'SETUP_ANDROID_SPEAKER_ON',
};

// ----------------------------------


const AGENT_STATUS_CODE = {
    BREAK: 'break',
    DND: 'dnd',
    ONLINE: 'online',
    OFFLINE: 'offline',
    CALLING: 'calling',
    SILENT: 'silent'
};


const TYPE = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER'
};
// ----------------------------------

const CALL_DIRECTION = {
    INBOUND : 'Inbound',
    OUTBOUND : 'Outbound',
    OUTGOING : 'outgoing',
    INCOMING: 'incoming',
};


const CALL_EVENT = {
    CALL_EVENT_INCOMING: 'incoming',
    CALL_EVENT_OUTGOING: 'outgoing',
    CALL_EVENT_PROGRESS: 'progress',
    CALL_EVENT_FAILED: 'failed',
    CALL_EVENT_ENDED: 'ended',
    CALL_EVENT_CONFIRMED: 'confirmed',
    CALL_EVENT_ACCEPTED: 'accepted',
    CALL_EVENT_ONTRACK: 'onTrack'
};

// ----------------------------------

const KEY = {
    LOCAL_STORAGE_SECRET_KEY : 'umifMwbgUQ'
};
// ----------------------------------


const API_HOOK = {
    HOOK_OUTGOING : 'hook_outgoing',
    HOOK_INCOMING : 'hook_incoming',
    HOOK_CONFIRMED : 'hook_confirmed',
    HOOK_ENDED : 'hook_ended',
    HOOK_PROGRESS : 'hook_progress',
    HOOK_FAILED : 'hook_failed',
    HOOK_ACCEPTED : 'hook_accepted',
    HOOK_ONTRACK : 'hook_ontrack'
};

// ----------------------------------

const COUCHDB = {
    COUCHDB_NAME : 'calllog-gcalls'
};


const LANGUAGE = {
    LAN_VI : 'vi',
    LAN_EN : 'en'
};


// ----------------------------------


const LOG_TYPE = {
    EXTENSION : 'extension',
};

// ----------------------------------


// Field nay dung de su dung cho chức năng handleRegionCode
// hotlineCallout ( số hotline) : chọn trước hotline gửi qua cho TEL4
// regionCode ( mã vùng) : chọn trước mã vùng gửi qua cho TEL4
// field này được gửi qua SIP header(trong sdk - event outgoing) cho TEL4, liên lạc TEL4 khi setup chức năng này (Chang)
// Usecase : sieuviet
const SIP_FIELD = {
    SIP_HOTLINE_OUTBOUND : 'hotline_outbound',
    STORAGE_SIP_REGION_CODE: 'regionCode',
    STORAGE_SIP_HOTLINE_CALLOUT: 'hotlineCallout'
}
// ----------------------------------


// Xoa  REACT_APP_CALLCENTERS_UAT, couchDb UAT o rawservice, sdk sau khi chuyen callbox sang duong CouchDB thanh cong
// Lam phan nay de config cho 1 so callcenter UAT theo doi traffic, team thay doi neu can
// Truoc khi lam couchDB cho Omnibox. Ky su co the review luong couchDB o webphone, logstash, rawservice 
const REACT_ENV = {
    REACT_APP_CALLCENTERS_UAT : 'REACT_APP_CALLCENTERS_UAT',
    REACT_APP_COUCHDB_UAT_HOST : 'REACT_APP_COUCHDB_UAT_HOST',
    REACT_APP_COUCHDB_UAT_PASSWORD : 'REACT_APP_COUCHDB_UAT_PASSWORD',
    REACT_APP_COUCHDB_UAT_USERNAME : 'REACT_APP_COUCHDB_UAT_USERNAME',
}

// ----------------------------------

const API_TEMPLATE = {
  HUBSPOT_SEARCH_OWNER :'search_owner',
  HUBSPOT_SEARCH_TICKETS : 'search_tickets',
  HUBSPOT_SEARCH_COMPANY : 'search_company',
  HUBSPOT_SEARCH_CONTACT: 'search_contact',
  HUBSPOT_POST_ENGAGEMENT : 'post_engagement',
  HUBSPOT_POST_TICKET: 'post_ticket',
  HUBSPOT_POST_CONTACT: 'post_contact',
  HUBSPOT_POST_ASSOCIATION: 'post_association',
  HUBSPOT_GET_ASSOCIATIONS_DEALS : 'get_associations_deals',
  HUBSPOT_GET_RECENT_DEALS : 'get_recent_deals',
  HUBSPOT_GET_OWNER : 'get_owner',

  BITRIX_SEARCH_CONTACT : 'search_contact_bitrix',
  BITRIX_CREATE_CONTACT : 'create_contact_bitrix',
  BITRIX_GET_USER_BY_ID : 'get_user_by_id',
  BITRIX_GET_LIST_DEAL : 'get_list_deal',
  BITRIX_GET_COMPANY_BY_ID  : 'get_company_by_id',
  BITRIX_SEARCH_LEAD : 'search_lead_bitrix',
  BITRIX_CREATE_CALLLOG : 'create_calllog',
  BITRIX_SEARCH_COMMENT : 'search_comment_bitrix',


  SAPO_GET_CUSTOMERS : 'get_customers',
  SAPO_GET_ORDERS : 'get_orders',
  SAPO_GET_ACCOUNT_ID : 'get_account_id',

  VIETGUY_SEND_SMS : 'send_sms',
}

// ----------------------------------





const LOCAL_STORAGE = {

    STORAGE_PARRENT_HOST_NAME: 'parrentHostname',
    STORAGE_PARRENT_URL : 'parrentUrl',
    STORAGE_PARRENT_PATH_NAME : 'parrentPathname',
    STORAGE_DEVICE : 'device',
    STORAGE_GCALLS_TOKEN : 'gcalls-token',
    STORAGE_GCALLS_TOKEN_KC : 'gcalls-token-kc',
    STORAGE_CALLBOX_STYLE : 'callBoxStyle', // iFRAME
    STORAGE_CURRENT_CRM_USER : 'currentCrmUser',
    STORAGE_GCALLS_SERVER : 'gcalls-server',
    STORAGE_URL_PATH : 'urlPath',
    STORAGE_GCALLS_REAM : 'gcalls-realm',
    STORAGE_GCALLS_USER : 'gcalls-user',
    STORAGE_GCALLS_TAB_ID : 'GcallsTabId',
    STORAGE_GCALLS_ID_CALLCENTER : 'gcalls-idCallcenter',
    STORAGE_GCALLS_ID_AGENT : 'gcalls-idAgent',
    STORAGE_GCALLS_DISABLE_AUTO_FORMAT_NUMBER : 'gcalls_disable_auto_format_number',
    STORAGE_SIP_LISTSIP_EXTENSION : 'listSipExtension',
    STORAGE_IS_FIRST_LOGIN: 'isFirstlogin',
    STORAGE_LOGIN_DATE: 'loginDate',
    STORAGE_DIRECTION: 'direction',
    STORAGE_CRM_LOGIN_UID: 'crmLoginUID',
    STORAGE_OPEN_TASK: 'openTask',
    STORAGE_XLIM: 'xlim',
    STORAGE_XLIP: 'xlip',
    STORAGE_OBJTYPE: 'objType',
    STORAGE_MOBILE_INTEGRATOR: 'mobileIntegrator',
    STORAGE_PHONE_NUMBER: 'phoneNumber',
    STORAGE_HOTLINE : 'hotline',
    STORAGE_PRESS : 'press',


    STORAGE_AUTO_COPY_HUBSPOT_GCALLS : 'auto_copy_hubspot_gcalls',
    STORAGE_AUTO_CREATE_TICKET_HUBSPOT : 'auto_create_ticket_hubspot'
    

};


// ----------------------------------


export const gConst = {
    ...API_HOOK,
    ...AGENT_STATUS_CODE,
    ...CSS,
    ...COUCHDB,
    ...CALL_STATUS,
    ...CALL_DIRECTION,
    ...CALL_EVENT,
    ...CALLBOX_SOCKET,
    ...COMMON,
    ...SF,
    ...SF_APEX,
    ...SMS,
    ...SIP_FIELD,
    ...LOCAL_STORAGE,
    ...LOG_TYPE,
    ...LANGUAGE,
    ...REACT_NATIVE,
    ...REGION_CODE,
    ...INTEGRATION_TYPE,
    ...ENV,
    ...KEY,
    ...OS_TYPE,
    ...REACT_ENV,
    ...API_TEMPLATE,
    ...TYPE
}

