import translationsVn from './vn';


export function translate(pLanguage, pText) {
    let translation;

    switch (pLanguage) {
        case 'vi':
            translation = translationsVn[pText];
            break;
        // case 'en':
        //     translation = translationsEn[pText];
        //     break;
        // case 'jp':
        //     translation = translationsJp[pText];
        //     break;

        default:
            translation = pText;
            break;
    }

    if(!translation) {
        translation = pText;
    }

    return translation;
}
