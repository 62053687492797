import React, { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Switch from 'react-switch';
import { ReactComponent as Delete } from '../../icons/delete.svg';
import { ReactComponent as Phone } from '../../icons/phone.svg';
import { ReactComponent as SMS } from '../../icons/sms.svg';
import ChooseNumber from './chooseNumber';
import {ReactComponent as Back } from "../../icons/back.svg";
import {ReactComponent as Forward} from "../../icons/forward.svg";
import { ReactComponent as Setting } from '../../icons/setting.svg';
import { ReactComponent as Up } from '../../icons/up-arrow.svg';
import { LoginStatus, UserInfo, AgentInfo } from "../../types/index";
import { useToasts } from 'react-toast-notifications';
import AgentStatus from "../../components/agent/agentStatus";


import { Data } from '../../types';
import { doCapitalizeFirstLetter, isMobile } from '../../lib/gUtils';
import { gConst } from '../../lib/gConst';
import { CDN } from '../../lib/gCdn';

// Get Data LocalStorage
let parrentHost: any = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
let gUserData: any = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
let isLockDiapad: any = false
gUserData = JSON.parse(gUserData)

let listCustomIntegration = [gConst.HUBSPOT, gConst.NHANH_V1, gConst.SALESFORCE]
let integrator = gConst.DEFAULT
if(!!gUserData && !!gUserData.integrator[parrentHost]){
  let templateName = gUserData.integrator[parrentHost].templateName || gConst.DEFAULT;
  let IdIntegrator = gUserData.integrator[parrentHost]._id || '';
  isLockDiapad = gUserData.integrator[parrentHost].info?.isLockDiapad || false
  

  if(listCustomIntegration.includes(templateName) && !Array.isArray(gUserData.integrator[parrentHost])){
    integrator = templateName || gConst.DEFAULT;
  }else {
    integrator = gConst.DEFAULT;
  }

  if(integrator === gConst.SALESFORCE) {
    integrator = integrator + "/" + IdIntegrator;
  }
}
const buttonNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
interface Props {
  handleOnSubmit: SubmitHandler<FieldValues>;
  onExtensionChange: any;
  extension: boolean;
  userInfo: UserInfo;
  agentInfo: AgentInfo;
  setNumber: any;
  number: string;
  isShow: boolean,
  setIsShow: any,
  handleSettingClick: any,
  isSettingShow: boolean,
  isDisable: boolean,
  loginStatus: LoginStatus,
  setIsOpenSMS:any,
  showSMS: boolean,
  handleChangeCallbox: any,
  handleChangeAgentStatus: any,

}

const showNumber = document.getElementById('showNumber') as HTMLInputElement;
const Outbound: React.FC<Props> = ({
  handleOnSubmit,
  onExtensionChange,
  extension,
  userInfo,
  agentInfo,
  setNumber,
  number,
  isShow,
  setIsShow,
  handleSettingClick,
  isSettingShow,
  isDisable,
  loginStatus,
  setIsOpenSMS,
  showSMS,
  handleChangeCallbox,
  handleChangeAgentStatus
}) => {
  const { addToast } = useToasts();
  const [isInterval, setIsInterval] = useState<boolean>(false);
  const [checkedDialpad, setCheckedDialpad] = useState<boolean>(true);
  const [branchName, setBranchName] = useState('Gcalls')
  

  const { handleSubmit, register } = useForm({
    defaultValues: {
      numberPhone: '',
    },
  });
  const hanldeNumberClick = (pNumber: any) => {
    // setup in portal for each integrator - check sieuviet
    if(isLockDiapad){
      return
    }

    let text = number + pNumber;
    setNumber(String(text));
    
  };
  const handleChangeNumber = (numberPhone: string) => {
    setNumber(numberPhone);
  };
  const handleSubmitNumber = () => {
    handleOnSubmit({ number });
  };
  const handleOpenSMS = () => {
    setIsOpenSMS(true)
  }
  const handleDisableOpenSMS = () => {
    addToast('Please login!', {
      appearance: 'error',
      autoDismiss: true,
    });
  }
  useEffect(() => {

    initStatic();

    const element = document.querySelectorAll('input[type=button]');
    if (!checkedDialpad) {
      setNumber('');
      if (showNumber) showNumber.value = '';
      element.forEach((value) => {
        value.setAttribute('disabled', 'true');
        value.classList.remove("hover:bg-gray-200");
      });
    } else {
      element.forEach((value) => {
        value.removeAttribute('disabled');
        value.classList.add("hover:bg-gray-200");

      });
    }
  }, [checkedDialpad]);



  const changeStatusSwitchDialpad = () => {
    setCheckedDialpad(!checkedDialpad);
  };
  const onDelete = (event: any) => {
    event.preventDefault();
    if (checkedDialpad) {
      setNumber(number.slice(0, -1));
    } else {
      const input = document.getElementById('showNumber') as HTMLInputElement;
      if (input) {
        input.value = '';
      }
    }
  };

  // --------------------------------------------------------------
  const onChangeNumberPhone = (event: any) => {
    if(isLockDiapad){return}
    setNumber(event.target.value);
  };
  // --------------------------------------------------------------

  useEffect(() => {
    if (number.split(';').length >= 2) {
      const makePhone = document.getElementById('makePhone');
      if (makePhone) {
        makePhone.classList.add('blur-effect');
      }
    } else {
      const makePhone = document.getElementById('makePhone');
      if (makePhone) {
        makePhone.classList.remove('blur-effect');
      }
    }
  }, [number]);


  // --------------------------------------------------------------

  
  useEffect(()=>{
    if(isInterval){
      let x = 0;
      var interval = setInterval(()=>{
        x++;
        if(x >= 2){
          setNumber('');
          setIsInterval(false)
        }
        
      }
      ,500)
    }
    return ()=>{
      clearInterval(interval);
    }
  },[isInterval]);

  // --------------------------------------------------------------

  function isMouseDown(event: any) {
    if (event.type === 'mousedown') {
      setIsInterval(true);
    }
    if (event.type === 'mouseup') {
      setIsInterval(false);
    }

    if (event.type === 'touchstart') {
      setIsInterval(true);
    }

    if (event.type === 'touchend') {
   
      setIsInterval(false);
    }
  }
  function handleHideCallbox() {
    let data = {
      type: "hideCallbox"
    }
    if(handleChangeCallbox){
      handleChangeCallbox(data)
    }
  }


  const  initStatic = async () => {
    setBranchName(doCapitalizeFirstLetter(await CDN.brandText() + ' User'))
  }

  // Remove warning
    // const { GcallsExtension, GcallsProxy, GcallsPassword, GcallsWebsocket} = userInfo;
    // const id = document.getElementById("setting-btn");
    // if(!(GcallsExtension && GcallsProxy && GcallsPassword && GcallsWebsocket) && !isSettingShow){
    //   id?.classList.add("notsip");
    // }else{
    //   id?.classList.remove("notsip");
    // }
  return (
    <div className="w-full h-full grid over-flow-hidden pb-10 pl-4 pr-4	  ">

      {number.split(';').length >= 2 ? <ChooseNumber onSetNumber={handleChangeNumber} number={number.split(';')} /> : null}


      
      <div id="makePhone" className=" w-full h-full flex">

         
      <form className="w-full h-full items-stretch grid grid-col-1" onSubmit={checkedDialpad ? handleSubmit(handleSubmitNumber) : handleSubmit(handleOnSubmit)}>
          
          
         <div className='w-full h-full flex  row-span-1 flex-auto'>
          <div className='w-full flex grid grid-col-1'>
            <div className='flex w-full flex justify-between'>
              <div className="text-xl text-white left-7 top-4">{branchName}: {loginStatus.fullname.length > 0 ? loginStatus.fullname : 'Not logged in'}</div>
              {loginStatus.fullname.length > 0 &&
                <AgentStatus agentInfo={agentInfo} handleChangeAgentStatus={handleChangeAgentStatus}></AgentStatus>
              }
              </div>
            <div className='flex w-full '>
            <span id="setting-btn" onClick={handleSettingClick} className="w-1/12   text-white flex items-center justify-start font-bold cursor-pointer select-none relative">
              {isSettingShow ? <Up width="30" height="30" fill="white" /> : <Setting width="30" height="30" fill="white" />}
            </span>


        

            {!isSettingShow ? (
              <div className="border-b-2 w-10/12 flex justify-center items-center ">
                <input
                  type="text"
                  value={number.split(';').length >= 2 ? '' : number}
                  {...register('numberPhone')}
            
                  onChange={onChangeNumberPhone}
                  className="w-11/12  text-center h-16 p-2 text-3xl font-bold bg-transparent text-gray-200 outline-none focus:outline-none"
                  id="showNumber"
                />
                <a onClick={onDelete} className="cursor-pointer" onTouchStart={isMouseDown} onTouchEnd={isMouseDown} onMouseDown={isMouseDown} onMouseUp={isMouseDown}  title="Hold for 2s to clear all!">
                  {number.length > 0 ? <Delete width="30" height="30" fill="white" /> : null}
                </a>
              </div>
            ) : (
              <div className="border-b-2 w-9/12 flex justify-center items-center opacity-0 z-0">
                <input
                  type="text"
                  value={number.split(';').length >= 2 ? '' : number}
                  readOnly={true}
                  {...register('numberPhone')}
              
                  onChange={onChangeNumberPhone}
                  className="w-11/12  text-center h-16 p-2 text-3xl font-bold bg-transparent text-gray-200 outline-none focus:outline-none"
                  id="showNumber"
                />
                <a onClick={onDelete} className="cursor-pointer" onTouchStart={isMouseDown} onTouchEnd={isMouseDown} onMouseDown={isMouseDown} onMouseUp={isMouseDown} title="Hold for 2s to clear all!">
                  {number.length > 0 ? <Delete width="30" height="30" fill="white" /> : null}
                </a>
              </div>
            )}


           {!isMobile(window) && <span className="w-10 text-3xl  ml-4 text-white flex items-center justify-end font-bold cursor-pointer select-none  	" style={!localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER) ? { display: 'none' } : {}} onClick={setIsShow}>
              {' '}
              {!isSettingShow ? isShow ? 
              
                <Back width="30" height="30" fill="white" /> 
              :
               
                  <Forward width="30" height="30" fill="white" /> 
             :
               null}{' '}
            </span> }

            </div>
            <div className='  flex h-full pt-8 '>
              <div id="func" className="flex justify-between  w-10/12">
                <div className="flex items-center h-full w-1/2 flex-wrap">
                  <Switch
                    disabled={!(isDisable == false && loginStatus.isLogin)}
                    offColor="#b9160a"
                    width={60}
                    uncheckedIcon={<span className="text-xl h-full flex items-center justify-center text-white font-bold ">OFF</span>}
                    checkedIcon={<span className="text-xl h-full flex items-center justify-center text-white font-bold ">ON</span>}
                    onChange={onExtensionChange}
                    checked={extension}
                  />
                  <div className="break"></div>
                    <span className="text-lg font-bold text-gray-200 ">{extension ? 'Extension On' : 'Extension Off'}</span>
                  </div>
                </div>
              </div>
            </div>
         </div>


          <div className='justify-center flex items-center w-full'>
            <div className='w-11/12   row-span-2'>
              <div className=" w-full h-full grid grid-cols-3 gap-2">
              {buttonNumber.map((value, index) => (
                <div className=" w-full flex items-center  justify-center" key={index}>
                  <div className='w-2/3 h-2/3  flex items-center justify-center'>
                  <button type="button" onClick={() =>hanldeNumberClick(value)} className="w-full aspect-w-1 aspect-h-1 rounded-full button_number border text-black bg-transparent cursor-pointer active:bg-gray-300 active:ring hover:bg-gray-200 text-white focus:outline-none">
                    <span className="text-3xl flex items-center justify-center">{value}</span>
                  </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
        </div>



  <div className=' flex-auto   row-span-1'>
  <div className=" bg-black-200 inset-0 w-full h-full flex justify-center items-center pt-2 ">


        
            <div className='w-11/12 h-full flex justify-center gap-2 '>
            {showSMS && (  <div className="w-full max-w-100px  flex justify-center items-center">
                  <button type="button" onClick={loginStatus.isLogin ? handleOpenSMS : handleDisableOpenSMS}  className="w-full h-24 rounded-full bg-yellow-400  flex justify-center items-center active:bg-yellow-700 active:ring  hover:bg-yellow-200">
                    <SMS className="w-3/6" fill="white"/>
                  </button>
              </div> )}

              <div className="w-full max-w-100px  flex justify-center items-center">
                <button type="submit" className="w-full h-24 rounded-full bg-green-400 flex justify-center items-center active:bg-green-700 active:ring  hover:bg-green-200">
                    <Phone className="w-12" fill="white" />
                  </button>              
              </div>

            </div>
            </div>
          </div>
        </form>
      </div>
      
    </div>
    
  );
};

export default Outbound;
