// import config from '../config.json';

import {getConfig} from './configEncrypt';
import { gConst } from '../lib/gConst';
import { isCouchDBUAT } from '../lib/gUtils';

const config = getConfig();

// require('dotenv').config()

let serverName = localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) ? localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) : process.env["REACT_APP_GCALLS_MOBILE"];

const mobileDomain = config[serverName]?.mobileDomain;
const recordHost = config[serverName]?.recordHost;



let enablePouchDbLog = config[serverName]?.enablePouchDbLog;

if(isCouchDBUAT()){
    enablePouchDbLog = true;

}

export default { 
    mobileDomain,
    recordHost,
    enablePouchDbLog
}
// module.exports = {
//     mobileDomain,
//     recordHost
// }