import axios from "axios";
import { gConst } from "../lib/gConst";
import { extractNumbers, isMobile, isValidJson } from "../lib/gUtils";

const eventListeners: any[] = [];

let mobileIntegrator = localStorage.getItem(gConst.STORAGE_MOBILE_INTEGRATOR) ||  gConst.DEFAULT
let phoneNumber = localStorage.getItem(gConst.STORAGE_PHONE_NUMBER) ||  ''
// ---------------------------------------------------------------------------------------------
let handleMobileIntegratorChange;

const handleMessage = (event: any,callback = (pData: any) => {}) => {
  const message = event.data; // Access the received message here

  if(!isValidJson(message)){
    return
  }

  let data = JSON.parse(message)


  if(data?.integrator){
    mobileIntegrator = data.integrator
    localStorage.setItem(gConst.STORAGE_MOBILE_INTEGRATOR, mobileIntegrator);
  }

  if(data?.phoneNumber){
    let phoneNumber = extractNumbers(data?.phoneNumber)
    localStorage.setItem(gConst.STORAGE_PHONE_NUMBER, phoneNumber);

  }

  if(data?.os){
    localStorage.setItem(gConst.STORAGE_DEVICE,data.os);

  }

  // Handle other message types
  if (typeof callback === 'function') {
    callback(data); // Invoke the provided callback function
  }
};

// ---------------------------------------------------------------------------------------------

const addMobileEventListener = (pWindow: any, callback: any = () => {} ) => {

  const handleEvent = (event: any) => {

    handleMessage(event, callback);
  };

  pWindow.addEventListener("message", handleEvent);

};

// ---------------------------------------------------------------------------------------------

const removeMobileEventListener =  (pWindow : any ,pObj : any)  => {
  window.removeEventListener("message", pObj);
  const index = eventListeners.indexOf(pObj);
  if (index !== -1) {
    eventListeners.splice(index, 1);
  }
};
// ---------------------------------------------------------------------------------------------

const removeAllMobileEventListeners = () => {
  eventListeners.forEach((listener) => {
    window.removeEventListener("message", listener);
  });
  eventListeners.length = 0;
};
// ---------------------------------------------------------------------------------------------


const setMobileIntegrator = (pWindow : any,pIntegrator: any) => {
  let data = {
    type : gConst.MOBILE_INTEGRATOR,
    integrator : pIntegrator
  }
  pWindow.ReactNativeWebView.postMessage(JSON.stringify(data))

}

// ---------------------------------------------------------------------------------------------


const activeMobileAppAwake = (pWindow : any) => {

  if(!isMobile(pWindow)) return
  // reactNativeLog(pWindow,gConst.KEEP_APP_AWAKE)
  let data = {
    type : gConst.KEEP_APP_AWAKE,
  }
  pWindow.ReactNativeWebView.postMessage(JSON.stringify(data))

}

// ---------------------------------------------------------------------------------------------


const deactiveMobileAppAwake = (pWindow : any) => {

  if(!isMobile(pWindow)) return
  let data = {
    type : gConst.DEACTIVE_KEEP_APP_AWAKE,
  }
  pWindow.ReactNativeWebView.postMessage(JSON.stringify(data))

}
// ---------------------------------------------------------------------------------------------

const getMobileParrentHostNameList = (pUserData : any) => {


  let integrator = pUserData?.integrator
  let listParrentHost = []

  if(!integrator) return []

  for (let key in integrator) {
    if (integrator.hasOwnProperty(key)) {
      let value = integrator[key];
      // console.log(`Key: ${key}, Value: ${value}`);
      listParrentHost.push(key)
    }
  }

  return listParrentHost

}
// ---------------------------------------------------------------------------------------------
const setMobileParrentHost = (pParrentHostname : any) => {
  localStorage.setItem(gConst.STORAGE_PARRENT_HOST_NAME,pParrentHostname)
}

// ---------------------------------------------------------------------------------------------
// Android only - earpice speaker on -> main speaker off - earpice speaker off -> main speaker on
function setAndroidSpeakerOn(pWindow : any){
  pWindow.ReactNativeWebView.postMessage(JSON.stringify({type : gConst.SETUP_ANDROID_SPEAKER_ON}))

}

// Android only 
function setAndroidSpeakerOff(pWindow : any){
  pWindow.ReactNativeWebView.postMessage(JSON.stringify({type : gConst.SETUP_ANDROID_SPEAKER_OFF}))

}
// ---------------------------------------------------------------------------------------------
function saveMobileLocalStorage(pWindow : any,pKey : any, pVal: any){
  if(!isMobile(pWindow)) return

  let data = {
    type : gConst.SAVE_MOBILE_LOCAL_STORAGE,
    key : pKey,
    value : pVal
  }
  pWindow.ReactNativeWebView.postMessage(JSON.stringify(data))
}

function reactNativeLog(pWindow : any, pValue : any){
  if(!isMobile(pWindow)) { return}
  pWindow.ReactNativeWebView.postMessage(JSON.stringify({
    type : 'CONSOLE_LOG',
    value: pValue
  }))
}

// ---------------------------------------------------------------------------------------------
function getMobileIntegrator(){
  return mobileIntegrator
}
// ---------------------------------------------------------------------------------------------
function getMobilePhoneNumber(){
  return phoneNumber
}
// ---------------------------------------------------------------------------------------------


const registerMobileIntegratorHandler = (callback : any) => {
  handleMobileIntegratorChange = callback;
};


export {
  addMobileEventListener,
  setMobileIntegrator,
  removeMobileEventListener,
  removeAllMobileEventListeners,
  getMobileIntegrator,
  getMobilePhoneNumber,
  handleMessage,
  registerMobileIntegratorHandler,
  handleMobileIntegratorChange,
  getMobileParrentHostNameList,
  setMobileParrentHost,
  setAndroidSpeakerOn,
  setAndroidSpeakerOff,
  activeMobileAppAwake,
  deactiveMobileAppAwake,
  saveMobileLocalStorage,
  reactNativeLog
};
