import React from 'react';

type Props = {
    number: string[];
    onSetNumber: any;
}

function ChooseNumber({number, onSetNumber}: Props) {
    const handleNumberClick = (number: string) => {
    }
    
    return (
        <div className="chooseNumber absolute  text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 z-50 rounded-3xl shadow-2xl p-2 py-8 overflow-y-auto bg-purple-200 max-h-96">
            {number.map((item, index) => {
                return (
                    <div key={index} onClick={()=>onSetNumber(item)} className="text-center mb-4 text-4xl font-bold cursor-pointer border border-black p-2 w-3/4 mx-auto
                    h-16 rounded-2xl text-purple-700 hover:bg-white hover:text-gray-900 hover:border-white">
                        {item}
                    </div>
                )
            })}
            
        </div>
    );
}

export default ChooseNumber;