import { useEffect, useState } from 'react';
import './Login.css';
import { login } from '../../../services/gcalls.service';
import { useToasts } from 'react-toast-notifications';
import { keycloakForRealm } from '../../../services/keycloak';
import { gdebug } from '../../../lib/gdebug';
// import config from '../../../config.json';
import { isMobile } from '../../../lib/gUtils';
import { getConfig } from '../../../config/configEncrypt';
import { getRealm, loginKeycloak } from '../../../services/keycloak.service';
import LoginForm from './loginForm';
import { ReactComponent as Phone } from '../../../icons/login-phone.svg';
import { CDN } from '../../../lib/gCdn';
import { gConst } from '../../../lib/gConst';
const config = getConfig();
require('dotenv').config()


export interface ProcessEnv {
  [key: string]: string | undefined
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GCALLS_WEBHOOK_URL?: string;
      REACT_APP_SF_USERNAME?: string;
      REACT_APP_GCALLS_MOBILE?: string;
    }
  }
}

declare const window: Window &
  typeof globalThis & {
    sforce: any;
    ReactNativeWebView: any
  };


type Props = {
  isLogin: boolean;
  setIsLogin: any;
  setUserData: any;
  closeLogin: any;
  handleChangeCallbox: any;
};

function LoginPage({ isLogin, setIsLogin, setUserData, closeLogin, handleChangeCallbox }: Props) {

  const [isShowLoginForm, setIsShowLoginForm] = useState<boolean>(false);
  const [callCenterName, setCallCenterName] = useState<string>('');
  const [serverName, setServerName] = useState<string>('');
  const [changeServerStatus, setChangeServerStatus] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { addToast } = useToasts();
  const [brandName, setBrandName] = useState<string>('');
  const [brandPhone, setBrandPhone] = useState<string>('');
  const [supportLink, setSupportLink] = useState<string>('');



  useEffect(() => {

    initStatic();

  }, [])

  const  initStatic = async () => {

    let mobileDomain = await CDN.getMobileDomain()
    setBrandName(await CDN.brandText())
    setBrandPhone(await CDN.brandPhone())
    setSupportLink(await CDN.brandSupportLink())
    let callCenterName = localStorage.getItem(gConst.STORAGE_GCALLS_REAM)
    if (callCenterName) {
      setCallCenterName(callCenterName)
    }

    
    let serverName = localStorage.getItem(gConst.STORAGE_GCALLS_SERVER)
    if (serverName) {
      setServerName(serverName)
    } else {
      let mobileUrl: any = mobileDomain;
      setServerName(mobileUrl)
      localStorage.setItem(gConst.STORAGE_GCALLS_SERVER, mobileUrl)
    }
  }

  const showLoginForm = async () => {

    let result =await getRealm(callCenterName)

    if(!result.success){
      addToast('Please enter Call Center Name', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return
    }
    window.localStorage.setItem(gConst.STORAGE_GCALLS_REAM, callCenterName)
    setIsShowLoginForm(true);
    
  }

  const handleChangeServer = async () => {
  
    let configData: any = config;
    let checkServer = Object.keys(configData).find(k => {
      if (serverName === k) {
        return true
      } else {
        return false
      }
    });
    if (!checkServer) {

      addToast('Server not found!', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    localStorage.setItem(gConst.STORAGE_GCALLS_SERVER, serverName)
    let data = {
      type: "changeServer",
      mobileUrl: serverName
    }
    if (handleChangeCallbox) {
      handleChangeCallbox(data)
    }
  }


  const gotoSupportPage = () => {
    window.location.href = supportLink;
  };


  return (
    isShowLoginForm ? (
      <LoginForm closeLogin={closeLogin}
      />
    ) : (
      <div className="Login grid grid-cols-1 relative rounded-lg">
        <button
          className="text-2xl font-bold text-white absolute top-4 right-4"
          onClick={closeLogin}
        >
          X
        </button>
        <div className="grid grid-cols-1 w-full h-full">
      {/* ------------------------------------------------------------- */}
          <div className='justify-center items-center' ></div>
          <div className='justify-center items-center' >
            <div className='title'>
              {brandName}
            </div>
          </div>
        {/* ------------------------------------------------------------- */}
          <div className='grid grid-cols-1 justify-center items-center'>
            {/* Section 1: Server Selection */}

            <div className={`inputWrapper w-full ${changeServerStatus ? 'hide-item' : ''}`} title={serverName}>
              <div className="grid grid-cols-4 items-center">
                <input
                  type="text"
                  value={serverName}
                  placeholder="Call Center Name..."
                  readOnly
                  style={{ background: 'transparent', border: 'none', color: 'white' }}
                  className='col-span-3'
                />
                <button
                  className='col-span-1 w-full bg-white border border-purple-500 text-purple-500 text-center py-2 px-2 rounded-full hover:bg-purple-500 hover:text-white transition duration-300'
                  onClick={() => setChangeServerStatus(true)}
                >
                  Change
                </button>
              </div>
            </div>
            {/* Section 2: Server Change */}
            <div className={`inputWrapper w-full ${changeServerStatus ? '' : 'hide-item'}`}>
              <label className="label">Server</label>
              <div className="grid grid-cols-4 items-center">
                <input
                  type="text"
                  value={serverName}
                  placeholder="Call Center Name..."
                  onChange={(e) => setServerName(e.target.value)}
                  required
                  className='col-span-3 bg-deep-purple-700 border border-white rounded focus:bg-deep-purple-700'
                />
                <button className="col-span-1" onClick={() => handleChangeServer()}>
                  Change
                </button>
              </div>
            </div>
          </div>

        {/* ------------------------------------------------------------- */}

          <div className='inputWrapper justify-center items-center'>

            <label className="label">Call Center Name</label>
            <input
              className='bg-deep-purple-700 border border-white rounded focus:bg-deep-purple-700 border border-white rounded-lg h-12' 
              type="text"
              value={callCenterName}
              placeholder="Call Center Name..."
              onChange={(e) => setCallCenterName(e.target.value)}
              required
            />

            <span className="error">{error}</span>
    
          <button className="w-1/2 rounded-2xl pt-4 px-2 py-2 border border-gray-300 font-bold " onClick={showLoginForm}>
            Login
          </button>


          </div>
        {/* ------------------------------------------------------------- */}

          <div></div>
        {/* ------------------------------------------------------------- */}

        <div className='grid grid-cols-1 justify-center items-center '>
          <div className='inputWrapper flex items-center justify-center items-center' onClick={() => gotoSupportPage()}>
            <Phone className='mr-2' /> 
            <span className='text-white'>{brandPhone}</span>
          </div>
        </div>
        </div>
      </div>

    )
  )
};

export default LoginPage;
