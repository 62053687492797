import Keycloak from "keycloak-js";
// import kcConfig from '../keycloak.json';
// import config from '../config.json';
import { gConst } from '../lib/gConst';

import {getConfig} from '../config/configEncrypt';
import { CDN } from "../lib/gCdn";
const config = getConfig();



let serverName = localStorage.getItem(gConst.STORAGE_GCALLS_SERVER);

if (!serverName) {
    CDN.getMobileDomain()
      .then(mobileDomain => {
        serverName = mobileDomain;
        localStorage.setItem(gConst.STORAGE_GCALLS_SERVER, mobileDomain)
        window.location.reload()
      })
      .catch(error => {
        console.error('Error fetching mobile domain:', error);
      });
  } 

  
function keycloakForRealm(realm) {
    const kcConfig = config[serverName]?.keycloak;
    const realmName = realm ? realm : "gcalls"
    const opts = {
        "realm": realmName,
        "url": kcConfig["auth-server-url"],
        "ssl-required": "external",
        "clientId": kcConfig.clientId,
        "public-client": true,
        "confidential-port": 0
    }
    const kc = new Keycloak(opts)
    return kc
}
export { keycloakForRealm };
