interface Props  {
    data: String;
    id: string;
    onClick: any;
    isChecked: boolean;
}
export default function Checkbox({data, id, onClick, isChecked}: Props) {
    return (
        <div className="flex items-center w-12/12 h-16 p-4 rounded-lg shadow-lg bg-neutral-100 text-black">
            <input defaultChecked={isChecked} onChange={onClick} id={id} type="checkbox" className="w-5 h-5 rounded-full checked:bg-blue-600 checked:border-transparent " />
            <label className="mx-4 font-medium select-none cursor-pointer " style={{fontSize: "1.3rem"}} htmlFor={id}>{data}</label>
        </div>
    )
}