import { LoginStatus, UserInfo } from '../../../types/index';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import IntegrationGcalls from '../../integrationGcalls';
import { getSession } from '../../../services/gcalls.service';
import { gdebug } from '../../../lib/gdebug';
interface Props {
  UserInfo: UserInfo;
  onSubmit: any;
  settingClick: any;
  syncSip: any;
  setWhatTab: any;
  isShowInfoPage: boolean;
  setIsShowInfoPage: any;
  loginStatus: LoginStatus;
  setLoginStatus: any;
  signOut: any;
  keycloak: any;
  handleChangeCallbox: any;
}
export default function ChangeSip({ UserInfo, onSubmit, settingClick, syncSip, setWhatTab, isShowInfoPage, setIsShowInfoPage, loginStatus, setLoginStatus, signOut, keycloak, handleChangeCallbox }: Props) {
  const { GcallsExtension, GcallsProxy, GcallsPassword, GcallsWebsocket } = UserInfo;

  const [socket, setSocket] = useState(GcallsWebsocket);
  const [extension, setExtension] = useState(GcallsExtension);
  const [proxy, setProxy] = useState(GcallsProxy);
  const [password, setPassword] = useState(GcallsPassword);
  const [isLogin, setIsLogin] = useState(false);
  const { register, handleSubmit } = useForm();
  const handleChangeInput = (e: any) => {
    const value = e.target.value;
    const attributes = e.target.getAttribute('name');
    switch (attributes) {
      case 'socket':
        setSocket(value);
        break;
      case 'extension':
        setExtension(value);
        break;
      case 'proxy':
        setProxy(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };
  const handleLoginButtonClick = () => {
    setIsShowInfoPage(!isShowInfoPage);
    settingClick(false);
  };
  console.warn = () => {};
  useEffect(()=>{
    getSession()
    .then(({ err }) => {
      if (err) {
        setIsLogin(false);
      } else {
        setIsLogin(true);
      }
    })
    .catch((error) => {
      // console.log(error);
      gdebug(1,"Get session error ", error)
    });
  },[])
  return (
    <>
    {isShowInfoPage? null: <IntegrationGcalls 
    syncSip={syncSip} 
    closeLogin={handleLoginButtonClick}
    updateUserInfo={onSubmit}
    loginStatus={loginStatus}
    setLoginStatus={setLoginStatus} 
    signOut={signOut}
    keycloak={keycloak}
    handleChangeCallbox={handleChangeCallbox}
     />}
    </>
  );
}
