import React, { useEffect, useState } from 'react';
import { ReactComponent as Question } from '../../icons/note.svg';
import { useToasts } from 'react-toast-notifications';
import { apiIntegration2, createContact, createSMSLogs, getSMSTemplates, searchContact, sendSMSVietGuy } from '../../services/gcalls.service';
import { gdebug } from '../../lib/gdebug';
import { gConst } from '../../lib/gConst';

declare const window: Window &
  typeof globalThis & {
    sforce: any;
  };
type Props = {
  name: string;
  status: any;
  setIsOpenSMS: any;
  number: any;
  userInfo: any;
  currentObject: any;
  objectData: any;
  createSMSNote: any;
  setNumber: any;
};

// Get Data LocalStorage
let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM);
let parrentHost: any = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
let gUserData: any = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);

let listSMS = [gConst.SMS_VIET_GUY, gConst.SMS_FPT, gConst.SMS_NEXMO, gConst.SMS_GAPIT];
let listCurrentSMS: any = [];
let listFptBrandName: any = [];

if (!!gUserData && !!gUserData?.integrator[gConst.SMS_FPT]) {
  if (!Array.isArray(gUserData.integrator[gConst.SMS_FPT])) {
    listFptBrandName.push(gUserData.integrator[gConst.SMS_FPT]);
  } else {
    listFptBrandName = gUserData.integrator[gConst.SMS_FPT];
  }
}
for (let i = 0; i < listSMS.length; i++) {
  let sms = listSMS[i];
  if (!!gUserData && !!gUserData?.integrator[sms]) {
    listCurrentSMS.push(sms);
  }else{
    if(sms === gConst.SMS_GAPIT){
      listCurrentSMS.push(sms);
    }
  }
}

function SMSBOX({ name, status, setIsOpenSMS, number, userInfo, currentObject, objectData, createSMSNote, setNumber }: Props) {
  const { addToast } = useToasts();
  const [listSmsTemplate, setListSmsTemplate] = useState<any>([]);
  const [currentSmsProvider, setCurrentSmsProvider] = useState<any>('');
  const [currentFptBrandName, setCurrentFptBrandName] = useState<any>('');
  const [currentSmsTemplate, setCurrentSmsTemplate] = useState<any>('');
  const [currentSmsContent, setCurrentSmsContent] = useState<any>('');
  const [currentNumberSMS, setCurrentNumberSMS] = useState<any>(1);
  const handleCloseSMS = () => {
    setIsOpenSMS(false);
  };
  useEffect(() => {
    let idCallcenter: any = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER);
    let completeSMSTemplates: any = [];
    async function getTemplates() {
      let SMSTemplate: any = await getSMSTemplates(idCallcenter);
      completeSMSTemplates = [
        {
          templateName: 'Nothing Selected',
          content: '',
        },
        ...SMSTemplate.map((template: any) => {
          return { templateName: template.title, content: template.name };
        }),
      ];
      // console.log(number, SMSTemplate);
      gdebug(3,"getTemplates ", number, SMSTemplate)
      setListSmsTemplate(completeSMSTemplates);
      setCurrentSmsTemplate(completeSMSTemplates[0].content);
    }
    getTemplates();
    if (listCurrentSMS.length) {
      setCurrentSmsProvider(listCurrentSMS[0]);
    }
    if (listFptBrandName.length) {
      setCurrentFptBrandName(listFptBrandName[0]?.fields?.BrandName);
    }
  }, []);
  useEffect(() => {
    setCurrentSmsContent(currentSmsTemplate);
  }, [currentSmsTemplate]);
  const handleSetSmsTemplate = (e: any) => {
    setCurrentSmsTemplate(e.target.value);
  };
  const handleSetSmsProvider = (e: any) => {
    setCurrentSmsProvider(e.target.value);
  };
  const handleSetFptBrandName = (e: any) => {
    setCurrentFptBrandName(e.target.value);
  };
  const handleSetCurrentSMSContent = (event: any) => {
    let currentSmsContents = event.target.value;
    let numberOfSms = 1;
    let smsLength = currentSmsContents.length;

    if (smsLength <= 70) {
      numberOfSms = 1;
    } else if (smsLength > 70 && smsLength <= 134) {
      numberOfSms = 2;
    } else if (smsLength > 134 && smsLength <= 201) {
      numberOfSms = 3;
    } else if (smsLength > 201 && smsLength <= 268) {
      numberOfSms = 4;
    } else {
      numberOfSms = 5;
    }
    setCurrentSmsContent(currentSmsContents);
    setCurrentNumberSMS(numberOfSms);
  };
  const removeSMSContent = () => {
    setCurrentSmsContent('');
  };
  function formatDate(date: any) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  function calcTime(date: any, offset: any) {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd;
  }
  const send = async (LatestPhone: any) => {
    let sms = currentSmsContent;
    let phone = LatestPhone;
    let notification = '';
    let origin_phone = phone;
    let status_sms: any = false;

    if (phone.length === 0) {
      notification = 'Vui lòng điền số điện thoại';
      addToast(notification, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (phone.slice(0, 1) == 0) {
      phone = '84' + phone.slice(1);
    }

    if (sms.length === 0) {
      notification = 'Vui lòng điền nội dung tin nhắn';
      addToast(notification, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    // console.log('sms', sms);
    gdebug(3,"SMS ", sms)

    let smsData: any = {
      phone,
      sms,
    };

    switch (currentSmsProvider) {
      case gConst.SMS_VIET_GUY:
        smsData = {
          phone,
          sms,
        };
        break;
      case gConst.SMS_NEXMO:
        // console.log(phone, phone.charAt(0));
        gdebug(3,"Nexmo Phone  ", phone, phone.charAt(0))
        if (phone.charAt(0) == '0') {
          phone = phone.slice(1);
          if (!!gUserData && gUserData?.idCallcenter == '89EqgIXY0') {
            phone = '+66' + phone;
          } else {
            phone = '+84' + phone;
          }
        }
        if (phone.charAt(0) != '+') {
          phone = '+' + phone;
        }
        smsData = {
          to: phone,
          text: sms,
        };
        break;

      case gConst.SMS_FPT:
        smsData = {
          BrandName: currentFptBrandName,
          Phone: phone,
          Message: new Buffer(sms).toString('base64'),
        };
        break;

      case gConst.SMS_GAPIT:
        smsData = {
          to: phone,
          text: sms,
        };
        break;

      default:
        break;
    }

    let endpoint = 'send_sms';
    if (currentSmsProvider === 'zns') {
      endpoint = 'send_zns';
    }

    let valueNote = '';

    let smsResponse: any = await apiIntegration2(smsData, currentSmsProvider, endpoint);
    // console.log('resSMS', smsResponse);
    gdebug(3,"resSMS  ", smsResponse)

    try {
      switch (currentSmsProvider) {
        case gConst.SMS_GAPIT:
          addToast('Gửi tin nhắn thành công', {
            appearance: 'success',
            autoDismiss: true,
          });
          valueNote = `Trạng thái: Gửi tin nhắn thành công!\nNội dung SMS: "${sms}"`;
          status_sms = true;
          removeSMSContent();
          break;
        case gConst.SMS_VIET_GUY:
          if (smsResponse.success === true) {
            // send ok
            addToast('Gửi tin nhắn thành công', {
              appearance: 'success',
              autoDismiss: true,
            });
            valueNote = `Trạng thái: Gửi tin nhắn thành công!\nNội dung SMS: "${sms}"`;
            status_sms = true;
            removeSMSContent();
          } else {
            notification = `Gửi tin nhắn thất bại`;
            valueNote = `Trạng thái: Thất bại \nNội dung SMS: "${sms}"`;
            addToast(notification, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
          break;

        case  gConst.SMS_NEXMO:
          if (!!smsResponse?.data?.messages && smsResponse?.data?.messages?.length && smsResponse?.data?.messages?.at(0).status == '0') {
            // send ok
            addToast('Gửi tin nhắn thành công', {
              appearance: 'success',
              autoDismiss: true,
            });
            valueNote = `Trạng thái: Gửi tin nhắn thành công!\nNội dung SMS: "${sms}"`;
            status_sms = true;
            removeSMSContent();
          } else {
            notification = `Gửi tin nhắn thất bại`;
            valueNote = `Trạng thái: Thất bại \nNội dung SMS: "${sms}"`;
            addToast(notification, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
          break;

        default:
          break;
      }
    } catch (error) {
      // console.log(error);
      gdebug(1,"Error send SMS  ", error)
    }

    let phonenumber = number;
    let idContact: any = '';
    let creator = localStorage.getItem(gConst.STORAGE_GCALLS_ID_AGENT);
    let idCallcenter = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER);
    let contact = await searchContact(phonenumber);

    if (contact.length) {
      idContact = contact[contact.length - 1]._id;
    } else {
      // let newcontact = await createContact(phonenumber)

      // if (newcontact) {
      //     idContact = newcontact._id
      // }
      idContact = null;
    }
    setCurrentSmsTemplate('');

    let logData: any = {
      text: valueNote,
      type: gConst.NOTE,
      body: null,
      creator,
      isSMS: true,
      idContact,
      phone: origin_phone,
    };

    let logData2 = {
      sms: sms,
      phone: origin_phone,
      status_sms: status_sms ? 'Success' : 'Fail',
      objectData
    };

    createSMSNote(logData2);
    createSMSLogs(logData);
  };

  const onChangeNumberPhone = (event: any) => {
    setNumber(event.target.value);
  };

  const handleRenameSms = (sms: any) => {
    let smsName = sms;
    switch (sms) {
      case gConst.SMS_VIET_GUY:
        smsName = 'VietGuys';
        break;

      case gConst.SMS_FPT:
        smsName = 'FPT';
        break;

      case gConst.SMS_NEXMO:
        smsName = 'Nexmo';
        break;

      case gConst.SMS_IRIS:
        smsName = 'IRIS';
        break;

      case gConst.SMS_ESMS:
        smsName = 'eSMS';
        break;

      case gConst.SMS_GAPIT:
        smsName = 'Gapit';
        break;

      default:
        break;
    }
    return smsName;
  };

  return (
    <div id="sms" className='w-full flex h-full'>
      <div className="w-full flex flex-col h-full justify-between">
        <div className="SMS_provider justify-center items-center pt-4 mt-1 ">
          <select onChange={handleSetSmsProvider} value={currentSmsProvider} className="bg-white p-1 text-2xl text-left mx-auto w-11/12 block outline-none rounded-lg resize-none ">
            {listCurrentSMS.map((smsName: any, index: any) => {
              return (
                <option key={index} value={smsName}>
                  {handleRenameSms(smsName)}
                </option>
              );
            })}
          </select>
        </div>
        <div className="SMS_phonenumbers items-center pt-0 mt-0 ">
          <span className="text-2xl w-10/12 font-bold mx-auto text-lelf text-white block my-2">Người nhận </span>
          <input value={number} onChange={onChangeNumberPhone} className="bg-white p-4 text-2xl text-left ml-5 w-11/12 outline-none rounded-lg resize-none " />
        </div>
        <div className="SMS_templatesms items-center pt-0 mt-0 ">
          <span className="text-2xl w-10/12 font-bold mx-auto text-lelf text-white block my-2">Mẫu tin </span>
          <select onChange={handleSetSmsTemplate} value={currentSmsTemplate} className="bg-white p-1 text-2xl text-left mx-auto w-11/12 block outline-none rounded-lg resize-none ">
            {listSmsTemplate.map((template: any, index: any) => {
              return (
                <option key={index} value={template.content}>
                  {template.templateName}
                </option>
              );
            })}
          </select>
        </div>
        {currentSmsProvider === gConst.SMS_FPT ? (
          <div className="SMS_fptBrandName items-center pt-0 mt-0 ">
            <span className="text-2xl w-10/12 font-bold mx-auto text-lelf text-white block my-2">Branch Name </span>
            <select onChange={handleSetFptBrandName} value={currentFptBrandName} className="bg-white p-1 text-2xl text-left mx-auto w-11/12 block outline-none rounded-lg resize-none ">
              {listFptBrandName.map((sms: any, index: any) => {
                return (
                  <option key={index} value={sms?.fields?.BrandName}>
                    {sms?.fields?.BrandName}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}
        <div className={currentSmsProvider === gConst.SMS_FPT ? 'SMS_content items-center pt-4 mt-1' : 'SMS_content items-center pt-4 mt-1 h-96'}>
          <textarea spellCheck="false" onChange={handleSetCurrentSMSContent} value={currentSmsContent} className="bg-white p-4 text-2xl text-left mx-auto w-11/12 block outline-none max-h-80 rounded-lg resize-none h-full" />
          <span className="text-base w-10/12 font-bold mx-auto text-right text-white block my-4">
            Tối đa : 335 kí tự, 5 SMS. Hiện tại:{currentSmsContent.length} kí tự, SMS:{currentNumberSMS}{' '}
          </span>
        </div>
        <div className="SMS_note items-center pt-12 mt-1   bottom-8"></div>
        <div className="w-full flex justify-around items-center pb-10">
          <button id="accepted" className="bg-gray-400 my-2 text-2xl w-30 text-white font-bold p-3 px-6 rounded-lg flex justify-center items-center active:bg-gray-800 focus:bg-gray-800" onClick={handleCloseSMS}>
            Close
          </button>
          <button id="accepted" className="bg-yellow-300 my-2 w-30 text-2xl text-black font-bold p-3 px-8 rounded-lg flex justify-center items-center active:bg-yellow-800 focus:bg-yellow-800" onClick={() => send(number)}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default SMSBOX;
