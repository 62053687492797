import axios from 'axios';
import { gdebug } from '../../lib/gdebug';
import { apiIntegration, apiIntegration2, createContact, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from '../../services/gcalls.service';
import { handleDuration, handleStartTimeZoho, handleTimeCreate } from '../../services/sharedFunction';
import { gConst } from '../../lib/gConst';
let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';
let parrentUrl = localStorage.getItem(gConst.STORAGE_PARRENT_URL) || '';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

//custom function for nhanh.vn
function sortArr(arr){
  gdebug(3,"Arr ",arr)
  let newArr = arr.sort((a,b)=>{
      let numA = Number(a.price)
      let numB =Number(b.price)
      return numB - numA
  })
  gdebug(3,"newArr ",newArr)

  return newArr
}

let agentName = gUserData?.fullName || '';
let dataFunction = gUserData?.integrator[parrentHostname]?.info?.dataFunction;
let logFunction = gUserData?.integrator[parrentHostname]?.info?.logFunction;

export async function getDataIframe(display_name, setIframeData, setName, direction) {
  let iframeDatas = {};
  iframeDatas.phone = display_name;
  iframeDatas.isEmpty = true;

  try {
    if (dataFunction && dataFunction.length) {
      dataFunction.forEach(async (e, i) => {
        setTimeout(async () => {
          // let searchField = e.searchField;
          // let searchValue = e.searchValue;
          let dataSearch = e?.data || '';
          if (!!dataSearch) {
            dataSearch = JSON.stringify(dataSearch);

            let replaceData = e.replaceData;

            if (!!replaceData && !!replaceData.length) {
              for (let i = 0; i < replaceData.length; i++) {
                try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value) || '';
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                  dataSearch = dataSearch.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              }
            }
            dataSearch = JSON.parse(dataSearch);
          }
          
          let objectName = e.objectName;
          let templateName = e.templateName;
          let endpointName = e.endpointName;
          let arrayResponse = e.arrayResponse;
          let responseData = e.responseData;
          let response = await apiIntegration2(dataSearch, templateName, endpointName);
          
          if (response.data) {            
            try {                
              
              iframeDatas.isEmpty = false;
              
              let obj = response.data.data.customers[Object.keys(response.data.data.customers)[0]]
              iframeDatas.id = obj.id
              iframeDatas[objectName] = [                
                [
                  {
                    name: obj.name,
                    level: obj.level,
                    totalMoney: numberWithCommas(obj.totalMoney),
                    totalBills: obj.totalBills 
                  }                  
                ]
              ]

              //custom for nhanh.vn
              iframeDatas["billList"] = []
              if(obj.billList && obj.billList.length > 0){                        
                iframeDatas.billList = obj.billList.splice(0, 3)                
                iframeDatas.billList.map((bill) => {
                  if(bill.products.length > 0){
                    bill.products = sortArr(bill.products)
                    bill.products = bill.products.splice(0,3)
                    bill.products.map((product) => {
                      product.price = numberWithCommas(product.price)
                      return product
                    })
                  }
                })
              }

              setIframeData(null);              
              iframeDatas.storeId = gUserData?.integrator[parrentHostname]?.info?.storeId;
              iframeDatas.domain = gUserData?.integrator[parrentHostname]?.info?.domain;
              gdebug(3,"iframeDatas ",iframeDatas)
              setIframeData(iframeDatas);
            } catch (error) {
              gdebug(1,"Error: ", error);
            }
            
          }
        }, i * 1000);
      });
    }
  } catch (error) {
    gdebug(1,"Error: ", error);
  }

  let gcallsContact = await searchContact(display_name);
  if (!!gcallsContact.length) {
    let name = gcallsContact[0].firstName + ' ' + gcallsContact[0].lastName;
    setName(name);
    // searchGroup(gcallsContact[0]._id, async function (res, err) {
    //     if (!err) {
    //         console.log(res)
    //     }
    // })
    // await getCalllogs(gcallsContact[0]._id, async function (res, err) {
    //   if (!err) {
    //     iframeDatas.latestCall = res[0];
    //   }
    // });
  }
  //Get Calllog By Phone
  await getCalllogs(display_name, async function (res, err) {
    if (!err) {
      iframeDatas.latestCall = res[0];
    }
  })
  
  gdebug(3,"iframeDatas ",iframeDatas)
  setIframeData(null);
  setIframeData(iframeDatas);
}

export async function saveLogCRM(calllog, iframeData) {
  gdebug(3,"Calllog ",calllog)

  const calllog_info = { ...calllog };
  const { status, duration, start, direction, recordings_url, playing_recordings_url, phone, end } = calllog_info;

  let record_url = playing_recordings_url //|| recordings_url; //only add playing record
  let callDuration = handleDuration(duration);
  let startTimeZoho = handleStartTimeZoho(start);
  let se_module = '$se_module';

  const directionCustom = direction === gConst.OUTGOING ? 'Outbound' : 'Inbound';
  const subjectTitle = direction === gConst.OUTGOING ? 'Call to ' + phone : 'Call from ' + phone;
  const subjectTitle2 = `[Gcalls] ${agentName} - ${directionCustom} - ${status} - Time: ${handleTimeCreate(start, false)}`;
  const phoneTitle = direction === gConst.OUTGOING ? 'made a phone call to ' + phone : 'received a call from ' + phone;
  const TextContent = `[${agentName}] ${phoneTitle} - Time: ${handleTimeCreate(start, false)} - Direction: ${directionCustom} - Status: ${status} - Duration: ${handleDuration(duration)} - Record: ${record_url}`;
  const HtmlContent = `<b>[${agentName}] ${phoneTitle}</b><br>Time: ${handleTimeCreate(start, false)} <br>Direction: ${directionCustom} <br>Status : ${status} <br>Duration : ${handleDuration(duration)} <br>Record : <a href=${record_url}>${record_url}</a>`;

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  if (logFunction && logFunction.length && dataFunction && dataFunction.length) {
    try {
      logFunction.forEach(async (e, i) => {
        setTimeout(async () => {
          let data = JSON.stringify(e.data);
          let replaceData = e.replaceData;

          if (!!replaceData && !!replaceData.length) {
            for (let i = 0; i < replaceData.length; i++) {
              try {
                let key = Object.keys(replaceData[i])[0];
                let value = replaceData[i][key];
                let replaceVal = '';
                try {
                  replaceVal = eval(value) || '';
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
                data = data.replaceAll(key, replaceVal);
              } catch (error) {
                gdebug(1,"Error: ", error);
              }
            }
          }
          data = JSON.parse(data);

          let templateName = e.templateName;
          let endpointName = e.endpointName;

          const result = await apiIntegration2(data, templateName, endpointName);
          gdebug(3,"Create ticket ",result)
        });
      });
    } catch (error) {
      gdebug(1,"Error: ", error);
    }
  }
}

export async function createSMSNote(data) {
  gdebug(3,"createSMSNote ",data)
}
