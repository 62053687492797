import axios from 'axios'
import { gdebug } from '../lib/gdebug';
import host from '../config/host';
import Swal from 'sweetalert2'
import jwtDecode from 'jwt-decode';
import { decrypt, encrypt, getIntergrator } from '../lib/gUtils';
import { gConst } from '../lib/gConst';
import { refreshTokenKeycloak } from './keycloak.service';

require('dotenv').config()
const { mobileDomain } = host;

export interface ProcessEnv {
  [key: string]: string | undefined
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GCALLS_WEBHOOK_URL?: string;
      REACT_APP_SF_USERNAME?: string;
      REACT_APP_GCALLS_MOBILE?: string;
    }
  }
}
// --------------------------------------------------------------------------------
const createCalllog = async (data: any) => {
  // console.log('this is webhook url', process.env["REACT_APP_SF_USERNAME"], process.env["REACT_APP_GCALLS_WEBHOOK_URL"]);
  let webhookUrl = process.env["REACT_APP_GCALLS_WEBHOOK_URL"];
  try {
    let call_id = data.call_id;
    let email = data.email;
    const { dataCreateCallLog }: any = await axios.get(`${webhookUrl}/calllog/iVEbk1MWF4jYW9Rd20zVzheeiMqcGh6KiUkJk9/${call_id}&email=${email}`);
    return dataCreateCallLog;
  } catch (error: any) {
    gdebug(1,"Error: ", error);
    // throw new Error(error)
  }
}

const login = async (email: string, password: string) => {
  try {
    let result = await axios.post<any>(
      `${mobileDomain}/signin`,
      { email, password }
    ).then(response => response.data)
      .catch(response => { 
        gdebug(1,"Error: ", response)
      })
    if (!result.success) {
      return { err: "Login fail" }
    } else {
      if (!localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER)) {
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      }
    }
    localStorage.setItem(gConst.STORAGE_GCALLS_TOKEN, encrypt(result.result._id))
    return result.result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return { err: "Login fail, Please try again" }
  }
}
// --------------------------------------------------------------------------------
const getSession = async () => {
  gdebug(3,"Get session gcalls ")
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN));
    if (!token) return { err: "invalid session" }
  
    // Swal.fire({
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   showConfirmButton: false,
    //   timer: 120000,
    // })
    // Swal.showLoading();

    let result = await axios.get<any>(
      `${mobileDomain}/session`,
      { headers: { 'x-sessiontoken': token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data);
    
    // Swal.close();

    if (! result || !result.success) return { err: "invalid session" }
    localStorage.setItem(gConst.STORAGE_GCALLS_USER, JSON.stringify(result.result))
    if (!localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER)) {
      localStorage.setItem(gConst.STORAGE_GCALLS_ID_CALLCENTER, result.result.idCallcenter)
    } else {
      if (localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER) !== result.result.idCallcenter) {
        localStorage.setItem(gConst.STORAGE_GCALLS_ID_CALLCENTER, result.result.idCallcenter)
        window.location.reload();
      } else {
        localStorage.setItem(gConst.STORAGE_GCALLS_ID_CALLCENTER, result.result.idCallcenter)
      }
    }
    localStorage.setItem(gConst.STORAGE_GCALLS_ID_AGENT, result.result._id)
    return result.result
  } catch (error:any) {
    gdebug(1,"Error: ", error)
    const isActive = error?.response?.data?.active;
    if(isActive === false){
     return {
      err: "",
      isActive: false
     }
    }else{
      return { err: "get session error" }
    }
    // Swal.close();
    
  }
}
// --------------------------------------------------------------------------------
const postGcallsLog = async (data: any) => {
  const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
  if (!token) return

  try {
    // let search = await searchContact(data.phone)
    // if (search.length == 0) {
    //   let newContact = await createContact(data.phone)
    //   if (newContact.err) return { err: newContact.err }
    //   data.idContact = newContact._id
    //   data.name = newContact.firstName
    // } else {
    //   data.idContact = search[0]._id
    //   data.name = search[0].firstName
    // }
    data.idCallcenter = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER)
    data.idAgent = localStorage.getItem(gConst.STORAGE_GCALLS_ID_AGENT)
    let validKey = ["idAgent", "recordUrl", "direction", "duration", "status", "start", "end", "source", "destination", "phone", "recordings_url","playing_recordings_url",
      "idContact", "browser", "name", "email", "sip_uri", "agent_name", "flowid", "readyTime", "reason", "call_id", "uuid", "idCallcenter", "hotline", "press",
      "buttonName", "inputPhone", "website", "wss", "answer", "deviceInfo"
    ]

    Object.keys(data).forEach((key) => {
      if (validKey.findIndex(x => x == key) == -1) {
        delete data[key]
      }
    })
    let result = await axios.post(
      `${mobileDomain}/calllog`,
      data,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    )
    return result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return { err: "create call log fail" }
  }
}

const logout = async () => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.post(
      `${mobileDomain}/signout`, {},
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    localStorage.removeItem(gConst.STORAGE_GCALLS_TOKEN)
    return true
  } catch (error) {
    gdebug(1,"Error: ", error)
    return true
  }

}
// --------------------------------------------------------------------------------
const searchContact = async (phone: string) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.get(
      `${mobileDomain}/contacts/search?q=${phone}&on=phone&mode=exact`,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    return result.result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return []
  }
}
// --------------------------------------------------------------------------------
const searchContact2 = async (phone: string, callback: any) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.get(
      `${mobileDomain}/contacts/search?q=${phone}&on=phone&mode=exact`,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    if (typeof callback === 'function') {
      callback(result.result, null)
    }
    return result.result
  } catch (error) {
    if (typeof callback === 'function') {
      callback(null, error)
    }
    gdebug(1,"Error: ", error)
    return []
  }
}
// --------------------------------------------------------------------------------
const searchGroup = async (id: string, callback: any) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.get(
      `${mobileDomain}/contactgroup/${id}/contacts`,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    if (typeof callback === 'function') {
      callback(result.result, null)
    }
    return result.result
  } catch (error) {
    if (typeof callback === 'function') {
      callback(null, error)
    }
    gdebug(1,"Error: ", error)
    return []
  }
}
// --------------------------------------------------------------------------------
const createContact = async (contact: any) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.post(
      `${mobileDomain}/contact`,
      contact,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    if (!result.success) return { err: "create contact error" }
    return result.result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return { err: "create contact error" }
  }
}
// --------------------------------------------------------------------------------
const updateContact = async (id: string, contact: any) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let result: any = await axios.put(
      `${mobileDomain}/contact/${id}`,
      contact,
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    if (!result.success) return { err: "update contact error" }
    return result.result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return { err: "update contact error" }
  }
}
// --------------------------------------------------------------------------------
const sendSMSIris = async (dataSMS: any) => {
  const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
  try {
    //fix endpoint send sms iris with new integration
    // let data: any = await axios.post(`${mobileDomain}/integration/send_sms_iris`, dataSMS, {
    let data: any = await axios.post(`${mobileDomain}/integration/iris/endpoint/send_sms_iris`, dataSMS, {
      headers: {
        'x-sessiontoken': sessionToken,
        'Authorization': 'Bearer ' + sessionToken
      }
    })
      .then((res) => res.data)
    // .then((res) => {
    //   return res
    // }).catch((error: any) => {
    //   if(error.response.status === 500){
    //     return ({
    //       Code: error.response.status,
    //       Message: "Hệ thống gửi SMS của IRIS đang gặp lỗi timeout. Vui lòng liên hệ nhà cung cấp SMS của bạn."
    //     })
    //   }else{
    //     return ({
    //       Code: error.response.status,
    //       Message: error.response.data ? error.response.data.error.message : 'Error'
    //     })
    //   }
    // })

    return data?.data;
  } catch (error: any) {
    gdebug(1,"Error: ", error)
    if (error?.response?.status === 500) {
      return ({
        Code: error?.response?.status,
        Message: "Hệ thống gửi SMS của IRIS đang gặp lỗi timeout. Vui lòng liên hệ nhà cung cấp SMS của bạn."
      })
    } else if (error?.response?.status === 404) {
      return ({
        Code: error?.response?.status,
        Message: "API Not Found."
      })
    } else {
      return ({
        Code: error?.response?.status,
        Message: 'Authorization has been denied for this request.'
      })
    }
  }
}
// --------------------------------------------------------------------------------
const sendSMSVietGuy = async (dataSMS: any) => {
  const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
  let data: any = await axios.post(`${mobileDomain}/vietguy/sms`, dataSMS, {
    headers: {
      'x-sessiontoken': sessionToken,
      'Authorization': 'Bearer ' + sessionToken
    }
  })
    .then((res) => res.data)
  return data;
}
// --------------------------------------------------------------------------------
const getSMSTemplates = async (idCallcenter: any) => {
  try {
    const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let templates = await axios.get(`${mobileDomain}/templates/all`, {
      headers: {
        'x-sessiontoken': sessionToken,
        'Authorization': 'Bearer ' + sessionToken
      }
    }).then(res => res.data).catch(err => {
      gdebug(1,"Error: ", err)
    });
    let result: any = []
    if (templates.success && templates.result.length) {
      result = templates.result.filter((e: any) => e.type === 'sms')
    }
    return result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return []
  }
}

const createSMSLogs = async (logs: any) => {
  const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
  await axios.post(`${mobileDomain}/contact/${logs.idContact}/activity`, logs, {
    headers: {
      'x-sessiontoken': sessionToken,
      'Authorization': 'Bearer ' + sessionToken
    }
  })
}
// --------------------------------------------------------------------------------
const getCalllogs = async (phone: any, callback: any) => {
  try {
    // let query = `query=${JSON.stringify({ idContact: phone, type: 'calllog'})}`;
    const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    // let listCalllogs = await axios.get(`${mobileDomain}/activities?${query}`, {
    //   headers: {
    //     'x-sessiontoken': sessionToken,
    //     'Authorization': 'Bearer ' + sessionToken
    //   }
    // }).then(response => response.data)
    //Get Calllog By Phone
    let query = `query=${JSON.stringify({ type: 'calllog'})}`;
    let listCalllogs = await axios.get(`${mobileDomain}/calllogs/phone/${phone}?${query}`, {
      headers: {
        'x-sessiontoken': sessionToken,
        'Authorization': 'Bearer ' + sessionToken
      }
    }).then(response => response.data)
    if(listCalllogs?.result?.length) {
      listCalllogs.result.map((e:any,i:any)=>{
        e.body = {...e}
        e.agent = {
          fullName: e.agent_name
        }
      })
    }
    if (typeof callback === 'function') {
      callback(listCalllogs.result, null)
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback(null, error)
    }
  }
};
// --------------------------------------------------------------------------------
const apiIntegration = async(dataSearch:any, template:any, endpoint:any, callback:any) => {
  try {
    const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let contactData = await axios.post(`${mobileDomain}/integration/${template}/endpoint/${endpoint}`, dataSearch,{
      headers: {
        'x-sessiontoken': sessionToken,
        'Authorization': 'Bearer ' + sessionToken
      }
    }).then(response => response.data)


    console.log(contactData,'contactData');

    if (typeof callback === 'function') {
      callback(contactData, null)
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback(null, error)
    }
  }
};
// --------------------------------------------------------------------------------
const apiIntegration2 = async(dataSearch2:any, template:any, endpoint:any) => {
  try {
    const sessionToken = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let contactData = await axios.post(`${mobileDomain}/integration/${template}/endpoint/${endpoint}`,dataSearch2,{
      headers: {
        'x-sessiontoken': sessionToken,
        'Authorization': 'Bearer ' + sessionToken
      }
    }).then(response => response.data)
    return contactData
  } catch (error) {
    gdebug(1,"Error: ", error)
  }
};


// --------------------------------------------------------------------------------
const updateAgentStatus = async (status: string) => {
  try {
    const token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN))
    let device = localStorage.getItem(gConst.STORAGE_DEVICE)

    if(!device){
      device = 'mobile';
    }
    
    let result: any = await axios.put(
      `${mobileDomain}/agent/status`,
      {status: status, device: device},
      { headers: { "x-sessiontoken": token, 'Authorization': 'Bearer ' + token } }
    ).then(response => response.data)
    if (!result.success) return { err: "update contact error" }
    return result.result
  } catch (error) {
    gdebug(1,"Error: ", error)
    return { err: "update contact error" }
  }
}

// --------------------------------------------------------------------------------
const isTokenExpired = async (token : any) => {
  try {
    const decodedToken : any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    const expiresInMinutes = Math.floor((decodedToken.exp - currentTime) / 60);
    // alert(`Token expires in ${expiresInMinutes} minutes.`);

    return decodedToken.exp < currentTime;
  } catch (err) {
    let result = await refreshTokenKeycloak()

    if(result.success){
      return false
    }

    return true; // Token is invalid or cannot be decoded
  }
};

// --------------------------------------------------------------------------------
const apiHook = async (pCalllog : any) => {

  let result = {success: false}


  let validKey = ["idAgent","hookUrl","eventType", "recordUrl", "direction", "duration", "status", "start", "end", "source", "destination", "phone", "recordings_url","playing_recordings_url",
  , "browser", "name", "email", "sip_uri", "agent_name", "flowid", "readyTime", "reason", "call_id", "uuid", "idCallcenter", "hotline", "press",
  , "website", "wss", "answer"
]


  Object.keys(pCalllog).forEach((key) => {
    if (validKey.findIndex(x => x == key) == -1) {
      delete pCalllog[key]
    }
  })

  let integrator = getIntergrator();
  let templateName = integrator.templateName


  if(pCalllog.eventType === gConst.CALL_EVENT_OUTGOING){
    result = await apiIntegration2(pCalllog,templateName,gConst.HOOK_OUTGOING)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_PROGRESS){
    result = await apiIntegration2(pCalllog,templateName,gConst.HOOK_PROGRESS)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_FAILED){
    result = await apiIntegration2(pCalllog,templateName, gConst.HOOK_FAILED)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_CONFIRMED){
    result = await apiIntegration2(pCalllog,templateName,gConst.HOOK_CONFIRMED)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_ACCEPTED){
    result = await apiIntegration2(pCalllog,templateName, gConst.HOOK_ACCEPTED)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_ONTRACK){
    result = await apiIntegration2(pCalllog,templateName,gConst.HOOK_ONTRACK)

  }

  if(pCalllog.eventType === gConst.CALL_EVENT_ENDED){
    result = await apiIntegration2(pCalllog,templateName,gConst.HOOK_ENDED)

  }

  
}

export { createCalllog, login, getSession, postGcallsLog, logout, sendSMSIris, sendSMSVietGuy, getSMSTemplates, searchContact, searchContact2, createContact, createSMSLogs, getCalllogs ,apiIntegration, apiIntegration2, updateContact, searchGroup, updateAgentStatus, isTokenExpired, apiHook }