import axios from 'axios';
import { gdebug } from '../../lib/gdebug';
import { apiIntegration, apiIntegration2, createContact, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from '../../services/gcalls.service';
import { handleDuration, handleStartTimeZoho, handleTimeCreate, handleTimeBitrix, timeout, handleTimePipedrive, handleTimeFS } from '../../services/sharedFunction';
import { doFormatPhoneNumber, findPropertyInNestedStructure } from '../../lib/gUtils';
//Handle dynamic import later
import * as customFunctions from '../../customCrmFunction/sieuviet';
import host from '../../config/host';
import { gConst } from '../../lib/gConst';

const { recordHost, enablePouchDbLog } = host;

let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';
let parrentUrl = localStorage.getItem(gConst.STORAGE_PARRENT_URL) || '';


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function sumArray(arr){

  let total = 0;

  for(let i = 0; i<arr.length ; i++){

      total += arr[i];
  }
  return total;
}

function getText(text){
  return text;
}

function setCallName(iframeDatas,setName){
  // todo: setName Rule for CRM

  //tel4 - sieuvet
if (iframeDatas?.Contacts?.length > 0 && (gUserData.idCallcenter === 'GsbrvdoBq' || gUserData.idCallcenter === 'jT66ArHJ9')) {
    let name = iframeDatas?.Contacts?.[0]?.[0]?.display_name || '';
    setName(name);
  }
  

}



  // longest 4 params
  // pFunctionName map with funcitonName in .js - config from portal : lookat example at freshsales sieuviet
  function customFunction(pFunctionName =null , pVal1 = null , pVal2 = null, pVal3 = null, pVal4 = null ) {
    // Dynamic custom function here

    let dynamicFunctionName = pFunctionName
    const customFunction = customFunctions[dynamicFunctionName];
  
    let val1, val2, val3, val4;
  
    try {
      val1 = eval(pVal1);
    } catch (error) {
      val1 = pVal1;
    }
  
    try {
      val2 = eval(pVal2);
    } catch (error) {
      val2 = pVal2;
    }
  
    try {
      val3 = eval(pVal3);
    } catch (error) {
      val3 = pVal3;
    }


      
    try {
      val4 = eval(pVal4);
    } catch (error) {
      val4 = pVal4;
    }
  
    try {
      // Call the imported function directly
      const value = customFunction(val1, val2, val3, val4);
      // console.log('customContact',value)
      return value;
    } catch (error) {
      console.log(error, 'err from custom func');
    }
  }


let integrator = gUserData?.integrator[parrentHostname];

let agentName = gUserData?.fullName || '';
let dataFunction = gUserData?.integrator[parrentHostname]?.info?.dataFunction;
let logFunction = gUserData?.integrator[parrentHostname]?.info?.logFunction;
let preLogFunction = gUserData?.integrator[parrentHostname]?.info?.preLogFunction;
let smsFunction = gUserData?.integrator[parrentHostname]?.info?.smsFunction;

let empty = '';
let zero = 0;

export async function getDataIframe(display_name, setIframeData, setName, direction) {


  
  let iframeDatas = {};
  iframeDatas.phone = display_name;
  iframeDatas.isEmpty = true;
  
  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  let currentCrmUser = localStorage.getItem(gConst.STORAGE_CURRENT_CRM_USER);
  if(currentCrmUser){
      currentCrmUser = JSON.parse(currentCrmUser)
      iframeDatas.currentCrmUser = currentCrmUser
  }


  if (Array.isArray(integrator) && integrator.length) {
    for (let i = 0; i < integrator.length; i++) {
      let dataFunctions = integrator[i]?.info?.dataFunction;
      try {
        if (dataFunctions && dataFunctions.length) {
          let dFLength = dataFunctions.length;
          let i = 0;
          (async () => {
            while (i < dFLength) {
              let e = dataFunctions[i];
              let dataSearch = e?.data || '';
              if (!!dataSearch) {
                dataSearch = JSON.stringify(dataSearch);

                let replaceData = e.replaceData;

                if (!!replaceData && !!replaceData.length) {
                  for (let i = 0; i < replaceData.length; i++) {
                    try {
                      let key = Object.keys(replaceData[i])[0];
                      let value = replaceData[i][key];
                      let replaceVal = '';
                      try {
                        replaceVal = eval(value);
                      } catch (error) {
                        gdebug(1,"Error ", error)
                      }
                      dataSearch = dataSearch.replaceAll(key, replaceVal);
                    } catch (error) {
                      gdebug(1,"Error ", error)
                    }
                  }
                }
                dataSearch = JSON.parse(dataSearch);
              }

              let objectName = e.objectName;
              let templateName = e.templateName;
              let endpointName = e.endpointName;
              let arrayResponse = e.arrayResponse;
              let responseData = e.responseData;
              let unCheckEmpty = e.unCheckEmpty;

              if(!iframeDatas[templateName]) {
                iframeDatas[templateName] = { isEmpty: true };
              }
              let response = await apiIntegration2(dataSearch, templateName, endpointName);

              if (response.data) {
                if (!!arrayResponse) {
                  try {
                    if (arrayResponse === 'none') {
                      arrayResponse = 'response?.data';
                    } else {
                      arrayResponse = 'response?.data?.' + arrayResponse;
                    }
                    eval(arrayResponse).forEach((item, i) => {
                      if (responseData) {
                        let ObjectItem = [];
                        if (Array.isArray(responseData) && responseData.length) {
                          let ObjectItem1 = {};
                          for (const item3 of responseData) {
                            let key = Object.keys(item3)[0];
                            let value = item3[key];
                            value = 'item?.' + value;

                            ObjectItem1[key] = eval(value);
                          }
                          ObjectItem.push(ObjectItem1);
                        }
                        if (i == 0) {
                          iframeDatas[objectName] = [];
                          iframeDatas[objectName].push(ObjectItem);
                        } else {
                          iframeDatas[objectName].push(ObjectItem);
                        }
                      } else {
                        if (i == 0) {
                          iframeDatas[objectName] = [];
                          iframeDatas[objectName].push(item);
                        } else {
                          iframeDatas[objectName].push(item);
                        }
                      }
                      if (iframeDatas[objectName]?.length && !unCheckEmpty) {
                        iframeDatas.isEmpty = false;
                        iframeDatas[templateName].isEmpty = false;
                      }
                      setIframeData(null);
                      setIframeData(iframeDatas);
                    });
                  } catch (error) {
                    gdebug(1,"Error ", error)
                  }
                } else {
                  try {
                    if (responseData) {
                      let itemResponseData = response.data;
                      let ObjectItem = [];
                      if (Array.isArray(responseData) && responseData.length) {
                        let ObjectItem1 = {};
                        for (const item3 of responseData) {
                          let key = Object.keys(item3)[0];
                          let value = item3[key];
                          value = 'itemResponseData?.' + value;

                          ObjectItem1[key] = eval(value);
                        }
                        ObjectItem.push(ObjectItem1);
                      }
                      iframeDatas[objectName] = [];
                      iframeDatas[objectName].push(ObjectItem);
                    } else {
                      iframeDatas[objectName] = [];
                      iframeDatas[objectName].push(item);
                    }
                    if (iframeDatas[objectName]?.length && !unCheckEmpty) {
                      iframeDatas.isEmpty = false;
                      iframeDatas[templateName].isEmpty = false;
                    }
                    setIframeData(null);
                    setIframeData(iframeDatas);
                  } catch (error) {
                    gdebug(1,"Error ", error)
                  }
                  let item = response.data;
                }
              }
              i++;
              // await timeout(1000);
            }
          })();
        }
      } catch (error) {
        gdebug(1,"Error ", error)
      }
    }
  } else {
    try {
      if (dataFunction && dataFunction.length) {
        let dFLength = dataFunction.length;
        let i = 0;
        (async () => {
          while (i < dFLength) {
            let e = dataFunction[i];
            let dataSearch = e?.data || '';
            if (!!dataSearch) {
              dataSearch = JSON.stringify(dataSearch);

              let replaceData = e.replaceData;

              if (!!replaceData && !!replaceData.length) {
                for (let i = 0; i < replaceData.length; i++) {
                  try {
                    let key = Object.keys(replaceData[i])[0];
                    let value = replaceData[i][key];
                    let replaceVal = '';
                    try {
                      replaceVal = eval(value);
                    } catch (error) {
                      gdebug(1,"Error ", error)
                    }
                    dataSearch = dataSearch.replaceAll(key, replaceVal);
                  } catch (error) {
                    gdebug(1,"Error ", error)
                  }
                }
              }
              dataSearch = JSON.parse(dataSearch);
            }

            let objectName = e.objectName;
            let templateName = e.templateName;
            let endpointName = e.endpointName;
            let arrayResponse = e.arrayResponse;
            let responseData = e.responseData;
            let pWrappedData = e.wrappedData || null;
    
            let response = await apiIntegration2(dataSearch, templateName, endpointName);
            let unCheckEmpty = e.unCheckEmpty;


            if (response?.data) {
              if (!!arrayResponse) {
                try {
                  if (arrayResponse === 'none') {
                    arrayResponse = 'response?.data';
                  } else {
                    arrayResponse = 'response?.data?.' + arrayResponse;
                  }
                  eval(arrayResponse).forEach((item, i) => {
                    if (responseData) {
                      let ObjectItem = [];
                      if (Array.isArray(responseData) && responseData.length) {
                        let ObjectItem1 = {};
                        for (const item3 of responseData) {
                          let key = Object.keys(item3)[0];
                          let value = item3[key];
                          value = 'item?.' + value;

                          ObjectItem1[key] = eval(value);
                        }
                        ObjectItem.push(ObjectItem1);
                      }
                      if (i == 0) {
                        iframeDatas[objectName] = [];
                        iframeDatas[objectName].push(ObjectItem);
                      } else {
                        iframeDatas[objectName].push(ObjectItem);
                      }
                    } else {
                      if (i == 0) {
                        iframeDatas[objectName] = [];
                        iframeDatas[objectName].push(item);
                      } else {
                        iframeDatas[objectName].push(item);
                      }
                    }
                    if (iframeDatas[objectName]?.length && !unCheckEmpty) {
                      iframeDatas.isEmpty = false;
                    }
                    setIframeData(null);
                    setIframeData(iframeDatas);

                    // easier to handle inframeDatas everywhere in integrator (sieuviet.js)  
                    gUserData.iframeDatas = iframeDatas

                  });
                } catch (error) {
                  gdebug(1,"Error ", error)
                }
              } else {
                try {
                  if (responseData) {

                    let itemResponseData = response.data; 

                    // implement wrapdata --------------------------------------
                    // for example : we have result : [{obj1},{obj2}]
                    // pWrappedData = users => result : users:[{obj1},{obj2}]


                    if (pWrappedData && Array.isArray(itemResponseData)) {
                      let wrappedData = { [pWrappedData]: itemResponseData };
                      wrappedData =  { [pWrappedData]: itemResponseData };
                      itemResponseData = wrappedData
                    }
                    // ------------------------------------------------------
                    try {
                    // map custom func for each integrator ( sieuviet.js, setup from portal)
                        itemResponseData.customFunction = function(pFuntionName,pVal1, pVal2, pVal3, pVal4) {
      
                          let val1, val2, val3, val4

                          try {
                            val1 = eval(pVal1)
                          } catch (error) {
                            val1 = pVal1
                          }

                          try {
                            val2 = eval(pVal2)
                          } catch (error) {
                            val2 = pVal2
                          }

                          try {
                            val3 = eval(pVal3)
                          } catch (error) {
                            val3 = pVal3
                          }

                          try {
                            val4 = eval(pVal4)
                          } catch (error) {
                            val4 = pVal4
                          }

                          const result = customFunction(pFuntionName, val1, val2, val3, val4);
                          return result
                        };
                      } catch (error) {
                          console.log(error,'error')
                      }

            
                    let ObjectItem = [];
                    if (Array.isArray(responseData) && responseData.length) {
                      let ObjectItem1 = {};
                      for (const item3 of responseData) {
                        let key = Object.keys(item3)[0];
                        let value = item3[key];
                        value = 'itemResponseData?.' + value;
                        ObjectItem1[key] = eval(value);
                      }
                      ObjectItem.push(ObjectItem1);
                    }


          
                    iframeDatas[objectName] = [];
                    iframeDatas[objectName].push(ObjectItem);
                  } else {
                    iframeDatas[objectName] = [];
                    iframeDatas[objectName].push(item);
                  }

                  // if login to crm through api ( portal ), default varible was crmLoginUID
                  let crmLoginUID = findPropertyInNestedStructure(iframeDatas, "crmLoginUID")
                  // remove all undefined object (customFunc from portal)
                  let id = findPropertyInNestedStructure(iframeDatas[objectName], "id")

                  if(!id.value){
                    // delete obj if any id is undefine
                    delete iframeDatas[objectName]
                  }

                  // -----------------------------------------------------------------------
                  if(crmLoginUID?.value){
                    //delete OBJ after get UserLoginInfo From Api 
                    //default varible name in intergrator is crmLoginUID
                    gUserData.crmLoginUID = crmLoginUID.value
                    iframeDatas.crmLoginUID = crmLoginUID.value
                    localStorage.setItem(gConst.STORAGE_CRM_LOGIN_UID,crmLoginUID.value)
                    delete iframeDatas[crmLoginUID.name];
                  }

                  
                  if (iframeDatas[objectName]?.length && !unCheckEmpty) {
                    iframeDatas.isEmpty = false;
                  }
     
                  setIframeData(null);
                  setIframeData(iframeDatas);
                  gUserData.iframeDatas = iframeDatas


                  setCallName(iframeDatas, setName)


                } catch (error) {
                  gdebug(1,"Error ", error)
                }
                let item = response.data;
              }
            }
            i++;
            // await timeout(1000);
          }
        })();
      }
    } catch (error) {
      gdebug(1,"Error ", error)
    }
  }

  let gcallsContact = await searchContact(display_name);
  if (!!gcallsContact.length) {
    let name = gcallsContact[0].firstName + ' ' + gcallsContact[0].lastName;
    setName(name);
    // searchGroup(gcallsContact[0]._id, async function (res, err) {
    //     if (!err) {
    //         console.log(res)
    //     }
    // })
    // await getCalllogs(gcallsContact[0]._id, async function (res, err) {
    //   if (!err) {
    //     iframeDatas.latestCall = res[0];
    //   }
    // });
  }
  //Get Calllog By Phone
  await getCalllogs(display_name, async function (res, err) {
    if (!err) {
      iframeDatas.latestCall = res[0];
    }
  })
  // console.log('iframeDatas ', iframeDatas);
  gdebug(3,"iframeDatas ", iframeDatas)
  setIframeData(null);
  setIframeData(iframeDatas);
}


export async function saveLogCRM(calllog, iframeData) {
  gdebug(3,"Calllog: ", calllog)
  const handleTimeCreate2 = handleTimeCreate;
  const handleDuration2 = handleDuration;
  const handleTimeBitrix2 = handleTimeBitrix;
  const handleTimePipedrive2 = handleTimePipedrive;

  const calllog_info = { ...calllog };

  let crmLogs = []

  // check sieuviet.js - to get calllog 
  // gUserData.calllog_info = calllog_info
  // -----------------------------------
  const { status, duration, start, direction, recordings_url, playing_recordings_url, phone, end } = calllog_info;
  // -------------------------------------
  let extension = null
  if(calllog_info?.sip_uri){
   extension = calllog_info?.sip_uri?.split('@')[0] || ''; // freshsale sieuviet
  }
  // -------------------------------------

  let record_url = playing_recordings_url // || recordings_url; fix only add playing record
  let callDuration = handleDuration(duration);
  let startTimeZoho = handleStartTimeZoho(start);
  // ---------------------------------------------------------------------------------
  // freshales sieuviet
  const startTimeFS = handleTimeFS(start)
  const endTimeFS = handleTimeFS(end)
  const endtimeSaleActivityFS = handleTimeFS(end + 3600)

  // ------------------------------------------------------------------------------------
  let hotline = doFormatPhoneNumber(calllog_info?.hotline) 
  let se_module = '$se_module';

  const directionCustom = direction === gConst.OUTGOING ? 'Outbound' : 'Inbound';
  const directionEnum = direction === gConst.OUTGOING ? 2 : 1;
  const subjectTitle = direction === gConst.OUTGOING ? 'Call to ' + phone : 'Call from ' + phone;
  let subjectTitle2 = `[Gcalls] ${agentName} - ${directionCustom} - ${phone} - Time: ${handleTimeCreate(start, false)} - Hotline: ${calllog_info.hotline ? calllog_info.hotline : ""}`;
  let subjectTitle2Tel4 = `[Tel4] - ${directionCustom} - ${phone} - Time: ${handleTimeCreate(start, false)} - Hotline: ${calllog_info.hotline ? calllog_info.hotline : ""}`;


  const phoneTitle = direction === gConst.OUTGOING ? 'made a phone call to ' + phone : 'received a call from ' + phone;
  const TextContent = `[${agentName}] ${phoneTitle} - Time: ${handleTimeCreate(start, false)} - Direction: ${directionCustom} - Status: ${status} - Duration: ${handleDuration(duration)} - Hotline: ${calllog_info.hotline ? calllog_info.hotline : ""} - Record: ${record_url}`;
  const HtmlContent = `<b>[${agentName}] ${phoneTitle}</b><br>Time: ${handleTimeCreate(start, false)} <br>Direction: ${directionCustom} <br>Status : ${status} <br>Duration : ${handleDuration(duration)} <br>Record : <a href=${record_url}>${record_url}</a>`;

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }



  if (Array.isArray(integrator) && integrator.length) {
    for (let i = 0; i < integrator.length; i++) {
      logFunction = integrator[i]?.info?.logFunction;
      if (logFunction && logFunction.length) {
        try {

          for (let i = 0; i < logFunction.length; i++) {
            const e = logFunction[i];
              let data = JSON.stringify(e.data);
              let replaceData = e.replaceData;

              if (!!replaceData && !!replaceData.length) {
                for (let i = 0; i < replaceData.length; i++) {
                  try {
                    let key = Object.keys(replaceData[i])[0];
                    let value = replaceData[i][key];
                    let replaceVal = '';
                    try {
                      replaceVal = eval(value);
                    } catch (error) {
                      gdebug(1,"Error: ", error)
                    }
                    data = data.replaceAll(key, replaceVal);
                  } catch (error) {
                    gdebug(1,"Error: ", error)
                  }
                }
              }
              data = JSON.parse(data);

              let templateName = e.templateName;
              let endpointName = e.endpointName;

              if(enablePouchDbLog){
                let logObj = {
                  data : data,
                  templateName : templateName,
                  endpointName : endpointName
                }
  
                crmLogs.push(logObj)
              }else{
                const result = await apiIntegration2(data, templateName, endpointName);
                gdebug(3,"Create ticket ",result);
              }
          }




        } catch (error) {
          gdebug(1,"Error: ", error)
        }
      }
    }
  } else {
    if (logFunction && logFunction.length) {
      try {

        for (let i = 0; i < logFunction.length; i++) {
          const e = logFunction[i];
          let data = JSON.stringify(e.data);
          let replaceData = e.replaceData;
          if (!!replaceData && !!replaceData.length) {
            for (let i = 0; i < replaceData.length; i++) {
              try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                  data = data.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error)
                }
            }
          }
          data = JSON.parse(data);
          let templateName = e.templateName;
          let endpointName = e.endpointName;

          if(enablePouchDbLog){
            let logObj = {
              data : data,
              templateName : templateName,
              endpointName : endpointName
            }
  
            crmLogs.push(logObj);
          }else{
            const result = await apiIntegration2(data, templateName, endpointName);
            gdebug(3,"Create ticket ",result);
          }
        }

        return crmLogs;
      } catch (error) {
        gdebug(1,"Error: ", error);
      }
    }
  }
}

export async function createSMSNote(data) {
  gdebug(3,"createSMSNote ",data)
  const {phone, sms, status_sms, objectData} = data
  const handleTimeCreate2 = handleTimeCreate;
  const handleDuration2 = handleDuration;
  const handleTimeBitrix2 = handleTimeBitrix;
  const handleTimePipedrive2 = handleTimePipedrive;

  let content = `[Gcalls] You sent an SMS to ${phone} \n.Status: ${status_sms} \n.Content: ${sms}`
  let contentHtml = `[Gcalls] You sent an SMS to ${phone}.<br/><b>Status:</b> ${status_sms}<br/><b>Content:</b> ${sms}`

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  if (Array.isArray(integrator) && integrator.length) {
    for (let i = 0; i < integrator.length; i++) {
      smsFunction = integrator[i]?.info?.smsFunction;
      if (smsFunction && smsFunction.length) {
        try {
          smsFunction.forEach(async (e, i) => {
            setTimeout(async () => {
              let data = JSON.stringify(e.data);
              let replaceData = e.replaceData;

              if (!!replaceData && !!replaceData.length) {
                for (let i = 0; i < replaceData.length; i++) {
                  try {
                    let key = Object.keys(replaceData[i])[0];
                    let value = replaceData[i][key];
                    let replaceVal = '';
                    try {
                      replaceVal = eval(value);
                    } catch (error) {
                      gdebug(1,"Error: ", error)
                    }
                    data = data.replaceAll(key, replaceVal);
                  } catch (error) {
                    gdebug(1,"Error: ", error)
                  }
                }
              }
              data = JSON.parse(data);

              let templateName = e.templateName;
              let endpointName = e.endpointName;

              const result = await apiIntegration2(data, templateName, endpointName);
              gdebug(3,"Create ticket ",result)
            });
          });
        } catch (error) {
          gdebug(1,"Error: ", error)
        }
      }
    }
  } else {
    if (smsFunction && smsFunction.length) {
      try {
        smsFunction.forEach(async (e, i) => {
          setTimeout(async () => {
            let data = JSON.stringify(e.data);
            let replaceData = e.replaceData;

            if (!!replaceData && !!replaceData.length) {
              for (let i = 0; i < replaceData.length; i++) {
                try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value);
                  } catch (error) {
                    gdebug(1,"Error: ", error)
                  }
                  data = data.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error)
                }
              }
            }
            data = JSON.parse(data);

            let templateName = e.templateName;
            let endpointName = e.endpointName;

            const result = await apiIntegration2(data, templateName, endpointName);
            gdebug(3,"Create ticket ",result)
          });
        });
      } catch (error) {
        gdebug(1,"Error: ", error)
      }
    }
  }
}

export async function savePreLogCRM(calllog, iframeData, setIframeData) {
  const calllog_info = { ...calllog };
  const { start, direction, phone } = calllog_info;
  const handleTimeCreate2 = handleTimeCreate;
  const handleDuration2 = handleDuration;
  const handleTimeBitrix2 = handleTimeBitrix;
  const handleTimePipedrive2 = handleTimePipedrive;

  let startTimeZoho = handleStartTimeZoho(start);
  let se_module = '$se_module';

  const directionCustom = direction === gConst.OUTGOING ? 'Outbound' : 'Inbound';
  const directionEnum = direction === gConst.OUTGOING ? 2 : 1;
  const subjectTitle = direction === gConst.OUTGOING ? 'Call to ' + phone : 'Call from ' + phone;
  const subjectTitle2 = `[Gcalls] ${agentName} - ${directionCustom} - ${phone} - Time: ${handleTimeCreate(start, false)} - Hotline: ${calllog_info.hotline ? calllog_info.hotline : ""}`;
  const phoneTitle = direction === gConst.OUTGOING ? 'made a phone call to ' + phone : 'received a call from ' + phone;
  const TextContent = `[${agentName}] ${phoneTitle} - Time: ${handleTimeCreate(start, false)} - Direction: ${directionCustom} - Hotline: ${calllog_info.hotline ? calllog_info.hotline : ""}`;
  const HtmlContent = `<b>[${agentName}] ${phoneTitle}</b><br>Time: ${handleTimeCreate(start, false)} <br>Direction: ${directionCustom}`;

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  if (Array.isArray(integrator) && integrator.length) {
    for (let i = 0; i < integrator.length; i++) {
      preLogFunction = integrator[i]?.info?.preLogFunction;
      if (preLogFunction && preLogFunction.length) {
        try {
          preLogFunction.forEach(async (e, i) => {
            setTimeout(async () => {
              let data = JSON.stringify(e.data);
              let replaceData = e.replaceData;
              let responseData = e.responseData;
              let quickOpenUrl = e.quickOpenUrl;
              let templateName = e.templateName;
              let endpointName = e.endpointName;

              if(!templateName || !endpointName) {
                if (!!quickOpenUrl) {
                  let url = quickOpenUrl.Url;
                  if (!!quickOpenUrl.replaceData && !!quickOpenUrl.replaceData.length) {
                    for (let i = 0; i < quickOpenUrl.replaceData.length; i++) {
                      try {
                        let key = Object.keys(quickOpenUrl.replaceData[i])[0];
                        let value = quickOpenUrl.replaceData[i][key];
                        let replaceVal = '';
                        try {
                          replaceVal = eval(value);
                        } catch (error) {
                          gdebug(1,"Error: ", error)
                        }
                        url = url.replaceAll(key, replaceVal);
                      } catch (error) {
                        gdebug(1,"Error: ", error)
                      }
                    }
                  }
                  window.open(url, '_blank');
                }
                return;
              }

              if (!!replaceData && !!replaceData.length) {
                for (let i = 0; i < replaceData.length; i++) {
                  try {
                    let key = Object.keys(replaceData[i])[0];
                    let value = replaceData[i][key];
                    let replaceVal = '';
                    try {
                      replaceVal = eval(value);
                    } catch (error) {
                      gdebug(1,"Error: ", error)
                    }
                    data = data.replaceAll(key, replaceVal);
                  } catch (error) {
                    gdebug(1,"Error: ", error)
                  }
                }
              }
              data = JSON.parse(data);

              gdebug(3,"createTicket ")
              const result = await apiIntegration2(data, templateName, endpointName);
              if (!!responseData && !!responseData.length) {
                let iframeDatas = Object.assign({}, iframeData);
                for (let i = 0; i < responseData.length; i++) {
                  try {
                    let key = Object.keys(responseData[i])[0];
                    let value = responseData[i][key];

                    let keyArray = key.split('.');
                    for (let j = 0; j < keyArray.length; j++) {
                      let k = keyArray[j];
                      if (j < keyArray.length - 1) {
                        iframeDatas[k] = {};
                      } else {
                        iframeDatas[keyArray[j - 1]][k] = eval(value);
                      }
                    }
                  } catch (error) {
                    gdebug(1,"Error: ", error)
                  }
                  gdebug(3,"iframeDatas ",iframeDatas)
                  setIframeData(null);
                  setIframeData(iframeDatas);
                }
              }
              if (!!quickOpenUrl) {
                let url = quickOpenUrl.Url;
                if (!!quickOpenUrl.replaceData && !!quickOpenUrl.replaceData.length) {
                  for (let i = 0; i < quickOpenUrl.replaceData.length; i++) {
                    try {
                      let key = Object.keys(quickOpenUrl.replaceData[i])[0];
                      let value = quickOpenUrl.replaceData[i][key];
                      let replaceVal = '';
                      try {
                        replaceVal = eval(value);
                      } catch (error) {
                        gdebug(1,"Error: ", error)
                      }
                      url = url.replaceAll(key, replaceVal);
                    } catch (error) {
                      gdebug(1,"Error: ", error)
                    }
                  }
                }
                window.open(url, '_blank');
              }
            }, 2000);
          });
        } catch (error) {
          gdebug(1,"Error: ", error)
        }
      }
    }
  } else {
    if (preLogFunction && preLogFunction.length) {
      try {
        preLogFunction.forEach(async (e, i) => {
          setTimeout(async () => {
            let data = JSON.stringify(e.data);
            let replaceData = e.replaceData;
            let responseData = e.responseData;
            let quickOpenUrl = e.quickOpenUrl;
            let templateName = e.templateName;
            let endpointName = e.endpointName;

            if(!templateName || !endpointName) {
              if (!!quickOpenUrl) {
                let url = quickOpenUrl.Url;
                if (!!quickOpenUrl.replaceData && !!quickOpenUrl.replaceData.length) {
                  for (let i = 0; i < quickOpenUrl.replaceData.length; i++) {
                    try {
                      let key = Object.keys(quickOpenUrl.replaceData[i])[0];
                      let value = quickOpenUrl.replaceData[i][key];
                      let replaceVal = '';
                      try {
                        replaceVal = eval(value);
                      } catch (error) {
                        gdebug(1,"Error: ", error);
                      }
                      url = url.replaceAll(key, replaceVal);
                    } catch (error) {
                      gdebug(1,"Error: ", error);
                    }
                  }
                }
                window.open(url, '_blank');
              }
              return;
            }

            if (!!replaceData && !!replaceData.length) {
              for (let i = 0; i < replaceData.length; i++) {
                try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                  data = data.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              }
            }
            data = JSON.parse(data);

            const result = await apiIntegration2(data, templateName, endpointName);
            gdebug(3,"Create ticket ",result)
            if (!!responseData && !!responseData.length) {
              let iframeDatas = Object.assign({}, iframeData);
              for (let i = 0; i < responseData.length; i++) {
                try {
                  let key = Object.keys(responseData[i])[0];
                  let value = responseData[i][key];

                  let keyArray = key.split('.');
                  for (let j = 0; j < keyArray.length; j++) {
                    let k = keyArray[j];
                    if (j < keyArray.length - 1) {
                      iframeDatas[k] = {};
                    } else {
                      iframeDatas[keyArray[j - 1]][k] = eval(value);
                    }
                  }
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
                
                gdebug(3,"iframeDatas ",iframeDatas)
                setIframeData(null);
                setIframeData(iframeDatas);
              }
            }

            if (!!quickOpenUrl) {
              let url = quickOpenUrl.Url;
              if (!!quickOpenUrl.replaceData && !!quickOpenUrl.replaceData.length) {
                for (let i = 0; i < quickOpenUrl.replaceData.length; i++) {
                  try {
                    let key = Object.keys(quickOpenUrl.replaceData[i])[0];
                    let value = quickOpenUrl.replaceData[i][key];
                    let replaceVal = '';
                    try {
                      replaceVal = eval(value);
                    } catch (error) {
                      gdebug(1,"Error: ", error);
                    }
                    url = url.replaceAll(key, replaceVal);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                }
              }
              window.open(url, '_blank');
            }
          }, 2000);
        });
      } catch (error) {
        gdebug(1,"Error: ", error);
      }
    }
  }
}
