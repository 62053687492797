import { gConst } from "../../lib/gConst";

declare const window: Window &
  typeof globalThis & {
    sforce: any;
  };

const salesforce = window.sforce;

export const globishDefaultObject: any = {
  account: [
    {
      name: '',
      phone: '',
      owner: '',
      industry: '',
      id: '',
    },
  ],
  lead: [
    {
      name: '',
      phone: '',
      status: '',
      owner: '',
      id: '',
    },
  ],
  student: [
    {
      name: '',
      phone: '',
      code: '',
      status: '',
      id: '',
      owner: '',
    },
  ],
  opportunity: [
    {
      name: '',
      contact: '',
      stage: '',
      owner: '',
      id: '',
    },
  ],
}

export const globishDefaultAccount: any = {
  name: '',
  phone: '',
  owner: '',
  industry: '',
  id: '',
}

export const globishDefaultContact: any = {
  name: '',
  phone: '',
  code: '',
  status: '',
  id: '',
  owner: '',
}

export const globishDefaultLead: any = {
  name: '',
  phone: '',
  status: '',
  owner: '',
  id: '',
}

export const globishDefaultOpportunity: any = {
  name: '',
  contact: '',
  stage: '',
  owner: '',
  id: '',
}

const defaultObject = globishDefaultObject;
const defaultAccount = globishDefaultAccount;
const defaultContact = globishDefaultContact;
const defaultLead = globishDefaultLead;
const defaultOpportunity = globishDefaultOpportunity;

// export async function runApexGlobish(sdt: string, setAccount: any, setOpportunity: any, setLead: any, student: any, setStudent: any, currentObject?: any) {
//   //TODO: get account by phone number
//   console.log('runApexxx', sdt, currentObject);
//   let getAccountFields = "select Id, Name, Phone, OwnerId, Industry from Account where Phone like\'%" + sdt + "%\' order by CreatedDate desc";
//   const param: any = {
//     apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//     methodName: 'getObjectData',
//     methodParams: `Params=${getAccountFields}`,
//   };
//   param.callback = async function (response: any) {
//     if (response.success) {
//       console.log(JSON.parse(response.returnValue.runApex));
//       const x = JSON.parse(response.returnValue.runApex);
//       const x1: any = [];
//       try {
//         for (let i: number = 0; i < x.length; i++) {
//           let value = x[i];
//           const param: any = {
//             apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//             methodName: 'searchUser',
//             methodParams: `userId=${value.OwnerId}`,
//             callback: (response: any) => {
//               if (response.success) {
//                 const userIf = JSON.parse(response.returnValue.runApex);
//                 console.log('aaa', response, userIf, userIf[0].Name);
//                 const x2: any = {
//                   name: value.Name,
//                   phone: value.Phone,
//                   owner: userIf[0].Name,
//                   industry: value.Industry,
//                   id: value.Id,
//                 };
//                 if (
//                   currentObject &&
//                   value.Id === currentObject.idObject &&
//                   currentObject.typeObject === gConst.ACCOUNT &&
//                   i > 0
//                 ) {
//                   x1.push(x2);
//                   let b = x1[0];
//                   x1[0] = x2;
//                   let leg = x1.length - 1;
//                   x1[leg] = b;
//                 } else {
//                   x1.push(x2);
//                 }
//                 if (x1.length > 0) {
//                   setAccount(x1);
//                 } else {
//                   setAccount([
//                     defaultAccount
//                   ]);
//                 }
//               }
//             },
//           };
//           await salesforce.opencti.runApex(param);
//         }
//         if (x.length === 0) {
//           setAccount([
//             defaultAccount
//           ]);
//         }
//       } catch (error) {
//         console.log(error);
//         setAccount([
//           defaultAccount
//         ]);
//       }
//     } else {
//       console.error('Something went wrong! Errors:', response);
//       setAccount([
//         defaultAccount
//       ]);
//     }
//   };
//   await salesforce.opencti.runApex(param);
//   //TODO: get lead by phone number
//   let getLeadFields = "select Id, Name, Phone, OwnerId, Status from Lead where Phone like\'%" + sdt + "%\' order by CreatedDate desc";
//   const param1: any = {
//     apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//     methodName: 'getObjectData',
//     methodParams: `Params=${getLeadFields}`,
//   };
//   param1.callback = async function (response: any) {
//     if (response.success) {
//       const x = JSON.parse(response.returnValue.runApex);
//       console.log(x);
//       const x1: any = [];
//       try {
//         for (let i: number = 0; i < x.length; i++) {
//           let value = x[i];
//           const param: any = {
//             apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//             methodName: 'searchUser',
//             methodParams: `userId=${value.OwnerId}`,
//             callback: (response: any) => {
//               if (response.success) {
//                 const userIf = JSON.parse(response.returnValue.runApex);
//                 const x2: any = {
//                   name: value.Name,
//                   phone: value.Phone,
//                   owner: userIf[0].Name,
//                   status: value.Status,
//                   id: value.Id,
//                 };
//                 if (
//                   currentObject &&
//                   value.Id === currentObject.idObject &&
//                   currentObject.typeObject === gConst.LEAD &&
//                   i > 0
//                 ) {
//                   x1.push(x2);
//                   let b = x1[0];
//                   x1[0] = x2;
//                   let leg = x1.length - 1;
//                   x1[leg] = b;
//                 } else {
//                   x1.push(x2);
//                 }
//                 if (x1.length > 0) {
//                   setLead(x1);
//                 } else {
//                   setLead([
//                     defaultLead
//                   ]);
//                 }
//               }
//             },
//           };
//           await salesforce.opencti.runApex(param);
//         }
//         if (x.length === 0) {
//           setLead([
//             defaultLead
//           ]);
//         }
//       } catch (error) {
//         console.log(error);
//         setLead([
//           defaultLead
//         ]);
//       }
//     } else {
//       console.error('Something went wrong! Errors:', response);
//       setLead([
//         defaultLead
//       ]);
//     }
//   };
//   await salesforce.opencti.runApex(param1);
//   // TODO: get contact by phone number
//   let getContactFields = "select Id, Name, Phone, Student_Code__c, Status__c, LastActivityDate, OwnerId from Contact where Phone like\'%" + sdt + "%\' order by CreatedDate desc";
//   const param2: any = {
//     apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//     methodName: 'getObjectData',
//     methodParams: `Params=${getContactFields}`,
//   };
//   param2.callback = function (response: any) {
//     if (response.success) {
//       localStorage.setItem('res2', response.returnValue.runApex);
//       const x = JSON.parse(response.returnValue.runApex);
//       console.log(x);
//       const x1: any = [];
//       try {
//         x.forEach(function (value: any, i: any) {
//           const x2: any = {
//             name: value.Name,
//             phone: value.Phone,
//             code: value.Student_Code__c,
//             status: value.Status__c,
//             id: value.Id,
//             owner: value.OwnerId,
//           };
//           if (
//             currentObject &&
//             value.Id === currentObject.idObject &&
//             currentObject.typeObject === gConst.CONTACT &&
//             i > 0
//           ) {
//             x1.push(x2);
//             let b = x1[0];
//             x1[0] = x2;
//             let leg = x1.length - 1;
//             x1[leg] = b;
//           } else {
//             x1.push(x2);
//           }
//         });
//         if (x1.length > 0) {
//           setStudent(x1);
//         } else {
//           setStudent([
//             defaultContact
//           ]);
//         }
//       } catch (error) {
//         console.log(error);
//         setStudent([
//           defaultContact
//         ]);
//       }
//     } else {
//       console.error('Something went wrong! Errors:', response);
//       setStudent([
//         defaultContact
//       ]);
//     }
//   };
//   await salesforce.opencti.runApex(param2);
//   let getOpportunityFields = "select Id, Name, Contact__c, StageName, OwnerId from Opportunity where Phone__c like\'%" + sdt + "%\' order by CreatedDate desc";
//   if(currentObject && currentObject.idObject){
//     getOpportunityFields = "select Id, Name, Contact__c, StageName, OwnerId from Opportunity where Contact__c = \'" + currentObject.idObject + "\' order by CreatedDate desc";
//   }
//   if (currentObject && currentObject.typeObject === gConst.CONTACT) {
//     const param: any = {
//       apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//       methodName: 'getObjectData',
//       methodParams: `Params=${getOpportunityFields}`,
//     };
//     param.callback = async function (response: any) {
//       if (response.success) {
//         const x = JSON.parse(response.returnValue.runApex);
//         console.log(x);
//         const x1: any = [];
//         try {
//           for (let i: number = 0; i < x.length; i++) {
//             let value = x[i];
//             const param: any = {
//               apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//               methodName: 'searchUser',
//               methodParams: `userId=${value.OwnerId}`,
//               callback: (response: any) => {
//                 if (response.success) {
//                   const userIf = JSON.parse(response.returnValue.runApex);
//                   if (student[0].id === value.Contact__c) {
//                     const x2: any = {
//                       name: value.Name,
//                       contact: student[0].name,
//                       stage: value.StageName,
//                       owner: userIf[0].Name,
//                       id: value.Id,
//                     };
//                     if (
//                       currentObject &&
//                       value.Id === currentObject.idObject &&
//                       currentObject.typeObject === gConst.OPPORTUNITY &&
//                       i > 0
//                     ) {
//                       x1.push(x2);
//                       let b = x1[0];
//                       x1[0] = x2;
//                       let leg = x1.length - 1;
//                       x1[leg] = b;
//                     } else {
//                       x1.push(x2);
//                     }
//                   } else {
//                     const x2: any = {
//                       name: value.Name,
//                       contact: currentObject ? currentObject.name : '',
//                       stage: value.StageName,
//                       owner: userIf[0].Name,
//                       id: value.Id,
//                     };
//                     if (
//                       currentObject &&
//                       value.Id === currentObject.idObject &&
//                       currentObject.typeObject === gConst.OPPORTUNITY &&
//                       i > 0
//                     ) {
//                       x1.push(x2);
//                       let b = x1[0];
//                       x1[0] = x2;
//                       let leg = x1.length - 1;
//                       x1[leg] = b;
//                     } else {
//                       x1.push(x2);
//                     }
//                   }
//                   if (x1.length > 0) {
//                     setOpportunity(x1);
//                   } else {
//                     setOpportunity([
//                       defaultOpportunity
//                     ]);
//                   }
//                 }
//               },
//             };
//             await salesforce.opencti.runApex(param);
//           }
//           if (x.length === 0) {
//             setOpportunity([
//               defaultOpportunity
//             ]);
//           }
//         } catch (error) {
//           console.log(error);
//           setOpportunity([
//             defaultOpportunity
//           ]);
//         }
//       } else {
//         console.error('Something went wrong! Errors:', response);
//         setOpportunity([
//           defaultOpportunity
//         ]);
//       }
//     };
//     await salesforce.opencti.runApex(param);
//   } else {
//     setTimeout(async () => {
//       let ctID = null;
//       let c2: any = localStorage.getItem('res2');
//       c2 = JSON.parse(c2);
//       console.log('c2', c2);
//       localStorage.removeItem('res2');
//       ctID = c2 && c2.length > 0 ? c2[0].Id : null;

//       if (currentObject && currentObject.typeObject === gConst.OPPORTUNITY) {
//         ctID = null;
//       }
//       getOpportunityFields = "select Id, Name, Contact__c, StageName, OwnerId from Opportunity where Contact__c = \'" + ctID + "\' order by CreatedDate desc";
//       if(!ctID){
//         getOpportunityFields = "select Id, Name, Contact__c, StageName, OwnerId from Opportunity where Phone__c like\'%" + sdt + "%\' order by CreatedDate desc";
//       }
//       let param: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getOpportunityFields}`,
//       };
//       param.callback = async function (response: any) {
//         if (response.success) {
//           const x = JSON.parse(response.returnValue.runApex);
//           console.log(x);
//           const x1: any = [];
//           try {
//             for (let i: number = 0; i < x.length; i++) {
//               let value = x[i];
//               const param: any = {
//                 apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                 methodName: 'searchUser',
//                 methodParams: `userId=${value.OwnerId}`,
//                 callback: (response: any) => {
//                   if (response.success) {
//                     const userIf = JSON.parse(response.returnValue.runApex);
//                     const x2: any = {
//                       name: value.Name,
//                       contact: c2 && c2.length > 0 ? c2[0].Name : value.Contact__c,
//                       stage: value.StageName,
//                       owner: userIf[0].Name,
//                       id: value.Id,
//                     };
//                     if (
//                       currentObject &&
//                       value.Id === currentObject.idObject &&
//                       currentObject.typeObject === gConst.OPPORTUNITY &&
//                       i > 0
//                     ) {
//                       x1.push(x2);
//                       let b = x1[0];
//                       x1[0] = x2;
//                       let leg = x1.length - 1;
//                       x1[leg] = b;
//                     } else {
//                       x1.push(x2);
//                     }
//                     console.log(x1);
//                     if (x1.length > 0) {
//                       setOpportunity(x1);
//                     } else {
//                       setOpportunity([
//                         defaultOpportunity
//                       ]);
//                     }
//                   }
//                 },
//               };
//               await salesforce.opencti.runApex(param);
//             }
//             if (x.length === 0) {
//               setOpportunity([
//                 defaultOpportunity
//               ]);
//             }
//           } catch (error) {
//             console.log(error);
//             setOpportunity([
//               defaultOpportunity
//             ]);
//           }
//         } else {
//           console.error('Something went wrong! Errors:', response);
//           setOpportunity([
//             defaultOpportunity
//           ]);
//         }
//       };
//       await salesforce.opencti.runApex(param);
//     }, 2000);
//   }
// }
export async function setIframeGlobish(setIframeData: any, userInfo: any, objectData: any, currentObject: any) {
  let a: any = defaultObject;
  if (userInfo.RoleId === '00E5g000000I2KxEAK') {
    if (objectData.account.length > 0 && objectData.account[0].id) {
      for (let i: number = 0; i < objectData.account.length; i++) {
        console.log('check i : ', i, objectData.account);
        if (objectData.account[i].owner === userInfo.OwnerName) {
          a.account = [objectData.account[i]];
          break;
        }
      }
    }
    if (objectData.lead.length > 0 && objectData.lead[0].id) {
      for (let i: number = 0; i < objectData.lead.length; i++) {
        if (objectData.lead[i].owner === userInfo.OwnerName) {
          a.lead = [objectData.lead[i]];
          break;
        }
      }
    }
    if (objectData.student.length > 0 && objectData.student[0].id) {
      for (let i: number = 0; i < objectData.student.length; i++) {
        if (objectData.student[i].owner === userInfo.OwnerId || (currentObject && objectData.student[i].id === currentObject.idObject)) {
          a.student = [objectData.student[i]];
          break;
        }
      }
    }
    if (objectData.opportunity.length > 0 && objectData.opportunity[0].id) {
      let b: any = [];
      for (let i: number = 0; i < objectData.opportunity.length; i++) {
        if (objectData.opportunity[i].owner === userInfo.OwnerName) {
          let c = objectData.opportunity[i];
          b.push(c);
          a.opportunity = b;
        }
      }
    }
    setIframeData(a);
  } else if (userInfo.RoleId === '00E5g000000I2L2EAK' || userInfo.RoleId === '00E5g000000I2KdEAK') {
    // if (objectData.account.length > 0 && objectData.account[0].id) {
    //   for (let i: number = 0; i < objectData.account.length; i++) {
    //     if (objectData.account[i].owner === userInfo.OwnerName) {
    //       a.account = [objectData.account[i]];
    //       break;
    //     }
    //   }
    // }
    // if (objectData.student.length > 0 && objectData.student[0].id) {
    //   for (let i: number = 0; i < objectData.student.length; i++) {
    //     if (objectData.student[i].owner === userInfo.OwnerId || objectData.student[i].id === currentObject.idObject) {
    //       a.student = [objectData.student[i]];
    //       break;
    //     }
    //   }
    // }
    a.account = [objectData.account[0]];
    a.student = [objectData.student[0]];
    setIframeData(a);
  } else if (
    userInfo.RoleId === '00E5g000000I2KOEA0' ||
    userInfo.RoleId === '00E5g000000I2KYEA0'
  ) {
    a.account = [objectData.account[0]];
    a.lead = [objectData.lead[0]];
    a.opportunity = objectData.opportunity;
    a.student = [objectData.student[0]];
    setIframeData(a);
  }
}
export async function setIdGlobish(currentObject: any, number: any, logData: any, objectData: any, userInfo: any) {
  if (
    currentObject &&
    currentObject.idObject &&
    (currentObject.typeObject === gConst.CONTACT ||
      currentObject.typeObject === gConst.LEAD ||
      currentObject.typeObject === gConst.ACCOUNT ||
      currentObject.typeObject === gConst.OPPORTUNITY ||
      currentObject.typeObject === gConst.CASE) &&
    number === currentObject.number
  ) {
    if (currentObject.typeObject === gConst.CONTACT || currentObject.typeObject === gConst.LEAD) {
      logData.whoId = currentObject.idObject;
      if (
        currentObject.typeObject === gConst.CONTACT &&
        objectData.opportunity.length > 0 &&
        objectData.opportunity[0].id
      ) {
        if (userInfo.RoleId === '00E5g000000I2KxEAK') {
          for (let i: number = 0; i < objectData.opportunity.length; i++) {
            if (objectData.opportunity[i].owner === userInfo.OwnerName) {
              logData.whatId = objectData.opportunity[i].id;
              break;
            }
          }
        } else if (
          userInfo.RoleId === '00E5g000000I2KOEA0' ||
          userInfo.RoleId === '00E5g000000I2KYEA0'
        ) {
          logData.whatId = objectData.opportunity[0].id;
        }
      } else if (
        currentObject.typeObject === gConst.CONTACT &&
        objectData.account.length > 0 &&
        objectData.account[0].id
      ) {
        if (userInfo.RoleId === '00E5g000000I2KxEAK') {
          for (let i: number = 0; i < objectData.account.length; i++) {
            if (objectData.account[i].owner === userInfo.OwnerName) {
              logData.whatId = objectData.account[i].id;
              break;
            }
          }
        } else if (
          userInfo.RoleId === '00E5g000000I2KOEA0' ||
          userInfo.RoleId === '00E5g000000I2KYEA0'
        ) {
          logData.whatId = objectData.account[0].id;
        }
      }
    } else {
      logData.whatId = currentObject.idObject;
      if (
        objectData.student.length > 0 &&
        objectData.student[0].id
      ) {
        if (userInfo.RoleId === '00E5g000000I2KxEAK') {
          for (let i: number = 0; i < objectData.student.length; i++) {
            if (objectData.student[i].owner === userInfo.OwnerId) {
              logData.whoId = objectData.student[i].id;
              break;
            }
          }
        } else {
          logData.whoId = objectData.student[0].id;
        }
      }
    }
  } else {
    if (userInfo.RoleId && userInfo.RoleId === '00E5g000000I2KxEAK') {
      if (objectData.account.length > 0 && objectData.account[0].id) {
        for (let i: number = 0; i < objectData.account.length; i++) {
          if (objectData.account[i].owner === userInfo.OwnerName) {
            logData.whatId = objectData.account[i].id;
            break;
          }
        }
      }
      if (objectData.opportunity.length > 0 && objectData.opportunity[0].id) {
        for (let i: number = 0; i < objectData.opportunity.length; i++) {
          if (objectData.opportunity[i].owner === userInfo.OwnerName) {
            logData.whatId = objectData.opportunity[i].id;
            break;
          }
        }
      }
      if (objectData.lead.length > 0 && objectData.lead[0].id) {
        for (let i: number = 0; i < objectData.lead.length; i++) {
          if (objectData.lead[i].owner === userInfo.OwnerName) {
            logData.whoId = objectData.lead[i].id;
            logData.whatId = '';
            break;
          }
        }
      }
      if (objectData.student.length > 0 && objectData.student[0].id) {
        for (let i: number = 0; i < objectData.student.length; i++) {
          if (objectData.student[i].owner === userInfo.OwnerId) {
            logData.whoId = objectData.student[i].id;
            break;
          }
        }
      }
    } else if (userInfo.RoleId && userInfo.RoleId === '00E5g000000I2L2EAK' || userInfo.RoleId === '00E5g000000I2KdEAK') {
      // if (objectData.account.length > 0 && objectData.account[0].id) {
      //   for (let i: number = 0; i < objectData.account.length; i++) {
      //     if (objectData.account[i].owner === userInfo.OwnerName) {
      //       logData.whatId = objectData.account[i].id;
      //       break;
      //     }
      //   }
      // }
      // if (objectData.student.length > 0 && objectData.student[0].id) {
      //   for (let i: number = 0; i < objectData.student.length; i++) {
      //     if (objectData.student[i].owner === userInfo.OwnerId) {
      //       logData.whoId = objectData.student[i].id;
      //       break;
      //     }
      //   }
      // }
      if (objectData.account.length > 0 && objectData.account[0].id) {
        logData.whatId = objectData.account[0].id;
      }
      if (objectData.student.length > 0 && objectData.student[0].id) {
        logData.whoId = objectData.student[0].id;
      }
    } else if (
      userInfo.RoleId &&
      (userInfo.RoleId === '00E5g000000I2KOEA0' || userInfo.RoleId === '00E5g000000I2KYEA0')
    ) {
      if (objectData.account.length > 0 && objectData.account[0].id) {
        logData.whatId = objectData.account[0].id;
      }
      if (objectData.opportunity.length > 0 && objectData.opportunity[0].id) {
        logData.whatId = objectData.opportunity[0].id;
      }
      if (objectData.lead.length > 0 && objectData.lead[0].id) {
        logData.whoId = objectData.lead[0].id;
        logData.whatId = '';
      }
      if (objectData.student.length > 0 && objectData.student[0].id) {
        logData.whoId = objectData.student[0].id;
      }
    }
  }
  return logData;
}

export async function setNameGlobish(currentObject: any, number: any, setName: any, userInfo: any, account: any, lead: any, opportunity: any, student: any) {
  if (currentObject && currentObject.name && number === currentObject.number) {
    setName(currentObject.name);
  } else {
    if (userInfo.RoleId && userInfo.RoleId === '00E5g000000I2KxEAK') {
      if (account.length > 0 && account[0].id) {
        for (let i: number = 0; i < account.length; i++) {
          if (account[i].owner === userInfo.OwnerName) {
            setName(account[i].name);
            break;
          }
        }
      }
      if (lead.length > 0 && lead[0].id) {
        for (let i: number = 0; i < lead.length; i++) {
          if (lead[i].owner === userInfo.OwnerName) {
            setName(lead[i].name);
            break;
          }
        }
      }
      if (opportunity.length > 0 && opportunity[0].id) {
        for (let i: number = 0; i < opportunity.length; i++) {
          if (opportunity[i].owner === userInfo.OwnerName) {
            setName(opportunity[i].name);
            break;
          }
        }
      }
      if (student.length > 0 && student[0].id) {
        for (let i: number = 0; i < student.length; i++) {
          if (student[i].owner === userInfo.OwnerId) {
            setName(student[0].name);
            break;
          }
        }
      }
    } else if (userInfo.RoleId && userInfo.RoleId === '00E5g000000I2L2EAK' || userInfo.RoleId === '00E5g000000I2KdEAK') {
      // if (account.length > 0 && account[0].id) {
      //   for (let i: number = 0; i < account.length; i++) {
      //     if (account[i].owner === userInfo.OwnerName) {
      //       setName(account[i].name);
      //       break;
      //     }
      //   }
      // }
      // if (student.length > 0 && student[0].id) {
      //   for (let i: number = 0; i < student.length; i++) {
      //     if (student[i].owner === userInfo.OwnerId) {
      //       setName(student[0].name);
      //       break;
      //     }
      //   }
      // }
      if (account.length > 0 && account[0].id) {
        setName(account[0].name);
      }
      if (student.length > 0 && student[0].id) {
        setName(student[0].name);
      }
    } else if (
      userInfo.RoleId &&
      (userInfo.RoleId === '00E5g000000I2KOEA0' || userInfo.RoleId === '00E5g000000I2KYEA0')
    ) {
      if (account.length > 0 && account[0].id) {
        setName(account[0].name);
      }
      if (lead.length > 0 && lead[0].id) {
        setName(lead[0].name);
      }
      if (opportunity.length > 0 && opportunity[0].id) {
        setName(opportunity[0].name);
      }
      if (student.length > 0 && student[0].id) {
        setName(student[0].name);
      }
    }
  }
}

export async function setObjectDataGlobish(setObjectData: any, account: any, lead: any, student: any, opportunity: any) {
  setObjectData({
    account:
      account !== undefined
        ? account
        : [
          defaultAccount
        ],
    lead:
      lead !== undefined
        ? lead
        : [
          defaultLead
        ],
    student:
      student !== undefined
        ? student
        : [
          defaultContact
        ],
    opportunity:
      opportunity !== undefined
        ? opportunity
        : [
          defaultOpportunity
        ],
  });
}