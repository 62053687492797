import React, { useEffect, useState } from 'react';
import ChangeSip from './changeSip';
// Name later
import Function from './function';
import IntegrationGcalls from '../integrationGcalls';
import { LoginStatus, UserInfo } from '../../types';
interface Props {
  UserInfo: UserInfo;
  onSubmit: any;
  settingClick: any;
  syncSip: any;
  loginStatus: LoginStatus,
  setLoginStatus: any,
  signOut: any,
  keycloak: any,
  handleChangeCallbox: any
}
type  Tab = 'setting' | 'tab1';
export default function SettingComponent({ UserInfo,onSubmit , settingClick, syncSip, loginStatus, setLoginStatus, signOut, keycloak, handleChangeCallbox }: Props) {
  const [whatTab, setWhatTab] = useState <Tab>("setting");
  const [isShowInfoPage, setIsShowInfoPage] = useState(false);

  const changeTab = (event: any)=>{
    const id  = event.target.id;
    document.querySelectorAll(".btn-fn").forEach(element=>{
      element.classList.remove("selected-tab");
    })
    event.target.classList.add("selected-tab");
    setWhatTab(id);
    handleIsShowInfoPage()
  }
  const settingElement = document.getElementById("setting");
  const {GcallsExtension, GcallsProxy, GcallsPassword, GcallsWebsocket} = UserInfo;
  if(!(GcallsExtension && GcallsProxy && GcallsPassword && GcallsWebsocket)){
    settingElement?.classList.add("notsip-setting");
  }else{
    settingElement?.classList.remove("notsip-setting");
  }
  useEffect(()=>{
    setWhatTab("setting")
  },[])
  const handleIsShowInfoPage = ()=>{
    setIsShowInfoPage(false);
  }

  return (
    <div className="sm:w-full sm:h-full z-50 fixed top-0 left-0" style={{backgroundColor: "rgb(103, 58, 183)"}}>
      {/* <div className="bg-white flex h-16 rounded-top overflow-x-auto" style={{borderColor: "#673AB7"}}>
        <button  onClick={changeTab} id="setting" className="relative btn-fn w-24 text-white mx-4 text-2xl my-2 " style={{backgroundColor: "rgba(123, 58, 244,0.8)"}}>Settings</button>
      </div> */}
     
      {whatTab === "setting"? <ChangeSip
        UserInfo={UserInfo}
        onSubmit={onSubmit}
        settingClick={settingClick}
        syncSip={syncSip}
        isShowInfoPage={isShowInfoPage}
        setIsShowInfoPage={handleIsShowInfoPage}
        setWhatTab={(value: Tab)=>{setWhatTab(value)}}
        loginStatus={loginStatus}
        setLoginStatus={setLoginStatus}
        signOut={signOut}
        keycloak={keycloak}
        handleChangeCallbox={handleChangeCallbox}
      />:null}
      
    </div>
  );
}
