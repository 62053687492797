import { CDN } from "../lib/gCdn";
import { gConst } from "../lib/gConst";

function checkStatus(reason: any, direction: any) {
  let status;
  switch (reason.toLowerCase()) {
    case 'not found':
      status = 'wrong-number';
      break;
    case 'unavailable':
      status = 'no-answer';
      break;
    case 'busy':
      status = 'busy';
      break;
    case 'canceled':
      if (direction === gConst.INCOMING) status = 'missed';
      else status = 'canceled';
      break;
    case 'rejected':
      status = 'rejected';
      break;
    default:
      status = 'canceled';
  }
  return status;
}

function calcTime(date: any, offset: any) {
  let d = new Date(date);

  let utc = d.getTime() + d.getTimezoneOffset() * 60000;

  let nd = new Date(utc + 3600000 * offset);

  return nd;
}
function formatDate(date: any) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}
function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
function addZero(i: any) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

//-------------------------------------------------------------------

function handleTimeFS( timestamp: any, timeZoneOffset: any) {

  const date = new Date(timestamp); // Convert Unix timestamp to milliseconds
  const formattedDate = date.toString();
  return formattedDate
}





//---------------------------------------------------------------------------
function handleTimeCreate(time: any, onlyDate: any) {
  let today = new Date();
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let month_vi = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let month_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let vi_month = month_vi[createdAt.getMonth()];
  let en_month = month_en[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  if (onlyDate) {
    return date + '/' + vi_month + '/' + year;
  }
  return date + '/' + vi_month + '/' + year + ' - ' + hour + ':' + minutes;
}

//---------------------------------------------------------------------------
function millisToSeconds(millis: number) {
  let minutes = Math.floor(millis / 60000) * 60;
  let seconds = Math.floor(millis % 60000) / 1000;
  let durations = (minutes + seconds).toFixed(0);
  return +durations;
}
function handleDuration(time: any) {
  var minutes: any = '0' + Math.floor(time / 60);
  var seconds: any = '0' + (time - minutes * 60);
  return minutes.substr(-2) + ':' + seconds.substr(-2);
}

function handleStartTimeZoho(time: any) {
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let seconds = addZero(createdAt.getSeconds());
  let month_vi = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let vi_month = month_vi[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  return `${year}-${vi_month}-${date}T${hour}:${minutes}:${seconds}`;
}

function handleTimeBitrix(time: any) {
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let seconds = addZero(createdAt.getSeconds());
  let month_vi = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let vi_month = month_vi[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  return `${year}-${vi_month}-${date}T${hour}:${minutes}:${seconds}+07:00`;
}

function timeout(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function makeNotify(title: string, content: string, brandLogo:any) {
  // `favicon.png`
  let notification = new Notification(title, {
    icon: brandLogo,
    body: content
  });
  notification.onclick = function () {
    window.parent.focus();
  };
}

function askAllowNotify() {
  Notification.requestPermission((permission) => {
    console.log('Permission: ', permission);
  });
}

function checkNotifyPermisson() {
  if (!!Notification) {
    let permission = Notification.permission;
    console.log(permission);
    if (permission === 'default' || permission === 'denied') {
      askAllowNotify();
    }
  }
}

function makeID(length:number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function handleTimePipedrive(time:any) {
  let createdAt = new Date(time);
  let date = addZero(createdAt.getDate());
  let hour = addZero(createdAt.getHours());
  let minutes = addZero(createdAt.getMinutes());
  let seconds = addZero(createdAt.getSeconds());
  let month_vi = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let vi_month = month_vi[createdAt.getMonth()];
  let year = createdAt.getFullYear();
  return `${year}-${vi_month}-${date} ${hour}:${minutes}:${seconds}`;
}

export { checkStatus, calcTime, formatDate, numberWithCommas, handleTimeCreate, millisToSeconds, handleDuration, handleStartTimeZoho, handleTimeBitrix, timeout, makeNotify, checkNotifyPermisson, makeID, handleTimePipedrive, handleTimeFS };
