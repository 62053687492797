import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EndCall } from '../../icons/call-end.svg';
import { ReactComponent as Mute } from '../../icons/mute.svg';
import { ReactComponent as ForwardCall } from '../../icons/forward-call.svg';
import { ReactComponent as Keypad } from '../../icons/keypad.svg';
import {ReactComponent as Mic} from "../../icons/mic.svg";
import {ReactComponent as SpeakerOn} from "../../icons/speaker-on.svg";
import {ReactComponent as SpeakerOff} from "../../icons/speaker-off.svg";
import {ReactComponent as CallOut} from "../../icons/callout.svg";
import Stopwatch from '../../customHooks/myStopwatch';
import TransferCall from './components/transferCall';
import KeypadComponent from './components/keypadComponent';
import { UserInfo } from "../../types/index";
import { isMobile } from '../../lib/gUtils';
import { setAndroidSpeakerOff, setAndroidSpeakerOn } from '../../services/gMobileApp.service';
import { gConst } from '../../lib/gConst';
import { translate } from '../../languages';


declare const window: Window &
  typeof globalThis & {
    sforce: any;
    ReactNativeWebView : any
  };

interface Props {
  onMuteClick: any;
  isMute: boolean;
  name: string;
  phoneNumber: string;
  status: string;
  language: string;
  onEndCallClick: any;
  onTransfer: any;
  handleSendDTMF: any;
  userInfo: UserInfo;
  callButtonInfo: any;
  handleChangeAndroidSpeaker : any;
  isAndroidSpeaker : any;
  direction: string;
  hotlineOutbound: string
}

const InPhoning: React.FC<Props> = ({
  onMuteClick,
  isMute,
  name,
  status,
  language,
  onEndCallClick,
  phoneNumber,
  onTransfer,
  handleSendDTMF,
  userInfo,
  callButtonInfo,
  handleChangeAndroidSpeaker,
  isAndroidSpeaker,
  direction,
  hotlineOutbound
}) => {
  const [transfer, setTransfer] = useState(false);
  const [tmpHotlineOutbound, setTpmHotlineOutbound] = useState('');

  const [keypad, setKeypad] = useState(false);
  let device = localStorage.getItem(gConst.STORAGE_DEVICE)


  const handleTransfer = () => {
    setTransfer(true);
  };
  const handleKeypad = () => {
    setKeypad(true);
  };
  const handleSetPhoneTransfer = (data: any) => {
    onTransfer(data);
    setTransfer(false);
  };
  const handleSetNumberDTMF = (data: any) => {
    handleSendDTMF(data);
  };
  const handleMuteClick = () => {
    onMuteClick();
  }

  const handleChangeSpeaker = () => {
    handleChangeAndroidSpeaker();
  }

  const initAndroidSpeaker = () => {
    if(!isMobile(window)){
      return
    }

    if(isAndroidSpeaker){
      setAndroidSpeakerOn(window)
    }

    if(!isAndroidSpeaker){
      setAndroidSpeakerOff(window)
    }
  }

  useEffect(() => {

    // the Android audio will only work once an audio stream has been initiated. 
    // To ensure that the status is set to "calling" and to check the Android audio stream, I have included "status" in the useEffect function.

    initAndroidSpeaker();

  }, [isAndroidSpeaker,status])



  useEffect(() => {

    if (direction == gConst.OUTGOING) {

      // setHotlineOutbound(localStorage.getItem('hotline_outbound') || '');
      // setHotlineOutbound('');

      // Fix tmp show hotline outbound for HuyenLoc
      if (userInfo.GcallsProxy.includes('2-huyenloc.gcalls.vn')) {
        let phoneTmp = '0931593881';
        let pattern = /(^(086|096|097|098|032|033|034|035|036|037|038|039)\d{7})$/;
        if (pattern.test(phoneNumber)) {
            phoneTmp = ['0334918279', '0337965179', '0337895179', '0335830689', '0335870689', '0335710689', '0335270689'][Math.floor(Math.random() * 7)];
        }
        setTpmHotlineOutbound(phoneTmp)
      } else if (userInfo.GcallsProxy.includes('2-coderschool.gcalls.vn')) {
        let phoneTmp = '0899172112';
        let pattern = /(^(086|096|097|098|032|033|034|035|036|037|038|039)\d{7})$/;
        if (pattern.test(phoneNumber)) {
            phoneTmp = '0375500015';
        }
        setTpmHotlineOutbound(phoneTmp)
      }
    } 
    
    // else {
    //   setTpmHotlineOutbound(localStorage.getItem(gConst.STORAGE_HOTLINE) || '');
    // }

  }, [direction]);

  useEffect(() => {

    const phoning = document.getElementById('phoning');
    if (phoning) {
      if (transfer || keypad) {
        phoning.classList.add('blur-effect');
      } else {
        phoning.classList.remove('blur-effect');
      }
    }
  }, [transfer,keypad]);
  const handleCloseClick = ()=>{
    setTransfer(false);
  }
  const handleCloseKeypadClick = ()=>{
    setKeypad(false);
  }
  return (
    <div  className='w-full h-5/6 flex'>
      <div className="w-full h-full"  >
        {transfer ? <TransferCall phone={handleSetPhoneTransfer} onCloseClick={handleCloseClick} /> : null}
        {keypad ? <KeypadComponent phone={handleSetNumberDTMF} onCloseClick={handleCloseKeypadClick} /> : null}



        <div id="" className="h-full grid grid-cols-1">
          <div id="" className=" items-center">
            <span className="block w-10/12 flex justify-center mx-auto">
              <img
                src={`https://dummyimage.com/50x50/b39cdb/fffffd.jpg&text=${
                  !name ? 'No name'.charAt(0) : name.charAt(0)
                }`}
                alt=""
                className="p-2 rounded-2xl"
              />
            </span>
            <span className="text-2xl w-10/12 font-bold mx-auto text-center text-gray-300 block my-4">
              {!name ? (callButtonInfo.buttonName && callButtonInfo.isButtonClick2Call ? callButtonInfo.buttonName : "No name") : name}
            </span>
            <span className="text-2xl w-10/12font-bold mx-auto text-center text-gray-300 block my-4">
              {phoneNumber !== '' ? phoneNumber : '0XXXXXXXX'}
            </span>
          </div>
          <div id="duration" className="mt-8 mb-2 flex justify-center">
            <span className="text-2xl w-10/12font-bold mx-auto text-center text-gray-300">
              {status === 'Confirm' ? <Stopwatch /> : translate(language, status)}
            </span>
          </div>
          <div id="hotline" className="my-0 flex justify-center">
            <span className="text-2xl w-10/12font-bold mx-auto text-center text-gray-300">
              <CallOut fill="white" width="1.4rem" height="1.4rem"/>
              &nbsp;{tmpHotlineOutbound ? tmpHotlineOutbound : hotlineOutbound}
            </span>
          </div>
          <div id="btn-fn" className="  flex justify-around items-center">
            <div className="flex justify-center items-center flex-wrap w-1/4">
              <button
                id="mute"
                className="rounded-full  w-20 h-20 flex justify-center items-center  mx-2 bg-transparent border border-white"
                onClick={handleMuteClick}
              >
                {!isMute?<Mic fill="white" width="2.2rem" height="2.2rem" /> :<Mute fill="white" width="2.2rem" height="2.2rem" />}
              </button>
              <div className="break"></div>
              <span className="text-2xl font-bold mx-auto text-center text-gray-300  my-2">
                Mute
              </span>
            </div>
            {/* ------------------------------------------------------------------------------------------------------------------- */}
            {isMobile(window) && device == gConst.ANDROID && <div className="flex justify-center items-center flex-wrap w-1/4">
              <button
                id=""
                className="rounded-full  w-20 h-20 flex justify-center items-center  mx-2 bg-transparent border border-white"
                onClick={handleChangeSpeaker}
              >
                {!isAndroidSpeaker?<SpeakerOff fill="white" width="2.2rem" height="2.2rem" /> :<SpeakerOn fill="white" width="2.2rem" height="2.2rem" />}
              </button>
              <div className="break"></div>
              <span className="text-2xl font-bold mx-auto text-center text-gray-300  my-2">
                Speaker
              </span>
            </div>}
            {/* ------------------------------------------------------------------------------------------------------------------- */}
            <div className="flex justify-center items-center flex-wrap w-1/4">
              <button
                className="rounded-full  w-20 h-20 flex justify-center items-center  mx-2 bg-transparent border border-white"
                onClick={handleKeypad}
              >
                <Keypad fill="white"  filter="invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(100%) contrast(100%)" width="1.5rem" height="1.5rem" />
              </button>
              <div className="break"></div>
              <span className="text-2xl font-bold mx-auto text-center text-gray-300  my-2">
                Keypad
              </span>
            </div>
            <div className="flex justify-center items-center flex-wrap w-1/4">
              <button
                className="rounded-full  w-20 h-20 flex justify-center items-center  mx-2 bg-transparent border border-white"
                onClick={handleTransfer}
              >
                <ForwardCall fill="white" width="1.5rem" height="1.5rem" />
              </button>
              <div className="break"></div>
              <span className="text-2xl font-bold mx-auto text-center text-gray-300  my-2">
                Forward
              </span>
            </div>



        </div>
          <div className="w-full flex justify-center items-center">
            <button
              id="end-call"
              className=" bg-red-600 square rounded-full flex justify-center items-center active:bg-gray-300 focus:bg-gray-300"
              onClick={onEndCallClick}
            >
              <EndCall fill="white" width="3rem" height="3rem" />
            </button>
          </div>
   
        </div>
       
      </div>
    </div>
  );
};

export default InPhoning;
