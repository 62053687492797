import React, { FC, useEffect, useRef, useState } from 'react';
import './App.css';
import ComponentsIndex from './components';
import { BroadcastChannel } from 'broadcast-channel';
// @ts-ignore
import { useBeforeunload } from 'react-beforeunload';
import { ToastProvider } from 'react-toast-notifications';
import { Param, UserInfo } from './types';
import { gdebug } from './lib/gdebug';
import host from './config/host';
// import gConst
import { gConst } from './lib/gConst';
import { decrypt, isMobile } from './lib/gUtils';
import { CDN } from './lib/gCdn';



const { mobileDomain } = host;
// Get Data LocalStorage
let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM);
let parrentHost: any = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
let gUserData: any = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData)

require('dotenv').config();

declare const window: Window &
  typeof globalThis & {
    sforce: any;
    ReactNativeWebView : any
  }

declare var io: any



const App: FC = () => {
  // const channel = new BroadcastChannel("tab");
  // channel.addEventListener("message",(msg)=>{
  //   if(msg === "xinchao"){
  //     if(localStorage.getItem("arh") === "i'm here"){
  //       alert("You cannot open multiple tabs!");
  //     }
  //   else{
  //     localStorage.setItem("arh","i'm here");
  //   }
  // }});
  // let io: any = null;
  let socket: any = null;
  let TabID: any = null;

  let listCustomIntegration = [gConst.HUBSPOT, gConst.NHANH_V1, gConst.SALESFORCE]

    let integrator = gConst.DEFAULT;
    if(!!gUserData && !!gUserData.integrator[parrentHost]){
      let templateName = gUserData.integrator[parrentHost].templateName || gConst.DEFAULT;
      if(listCustomIntegration.includes(templateName) && !Array.isArray(gUserData.integrator[parrentHost])){
        integrator = templateName || gConst.DEFAULT;
      }else {
        integrator = gConst.DEFAULT;
      }
    }

  useEffect(() => {
    CDN.load()
  })


  useEffect(() => {

    if(window.sforce){
      window.sforce.opencti.getAppViewInfo({
        callback: (response: any) => {
          gdebug(3,'getAppViewInfo: ',response)
          if(response.success){
            let srcUrl = response?.returnValue?.url;
            let urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(srcUrl);
            if(urlParts && urlParts.length){
              gdebug(3,'urlParts: ',urlParts)
              let parrentHostname: any = urlParts[1] || '';
              let parrentUrl = urlParts[0] || '';
              let parrentPathname = urlParts[2] || '';

              localStorage.setItem(gConst.STORAGE_PARRENT_HOST_NAME, parrentHostname)
              localStorage.setItem(gConst.STORAGE_PARRENT_URL, parrentUrl)
              localStorage.setItem(gConst.STORAGE_PARRENT_PATH_NAME, parrentPathname)
            }
          }
        }
      })
    }
    switch (integrator) {
      case gConst.SALESFORCE:
        window.sforce.opencti.setSoftphonePanelHeight({ heightPX: 570, callback: () => {
          
          gdebug(1,'Set height')
        }});
        window.sforce.opencti.setSoftphonePanelWidth({ widthPX: 320, callback: () => {
          gdebug(1,'Set width')
        }});
        window.sforce.opencti.enableClickToDial({ callback: () => {
          gdebug(1,'enableClickToDial - enable')
        }});
        break;

      default:
        var option = { transports: [gConst.SOCKET_WEBSOCKET], path: process.env.REACT_APP_PUBLIC_URL ? "/mobilesocket" : "" };
        setTimeout(() => {
          if (!!io) {
            socket = io.connect(mobileDomain, option);
            TabID = window.location.hash.replace(/#/, '');
            let device =  localStorage.getItem(gConst.STORAGE_DEVICE);
            if(!device){
              device = 'mobile';
            }

            socket.on(gConst.SOCKET_CONNECT, function () {
              gdebug(1,'Call box socket connect ',TabID)
              socket.emit(gConst.SOCKET_EXTENSION_TAB_ROOM, { tabId: TabID });

              if (gUserData) {
                let userSession = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN)) || null;
                socket.emit(gConst.SOCKET_ROOM, { idUser: gUserData._id, idCallcenter: gUserData.idCallcenter, email: gUserData.email, token: userSession, device : device });
              }

            });

            socket.on(gConst.SOCKET_DISCONNECT, function (reason: any) {
              gdebug(1,'Call box socket disconnect ',reason)
            });

            socket.on(gConst.SOCKET_GET_INFO, function (data: any) {
              let message = data.message;

              try {
                message= JSON.parse(message);

              } catch (e) {
                console.error(e,'ERROR PARSE JSON');
              }

              // console.log('Location', data, message);
              gdebug(3,'Location ', data, message)
              if (localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) !== message.location) {
                localStorage.setItem(gConst.STORAGE_PARRENT_HOST_NAME, message.location);
                window.location.reload();
              }
              // -----------------------------------------------------------

              if (localStorage.getItem(gConst.STORAGE_PARRENT_URL) !== message.url) {
                localStorage.setItem(gConst.STORAGE_PARRENT_URL, message.url);
              }
              // -----------------------------------------------------------
              if (localStorage.getItem(gConst.STORAGE_PARRENT_PATH_NAME) !== message.pathname) {
                localStorage.setItem(gConst.STORAGE_PARRENT_PATH_NAME, message.pathname);
              }
              // -----------------------------------------------------------

              if(data.callBoxStyle){
                localStorage.setItem(gConst.STORAGE_CALLBOX_STYLE, data.callBoxStyle);
              }
            
            });

            socket.on(gConst.SOCKET_CLICK_TO_CALL, function (data: any) {
              let message = data.message;
              message = JSON.parse(message);
              gdebug(3,'Click To Call ', data)
              // get url from click2call
              if(message?.urlPath){
                localStorage.setItem(gConst.STORAGE_URL_PATH,message.urlPath);
              }
              if (ComponentRef.current) {
                ComponentRef.current.clickToCall(message.phone);
              }
            });

            socket.on(gConst.SOCKET_SHOW_SMS, function (data: any) {
              let message = data.message;
              message = JSON.parse(message);
              gdebug(3,'Show SMS ', data)

              if (ComponentRef.current) {
                ComponentRef.current.showSMS(message.phone);
              }
            });
            
            socket.on(gConst.SOCKET_AGENT_UPDATE, (data: any) => {
        
              gdebug(3,'agent-updated', data)
              ComponentRef.current.changeAgentStatus(data);
            });

            socket.on(gConst.SOCKET_MESSAGE, (data: any) => {
              // console.log('Socket message c');
              // console.log(data);
              if (data.type === gConst.SOCKET_CALL_BUTTON_INFO) {
                if (ComponentRef.current) {
                  ComponentRef.current.callButtonInfo(data.message);
                }
              }
              gdebug(3,'Socket message ', data)
            });

            socket.on(gConst.SOCKET_GET_CURRENT_USER, function (data: any) {
              let message = data.message;
              // message = JSON.parse(message);
              gdebug(3,'getCurrentUser ', data, message)
              if (localStorage.getItem(gConst.STORAGE_CURRENT_CRM_USER) !== message) {
                localStorage.setItem(gConst.STORAGE_CURRENT_CRM_USER, message);
              }
            });
          }
        }, 2000);
        break;
    }
    

  }, [])

  useEffect(() => {
    switch (integrator) {
      case gConst.SALESFORCE:
        window.sforce.opencti.setSoftphonePanelIcon({ key: 'call' });
        window.sforce.opencti.setSoftphonePanelLabel({ label: 'Gcalls' });
        window.sforce.opencti.setSoftphoneItemIcon({ key: 'call' });
        window.sforce.opencti.setSoftphoneItemLabel({ label: 'Gcalls' });
        break;

      default:
        break;
    }
    window.addEventListener(gConst.SOCKET_MESSAGE, (event:any) => {
      if (event?.data?.extension !== gConst.SOCKET_GCALLS){
        return;
      }
      else {
        event.source.postMessage({ gcallsServer:localStorage.getItem(gConst.STORAGE_GCALLS_SERVER)},
        event.origin);
      }
    }, false);
  });

  function handleChangeCallbox(data: any) {
    // console.log(data, TabID)
    gdebug(3,'handleChangeCallbox ', data, TabID)
    if (socket) {
      
      switch (data.type) {
        case gConst.SOCKET_HIDE_CALLBOX:
          socket.emit(gConst.SOCKET_CHANGE_CALLBOX, { tabId: TabID, type: gConst.SOCKET_HIDE_CALLBOX });
          break;

        case gConst.SOCKET_INCOMING_CALL:
          socket.emit(gConst.SOCKET_CHANGE_CALLBOX, { tabId: TabID, type: gConst.SOCKET_INCOMING_CALL});
          break;

        case gConst.SOCKET_CHANGE_WIDTH_CALLBOX:
          socket.emit(gConst.SOCKET_CHANGE_CALLBOX, { tabId: TabID, type: gConst.SOCKET_CHANGE_WIDTH_CALLBOX, width: data.width });
          break;

        case gConst.SOCKET_SET_CALLBOX_STYLE:
            socket.emit(gConst.SOCKET_CHANGE_CALLBOX, { tabId: TabID, type: gConst.SOCKET_SET_CALLBOX_STYLE,callBoxStyle : data.callBoxStyle });
            break;

        case gConst.SOCKET_CHANGE_SERVER:
          socket.emit(gConst.SOCKET_CHANGE_CALLBOX, { tabId: TabID, type: gConst.SOCKET_CHANGE_SERVER, mobileUrl: data.mobileUrl });
          break;

        case gConst.SOCKET_GET_SESSION:
          let userSession = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN)) || null;
          socket.emit(gConst.SOCKET_SESSION, { tabId: TabID, type: gConst.SOCKET_GET_SESSION, session: userSession });
          break;

        default:
          break;
      }
    }
  }
  const ComponentRef = useRef() as any;



  
  return (
    <ToastProvider autoDismissTimeout={3000} >
      <div className="App " style={!isMobile(window) ? { boxShadow: " ", padding:"4px"} : {}}>
        <ComponentsIndex handleChangeCallbox={handleChangeCallbox} ref={ComponentRef} />
      </div>
    </ToastProvider>
  );
}

export default App;
