import { useEffect, useState } from 'react';
import './Login.css';
import { login } from '../../../services/gcalls.service';
import { useToasts } from 'react-toast-notifications';
import { keycloakForRealm } from '../../../services/keycloak';
import { gdebug } from '../../../lib/gdebug';
// import config from '../../../config.json';
import { decrypt, isMobile, encrypt } from '../../../lib/gUtils';
import { ReactComponent as Phone } from '../../../icons/login-phone.svg';
import { ReactComponent as Eye } from '../../../icons/eye-18.svg';
import { ReactComponent as EyeClose } from '../../../icons/eye-close18.svg';

import {getConfig} from '../../../config/configEncrypt';
import { forgetPasswordKeycloak, loginKeycloak } from '../../../services/keycloak.service';

import Swal from 'sweetalert2';
import { CDN } from '../../../lib/gCdn';
import { gConst } from '../../../lib/gConst';
const config = getConfig();
require('dotenv').config()

export interface ProcessEnv {
  [key: string]: string | undefined
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GCALLS_WEBHOOK_URL?: string;
      REACT_APP_SF_USERNAME?: string;
      REACT_APP_GCALLS_MOBILE?: string;
    }
  }
}

declare const window: Window &
  typeof globalThis & { 
    sforce: any;
    ReactNativeWebView : any
  };


type Props = {
  closeLogin: any;
};

function LoginForm({closeLogin }: Props) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isForgetPassword, setIsforgetPassword] = useState(false)
  const [logo, setLogo] = useState<string>('');
  const [brandPhone, setBrandPhone] = useState<string>('');
  const [supportLink, setSupportLink] = useState<string>('');
  const [callCenterName, setCallCenterName] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);


  const { addToast } = useToasts();

  useEffect(() => {
    initStatic()
    // xlim = login mail
    // xlip = login pass
    if(localStorage.getItem(gConst.STORAGE_XLIM) && localStorage.getItem(gConst.STORAGE_XLIP)){
      setEmail(decrypt(localStorage.getItem(gConst.STORAGE_XLIM)))
      setPassword( decrypt(localStorage.getItem(gConst.STORAGE_XLIP)))
    }

  }, [])


  const gotoSupportPage = async () => {
    window.location.href = supportLink
  };

  const inputType = showPassword ? 'text' : 'password';

  const initStatic = async () =>{
    const logo = await CDN.brandLogo();
    const branchPhone = await CDN.brandPhone();
    const suportLink = await CDN.brandSupportLink();    
    let callCenterName = localStorage.getItem(gConst.STORAGE_GCALLS_REAM);
    if (callCenterName) {
      setCallCenterName(callCenterName)
    }

    if (logo) {
      setLogo(logo);
    }

    if (suportLink) {
      setSupportLink(suportLink);
    }


    if(branchPhone){
      setBrandPhone(branchPhone);
    }

  }  

  const handleLoginKeycloak = async (event : any) => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timer: 120000,
    })

    Swal.showLoading()
    // -------------------------------------------------------
    event.preventDefault();

    if(email && password){
      localStorage.setItem(gConst.STORAGE_XLIM,encrypt(email))
      localStorage.setItem(gConst.STORAGE_XLIP,encrypt(password))
    }
    // -------------------------------------------------------
    if(isForgetPassword){
      let result = await handleForgetPasswordKeycloak()
      Swal.close();
      return
    }
    // -------------------------------------------------------
    let currentDateTime = new Date()
    const offset = currentDateTime.getTimezoneOffset()
    currentDateTime = new Date(currentDateTime.getTime() - (offset*60*1000))
    let currentDate = currentDateTime.toISOString().split('T')[0]
    localStorage.setItem(gConst.STORAGE_LOGIN_DATE,currentDate);
    // -------------------------------------------------------
    try {
      let result = await loginKeycloak(email,password)
      if(result.success){
        addToast('Login Success', {
          appearance: 'success',
          autoDismiss: true,
        });
        localStorage.setItem(gConst.STORAGE_IS_FIRST_LOGIN,'true');
        window.location.reload();
        
        
      }else{
        addToast('Invalid login credential', {
          appearance: 'warning',
          autoDismiss: true,
        });
      }
      Swal.close()
    } catch (error) {
      Swal.close()
      
    }
    // Swal.close()

    // -------------------------------------------------------

  }

 const handleForgetPasswordKeycloak = async () => {
    // window.location.href = await forgetPasswordKeycloak(email)
    if(!email){
      addToast('Please enter your email!', {
        appearance: 'warning',
        autoDismiss: true,
      });

      return;
    }
    let result = await forgetPasswordKeycloak(email)

    if(result?.data?.success){
      addToast('Please check your email!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }else{
      addToast('Email not found!', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  }

  function handlePasswordChange(event: any) {
    setPassword(event.target.value);
  }

  function handleEmailChange(event: any) {
    setEmail(event.target.value);
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function handleForgetPassword(){
    setIsforgetPassword(true)
    // window.location.href = forgetPasswordKeycloak('')
  }

  return (
    <div className='Login grid grid-cols-1 relative rounded-lg'>
      <button
        className="text-2xl font-bold text-white absolute top-4 right-4"
        onClick={closeLogin}
      >
        X
      </button>
  
      {/* ----------------------------------------------------------------------- */}
      <div></div>
      {/* ----------------------------------------------------------------------- */}
      <div className='text-center'>
          {/* <div className='flex flex-col items-center justify-center'>

            <img className="w-1/2 md:w-1/4 lg:w-1/6 h-auto" src={logo}></img>
          </div> */}
           {/* <p className="text-2xl md:text-3xl lg:text-4xl font-semibold mt-4">SIGN IN TO YOUR ACCOUNT</p> */}
          <p className="text-2xl md:text-3xl lg:text-4xl font-semibold mt-4">{callCenterName}</p>
        </div>
  

  
      {/* ----------------------------------------------------------------------- */}
  
      
        <div className='inputWrapper changeServerBox2'>
          <form
            className='rounded pt-6 pb-8 mb-4'
            onSubmit={handleLoginKeycloak}
          >
          <div className='mb-4'>
            <label
              htmlFor='username'
              className='block text-gray-300   mb-2 text-left'
            >
                
              Email
            </label>
            <input
              type='text'
              id='username'
              name='username'
              value={email}
              onChange={handleEmailChange}
              className='bg-deep-purple-700 border border-white rounded-lg focus:bg-deep-purple-700'
              autoComplete="off"
            />
          </div>
        { !isForgetPassword &&   <div className='mb-6'>
              <label
                htmlFor='password'
                className='block text-gray-300   mb-2 text-left'
              >
                Password
              </label>
              <div className='grid grid-cols-6 bg-deep-purple-700 border border-white rounded-lg focus:bg-deep-purple-700'>
                  <input
                    type={inputType}
                    id='password'
                    name='password'
                    value={password}
                    onChange={handlePasswordChange}
                    className='col-span-5 bg-deep-purple-700 focus:bg-deep-purple-700 border-r-0 pl-2'
                    autoComplete='off'
                  />
                  <span
                    className='col-span-1 flex items-center justify-center cursor-pointer'
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <Eye></Eye>
                    ) : (
                      <EyeClose></EyeClose>
                    )}
                  </span>
                </div>

              <div className="text-sm text-right pt-4">
                <a onClick={handleForgetPassword}>Forgot Password?</a>
              </div>
            </div>
            
            }

            <div className='flex items-center justify-center'>
              <button
                type='submit'
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-lg focus:outline-none focus:shadow-outline w-1/2'
                style={{
                  height: '35px',
                  borderRadius: '30px',
                  backgroundColor: '#30D68C', // Background color
                }}
              >
              { isForgetPassword ? 'SUBMIT' : 'SIGN IN'}
              </button>
            </div>
          </form>
        </div>
  
  
      {/* Third div for any additional content */}
      <div className='grid grid-cols-1 justify-center items-center '>
        <div className='inputWrapper flex items-center justify-center items-center' onClick={() => gotoSupportPage()}>
          <Phone className='mr-2' /> {/* Add margin to create space between the icon and text */}
          <span className='text-white'>{brandPhone}</span>
        </div>
      </div>
    </div>
  );
  
  
}

export default LoginForm;
