
import { gConst } from '../lib/gConst';
// TODO : implement load from Cdn?
const regex = /\/crm\/sales\/contacts\/(\d+)$/;

export function customContactIdSietViet(pCalllog, pUserData, pContactDefault, pval4 = null) {
    try {
        let calllog, userData, contactDefault;
        calllog = pCalllog
        userData = pUserData
        contactDefault = pContactDefault
        let callDirection = localStorage.getItem(gConst.STORAGE_DIRECTION)

        let iframeDatas = userData?.iframeDatas
        let crmLoginUID = userData?.crmLoginUID
        let contactList = iframeDatas?.ContactsData?.[0]?.[0]?.contacts || []
        let match = null

        if (callDirection == gConst.OUTGOING) {

            let click2callContact = localStorage.getItem(gConst.STORAGE_URL_PATH)
            if(click2callContact){
                match = click2callContact.match(regex);
            }
         
            
    
            if (match) {
              const id = match[1];
              return id
            } 
            
            if (!iframeDatas?.ContactsData) {
                return contactDefault
            }


            if (contactList.length == 1) {
                return contactList[0]?.id
            }


            if (contactList.length > 1) {

                for (let i = 0; i < contactList.length; i++) {
                    let contact = contactList[i];

                    if (contact?.owner?.id == crmLoginUID) {
                        return contact.id
                    }
                }

                return contactList[0].id
            }

            return contactDefault
        }

        if (callDirection == gConst.INCOMING) {

            if(iframeDatas?.CreateContacts?.[0].length >0 ){
                return iframeDatas?.CreateContacts?.[0]?.[0]?.id
            }

            if (contactList.length == 0) {
                return contactDefault
            }
            //
            if (contactList.length == 1) {

                return contactList[0]?.id
            }
            //
            if (contactList.length > 1) {


                for (let i = 0; i < contactList.length; i++) {

                    let contact = contactList[i];
                    if (contact.owner.id == crmLoginUID) {
                        return contact.id
                    }
                }
                return contactDefault
            }


            return contactDefault

        }
        return contactDefault
    } catch (error) {
        console.log('err from customContactIdSietViet')
    }
}

// --------------------------------------------------------

export function getDataFromList(pKey, pVal, pList, itemResponseData) {
    try {


        let result = null;
        let arrays = itemResponseData[pList] || []
        let value = pVal

        try {
            value = eval(pVal)
        } catch (error) {
            value = pVal
        }


        for (let i = 0; i < arrays.length; i++) {
            const item = arrays[i];
            if (item[pKey] === value) {
                result = item;
                break;
            }
        }
        return result;
    } catch (error) {

        console.log('err from getDataFromList')
    }
};


// --------------------------------------------------------
export function customFilterSV(pList, gUserData, itemResponseData) {
    try {

        let arrays = itemResponseData[pList] || []
        let callDirection = localStorage.getItem(gConst.STORAGE_DIRECTION)
        let crmLoginUID = localStorage.getItem(gConst.STORAGE_CRM_LOGIN_UID);

        
        if (callDirection == gConst.INCOMING) {
            if (arrays?.length == 1) {
                return arrays[0]
            }

            if (arrays?.length > 1) {
                for (let index = 0; index < arrays.length; index++) {
                    let contact = arrays[index];

                    if (contact?.owner?.id == crmLoginUID) {
                        return contact
                    }
                }
            }
        }

        if (callDirection == gConst.OUTGOING) {
            let match = null 

            let click2callContact = localStorage.getItem(gConst.STORAGE_URL_PATH)
            if(click2callContact){
                match = click2callContact.match(regex);
            }
            
            let id = null

            if (match) {
                id = match[1];
            } 


            if (arrays?.length > 1) {
                // console.log(arrays,'contact')
                for (let index = 0; index < arrays.length; index++) {
                    let contact = arrays[index];
                    
                    if (contact?.owner?.id == crmLoginUID && contact.id == id) {
                        return contact
                    }

                    if (contact?.owner?.id == crmLoginUID) {
                        return contact
                    }

                    if(contact.id == id){
                        return contact
                    }
                    
                }
                return arrays[0]
            }

            // -------------------------------------------------------------------------------


            if (arrays?.length == 1) {
                return arrays[0]
            }
        }

        return null

    } catch (error) {
        console.log('err from customFilterSV')

    }

};


