import React, { useEffect } from 'react';
import { ReactComponent as CallRing } from '../../../icons/call-ringing.svg';
import { ReactComponent as CallEnd } from '../../../icons/call-end.svg';
import info from '../../../icons/info.png';
import up from '../../../icons/up-arrow.svg';
import { ReactComponent as Up } from '../../../icons/forward.svg';
// import { ReactComponent as Up } from '../../../icons/up-arrow.svg';


type Props = {
  name: string;
  phoneNumber: string,
  hangUp: any,
  hangDown: any,
  status: any,
  handleChangeCallBoxMode : any,
}
function MinInComing({ name, phoneNumber, hangUp, hangDown, status, handleChangeCallBoxMode }: Props) {
  return (
    <>
      <div className="rounded-lg grid grid-cols-2" style={{ backgroundColor: "#673ab7", width: "100%", height: "100px", zIndex: 100 }}>
      <div className="text-2xl grid grid-cols-1  text-center items-center justify-center" style={{height: "100px"}}>
        <div>
        <p className="pl-3 text-white">    {phoneNumber !== '' ? phoneNumber : '0XXXXXXXX'}</p>
        <p className="pl-3 text-white"> {status ? status : 'calling'}</p>
        </div>

      </div>


        <div className="text-2xl flex  text-center items-center">


        <button
            id="contact"
            style={{ width: '3rem', height: '3rem' }}
            className="flex justify-center items-center  mx-2 "
            onClick={handleChangeCallBoxMode} 
          >

          < div className='transform -rotate-90'> <Up width="25" height="25" fill="white" /> </div>
          </button>

          <button
            id="accepted"
            style={{ width: '3rem', height: '3rem' }}
            className=" flex justify-center items-center  mx-2 bg-green-500 rounded-full"
            onClick={hangUp}
          >
            <CallRing fill="white" width="2rem" height="2rem"   />
          </button>

          <button
            id="cancel"
            style={{ width: '3rem', height: '3rem' }}
            className="flex justify-center items-center  mx-2 bg-red-500   rounded-full"
            onClick={hangDown}
          >
            <CallEnd fill="white" width="2rem" height="2rem" />
          </button>




        </div>
      </div>
    </>
  );
}

export default MinInComing;