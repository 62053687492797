import React, { useEffect, useState, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gConst } from '../../lib/gConst';
import { AgentInfo } from '../../types/index';
import {
  faCheckCircle,
  faBellSlash,
  faMugHot,
  faMinusCircle,
  faCheck,
  faPhone,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import { updateAgentStatus } from '../../services/gcalls.service';

const listAgentStatus = {
  [gConst.ONLINE]: {
    code: gConst.ONLINE,
    name: 'Online',
    icon: <FontAwesomeIcon className="h-5 w-5 text-green-600" icon={faCheckCircle} />,
  },
  [gConst.OFFLINE]: {
    code: gConst.OFFLINE,
    name: 'Offline',
    icon: <FontAwesomeIcon className="h-5 w-5 text-gray-400" icon={faCircle} />,
  },
  [gConst.SILENT]: {
    code: gConst.SILENT,
    name: 'Im lặng',
    icon: <FontAwesomeIcon className="h-5 w-5 text-gray-400" icon={faBellSlash} />,
  },
  [gConst.BREAK]: {
    code: gConst.BREAK,
    name: 'Nghỉ trưa',
    icon: <FontAwesomeIcon className="h-5 w-5 text-yellow-800" icon={faMugHot} />,
  },
  [gConst.DND]: {
    code: gConst.DND,
    name: 'Không làm phiền',
    icon: <FontAwesomeIcon className="h-5 w-5 text-red-800" icon={faMinusCircle} />,
  },
  [gConst.CALLING]: {
    code: gConst.CALLING,
    name: 'Đang gọi',
    icon: <FontAwesomeIcon className="h-5 w-5 text-blue-400" icon={faPhone} />,
  },
};

interface Props {
  agentInfo: AgentInfo;
  handleChangeAgentStatus: any;
}

const AgentStatus: React.FC<Props> = ({ agentInfo, handleChangeAgentStatus }) => {
  function handleOnChange(value: string) {
    handleChangeAgentStatus(value, true);
  }

  return (
    <div>
      <Listbox value={agentInfo.status} onChange={handleOnChange}>
        {({ open }) => (
          <>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="pointer-events-none absolute inset-y-0 left-0 ml-2 flex items-center pr-2">
                  {listAgentStatus[agentInfo.status]?.icon}
                </span>
                <span className="flex items-center">
                  <span className="ml-6 block truncate text-xl">
                    {listAgentStatus[agentInfo.status]?.name}
                  </span>
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {Object.keys(listAgentStatus)
                    .filter((item) => item !== gConst.CALLING && item !== gConst.OFFLINE)
                    .map((index) => (
                      <Listbox.Option
                        key={listAgentStatus[index].code}
                        className={({ active }) => {
                          return active
                            ? 'bg-indigo-600 text-white relative cursor-default select-none py-2 pl-3 pr-9 text-lg'
                            : 'text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-lg';
                        }}
                        value={index}>
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center text-xl">
                              {listAgentStatus[index].icon}

                              <span
                                className={
                                  selected
                                    ? 'font-semibold ml-3 block truncate'
                                    : 'font-normal ml-3 block truncate'
                                }>
                                {listAgentStatus[index].name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={
                                  active
                                    ? 'text-white absolute inset-y-0 right-0 flex items-center pr-4'
                                    : 'text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                }>
                                <FontAwesomeIcon className="h-5 w-5 text-gray-400" icon={faCheck} />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};
export default AgentStatus;
