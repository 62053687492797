import React, { lazy, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Outbound from './outgoing';

import jsQR from 'jsqr';
import png from 'png.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// @ts-ignore
import Calllog from '@devops_gcalls/gcalls-web-sdk';



import { options } from '../lib';
import InPhoning from './inPhoning';
import InComing from './incoming';
import { ReactComponent as Back } from '../icons/back.svg';
import { ReactComponent as Forward } from '../icons/forward.svg';
import MinInPhoning from './miniComponents/minInPhoning';
import MinIncoming from './miniComponents/minIncoming';

import DownArrow from '../icons/white-arrow-down.png';
import { LogSip, UserInfo, Configuration, LogData, LoginStatus, AgentInfo } from '../types';
// import ShowInfoHubspot from '../customCallbox/hubspot/showInfo';
import { getCalllogs, getSession, postGcallsLog, searchContact, searchContact2, apiIntegration, apiIntegration2, logout, updateAgentStatus, isTokenExpired, apiHook } from '../services/gcalls.service';
import SettingComponent from './settingsComponent';
import SMSBOX from './sms/sms';
import { keycloakForRealm } from '../services/keycloak';
// import { createSMSNoteHubspot, getDataIframeHubspot, saveLogHubspot } from '../customCallbox/hubspot/function';
// import { checkStatus, millisToSeconds } from '../services/sharedFunction';
import { checkNotifyPermisson, checkStatus, makeID, makeNotify, millisToSeconds } from '../services/sharedFunction';

import { gdebug } from '../lib/gdebug';

import { isMobile, doFormatPhoneNumber, doValidatePhoneNumber, callBoxCss, isShowInfotableCss, isShowKeyPadCss, encrypt, decrypt, getIntergrator, getRegionCode, setRegionCode, handleAddSipHeader, handleChangeRegionCode, getFieldFromSipMessage, handleConfig, getGUserData} from '../lib/gUtils';
import { useBeforeunload } from 'react-beforeunload';
import { gConst } from '../lib/gConst';
import { activeMobileAppAwake, addMobileEventListener, deactiveMobileAppAwake, getMobileIntegrator,  getMobileParrentHostNameList,  reactNativeLog,  saveMobileLocalStorage,  setAndroidSpeakerOff,  setAndroidSpeakerOn,  setMobileIntegrator } from '../services/gMobileApp.service';
import { getAudioDuration } from '../customCallbox/hubspot/function';
import Swal from 'sweetalert2';
import { CDN } from '../lib/gCdn';
import { logoutKeycloak, refreshTokenKeycloak } from '../services/keycloak.service';

import host from '../config/host';
const { recordHost, enablePouchDbLog } = host;
require('dotenv').config();

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_LOG_MISSED_USER_ID_GLOBISH?: string;
      REACT_APP_RECORDINGS_DOMAIN?: string;
      REACT_APP_COUCHDB_USERNAME?: string;
      REACT_APP_COUCHDB_PASSWORD?: string;
      REACT_APP_COUCHDB_HOST?: string;
      REACT_APP_VINUNI_TENANT_ID?: string;
      REACT_APP_GLOBISH_TENANT_ID?: string;
      REACT_APP_GIMASYS_TENANT_ID?: string;
    }
  }
}

declare const window: Window &
  typeof globalThis & {
    sforce: any;
    ReactNativeWebView : any
  };

const salesforce = window.sforce;

// Get Data LocalStorage
let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM);
let parrentHost: any = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME);
let gUserData: any = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let handleRegionCode = gUserData?.integrator[parrentHost]?.info?.handleRegionCode;
let lang = gUserData?.integrator[parrentHost]?.info?.lang || gConst.LAN_EN;

getMobileParrentHostNameList(gUserData)

// Define Gcalls SDK
let optionsSdk = options;
// optionsSdk.databaseConfigurations.db = "calllog-gimasys"
const portProxy = ':50061';
const portSocket = ':7444';
const wssSocket = 'wss://';


let showSMS = false;

//Import File
const importIframeView = (integrator: any) =>
  lazy(() =>
    import(`../customCallbox/${integrator}/showInfo`).catch(() => {
      import(`../customCallbox/default/showInfo`);
    }),
  );

const importSMSView = (integrator: any) =>
  lazy(() =>
    import(`../customCallbox/${integrator}/sms/sms`).catch(() => {
      import(`./sms/sms`);
    }),
  );

let listCustomIntegration = [gConst.HUBSPOT,  gConst.NHANH_V1, gConst.SALESFORCE];
let listCustomSms = [gConst.SALESFORCE];
let customSMS = false;
let integrator = gConst.DEFAULT;


if(isMobile(window) ){
  addMobileEventListener(window)
}


// alert(parrentHost)
gdebug(3, 'parrentHost ', parrentHost);

if ((!!gUserData && !!gUserData?.integrator[parrentHost]) || ( isMobile(window) && !!gUserData) ) {
  let templateName = gUserData?.integrator[parrentHost]?.templateName || gConst.DEFAULT;
  let IdIntegrator = gUserData?.integrator[parrentHost]?._id || '';

  if (listCustomIntegration.includes(templateName) && !Array.isArray(gUserData.integrator[parrentHost])) {
    integrator = templateName || gConst.DEFAULT;
  } else {
    integrator = gConst.DEFAULT;
  }

  if (listCustomSms.includes(templateName)) {
    customSMS = true;
  }

  gdebug(3, 'Integrator ', integrator);

  let listSMS = [gConst.SMS_VIET_GUY, gConst.SMS_FPT, gConst.SMS_NEXMO, gConst.SMS_GAPIT];
  for (let i = 0; i < listSMS.length; i++) {
    let sms = listSMS[i];
    if (!!gUserData.integrator[sms]) {
      showSMS = true;
    }
  }

  if (integrator === gConst.SALESFORCE) {
    integrator = integrator + '/' + IdIntegrator;
    if (!!gUserData.integrator[gConst.SMS_IRIS]) {
      showSMS = true;
    }
  }
}
const ShowInfo = importIframeView(integrator);
let SMSCONTAINER: any;
if (!!customSMS) {
  SMSCONTAINER = importSMSView(integrator);
} else {
  SMSCONTAINER = SMSBOX;
}


const { getDataIframe, saveLogCRM, createSMSNote, savePreLogCRM } = require(`../customCallbox/${integrator}/function`);


let config = handleConfig(options)
const calllog = new Calllog(config);



// TabId
let TabID: any = makeID(18);

let GcallsTabId: String = sessionStorage.getItem(gConst.STORAGE_GCALLS_TAB_ID) || ''
if(!GcallsTabId){
  sessionStorage.setItem(gConst.STORAGE_GCALLS_TAB_ID,TabID)
}else {
  TabID = GcallsTabId
}



// Define Keycloak
const keycloak = keycloakForRealm(callCenterName);
interface Props {
  handleChangeCallbox: any;
}

type HandleFunction = {
  clickToCall: (phone: any) => void;
  showSMS: (phone: any) => void;
  callButtonInfo: (data: any) => void;
  changeAgentStatus: (data: any) => void;
};

const ComponentsIndex: React.ForwardRefRenderFunction<HandleFunction, Props> = (props, ref) => {
  const { addToast } = useToasts();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    OwnerId: '',
    OwnerName: '',
    RoleName: '',
    Email: '',
    RoleId: '',
    GcallsExtension: '',
    GcallsProxy: '',
    GcallsWebsocket: '',
    GcallsPassword: '',
    GcallsName: '',
  });
  const [agentInfo, setAgentInfo] = useState<AgentInfo>({
    id: '',
    status: gConst.ONLINE
  });
  const [userSfInfo, setUserSfInfo] = useState<any>({
    OwnerId: '',
    OwnerName: '',
    RoleName: '',
    Email: '',
    RoleId: '',
  });
  const [isOpenSMS, setIsOpenSMS] = useState<any>(false);

  const [currentObject, setCurrentObject] = useState<any>({
    typeObject: '',
    idObject: null,
    number: '',
    name: '',
  });

  const [phoneData, setPhoneData] = useState<any>();
  const [iframeData, setIframeData] = useState<any>();

  const [extension, setExtension] = useState<boolean>(false);
  const [delay, setDelay] = useState<boolean>(false);
  // Is session in calling
  const [calling, setCalling] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  // true is out going, false is incoming
  const [inOrOut, setInOrOut] = useState<boolean>(true);
  const [status, setStatus] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [isShow, setIsShow] = useState<boolean>(false);
  const [miniMode, setMiniMode] = useState<boolean>(false);
  const [direction, setDirection] = useState<string>('');
  const [hotlineOutbound, setHotlineOutbound] = useState<string>('');

  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(false);
  const [isAndroidSpeaker, setAndroidSpeaker] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>({
    isLogin: false,
    username: '',
    fullname: '',
  });
  const [fromDialPad, setFromDialPad] = useState<string>('');
  const [language, setLanguage] = useState<any>(lang);

  const [callButtonInfo, setCallButtonInfo] = useState<any>({
    buttonDestination: '',
    buttonExtension: '',
    buttonInputPhone: '',
    buttonName: '',
    buttonWebsite: '',
    isButtonClick2Call: false,
  });

  const extractUserAgentInfo = () => {
    const ua = navigator.userAgent;
    let os = "Unidentified";
    // let device = "unknown";
    let browser = "Unidentified";
    let callFrom = "Omnibox";
    let currentUrl = window.location.href;
    if(ua.indexOf("Windows") !== -1){
      os = "Windows"
      console.log("os", os)
    }else if(ua.indexOf("Linux") !== -1){
      os = "Linux"
      console.log("os", os)
    }else if(ua.indexOf("Mac") !== -1){
      os = "Mac OS X"
      console.log("os", os)
    }else if(ua.includes("Mobile")){
      os = "Unidentified Mobile"
      console.log("browser", os)
    }else if(ua.includes("Android")){
      os = "Android"
      console.log("browser", os)
    }else if(/iPhone|iPad|iPod/.test(ua)){
      os = "iOS"
      console.log("browser", os)
    }
    if(ua.includes("Chrome")){
      browser = "Chrome"
      console.log("browser", browser)
    }else if(ua.includes("Firefox")){
      browser = "Firefox"
      console.log("browser", browser)
    }else if(ua.includes("Safari") && !ua.includes("Chrome")){
      browser = "Safari"
      console.log("browser", browser)
    }else if(ua.includes("MSIE")){
      browser = "Internet Explorer"
      console.log("browser", browser)
    }else if(ua.includes("Edg")){
      browser = "Microsoft Edge"
      console.log("browser", browser)
    }else if(ua.includes("OPR")){
      browser = "Opera GX"
      console.log("browser", browser)
    }else if(ua.includes("Brave")){
      browser = "Brave"
      console.log("browser", browser)
    }else if(ua.includes("Opera")){
      browser = "Opera"
      console.log("browser", browser)
    }else if(ua.includes("CocCoc")){
      browser = "Cốc Cốc"
      console.log("browser", browser)
    }else if(ua.includes("Edg")){
      browser = "Internet Explorer"
      console.log("browser", browser)
    }

    return {deviceInfo: {os, browser, ua, callFrom}}
  }
  let deviceInfo = extractUserAgentInfo()


  const handleApiHook = async (pCalllog: any) => {

    let result = await apiHook(pCalllog)
    
  
  }

  

  // Save Log Function
  async function saveLog(logData: any) {
    

    logData._id = logData.call_id;
    logData.recordUrl = logData.playing_recordings_url ? logData.playing_recordings_url : '';

    // -----------------------------------------------------------------------------------

    if (callButtonInfo.buttonInputPhone) {
      if (callButtonInfo.isButtonClick2Call) {
        logData.buttonName = callButtonInfo.buttonName;
        logData.inputPhone = callButtonInfo.buttonInputPhone;
        logData.website = callButtonInfo.buttonWebsite;
        setCallButtonInfo({
          buttonDestination: '',
          buttonExtension: '',
          buttonInputPhone: '',
          buttonName: '',
          buttonWebsite: '',
          isButtonClick2Call: false,
        })
      }
    }

    // -----------------------------------------------------------------------------------
    

      let audioDuration = 0;
      try {
          audioDuration = await getAudioDuration(logData.playing_recordings_url);
      } catch (e) {
          audioDuration = 0;
      }    


      let userData : any =  getGUserData();
      let integratorId = getIntergrator()?._id;

      logData.integratorId = integratorId;
      logData.idCallcenter = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER);
      logData.idAgent =  localStorage.getItem(gConst.STORAGE_GCALLS_ID_AGENT);
      logData.agent_name = userData?.fullName;
      logData.couchDbName = gConst.COUCHDB_NAME;
      logData.logType = gConst.EXTENSION;


    // function getOS() {
    //   var uA = navigator.userAgent || navigator?.vendor;
    //   if ((/iPad|iPhone|iPod/.test(uA) && !window.MediaStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
    
    //   var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry', 'webOS', 'Opera', 'Chrome'];
    //   console.log("os", navigator.userAgent)
    //   for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
    // }
    let deviceInfoWithLogData = {...deviceInfo, ...logData}
    let logDataWithUA = {...deviceInfo, ...logData}
    console.log("deviceInfoWithLogData", deviceInfoWithLogData)
    console.log("logDataWithUA", JSON.stringify(logDataWithUA))
    console.log("os", navigator.userAgent)
    if (integrator.includes(gConst.SALESFORCE)) {
      let newCurrentObject: any = { currentObject: { ...currentObject } };
      let newUserInfo: any = { userInfo: { ...userSfInfo, GcallsExtension: userInfo.GcallsExtension } };
      let newIframeData: any = Object.assign(iframeData, newUserInfo, newCurrentObject);

      saveLogCRM(logData, newIframeData);
      if(enablePouchDbLog){
        calllog.insertCalllog(logDataWithUA);
      }else{
        postGcallsLog(logDataWithUA);
      }


    }else if (integrator.includes(gConst.DEFAULT) || integrator.includes(gConst.HUBSPOT)){
      if(enablePouchDbLog){
        calllog.insertCalllog(logDataWithUA);
      }else{
        saveLogCRM(logData, iframeData);
        postGcallsLog(logDataWithUA);
      }

    }else{
      saveLogCRM(logData, iframeData);
      postGcallsLog(logDataWithUA);
    }





  }



  const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));
  useBeforeunload(() => {

    if(isMobile(window)){
      return;
    }


    if(extension){
      calllog.unregister()
      setExtension(false)
      addToast('Sip Extension đã bị tắt do tab bị tắt hoặc reload', {
        appearance: 'info',
        autoDismiss: false,
      });
    }
  });
  // Reset Name
  useEffect(() => {
    if (!!phoneData && !!phoneData.length) {
      // let dataIframe: any = {}
      // setName(phoneData[0].firstName);
      // getCalllogs(phoneData[0]._id, function (res: any, err: any) {
      //   if (!err) {
      //     dataIframe.gcallsCalllogs = res;
      //     setIframeData(dataIframe)
      //   }
      // })
    } else {
      setName('');
    }
  }, [phoneData]);

  React.useImperativeHandle(ref, () => ({
    clickToCall(phone) {
      let data = {
        number: phone,
      };
      handleSubmit(data);
    },
    showSMS(phone) {
      setNumber(phone);
      if (!showSMS) {
        addToast('Vui lòng mua dịch vụ sms từ nhà mạng để tích hợp!', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
      if (!isOpenSMS) {
        setIsOpenSMS(true);
        getDataIframe(phone, setIframeData, setName, userSfInfo);
      }
    },
    callButtonInfo(data) {
      if (inOrOut && !calling) {
        setCallButtonInfo({
          buttonDestination: data.buttonDestination,
          buttonExtension: data.buttonExtension,
          buttonInputPhone: data.buttonInputPhone,
          buttonName: data.buttonName,
          buttonWebsite: data.buttonWebsite,
          isButtonClick2Call: false,
        })
      }
    },
    changeAgentStatus(data) {
      if (data && data.user == agentInfo.id && data.data.status != agentInfo.status) {
        console.log('changeAgentStatus')
        handleChangeAgentStatus(data.data.status);
      }
    },
  }));
  // SDK eventHandlers
  const eventHandlers = {
    progress: function (e: any) {
      setStatus(gConst.CALLING);
      handleShowHideProfile();
      activeMobileAppAwake(window);
       
    },
    registered: function (e: any) {
      if (!calling && (agentInfo.status == gConst.BREAK || agentInfo.status == gConst.DND)) {
        handleChangeAgentStatus(agentInfo.status);
      }
    },
    failed: async function (e: any) {
      gdebug(2, 'Call failed with cause from user: ', e.cause);
      const ringing = document.getElementById(gConst.RINGRING) as HTMLAudioElement;
      if (ringing) {
        ringing.pause();
      }


      setTimeout(() => {
        // end
        setMiniCallbox(false);
        setInOrOut(true);
        setCalling(false);
        setName('');
        setStatus('');
        setFromDialPad('');
        handleShowHideProfile();
        // setNumber('');
        deactiveMobileAppAwake(window)
        saveMobileLocalStorage(window, gConst.PHONE_NUMBER,'')
  
        if (agentInfo.status == gConst.BREAK || agentInfo.status == gConst.DND) {
          if (extension && calllog.isRegistered()) {
            calllog.unregister();
          }
        }
      }, 3000);
    },

    ended: async (e: any) => {
      gdebug(2, 'Call ended');

      // end
      setTimeout(() => {
        setName('');
        setStatus('');
        setCalling(false);
        setInOrOut(true);
        setFromDialPad('');
        setMiniCallbox(false);
        handleShowHideProfile();
        // setNumber('');
        deactiveMobileAppAwake(window);
        saveMobileLocalStorage(window, gConst.PHONE_NUMBER,'')

        if (agentInfo.status == gConst.BREAK || agentInfo.status == gConst.DND) {
          if (extension && calllog.isRegistered()) {
            calllog.unregister();
          }
        }
        // end
      }, 3000);
    },
    confirmed: function (e: any) {
      // console.log('call confirmed from user');
      gdebug(3, 'Call confirmed from user');

      let logData: any = {
        email: userInfo.Email,
        start: calllog.logSip.start,
        direction: calllog.logSip.direction,
        source: calllog.logSip.source,
        destination: calllog.logSip.destination,
        phone: calllog.logSip.display_name,
        sip_uri: `${userInfo.GcallsExtension}@${userInfo.GcallsProxy}`,
        call_id: calllog.logSip.call_id,
        flowid: calllog.logSip.flowid,
      };
      gdebug(3,"confirm",logData)
      !!savePreLogCRM && savePreLogCRM(logData, iframeData, setIframeData);
    },
    incoming: async function (e: any) {
      // A Liem
      const ringing = document.getElementById('ringing') as HTMLAudioElement;
      if (ringing) {
        //TODO: fix cannot use sound file
        // ringing.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-02.mp3';
        ringing.src = 'https://static.gcalls.co/Mp3/incoming-call.mp3';
        if (integrator === 'salesforce/62e206a54e4a1c0d633bdaea') {
          ringing.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-02.mp3';
        }
        try {
          if (agentInfo.status != gConst.SILENT) {
            ringing.play();
          }
        } catch (error) {
          // console.warn('play() | error: %o', error);
          gdebug(1, 'Play() | error: %o', error);
        }
      } else {
        // console.log('No sound');
        gdebug(1, 'No sound');
      }
      let call: any = e.session;
      if (isOpenSMS) {
        setIsOpenSMS(false);
      }

      // end
      let dataType = {
        type: gConst.SOCKET_INCOMING_CALL,
      };
      if (props.handleChangeCallbox) {
        props.handleChangeCallbox(dataType);
      }

      let { uri, display_name }: any = call.remote_identity;
      display_name = uri.user || display_name;

      // Incoming come from website click2call button.
      if (
          call.direction == gConst.INCOMING &&
          callButtonInfo.buttonInputPhone &&
          (
              callButtonInfo.buttonExtension == display_name ||
              callButtonInfo.buttonDestination == display_name
          )
      ) {
        display_name = callButtonInfo.buttonInputPhone;
        calllog.logSip.destination = callButtonInfo.buttonInputPhone;
        calllog.logSip.display_name = callButtonInfo.buttonInputPhone;
        calllog.logSip.phone = callButtonInfo.buttonInputPhone;

        setCallButtonInfo({
          ...callButtonInfo,
          ...{
            isButtonClick2Call: true,
          },
        });
      }
      //Notify
      let title = await CDN.brandText();
      let content = "You are having a call from " + display_name;

      

      if (isMobile(window)) {

      } else {
        //Not work on Mobile App
        let brandFavicon = await CDN.brandFavicon()
        makeNotify(title, content, brandFavicon)
      }


      // Refesh Iframe Data
      setIframeData(null);

      //ResetSFCurrentObject
      if (integrator.includes(gConst.SALESFORCE)) {
        if (currentObject.number && currentObject.number !== display_name) {
          setCurrentObject({
            typeObject: '',
            idObject: null,
            number: '',
            name: '',
          });
          setName('');
          setIframeData(null);
        }
      }

      let currentNumber = fromDialPad ? fromDialPad : display_name;
      // Get Data Iframe
      
      getDataIframe(currentNumber, setIframeData, setName, userSfInfo);
      
      setNumber(display_name);
      // hideProfile();
      setCalling(false);
      setInOrOut(false);

      // end

      setDirection(gConst.INCOMING);
      localStorage.setItem(gConst.STORAGE_DIRECTION, gConst.INCOMING)
    },
    outgoing: function (e: any) {
      let call: any = e.session;

      if (isOpenSMS) {
        setIsOpenSMS(false);
      }

      let local_uri: any = call.local_identity.uri;
      let { uri, display_name }: any = call.remote_identity;
      display_name = uri.user || display_name;

      // Refesh Iframe Data
      setIframeData(null);

      let currentNumber = fromDialPad ? fromDialPad : display_name;

      //ResetSFCurrentObject
      if (integrator.includes(gConst.SALESFORCE)) {
        if (currentObject.number && currentObject.number !== currentNumber) {
          setCurrentObject({
            typeObject: '',
            idObject: null,
            number: '',
            name: '',
          });
          setName('');
          setIframeData(null);
        }
      }
      
      
      getDataIframe(currentNumber, setIframeData, setName, userSfInfo);


      setDirection(gConst.OUTGOING);
      localStorage.setItem(gConst.STORAGE_DIRECTION, gConst.OUTGOING)
    },
    onTrack: (e: any) => {
      // console.log('On Track');
      // Handle audio incoming call

      gdebug(2, 'On Track');
      let audio = document.createElement('audio');
      audio.srcObject = e.streams[0];
      audio.play();
    },
    functions: {
      setNumber,
      setStatus,
      saveLog,
      recordHost,
      userInfo,
      handleApiHook,
    }
  };

  calllog.setEventHandlers(eventHandlers);

  // SDK socketEventHandlers
  let socketEventHandlers = {
    onMessage: (e: any) => {
      console.log(e, 'callbox on message')

      let data = e?.data
      let display_hotline = ''

      // just for display in the UI
      if(data){
        display_hotline = getFieldFromSipMessage(gConst.SIP_HOTLINE_OUTBOUND,data)
      }

      setHotlineOutbound(display_hotline);

    },
    onClose: (e: any) => {
      console.log('callbox on close')
    },
    onOpen: (e: any) => {
      console.log('callbox on open')
    },
  };
  calllog.setSocketEventHandlers(socketEventHandlers);


  const handleMobileData = (data : any) => {

      if(data?.phoneNumber){
      setNumber(data.phoneNumber)
    }

    if(data?.type == gConst.RELOAD){
      window.location.reload()
     
    }
    
  };
  

  // SDK setConfiguration
  useEffect(() => {
    // TODO:
    setDelay(true);

    const configuration = {
      // proxy: userInfo.GcallsProxy.length > 0 ? (userInfo.GcallsProxy.includes(portProxy) ? userInfo.GcallsProxy : userInfo.GcallsProxy + portProxy) : '',
      proxy: userInfo.GcallsProxy,
      sip: userInfo.GcallsExtension,
      password: userInfo.GcallsPassword,
      sockets: [
        userInfo.GcallsWebsocket.length > 0
          // ? userInfo.GcallsWebsocket.includes(portSocket)
          ? userInfo.GcallsWebsocket.includes(wssSocket)
            ? userInfo.GcallsWebsocket
            : wssSocket + userInfo.GcallsWebsocket
          // : (userInfo.GcallsWebsocket + portSocket).includes(wssSocket)
          // ? userInfo.GcallsWebsocket + portSocket
          // : wssSocket + userInfo.GcallsWebsocket + portSocket
          : '',
      ],
    };

    setTimeout(() => {


      // check sdk config
      calllog.setConfiguration(configuration);

      /* Disable auto register sip */
      if (configuration.sip.length > 0 && configuration.password.length > 0 && configuration.proxy.length > 0 && configuration.sockets.length > 0) {
        setIsDisable(false);
        if (integrator.includes(gConst.SALESFORCE)) {
          calllog.register();
          setExtension(true);
        }
      }
      setDelay(false);
    }, 3000);

    // Event Get User Salesforce
    if (integrator.includes(gConst.SALESFORCE)) {
      let info: any
      const param: any = {
        apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
        methodName: gConst.SF_METHOD_GET_CURRENT_USER,
        methodParams: '',
        callback: (response: any) => {
          if (response.success) {
            const { runApex } = response.returnValue;
            info = JSON.parse(runApex);
            // console.log('TCL: App -> componentDidMount -> info', info);
            gdebug(3, 'TCL: App -> componentDidMount -> info ', info);
            setUserSfInfo({
              ...userSfInfo,
              OwnerId: info.ownerId,
              OwnerName: info.ownerName,
              RoleName: info.RoleName,
              Email: info.Email,
              RoleId: info.UserRoleId,
            });
          }
        },
      };
      window.sforce.opencti.runApex(param);
      salesforce.opencti.onClickToDial({ listener: (e: any) => onClickToCallListener(e, info) });
    }
  }, []);

  useEffect(() => {
    
    setUserInfo({
      ...userInfo,
      OwnerId: userSfInfo.OwnerId,
      OwnerName: userSfInfo.OwnerName,
      RoleName: userSfInfo.RoleName,
      Email: userSfInfo.Email,
      RoleId: userSfInfo.RoleId,
    });

  }, [userSfInfo])

  useEffect(() => {


    /* Listen LocalStorage Change */
    if (!integrator.includes(gConst.SALESFORCE)) {
      window.addEventListener('storage', (e) => {
        const key = e.key;
        gdebug(3,'storage', e, e.key);
        if (key === gConst.STORAGE_SIP_LISTSIP_EXTENSION) {
          let newValue: any = e.newValue;
          if (newValue) {
            newValue = JSON.parse(newValue);
            newValue.forEach((e: any) => {
              let key: any = Object.keys(e)[0];
              let value: any = Object.values(e)[0];
              if (key === TabID && !value.extension) {
                if (calllog.isConnected()) {
                  calllog.unregister();
                  setExtension(false);
                  addToast('Sip status has changed', {
                    appearance: 'success',
                    autoDismiss: true,
                  });
                }

              } 
            });
          }
        }
      });
    }
  }, []);

  // Event Click To Call Salesforce
  const onClickToCallListener = async (event: any, info: any) => {
    
    // console.log('clicktocalll', event);
    info.OwnerId = info.ownerId;
    info.ownerName = info.ownerName;
    info.RoleId = info.UserRoleId;

  
    gdebug(3, 'Click to call ', event, info);
    salesforce.opencti.isSoftphonePanelVisible({
      callback: (response: any) => {
        if (response.success) {
          if (!response.returnValue.visible) {
            salesforce.opencti.setSoftphonePanelVisibility({
              visible: true,
            });
          }
        }
      },
    });

    let currentObj = {
      typeObject: event.objectType,
      idObject: event.recordId,
      number: event.number,
      name: event.recordName,
    };
    setCurrentObject(currentObj);
    setNumber(event.number);
    let ListObject = [gConst.CONTACT, gConst.LEAD, gConst.APPLICATION, gConst.CASE,  gConst.OPPORTUNITY];
    if (ListObject.includes(currentObj?.typeObject)) {
      setName(event.recordName);
    } else {
      setName('');
    }
    getDataIframe(currentObj, setIframeData, setName, info);
    if (isOpenSMS) {
      setIsOpenSMS(false);
    }
  };

  // Handle Click Call
  const handleSubmit = async (data: any) => {
    
    let { number } = data;

    
    if (!window.localStorage.getItem(gConst.STORAGE_GCALLS_DISABLE_AUTO_FORMAT_NUMBER)) {
      setFromDialPad(number);
      number =  doFormatPhoneNumber(number)
    }

    let token = decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN));
    if( await isTokenExpired(token)){
      handleLogoutKeycloak();
      return;
    }

    console.log('this is dest', number);

    if(doValidatePhoneNumber(number) === false) {
      if(isMobile(window)){
        let data = {        
          type : gConst.REACT_NATIVE_LOCAL_PUSH,
          title : `Gcalls`,
          message: 'Please enter a valid phone number'
        }
        window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
      }else{
        addToast('Please enter a valid phone number', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      return;
    }


    if(handleRegionCode?.regionCode.length > 0){
      let result = await handleChangeRegionCode(handleRegionCode)
    }
    handleAddSipHeader(calllog)

    if (extension) {
      if (!!parrentHost || isMobile(window)) {

        let timeout = 0;
        setCalling(true);
        if (agentInfo.status == gConst.BREAK || agentInfo.status == gConst.DND) {
          if (!calllog.isRegistered()) {
            handleResgisterSip();
            timeout = 1000;
          }
        }
 
        setTimeout(()=>{
          setNumber(number);
          setCalling(true);
          //Disable auto open iframe
          // setIsShow(true);
          calllog.call(number);
        }, timeout)
      }
    }
    // } 
    else {
      if(isMobile(window)){
        let data = {        
          type : gConst.REACT_NATIVE_LOCAL_PUSH,
          title : `Gcalls`,
          message: 'Call is stopped'
        }
        window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
      }else alert('Call is stopped');
    }
  };






  // Handle Change Status SIP
  const hanldeStatusExtensionChange = () => {
    let listSip: any = localStorage.getItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION);
    let currentTab: any = {[TabID]:{
      'extension': false
    }}
    listSip = listSip ? JSON.parse(listSip) : [];

    if (extension) {

      var options = {
        all: true
      };      
      calllog.unregister(options)
      setExtension(false)

      try {
        let isTabExist = false;
        listSip = listSip.map((e: any) => {
          let key: any = Object.keys(e)[0];
          if (key === TabID) {
            e = currentTab;
            isTabExist = true;
          }
          return e;
        });
        !isTabExist && listSip.push(currentTab);
        listSip = JSON.stringify(listSip);
        gdebug(3, 'listSip: ', listSip);
        localStorage.setItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION, listSip);
      } catch (error) {
        gdebug(1, 'error: ', error);
      }

    } else if (delay === false && userInfo.GcallsExtension) {
      try {
        const configuration = {
          // proxy: userInfo.GcallsProxy.length > 0 ? (userInfo.GcallsProxy.includes(portProxy) ? userInfo.GcallsProxy : userInfo.GcallsProxy + portProxy) : '',
          proxy: userInfo.GcallsProxy,
          sip: userInfo.GcallsExtension,
          password: userInfo.GcallsPassword,
          sockets: [
            userInfo.GcallsWebsocket.length > 0
              // ? userInfo.GcallsWebsocket.includes(portSocket)
              ? userInfo.GcallsWebsocket.includes(wssSocket)
                ? userInfo.GcallsWebsocket
                : wssSocket + userInfo.GcallsWebsocket
              // : (userInfo.GcallsWebsocket + portSocket).includes(wssSocket)
              // ? userInfo.GcallsWebsocket + portSocket
              // : wssSocket + userInfo.GcallsWebsocket + portSocket
              : '',
          ],
        };

        // if calllog connect success, set extension true
        calllog.setConfiguration(configuration);
        if (configuration.sip.length > 0 && configuration.password.length > 0 && configuration.proxy.length > 0 && configuration.sockets.length > 0) {
          if (!integrator.includes(gConst.SALESFORCE)) {

            if(isMobile(window)){
              calllog.register();
              setExtension(true);
              // console.log(calllog.isConnected());
              gdebug(3, 'Calllog isConnected ', calllog.isConnected());

              return
            }
            confirmAlert({
              customUI: ({ onClose }) => (
                <div className="customConfirmBox">
                  <h1 className="customConfirmBox_title">Extension</h1>
                  <p className="customConfirmBox_message">Extension chỉ được bật ở 1 tab, extension ở các tab khác (nếu có) sẽ bị tắt đi, tiếp tục?</p>
                  <div className="customConfirmBox_btnDiv">
                    <button className="customConfirmBox_btn1" onClick={onClose}>
                      Không
                    </button>
                    <button
                      className="customConfirmBox_btn2"
                      onClick={() => {
                        calllog.register();
                        setExtension(true);
                        gdebug(3, 'Calllog isConnected ', calllog.isConnected());

                        /* Set Current Sip Session Status */
                        handleExtensionOverMutipleTabs();
                        onClose();
                      }}
                    >
                      Tiếp tục
                    </button>
                  </div>
                </div>
              ),
            });
          } else {
            calllog.register();
            setExtension(true);
            // console.log(calllog.isConnected());
            gdebug(3, 'Calllog isConnected ', calllog.isConnected());
          }
        }
      } catch (error) {

        //if calllog connect error
        setExtension(false);

        gdebug(1, 'hanldeStatusExtensionChange Error ', error);
      }
    }

  };


  const handleResgisterSip = () => {
    const configuration = {
      proxy: userInfo.GcallsProxy,
      sip: userInfo.GcallsExtension,
      password: userInfo.GcallsPassword,
      sockets: [
        userInfo.GcallsWebsocket.length > 0
          ? userInfo.GcallsWebsocket.includes(wssSocket)
            ? userInfo.GcallsWebsocket
            : wssSocket + userInfo.GcallsWebsocket
          : '',
      ],
    };
    calllog.setConfiguration(configuration);
    calllog.register();
  }

  // Handle Mute
  const handleMuteClick = () => {

    if(isMute){
      setIsMute(false)
      calllog.unmuteCalls();
    }else{
      setIsMute(true)
      calllog.muteCalls();
    }
  };

  // Handle End Call
  const handleEndClick = () => {
    calllog.terminate(); 


    
  };

  // Handle change android speaker
  // -------------------------------------------------------------------------------------
  const handleChangeAndroidSpeaker= () => {
    if(isAndroidSpeaker){
      setAndroidSpeaker(false)
      setAndroidSpeakerOff(window)
    }else{
      setAndroidSpeaker(true)
      setAndroidSpeakerOn(window)

    }
  }

  // -------------------------------------------------------------------------------------

  // Handle HangUp
  const handleHangUp = () => {
    const ringing = document.getElementById(gConst.RINGRING) as HTMLAudioElement;
    if (ringing) {
      ringing.pause();
    }
    calllog.answer();
    setCalling(true);
  };

  // Handle HangDown
  const handleHangDown = () => {
    const ringing = document.getElementById(gConst.RINGRING) as HTMLAudioElement;
    if (ringing) {
      ringing.pause();
    }
    setCalling(false);
    setInOrOut(true);
    calllog.terminate();
    handleShowHideProfile();
    // hideProfile()
    // setTimeout(() => {
    // }, 1000);
  };
  // -------------------------------------------------------------------------------------


  // Handle Transfer
  const handleTransfer = (data: string) => {
    setStatus(gConst.FORWARD);
    setTimeout(() => {
      const statusTransfer = calllog.transfer(data);
      if (statusTransfer) {
        addToast('Forward success!', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast('Forward fail!', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }, 1000);
  };

  // Handle Transfer
  const handleSendDTMF = (data: string) => {
    try {
      calllog.sendDTMF(data);
      addToast(`Send ${data}`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) { }
  };
  // -------------------------------------------------------------------------------------

  // Toggle Setting
  const toggleShowHideProfile = () => {


    if(!isShow){
      showProfile()
    } else {
      hideProfile()
    }
  };


  const handleShowHideProfile = () => {


    if(isShow){
      showProfile()
    } else {
      hideProfile()
    }
  };

  // -------------------------------------------------------------------------------------

  const showProfile = () => {

   if(integrator != gConst.HUBSPOT && !integrator.includes(gConst.SALESFORCE) && integrator != gConst.NHANH_V1 && integrator != gConst.DEFAULT){
    hideProfile();
   }

    if(isMobile(window)){
      hideProfile();
      return;
    }

    if(!extension){
      hideProfile();
      return;
    }
    
    
    const boxInfo = document.getElementById(gConst.CSS_INFO_CONTACT);
    
    if (boxInfo) {
      boxInfo.style.display = gConst.CSS_BLOCK;
    }
    
    setIsShow(true);
    changeWidthCallbox(830)
   
  };

  const hideProfile = () => {
    const boxInfo = document.getElementById(gConst.CSS_INFO_CONTACT);
    
    if (boxInfo) {
      boxInfo.style.display = gConst.CSS_NONE;
      setIsShow(false);
      changeWidthCallbox(300)
    }
   
  };

  // Toggle Iframe
  useEffect(() => {
    if (isShow) {
      if (integrator.includes(gConst.SALESFORCE)) {
        window.sforce.opencti.setSoftphonePanelWidth({
          widthPX: 830,
          callback: () => {
            // console.log('set width')
            gdebug(1, 'Set width');
          },
        });
      } else {
        showProfile()     
       }

    } else {
      if (integrator.includes(gConst.SALESFORCE)) {
        window.sforce.opencti.setSoftphonePanelWidth({
          widthPX: 300,
          callback: () => {
            // console.log('set width')
            gdebug(1, 'Set width');
          },
        });
      } else {
        hideProfile()
      }
     
    }
  }, [isShow]);

  //TODO: show iframedata when numberphone has changed
  useEffect(() => {
    setInOrOut(true);
  }, [number]);

  // Update SIP
  const [isSettingShow, setIsSettingShow] = useState(false);
  const handleSettingClick = (data?: any) => {
    if (data === true) {
      setIsSettingShow(data);
      hideProfile();
    } else {
      if (!isSettingShow) {
        hideProfile();
      }
      setIsSettingShow(!isSettingShow);
    }
  };

  // QR SIP
  // source https://codesandbox.io/s/pwoywz6n5x?file=/index.js:0-26
  function convertPNGtoByteArray(pngData: any) {
    const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
    for (let y = 0; y < pngData.height; y++) {
      for (let x = 0; x < pngData.width; x++) {
        const pixelData = pngData.getPixel(x, y);
        data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
        data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
        data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
        data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
      }
    }
    return data;
  }

  // Refresh SIP Register When UserInfo Change
  useEffect(() => {

    if(isMobile(window)){
      addMobileEventListener(window,handleMobileData)
    }

    
    try {
      if (calllog.isRegistered()) {
        calllog.unregister();
      }

      hideProfile();
      setIsDisable(true);
      const configuration = {
        // proxy: userInfo.GcallsProxy && userInfo.GcallsProxy.length > 0 ? (userInfo.GcallsProxy.includes(portProxy) ? userInfo.GcallsProxy : userInfo.GcallsProxy + portProxy) : '',
        proxy: userInfo.GcallsProxy,
        sip: userInfo.GcallsExtension,
        password: userInfo.GcallsPassword,
        sockets: [
          userInfo.GcallsWebsocket && userInfo.GcallsWebsocket.length > 0
            // ? userInfo.GcallsWebsocket.includes(portSocket)
            ? userInfo.GcallsWebsocket.includes(wssSocket)
              ? userInfo.GcallsWebsocket
              : wssSocket + userInfo.GcallsWebsocket
            // : (userInfo.GcallsWebsocket + portSocket).includes(wssSocket)
            // ? userInfo.GcallsWebsocket + portSocket
            // : wssSocket + userInfo.GcallsWebsocket + portSocket
            : '',
        ],
      };
      if (userInfo.GcallsExtension && userInfo.GcallsProxy && userInfo.GcallsWebsocket && userInfo.GcallsPassword) {

        calllog.setConfiguration(configuration);

        if (configuration.sip.length > 0 && configuration.password.length > 0 && configuration.proxy.length > 0 && configuration.sockets.length > 0) {
          
          if(isMobile(window) || integrator.includes(gConst.SALESFORCE)){
            calllog.register();
            setExtension(true);

          }
            
          setTimeout(() => {
            setIsDisable(false);
          }, 2345);
        }
      }
    } catch (error) {
      // console.log(error);
      gdebug(1, 'Configuration Error ', error);
      addToast('Login fail!', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [userInfo]);

  const handleLogoutKeycloak = async () => {
    try {
      gdebug(3, 'Logout ');
      await logout();
      setUserInfoWhenNotLoggedIn();
      setLoginStatus({
        isLogin: false,
        username: '',
        fullname: '',
      });

      localStorage.removeItem(gConst.STORAGE_GCALLS_TOKEN);
      localStorage.removeItem(gConst.STORAGE_GCALLS_USER);

      keycloak.logout();

      setExtension(false);
      
      if(!isMobile(window)){
        addToast('Session expired! Please login again.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.log(error)
    }
    
    
    // else{
    //   let data = {        
    //     type : gConst.REACT_NATIVE_LOCAL_PUSH,
    //     title : `Gcalls`,
    //     message: 'Session expired! Please login again.'
    //   }
    //   window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
    // }
  };

  
  
  // Keycloak Init
  useEffect(() => {
    // f5 to test the UI at the beginning if remove this 
    hideProfile();
    
    // =================================================


    if(localStorage.getItem(gConst.STORAGE_IS_FIRST_LOGIN)){
      getUserDataKeycloak(true);
      localStorage.removeItem(gConst.STORAGE_IS_FIRST_LOGIN)
    }else{
      getUserDataKeycloak();
    }
    // =================================================
    
    setTimeout(() => {
      if (integrator.includes(gConst.SALESFORCE)) {
        window.sforce.opencti.setSoftphonePanelWidth({
          widthPX: 300,
          callback: () => {
            gdebug(1, 'Set width ');
          },
        });
      } else {
        hideProfile();
        
        
      }
    }, 3000);
    
    

    if (isMobile(window)) {
      //alert('Mobile');
    } else {
      // Not work on mobile
      setTimeout(() => {
        checkNotifyPermisson()
      }, 3000);
    }
  }, []);

  //Trigger Call
  useEffect(() => {
    // console.log(number);
    gdebug(3, 'Callling status number ', number);
    //Trigger Incoming Call
    if (!inOrOut && integrator.includes(gConst.SALESFORCE)) {
      salesforce.opencti.isSoftphonePanelVisible({
        callback: (response: any) => {
          if (response.success) {
            if (!response.returnValue.visible) {
              salesforce.opencti.setSoftphonePanelVisibility({
                visible: true,
              });
            }
          }
        },
      });
    }
  }, [calling, inOrOut]);
  
  
    const getUserDataKeycloak = async (isReload: boolean = false) => {

        
      let currentDateTime = new Date();
      const offset = currentDateTime.getTimezoneOffset();
      currentDateTime = new Date(currentDateTime.getTime() - offset * 60 * 1000);
      let currentDate = currentDateTime.toISOString().split('T')[0];
      gdebug(1, 'currentDate', currentDate);
  
      let loginDate = localStorage.getItem(gConst.STORAGE_LOGIN_DATE);
      gdebug(1, gConst.STORAGE_LOGIN_DATE, loginDate);
  
      if (currentDate !== loginDate && !isMobile(window)) {

        handleLogoutKeycloak();
        localStorage.setItem(gConst.STORAGE_LOGIN_DATE, currentDate);
        // -------------------------------------------------
      } else {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 120000,
        })
    
        Swal.showLoading()
        setTimeout(() => {
          if (props.handleChangeCallbox) {
            gdebug(3,'Handle change callbox ')
            props.handleChangeCallbox({ type: gConst.SOCKET_GET_SESSION });
          }
        }, 3000);
        let userData = await getSession();

        if(userData.isActive === false){
          Swal.close();
          addToast('Your account is inactive, please contact to ADMIN',{
            appearance: 'error',
            autoDismiss: true
          });;
          logoutKeycloak();
          return;
        }
        if (!userData.err) {
          handleUpdateUserInfo({
            idUser: userData.idUser ? userData.idUser : '',
            fullName: userData.fullName,
            extension: userData.sip ? userData.sip.extension : '',
            proxy: userData.sip ? userData.sip.domain : '',
            socket: userData.sip ? userData.sip.websocket : '',
            password: userData.sip ? userData.sip.password : '',
          });
          setLoginStatus({
            isLogin: true,
            username: userData.email,
            fullname: userData.fullName,
          });
          setAgentInfo({
            ...agentInfo,
            id: userData.idUser,
            status: userData.status
          })
          
          // reload when first login
          if (isReload) {
            setTimeout(() => {
              window.location.reload();
              Swal.close()
            }, 5000);
            return;
          }
          Swal.close()

          try {
            const configuration = {
              // proxy: userData.sip ? (userData.sip.proxy.includes(portProxy) ? userData.sip.proxy : userData.sip.proxy + portProxy) : '',
              proxy: userData.sip ? userData.sip.domain : '',
              sip: userData.sip ? userData.sip.extension : '',
              password: userData.sip ? userData.sip.password : '',
              sockets: [
                userData.sip
                  // ? userData.sip.websocket.includes(portSocket)
                  ? userData.sip.websocket.includes(wssSocket)
                    ? userData.sip.websocket
                    : wssSocket + userData.sip.websocket
                  // : (userData.sip.websocket + portSocket).includes(wssSocket)
                  // ? userData.sip.websocket + portSocket
                  // : wssSocket + userData.sip.websocket + portSocket
                  : '',
              ],
            };
  
            // if calllog connected, set extension to true
            calllog.setConfiguration(configuration);
            if (configuration.sip.length > 0 && configuration.password.length > 0 && configuration.proxy.length > 0 && configuration.sockets.length > 0) {
              /* Disable auto register sip */
              if (integrator.includes(gConst.SALESFORCE)) {
                calllog.register();
                setExtension(true);
              }else {
                let listSip: any = localStorage.getItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION);
                listSip = listSip ? JSON.parse(listSip) : [];
  
                if(listSip.length) {
                  for(let i=0; i<listSip.length;i++){
                    try {
                      let objectSip = listSip[i]
                      let key = Object.keys(objectSip)[0]
                      let value = objectSip[key]
                      if(key === TabID && !!value.extension) {
                        calllog.register();
                        setExtension(true);
                        break;
                      }
                    } catch (error) {
                      gdebug(1,error)
                    }
                    
                  }
                }
              }
              setIsDisable(false);
              gdebug(3, 'Calllog isConnected ', calllog.isConnected());
    
            }
          } catch (error) {
            // console.log(error);
            // if calllog not connected, set extension to false
            gdebug(1, 'Configuration Error ', error);
  
            setExtension(false);
            setIsDisable(true);
            addToast('Configuration Error', {
              appearance: 'error',
              autoDismiss: true,
            });
            Swal.close()
          }
  
        } else {
          Swal.close()
          // catch when session expired or session invalid
          setLoginStatus({
            isLogin: false,
            username: '',
            fullname: '',
          });
  
  
          // something wrong with session
  
          setExtension(false);
          setIsDisable(true);
          if(!isMobile(window)){
            addToast('Session expired! Please login again.', {
              appearance: 'error',
              autoDismiss: true,
            });
          }else{
            // auto login for mobile using refreshtoken to get token
            let refreshToken = await refreshTokenKeycloak();
            if(!refreshToken.success){
                let data = {        
                  type : gConst.REACT_NATIVE_LOCAL_PUSH,
                  title : `Gcalls`,
                  message: 'Session expired! Please login again.'
                }
                window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
                return
            }
            window.location.reload();
          }
        }
      }
    };
  // Handle Update User Info
  const handleUpdateUserInfo = (data: any) => {
    const { extension, proxy, socket, password, image, files, fullName, idUser } = data;
    let file;
    if (files) {
      file = files[0];
    } else {
      file = image ? image[0] : false;
    }

    const fileReader = new FileReader();
    if (file) {
      try {
        fileReader.onload = function (event: any) {
          const pngReader = new png(event.target.result);
          pngReader.parse(function (err: any, pngData: any) {
            if (err) throw err;
            const pixelArray = convertPNGtoByteArray(pngData);
            const code = jsQR(pixelArray, pngData.width, pngData.height);
            const sipInfo = code ? JSON.parse(`${code.data}`) : null;
            if (sipInfo !== null) {
              const { extension, socket, password, proxy } = sipInfo;
              const newUserInfo = {
                ...userInfo,
                GcallsProxy: proxy,
                GcallsWebsocket: socket,
                GcallsPassword: password,
                GcallsExtension: extension,
              };
              if (JSON.stringify(newUserInfo) !== JSON.stringify(userInfo)) {
                setUserInfo(newUserInfo);
              } else {
                if (!loginStatus.isLogin) {
                  addToast('It same!', {
                    appearance: 'warning',
                    autoDismiss: true,
                  });
                }
              }
              setIsSettingShow(false);
            } else {
              addToast('Invalid QR code!', {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          });
        };
        fileReader.readAsArrayBuffer(file);
      } catch (error) {
        // console.log(error);
        addToast('Error Update User Info', {
          appearance: 'error',
          autoDismiss: true,
        });
        gdebug(1, 'Error Update User Info ', error);
      }
    } else {
      const newUserInfo = {
        ...userInfo,
        IdUser: idUser,
        GcallsProxy: proxy,
        GcallsWebsocket: socket,
        GcallsPassword: password,
        GcallsExtension: extension,
        GcallsName: fullName,
      };
      if (JSON.stringify(newUserInfo) !== JSON.stringify(userInfo)) {
        setUserInfo(newUserInfo);
      } else {
        if (!loginStatus.isLogin) {
          addToast('It same!', {
            appearance: 'warning',
            autoDismiss: true,
          });
        }
      }

      // setIsSettingShow(false);
    }
  };

  // Sync Custom SIP
  const handleSyncSip = (data: any) => {
    const { extension, proxy, websocket, password, domain } = data;
    setUserInfo({
      ...userInfo,
      GcallsProxy: domain,
      GcallsWebsocket: `wss://${websocket}`,
      GcallsPassword: password,
      GcallsExtension: extension,
    });
    setIsSettingShow(false);
    setTimeout(() => {
      setIsSettingShow(true);
    }, 1);
    addToast('Change Sip info success!', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  // Set Login Status
  const handleSetLoginStatus = (value: any) => {
    setLoginStatus({
      ...loginStatus,
      ...value,
    });
  };

  // Refresh User Info
  const setUserInfoWhenNotLoggedIn = () => {
    const newUserInfo = {
      ...userInfo,
      GcallsExtension: '',
      GcallsPassword: '',
      GcallsProxy: '',
      GcallsWebsocket: '',
    } as UserInfo;
    setUserInfo(newUserInfo);
    setExtension(false);
  };
  // TODO: check if user is logged in

  console.warn = console.error = () => {};

  //HandleGetIframe
  const handleGetIframe = (phone: any) => {
    // Refesh Iframe Data
    setIframeData(null);

    // Get Data Iframe
    getDataIframe(phone, setIframeData, setName, userSfInfo);
  };

  const handleChangeCallBoxMode = async () => {
    let dataType = {
      type: gConst.SOCKET_HIDE_CALLBOX,
    };

    
    if (!miniMode && calling) {
      hideProfile();
      setMiniCallbox(true)
    }
    else if (!miniMode && !calling && !inOrOut) {
      hideProfile();
      setMiniCallbox(true)
    }
    else if (miniMode && !calling) {
      setMiniCallbox(false)
    }
    else if (miniMode && calling) {
      setMiniCallbox(false)
    } 
    else {
      props.handleChangeCallbox(dataType);
    }

  }

  const setMiniCallbox = (pType: any = false) => {

    let callboxStyle = localStorage.getItem(gConst.STORAGE_CALLBOX_STYLE)

    let boxStyle = {
      width: isShow? '830px' : '300px',
      height: '570px',
      // style: 'border:none; background:none;z-index:10000; position:fixed;bottom:0;left:50px;transform:translateY(10px)'
      style: callboxStyle
    }
    if (pType == true) {
      boxStyle.style = 'border:none; z-index:10000; position:fixed;bottom:0;left:50%; overflow:hidden; '
      boxStyle.height = '100px'
      hideProfile()
      setMiniMode(true)
    } else {
      setMiniMode(false)
      handleShowHideProfile();
    }


    let dataType = {
      type: gConst.SOCKET_SET_CALLBOX_STYLE,
      callBoxStyle: boxStyle
    };
    if (props.handleChangeCallbox) {
      props.handleChangeCallbox(dataType);
      
    }
  }

  const changeWidthCallbox = (width: any) => {
    // Change shell width via wss
    let data = {
      type: gConst.SOCKET_CHANGE_WIDTH_CALLBOX,
      width: width,
    };
    if (props.handleChangeCallbox) {
      props.handleChangeCallbox(data);
    }
  };



  const handleExtensionOverMutipleTabs = () => {
    // enable current tab ( extension ) and disable others
    /* Set Current Sip Session Status */
       let currentTab: any = {[TabID]:{
        'extension': true
      }}
      let listSip: any = localStorage.getItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION);

      listSip = listSip ? JSON.parse(listSip) : [];
      currentTab[TabID] = {
        extension: true,
      };
      try {
        let isTabExist = false;
        listSip = listSip.map((e: any) => {
          let key: any = Object.keys(e)[0];
          if (key === TabID) {
            e = currentTab;
            isTabExist = true;
          } else {
            !!currentTab[TabID].extension && (e[key].extension = false);
          }
          return e;
        });
        !isTabExist && listSip.push(currentTab);
        listSip = JSON.stringify(listSip);
        gdebug(3, 'listSip: ', listSip);
        localStorage.setItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION, listSip);

        listSip = JSON.parse(listSip);
        if(listSip.length > 10) {
          listSip = [currentTab]
          localStorage.setItem(gConst.STORAGE_SIP_LISTSIP_EXTENSION, JSON.stringify(listSip));
        }
      } catch (error) {
        gdebug(1, 'error: ', error);
      }

  }




  const handleChangeAgentStatus = (status: string, isUpdateAgentLog: boolean = false) => {
    if (status == gConst.BREAK || status == gConst.DND) {
      if (extension && calllog.isRegistered()) {
        calllog.unregister();
      }
    }

    if (status == gConst.ONLINE || status == gConst.SILENT) {
      if (extension && !calllog.isRegistered()) {
        handleResgisterSip();
      }
    }
    if (isUpdateAgentLog) {
      updateAgentStatus(status)
    }

    setAgentInfo({...agentInfo, status});
  };



  return (
    <div className={callBoxCss(isMobile(window),'container')} style={{backgroundColor: "var(--background)" }}>

  
      <header className='w-full'>
        <div className='justify-center items-center  flex'>
              {!miniMode  && <div onClick={handleChangeCallBoxMode} className='flex' style={{height : "30px"}}>
                {!isMobile(window) && !integrator.includes(gConst.SALESFORCE) ? 
                  <div className='w-10 transform -rotate-90'> <Back width="25" height="25" fill="white" /> </div> : < div className='transform -rotate-90'></div> }
              </div>
              }    
        </div>

      </header> 

      {/* ======================================================================================================= */}
      <div className='w-full h-full flex items-center justify-center'>
        <div id='keypad' className={isShowKeyPadCss(isShow, isMobile(window))}>

        {!miniMode &&
          <div style={{ backgroundColor: "var(--background)" }} className=" w-11/22 h-full  ">
            
            {isSettingShow ? (
              <SettingComponent
                UserInfo={userInfo}
                onSubmit={handleUpdateUserInfo}
                settingClick={handleSettingClick}
                syncSip={handleSyncSip}
                loginStatus={loginStatus}
                setLoginStatus={handleSetLoginStatus}
                signOut={setUserInfoWhenNotLoggedIn}
                keycloak={keycloak}
                handleChangeCallbox={props.handleChangeCallbox}
              />
            ) : null}



          {!isMobile(window)  && <div className="flex justify-end items-center w-11/12 mx-auto rounded-xl  px-4">
            {!inOrOut || calling ? (
              <div className='w-10'>
              <button onClick={toggleShowHideProfile} className="text-2xl active:bg-purple-700 text-white font-semibold">
                {!isShow ? (
                  <div className='w-10'>
                    <Forward width="30" height="30" fill="white" />

                  </div>
               
                ) : (
               <div className='w-10'>
                 <Back width="30" height="30" fill="white" />

               </div>
               
                )}
              </button>

              </div>
            ) : null}
          </div> }
    


          {isOpenSMS ? (
            <div className={isShowKeyPadCss(isShow,isMobile(window))}>
              <SMSCONTAINER  setNumber={setNumber}  name={name} status={status} setIsOpenSMS={setIsOpenSMS} number={number} createSMSNote={createSMSNote}  userInfo={userSfInfo} currentObject={currentObject} objectData={iframeData} />

            </div>
          ) : !calling ? (
            inOrOut ? (
              <div className={isShowKeyPadCss(isShow,isMobile(window))}>

                <Outbound
                  onExtensionChange={hanldeStatusExtensionChange}
                  extension={extension}
                  handleOnSubmit={handleSubmit}
                  userInfo={userInfo}
                  agentInfo={agentInfo}
                  number={number}
                  setNumber={setNumber}
                  isShow={isShow}
                  setIsShow={toggleShowHideProfile}
                  handleSettingClick={handleSettingClick}
                  isSettingShow={isSettingShow}
                  isDisable={isDisable}
                  loginStatus={loginStatus}
                  setIsOpenSMS={setIsOpenSMS}
                  showSMS={showSMS}
                  handleChangeCallbox={props.handleChangeCallbox}
                  handleChangeAgentStatus={handleChangeAgentStatus}
                />

              </div>
         

          
            ) : (
              <div className={isShowKeyPadCss(isShow,isMobile(window))}>

                <InComing name={name} status={status} phoneNumber={number} hangUp={handleHangUp} hangDown={handleHangDown} callButtonInfo={callButtonInfo} language={language}/>
              </div>
            )
          ) : (
            <div className={isShowKeyPadCss(isShow,isMobile(window))}>
              <InPhoning language={language} handleChangeAndroidSpeaker={handleChangeAndroidSpeaker} isAndroidSpeaker={isAndroidSpeaker} onMuteClick={handleMuteClick} isMute={isMute} onEndCallClick={handleEndClick} name={name} phoneNumber={number} status={status} onTransfer={handleTransfer} handleSendDTMF={handleSendDTMF} userInfo={userInfo} callButtonInfo={callButtonInfo} direction={direction} hotlineOutbound={hotlineOutbound} />
            </div>
          )}
          <audio id="ringing" loop></audio>
        </div>
        }


      <div className='flex justify-center items-center'>
          {miniMode && calling && <MinInPhoning handleChangeCallBoxMode={handleChangeCallBoxMode} onMuteClick={handleMuteClick} isMute={isMute} onEndCallClick={handleEndClick} name={name} phoneNumber={number} status={status} onTransfer={handleTransfer} handleSendDTMF={handleSendDTMF} />}
          {miniMode && !calling && <MinIncoming handleChangeCallBoxMode={handleChangeCallBoxMode} name={name} phoneNumber={number} hangUp={handleHangUp} hangDown={handleHangDown} status={status} />}
        </div>
        


      </div>
      <div id='tableInfo' className={isShowInfotableCss(isShow)} >
        <ShowInfo  data={iframeData} number={number} toggleShowHideProfile={toggleShowHideProfile} handleGetIframe={handleGetIframe} />

      </div>
    
    </div>


     
      {/* ======================================================================================================== */}
      {/* <footer className="p-4">
        <button className="w-full p-2 bg-blue-500 text-white rounded-md">Call</button>
      </footer> */}
    </div>
  );
};

export default React.forwardRef(ComponentsIndex);




