import {getConfig} from '../config/configEncrypt';
import host from '../config/host';
import { gConst } from '../lib/gConst';
import { decrypt, encrypt } from "../lib/gUtils";
const { mobileDomain } = host;


const axios = require('axios');
const config = getConfig();

let serverName = localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) ? localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) : process.env["REACT_APP_GCALLS_MOBILE"];

const kcConfig = config[serverName]?.keycloak;


async function loginKeycloak(pUserName:any , pPassword: any) {
  try {
    
      let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM) || "gcalls";
      const data = new URLSearchParams();
      data.append('username', pUserName);
      data.append('password', pPassword);
      data.append('client_id', kcConfig.clientId);
      data.append('grant_type', 'password');
      data.append( 'scope', 'openid');
  
      const response = await axios.post(`${kcConfig["auth-server-url"]}/realms/${callCenterName}/protocol/openid-connect/token`, data);

      if (response.status === 200) {
        const tokenData = response.data;
        window.localStorage.setItem(gConst.STORAGE_GCALLS_TOKEN,  encrypt(tokenData.access_token));
        window.localStorage.setItem(gConst.STORAGE_GCALLS_TOKEN_KC,  encrypt(JSON.stringify(tokenData)));
        return {success : true}
      }
        
      return {success : false}
      


    } catch (error : any) {
      console.log("error from login",error)
      return {success : false}
    }
  }
  
// ----------------------------------------------------------------------------------------------------
function logoutKeycloak() {
 
  try {
    let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM) || "gcalls";
    const logoutEndpoint = `${kcConfig["auth-server-url"]}/realms/${callCenterName}/protocol/openid-connect/logout`;
  
    // Define the data to send in the request body
    const data = new URLSearchParams();
    let kcinfo = JSON.parse(decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN_KC)))
    data.append('client_id', 'extension');
    data.append('refresh_token', kcinfo.refresh_token);
  
  
    // Define the headers for the request
    const headers = {
      'Authorization': `${kcinfo.token_type} ${kcinfo.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  
    // Send the POST request to the logout endpoint
    return axios.post(logoutEndpoint, data, { headers })
      .then((response: any) => {
        // console.log(response)
        localStorage.removeItem(gConst.STORAGE_GCALLS_TOKEN);
        localStorage.removeItem(gConst.STORAGE_GCALLS_TOKEN_KC);
        localStorage.removeItem(gConst.STORAGE_GCALLS_USER);
        localStorage.removeItem(gConst.STORAGE_IS_FIRST_LOGIN);
        return {success : true}
      })
      .catch((error: any) => {
        console.log(error)
        return {success : false}
  
      });
  } catch (error) {
    console.log(error)
    return {success : false}
  }
}

// ---------------------------------------------------------------------------------------------------------------
async function refreshTokenKeycloak() {
  
  try {
    let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM) || "gcalls";
    const tokenEndpoint = `${kcConfig["auth-server-url"]}/realms/${callCenterName}/protocol/openid-connect/token`;
    const kcinfo = JSON.parse(decrypt(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN_KC)));
  
    const data = new URLSearchParams();
    data.append('client_id', 'extension');
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', kcinfo.refresh_token);
    const response = await axios.post(tokenEndpoint, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 200) {
      const tokenData = response.data;
      window.localStorage.setItem(gConst.STORAGE_GCALLS_TOKEN,  encrypt(tokenData.access_token));
      window.localStorage.setItem(gConst.STORAGE_GCALLS_TOKEN_KC,  encrypt(JSON.stringify(tokenData)));
      return {success : true, token: tokenData}
    } 
      
    return {success : false}
    
  } catch (error) {
    console.log(error)
    return {success : false}
  }
}
// -----------------------------------------------------------------------------------------------------------------
const forgetPasswordKeycloak = async (pEmail : any) => {
  let callCenterName: any = window.localStorage.getItem(gConst.STORAGE_GCALLS_REAM) || "gcalls";
  let data = {
    email : pEmail,
    realm : callCenterName
  }

  let result = await axios.post(
    `${mobileDomain}/fogetPasswordKeycloak`,
    data,
  )
  return result
}
  
// -----------------------------------------------------------------------------------------------------------------

const getRealm = async (pCallcenter : any) => {


  const url = `${kcConfig["auth-server-url"]}/realms/${pCallcenter}`;

  try {
    const result = await axios.get(url);
    if (result.status === 200 && result.data.realm === pCallcenter) {
      return {success : true, data : result.data}
    }
  } catch (error) {
    return {success : false }
  }
  return {success : false }
}
// -----------------------------------------------------------------------------------------------------------------



export {loginKeycloak, logoutKeycloak, refreshTokenKeycloak, forgetPasswordKeycloak, getRealm}

