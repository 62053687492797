import axios from 'axios';
import { gdebug } from '../../../lib/gdebug';
import { apiIntegration, apiIntegration2, createContact, createSMSLogs, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from '../../../services/gcalls.service';
import { calcTime, formatDate, handleDuration, handleStartTimeZoho, handleTimeCreate, timeout } from '../../../services/sharedFunction';
import { gConst } from '../../../lib/gConst';
const salesforce = window.sforce;

let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData);
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';
let parrentUrl = localStorage.getItem(gConst.STORAGE_PARRENT_URL) || '';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

let integrator = gUserData?.integrator[parrentHostname];

let agentName = gUserData?.fullName || '';
let dataFunction = gUserData?.integrator[parrentHostname]?.info?.dataFunction;
let logFunction = gUserData?.integrator[parrentHostname]?.info?.logFunction;
let preLogFunction = gUserData?.integrator[parrentHostname]?.info?.preLogFunction;
let mode = gUserData?.integrator[parrentHostname]?.info?.mode;
mode = '1';

export async function getDataIframe(currentObject, setIframeData, setName, userInfo) {
  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }
  let iframeDatas = {};
  if (typeof currentObject === 'object') {
    iframeDatas.phone = currentObject?.number;
  } else {
    iframeDatas.phone = currentObject;
  }
  iframeDatas.isEmpty = true;
  // dataFunction = [
  //   {
  //     objectName: gConst.ACCOUNT,
  //     endpointName: 'getObjectData',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Id: 'Id',
  //       },
  //       {
  //         Name: 'Name',
  //       },
  //       {
  //         Phone: 'Phone',
  //       },
  //       {
  //         OwnerId: 'OwnerId',
  //       },
  //       {
  //         Industry: 'Industry',
  //       },
  //     ],
  //     data: "Params=select Id, Name, Phone, OwnerId, Industry from Account where Phone like'%:phone%' order by CreatedDate desc",
  //     replaceData: [
  //       {
  //         ':phone': 'iframeDatas?.phone',
  //       },
  //     ],
  //   },
  //   {
  //     objectName: gConst.LEAD,
  //     endpointName: 'getObjectData',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //       {
  //         Status: 'Status',
  //       },
  //       {
  //         Phone: 'Phone',
  //       },
  //       {
  //         OwnerId: 'OwnerId',
  //       },
  //       {
  //         Id: 'Id',
  //       },
  //     ],
  //     data: "Params=select Id, Name, Phone, OwnerId, Status from Lead where Phone like'%:phone%' order by CreatedDate desc",
  //     replaceData: [
  //       {
  //         ':phone': 'iframeDatas?.phone',
  //       },
  //     ],
  //   },
  //   {
  //     objectName: gConst.STUDENT,
  //     endpointName: 'getObjectData',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //       {
  //         Phone: 'Phone',
  //       },
  //       {
  //         Student_Code__c: 'Student_Code__c',
  //       },
  //       {
  //         Status__c: 'Status__c',
  //       },
  //       {
  //         LastActivityDate: 'LastActivityDate',
  //       },
  //       {
  //         OwnerId: 'OwnerId',
  //       },
  //       {
  //         Id: 'Id',
  //       },
  //     ],
  //     data: "Params=select Id, Name, Phone, Student_Code__c, Status__c, LastActivityDate, OwnerId from Contact where Phone like'%:phone%' order by CreatedDate desc",
  //     replaceData: [
  //       {
  //         ':phone': 'iframeDatas?.phone',
  //       },
  //     ],
  //     delay: 2000,
  //   },
  //   {
  //     objectName: gConst.OPPORTUNITY,
  //     endpointName: 'getObjectData',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //       {
  //         Contact__c: 'Contact__c',
  //       },
  //       {
  //         StageName: 'StageName',
  //       },
  //       {
  //         OwnerId: 'OwnerId',
  //       },
  //       {
  //         Id: 'Id',
  //       },
  //     ],
  //     data: 'Params=select Id, Name, Contact__c, StageName, OwnerId from Opportunity where :where1 order by CreatedDate desc',
  //     replaceData: [
  //       {
  //         ':where1': "checkValue('','ie',\"currentObject?.objectType == gConst.CONTACT\",\"Contact__c = ':phone'\",\"Phone__c like'%:phone%'\")",
  //       },
  //       {
  //         ':phone': "checkValue('eval','ie',\"currentObject?.objectType == gConst.CONTACT\",'currentObject?.idObject','iframeDatas?.phone')",
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'AccountUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: '',
  //     responseData: [
  //       {
  //         Name: '[0].Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Account[0].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'LeadUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: '',
  //     responseData: [
  //       {
  //         Name: '[0].Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Lead[0].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'StudentUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: '',
  //     responseData: [
  //       {
  //         Name: '[0].Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Student[0].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'OpportunityUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Opportunity[0].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'OpportunityUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Opportunity[1].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  //   {
  //     objectName: 'OpportunityUser',
  //     endpointName: 'searchUser',
  //     arrayResponse: 'none',
  //     responseData: [
  //       {
  //         Name: 'Name',
  //       },
  //     ],
  //     data: 'userId=:OwnerId',
  //     replaceData: [
  //       {
  //         ':OwnerId': 'iframeDatas?.Opportunity[2].OwnerId',
  //       },
  //     ],
  //     delay: 1000,
  //   },
  // ];
  try {
    if (dataFunction && dataFunction.length) {
      let dFLength = dataFunction.length;
      let i = 0;
      (async () => {
        while (i < dFLength) {
          let e = dataFunction[i];
          let dataSearch = e?.data || '';
          if (!!dataSearch) {
            dataSearch = JSON.stringify(dataSearch);

            let replaceData = e.replaceData;

            if (!!replaceData && !!replaceData.length) {
              for (let i = 0; i < replaceData.length; i++) {
                try {
                  let key = Object.keys(replaceData[i])[0];
                  let value = replaceData[i][key];
                  let replaceVal = '';
                  try {
                    replaceVal = eval(value);
                  } catch (error) {
                    gdebug(1,"Error: ", error);
                  }
                  dataSearch = dataSearch.replaceAll(key, replaceVal);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              }
            }
            dataSearch = JSON.parse(dataSearch);
          }

          let objectName = e.objectName;
          let templateName = e.templateName;
          let endpointName = e.endpointName;
          let arrayResponse = e.arrayResponse;
          let responseData = e.responseData;
          let delay = e.delay || 0;

          const param = {
            apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
            methodName: endpointName,
            methodParams: dataSearch,
          };
          param.callback = async function (response) {
            if (response.success && response.returnValue) {
              if (!!arrayResponse) {
                try {
                  let data = JSON.parse(response.returnValue.runApex);
                  if (arrayResponse === 'none') {
                    arrayResponse = 'data';
                  } else {
                    arrayResponse = 'data?.' + arrayResponse;
                  }
                  eval(arrayResponse).forEach((item, i) => {
                    if (responseData) {
                      let ObjectItem = {};
                      if (Array.isArray(responseData) && responseData.length) {
                        //   let ObjectItem1 = {};
                        for (const item3 of responseData) {
                          let key = Object.keys(item3)[0];
                          let value = item3[key];
                          value = 'item?.' + value;

                          ObjectItem[key] = eval(value);
                        }
                        //   ObjectItem.push(ObjectItem1);
                      }
                      if (i == 0 && !iframeDatas[objectName]) {
                        iframeDatas[objectName] = [];
                        iframeDatas[objectName].push(ObjectItem);
                      } else {
                        iframeDatas[objectName].push(ObjectItem);
                      }
                    } else {
                      if (i == 0 && !iframeDatas[objectName]) {
                        iframeDatas[objectName] = [];
                        iframeDatas[objectName].push(item);
                      } else {
                        iframeDatas[objectName].push(item);
                      }
                    }
                    if (iframeDatas[objectName]?.length) {
                      iframeDatas.isEmpty = false;
                    }

                    //Recheck Iframe By Role
                    try {
                      if (userInfo?.RoleId === '00E5g000000I2KxEAK') {
                        if (iframeDatas?.Account?.length > 0) {
                          for (let i = 0; i < iframeDatas?.Account?.length; i++) {
                            if (iframeDatas?.Account[i]?.OwnerId === userInfo?.OwnerId) {
                              iframeDatas.Account = [iframeDatas?.Account[i]];
                              break;
                            }
                          }
                        }
                        if (iframeDatas?.Lead?.length > 0) {
                          for (let i = 0; i < iframeDatas?.Lead?.length; i++) {
                            if (iframeDatas?.Lead[i]?.OwnerId === userInfo?.OwnerId) {
                              iframeDatas.Lead = [iframeDatas?.Lead[i]];
                              break;
                            }
                          }
                        }
                        if (iframeDatas?.Student?.length > 0) {
                          for (let i = 0; i < iframeDatas?.Student?.length; i++) {
                            if (iframeDatas?.Student[i]?.OwnerId === userInfo?.OwnerId || (currentObject && iframeDatas?.Student[i]?.Id === currentObject?.idObject)) {
                              iframeDatas.Student = [iframeDatas?.Student[i]];
                              break;
                            }
                          }
                        }
                        if (iframeDatas?.Opportunity?.length > 0) {
                          let b = [];
                          for (let i = 0; i < iframeDatas?.Opportunity?.length; i++) {
                            if (iframeDatas?.Opportunity[i]?.OwnerId === userInfo?.OwnerId) {
                              let c = iframeDatas?.Opportunity[i];
                              b.push(c);
                              iframeDatas.Opportunity = b;
                            }
                          }
                        }
                      } else if (userInfo?.RoleId === '00E5g000000I2L2EAK' || userInfo?.RoleId === '00E5g000000I2KdEAK') {
                        iframeDatas.Account = iframeDatas?.Account?.length ? [iframeDatas?.Account[0]] : [];
                        iframeDatas.Student = iframeDatas?.Student?.length ? [iframeDatas?.Student[0]] : [];
                      } else if (
                        userInfo?.RoleId === '00E5g000000I2KOEA0' ||
                        userInfo?.RoleId === '00E5g000000I2KYEA0'
                      ) {
                        iframeDatas.Account = iframeDatas?.Account?.length ? [iframeDatas?.Account[0]] : [];
                        iframeDatas.Lead = iframeDatas?.Lead?.length ? [iframeDatas?.Lead[0]] : [];
                        iframeDatas.Opportunity = iframeDatas?.Opportunity?.length ? iframeDatas?.Opportunity : [];
                        iframeDatas.Student = iframeDatas?.Student?.length ? [iframeDatas?.Student[0]] : [];
                      }
                    } catch (error) {
                      gdebug(1,"Error: ", error)
                    }

                    setIframeData(null);
                    setIframeData(iframeDatas);
                  });
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              } else {
                try {
                  let item = JSON.parse(response.returnValue.runApex);
                  if (responseData) {
                    let ObjectItem = {};
                    if (Array.isArray(responseData) && responseData.length) {
                      // let ObjectItem1 = {};
                      for (const item3 of responseData) {
                        let key = Object.keys(item3)[0];
                        let value = item3[key];
                        value = 'item?.' + value;

                        ObjectItem[key] = eval(value);
                      }
                      // ObjectItem.push(ObjectItem1);
                    }
                    if (!iframeDatas[objectName]) {
                      iframeDatas[objectName] = [];
                    }
                    iframeDatas[objectName].push(ObjectItem);
                  } else {
                    if (!iframeDatas[objectName]) {
                      iframeDatas[objectName] = [];
                    }
                    iframeDatas[objectName].push(item);
                  }
                  if (iframeDatas[objectName]?.length) {
                    iframeDatas.isEmpty = false;
                  }
                  setIframeData(null);
                  setIframeData(iframeDatas);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
              }
            }
          };

          await salesforce.opencti.runApex(param);
          i++;
          // Set Name
          try {
            if (typeof currentObject !== 'object') {
              if (userInfo?.RoleId && userInfo?.RoleId === '00E5g000000I2KxEAK') {
                if (iframeDatas?.Account?.length > 0) {
                  for (let i = 0; i < iframeDatas?.Account?.length; i++) {
                    if (iframeDatas?.Account[i].OwnerId === userInfo?.OwnerId) {
                      setName(iframeDatas?.Account[i]?.Name);
                      break;
                    }
                  }
                }
                if (iframeDatas?.Lead?.length > 0) {
                  for (let i = 0; i < iframeDatas?.Lead?.length; i++) {
                    if (iframeDatas?.Lead[i]?.OwnerId === userInfo?.OwnerId) {
                      setName(iframeDatas?.Lead[i]?.Name);
                      break;
                    }
                  }
                }
                if (iframeDatas?.Opportunity?.length > 0) {
                  for (let i = 0; i < iframeDatas?.Opportunity?.length; i++) {
                    if (iframeDatas?.Opportunity[i]?.OwnerId === userInfo?.OwnerId) {
                      setName(iframeDatas?.Opportunity[i]?.Name);
                      break;
                    }
                  }
                }
                if (iframeDatas?.Student?.length > 0) {
                  for (let i = 0; i < iframeDatas?.Student?.length; i++) {
                    if (iframeDatas?.Student[i]?.OwnerId === userInfo?.OwnerId) {
                      setName(iframeDatas?.Student[0]?.Name);
                      break;
                    }
                  }
                }
              } else if ((userInfo.RoleId && userInfo.RoleId === '00E5g000000I2L2EAK') || userInfo.RoleId === '00E5g000000I2KdEAK') {
                if (iframeDatas?.Account?.length > 0) {
                  setName(iframeDatas?.Account[0]?.Name);
                }
                if (iframeDatas?.Student.length > 0) {
                  setName(iframeDatas?.Student[0]?.Name);
                }
              } else if (userInfo.RoleId && (userInfo.RoleId === '00E5g000000I2KOEA0' || userInfo.RoleId === '00E5g000000I2KYEA0')) {
                if (iframeDatas?.Account?.length > 0) {
                  setName(iframeDatas?.Account[0]?.Name);
                }
                if (iframeDatas?.Lead?.length > 0) {
                  setName(iframeDatas?.Lead[0]?.Name);
                }
                if (iframeDatas?.Opportunity?.length > 0) {
                  setName(iframeDatas?.Opportunity[0]?.Name);
                }
                if (iframeDatas?.Student?.length > 0) {
                  setName(iframeDatas?.Student[0]?.Name);
                }
              }
            }
          } catch (error) {
            gdebug(1,"Error: ", error);
          }
          await timeout(delay);
        }
      })();
    }
  } catch (error) {
    gdebug(1,"Error: ", error);
  }

  // let gcallsContact = await searchContact(currentObject?.number);
  // if (!!gcallsContact.length) {
  //   let name = gcallsContact[0].firstName + ' ' + gcallsContact[0].lastName;
  //   setName(name);
  //   await getCalllogs(gcallsContact[0]._id, async function (res, err) {
  //     if (!err) {
  //       // console.log(res)
  //       iframeDatas.latestCall = res[0];
  //     }
  //   });
  // }
  gdebug(3,"iframeDatas: ", iframeDatas)

  setIframeData(null);
  setIframeData(iframeDatas);
}

export async function saveLogCRM(calllog, iframeData) {
  gdebug(3,"Calllog: ", calllog, iframeData)
  const handleTimeCreate2 = handleTimeCreate;
  const handleDuration2 = handleDuration;
  const formatDate2 = formatDate;

  const calllog_info = { ...calllog };
  const { status, duration, start, direction, recordings_url, playing_recordings_url, phone, end } = calllog_info;

  let record_url = playing_recordings_url || recordings_url;
  let callDuration = handleDuration(duration);
  let startTimeZoho = handleStartTimeZoho(start);
  let se_module = '$se_module';
  let callStatus = status === 'connected' || status === 'success' ? 'Answered' : 'Unanswered';
  let callStatusText = callStatus === 'Answered' ? 'successful' : 'missed';
  let empty = '';
  let start1 = new Date(start);
  let end1 = new Date(end);

  const directionCustom = direction === gConst.OUTGOING ? 'Outbound' : 'Inbound';
  const subjectTitle = direction === gConst.OUTGOING ? 'Call to ' + phone : 'Call from ' + phone;
  const subjectTitle2 = `[Gcalls] ${agentName} - ${directionCustom} - ${status} - Time: ${handleTimeCreate(start, false)}`;
  const phoneTitle = direction === gConst.OUTGOING ? 'made a phone call to ' + phone : 'received a call from ' + phone;
  const TextContent = `[${agentName}] ${phoneTitle} - Time: ${handleTimeCreate(start, false)} - Direction: ${directionCustom} - Status: ${status} - Duration: ${handleDuration(duration)} - Record: ${record_url}`;
  const HtmlContent = `<b>[${agentName}] ${phoneTitle}</b><br>Time: ${handleTimeCreate(start, false)} <br>Direction: ${directionCustom} <br>Status : ${status} <br>Duration : ${handleDuration(duration)} <br>Record : <a href=${record_url}>${record_url}</a>`;

  function checkValue(type, cases, val1, rval1, rval2) {
    let value = '';
    switch (cases) {
      case 'ie':
        try {
          value = eval(val1) ? rval1 : rval2;
        } catch (error) {
          value = rval2;
        }
        break;

      default:
        break;
    }
    switch (type) {
      case 'eval':
        return eval(value);
        break;

      default:
        return value;
        break;
    }
  }

  // logFunction = [
  //   {
  //     data: {
  //       CallDurationInSeconds: ':callDuration',
  //       CallDisposition: ':callStatus',
  //       Gcalls_Call_Type__c: ':directionCustom',
  //       Gcalls_Customer_Phone__c: ':phone',
  //       Gcalls_URL_Recorder__c: ':recordings_url',
  //       Gcalls_Call_Flowid__c: ':flowid',
  //       Gcalls_ActivityId__c: ':call_id',
  //       Gcalls_Call_Duration__c: ':callDuration',
  //       Gcalls_Call_Status__c: ':callStatus',
  //       Subject: '[Gcalls] :OwnerName (:GcallsExtension) has a :statusText :directionCustom call at :Time',
  //       Type: 'Call',
  //       Status: 'Completed',
  //       OwnerId: ':OwnerId',
  //       ActivityDate: ':ActivityDate',
  //       WhoId: '',
  //       WhatId: '',
  //     },
  //     replaceData: [
  //       {
  //         ':callDuration': 'callDuration',
  //       },
  //       {
  //         ':callStatus': 'callStatus',
  //       },
  //       {
  //         ':directionCustom': 'directionCustom',
  //       },
  //       {
  //         ':phone': 'phone',
  //       },
  //       {
  //         ':recordings_url': 'record_url',
  //       },
  //       {
  //         ':flowid': "checkValue('eval','ie','calllog_info?.flowid','calllog_info?.flowid','empty')",
  //       },
  //       {
  //         ':call_id': "checkValue('eval','ie','calllog_info?.call_id','calllog_info?.call_id','empty')",
  //       },
  //       {
  //         ':OwnerName': "checkValue('eval','ie','iframeData?.userInfo?.OwnerName','iframeData?.userInfo?.OwnerName','empty')",
  //       },
  //       {
  //         ':OwnerId': "checkValue('eval','ie','iframeData?.userInfo?.OwnerId','iframeData?.userInfo?.OwnerId','empty')",
  //       },
  //       {
  //         ':GcallsExtension': "checkValue('eval','ie','iframeData?.userInfo?.GcallsExtension','iframeData?.userInfo?.GcallsExtension','empty')",
  //       },
  //       {
  //         ':statusText': 'callStatusText',
  //       },
  //       {
  //         ':Time': 'handleTimeCreate2(calllog_info?.start, true)',
  //       },
  //       {
  //         ':ActivityDate': 'formatDate2(new Date(start))',
  //       },
  //     ],
  //   },
  // ];

  if (logFunction && logFunction.length) {
    try {
      logFunction.forEach(async (e, i) => {
        setTimeout(async () => {
          let data = JSON.stringify(e.data);
          let replaceData = e.replaceData;

          if (!!replaceData && !!replaceData.length) {
            for (let i = 0; i < replaceData.length; i++) {
              try {
                let key = Object.keys(replaceData[i])[0];
                let value = replaceData[i][key];
                let replaceVal = '';
                try {
                  replaceVal = eval(value);
                } catch (error) {
                  gdebug(1,"Error: ", error);
                }
                data = data.replaceAll(key, replaceVal);
              } catch (error) {
                gdebug(1,"Error: ", error);
              }
            }
          }
          data = JSON.parse(data);
          data = { ...data, Gcalls_End_Time__c: new Date(end), Gcalls_Start_Time__c: new Date(start) };

          let templateName = e.templateName;
          let endpointName = e.endpointName;

          gdebug(3,"Create task: ", data)

          const saveLogCallback = (response) => {
            if (response.success) {
              const { returnValue } = response;
              const { recordId } = returnValue;
              gdebug(3,"Save log data: ", response)
              //   let isOpenTask = window.localStorage.getItem(gConst.STORAGE_OPEN_TASK);;
              //   console.log('isOpenTask', isOpenTask);
              //   if (isOpenTask === 'true') {
              window.sforce.opencti.screenPop({
                type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT,
                params: { recordId },
                callback: (res) => {
                  gdebug(3,"screenPop ", res)
                }
              });
              //   }
            } else {
              gdebug(1,"Save log error: ", response)
            }
          };

          let value = {
            entityApiName: gConst.TASK,
            ...data,
          };

          value = await setId(iframeData.currentObject, value, iframeData);
          gdebug(1,"vl: ", value)
          window.sforce.opencti.saveLog({ value, callback: saveLogCallback });
        });
      });
    } catch (error) {
      gdebug(1,"Error: ", error);
    }
  }
}

export async function createSMSNote(data) {
  //   let { number, valueNote, OwnerName, OwnerId, origin_phone, status_sms, currentObject, objectData } = data;
  //   let phonenumber = number;
  //   let idContact = '';
  //   let creator = localStorage.getItem(gConst.STORAGE_GCALLS_ID_AGENT);
  //   let idCallcenter = localStorage.getItem(gConst.STORAGE_GCALLS_ID_CALLCENTER);
  //   let contact = await searchContact(phonenumber);

  //   if (contact.length) {
  //     idContact = contact[contact.length - 1]._id;
  //   } else {
  //     let contactData = {
  //       firstName: phonenumber,
  //       lastName: '',
  //       phone: phonenumber,
  //       email: '',
  //       gender: 'unknown',
  //       avatar: '',
  //     };
  //     let newcontact = await createContact(contactData);

  //     if (newcontact) {
  //       idContact = newcontact._id;
  //     }
  //   }

  //   const TextArray = valueNote.split('\n');
  //   let title = TextArray[0].replace('Trạng thái:', '').trim();
  //   let content = TextArray[1].replace('Nội dung SMS:', '').trim();
  //   let time = calcTime(Date.now(), '+7');
  //   let minuate = time.getMinutes();
  //   if (minuate < 10) {
  //     minuate = '0' + minuate;
  //   }

  //   let SubjectError = `[Gcalls] ${OwnerName} có lỗi ${title} - vào lúc ${time.getHours() + ':' + minuate}`;
  //   let SubjectSuccess = `[Gcalls] ${OwnerName} Gửi tin nhắn thành công - vào lúc ${time.getHours() + ':' + minuate}`;
  //   let Subject = status_sms === true ? SubjectSuccess : SubjectError;
  //   let logTask = {
  //     Subject,
  //     OwnerId,
  //     Status: 'Completed',
  //     ActivityDate: formatDate(new Date()),
  //     Type: gConst.SMS,
  //     Gcalls_Customer_Phone__c: origin_phone,
  //     Gcalls_SMS_Content__c: content,
  //   };
  //   logTask = await setId(currentObject, logTask, objectData);
  //   console.log(logTask);
  //   let logData = {
  //     text: valueNote,
  //     type: gConst.NOTE,
  //     body: null,
  //     creator,
  //     isSMS: true,
  //     idContact,
  //     phone: origin_phone,
  //   };

  //   let saveSMSCallback = (response) => {
  //     if (response.success) {
  //       let { returnValue } = response;
  //       let { recordId } = returnValue;
  //       console.log('Save log data : ', logData, response);
  //       logTask.idTask = recordId;
  //       //   let isOpenTask = window.localStorage.getItem(gConst.STORAGE_OPEN_TASK);;
  //       //   console.log('isOpenTask', isOpenTask);
  //       //   if (isOpenTask === 'true') {
  //       window.sforce.opencti.screenPop({
  //         type: window.sforce.opencti.SCREENPOP_TYPE.SOBJECT,
  //         params: { recordId },
  //         callback: (res) => console.log(res),
  //       });
  //       //   }
  //     } else {
  //       console.log('Save log error : ', response);
  //     }
  //   };
  //   let value = {
  //     entityApiName: gConst.TASK,
  //     ...logTask,
  //   };
  //   console.log('vl : ', value);
  //   window.sforce.opencti.saveLog({ value, callback: saveSMSCallback });
  //   createSMSLogs(logData);
}

export async function savePreLogCRM(calllog, iframeData, setIframeData) {}

export async function setId(currentObject, logData, objectData) {
  let { userInfo } = objectData;
  let ListObject = [gConst.CONTACT, gConst.LEAD, gConst.ACCOUNT, gConst.OPPORTUNITY, gConst.REGISTRATION_FORM_C];
  if (typeof currentObject === 'object' && ListObject.includes(currentObject?.typeObject)) {
    if (currentObject?.typeObject === gConst.CONTACT){
      logData.RecordTypeId = "0125i000000z2hoAAA"
    }else if (currentObject?.typeObject === gConst.LEAD){
      logData.RecordTypeId = "0125i000000z2hqAAA"
    }else if (currentObject?.typeObject === gConst.CASE){
      logData.RecordTypeId = "0125i000000z2hnAAA"
    }else if (currentObject?.typeObject === gConst.OPPORTUNITY){
      logData.RecordTypeId = "0125i000000z2hrAAA"
    }else if (currentObject?.typeObject === gConst.ORDER){
      logData.RecordTypeId = "0121y000002dIN9AAM"
    }else if (currentObject?.typeObject === gConst.REGISTRATION_FORM_C){
      logData.RecordTypeId = "0125i000000z2hpAAA"
    }
    if (currentObject?.typeObject === gConst.CONTACT || currentObject?.typeObject === gConst.LEAD) {
      logData.WhoId = currentObject?.idObject;
      if (currentObject?.typeObject === gConst.CONTACT && objectData?.Opportunity?.length > 0) {
        if (userInfo?.RoleId === '00E5g000000I2KxEAK') {
          for (let i = 0; i < objectData?.Opportunity?.length; i++) {
            if (objectData?.Opportunity[i]?.OwnerId === userInfo?.OwnerId) {
              logData.WhatId = objectData?.Opportunity[i]?.Id;
              break;
            }
          }
        } else if (userInfo?.RoleId === '00E5g000000I2KOEA0' || userInfo?.RoleId === '00E5g000000I2KYEA0') {
          logData.WhatId = objectData?.Opportunity[0]?.Id;
        }
      } else if (currentObject?.typeObject === gConst.CONTACT && objectData?.Account?.length > 0) {
        if (userInfo?.RoleId === '00E5g000000I2KxEAK') {
          for (let i = 0; i < objectData?.Account?.length; i++) {
            if (objectData?.Account[i]?.OwnerId === userInfo?.OwnerId) {
              logData.WhatId = objectData?.Account[i]?.Id;
              break;
            }
          }
        } else if (userInfo?.RoleId === '00E5g000000I2KOEA0' || userInfo?.RoleId === '00E5g000000I2KYEA0') {
          logData.WhatId = objectData?.Account[0]?.Id;
        }
      }
    } else {
      logData.WhatId = currentObject?.idObject;
      if (objectData?.Student?.length > 0) {
        if (userInfo?.RoleId === '00E5g000000I2KxEAK') {
          for (let i = 0; i < objectData?.Student?.length; i++) {
            if (objectData?.Student[i]?.OwnerId === userInfo?.OwnerId) {
              logData.WhoId = objectData?.Student[i]?.Id;
              break;
            }
          }
        } else {
          logData.WhoId = objectData?.Student[0]?.Id;
        }
      }
    }
  } else {
    // if (userInfo?.Contact?.length > 0){
    //   logData.RecordTypeId = "0125i000000z2hoAAA"
    // }else if (userInfo?.Lead?.length > 0){
    //   logData.RecordTypeId = "0125i000000z2hqAAA"
    // }else if (userInfo?.Case?.length > 0){
    //   logData.RecordTypeId = "0125i000000z2hnAAA"
    // }else if (userInfo?.Opportunity?.length > 0){
    //   logData.RecordTypeId = "0125i000000z2hrAAA"
    // }else if (userInfo?.Order?.length > 0){
    //   logData.RecordTypeId = "0121y000002dIN9AAM"
    // }else if (userInfo?.Enrollment?.length > 0){
    //   logData.RecordTypeId = "0125i000000z2hpAAA"
    // }

    if (objectData?.Student?.length > 0){ //Student = Contact
      logData.RecordTypeId = "0125i000000z2hoAAA"
    } else if (objectData?.Lead?.length > 0){
      logData.RecordTypeId = "0125i000000z2hqAAA"
    } else if (objectData?.Case?.length > 0){
      logData.RecordTypeId = "0125i000000z2hnAAA"
    } else if (objectData?.Opportunity?.length > 0){
      logData.RecordTypeId = "0125i000000z2hrAAA"
    } else if (objectData?.Order?.length > 0){
      logData.RecordTypeId = "0121y000002dIN9AAM"
    } else if (objectData?.Enrollment?.length > 0){
      logData.RecordTypeId = "0125i000000z2hpAAA"
    }



    if (userInfo?.RoleId && userInfo?.RoleId === '00E5g000000I2KxEAK') {
      if (objectData?.Account?.length > 0) {
        for (let i = 0; i < objectData?.Account?.length; i++) {
          if (objectData?.Account[i]?.OwnerId === userInfo?.OwnerId) {
            logData.WhatId = objectData?.Account[i]?.Id;
            break;
          }
        }
      }
      if (objectData?.Opportunity?.length > 0) {
        for (let i = 0; i < objectData?.Opportunity?.length; i++) {
          if (objectData?.Opportunity[i]?.OwnerId === userInfo?.OwnerId) {
            logData.WhatId = objectData?.Opportunity[i]?.Id;
            break;
          }
        }
      }
      if (objectData?.Lead?.length > 0) {
        for (let i = 0; i < objectData?.Lead?.length; i++) {
          if (objectData?.Lead[i]?.OwnerId === userInfo?.OwnerId) {
            logData.WhoId = objectData?.Lead[i]?.Id;
            logData.WhatId = '';
            break;
          }
        }
      }
      if (objectData?.Student?.length > 0) {
        for (let i = 0; i < objectData?.Student?.length; i++) {
          if (objectData?.Student[i]?.OwnerId === userInfo?.OwnerId) {
            logData.WhoId = objectData?.Student[i]?.Id;
            break;
          }
        }
      }
    } else if (userInfo?.RoleId && (userInfo?.RoleId === '00E5g000000I2L2EAK' || userInfo?.RoleId === '00E5g000000I2KdEAK')) {
      if (objectData?.Account?.length > 0) {
        logData.WhatId = objectData?.Account[0]?.Id;
      }
      if (objectData?.Student?.length > 0) {
        logData.WhoId = objectData?.Student[0]?.Id;
      }
    } else if (userInfo?.RoleId && (userInfo?.RoleId === '00E5g000000I2KOEA0' || userInfo?.RoleId === '00E5g000000I2KYEA0')) {
      if (objectData?.Account?.length > 0) {
        logData.WhatId = objectData?.Account[0]?.Id;
      }
      if (objectData?.Opportunity?.length > 0) {
        logData.WhatId = objectData?.Opportunity[0]?.Id;
      }
      if (objectData?.Lead?.length > 0) {
        logData.WhoId = objectData?.Lead[0]?.Id;
        logData.WhatId = '';
      }
      if (objectData?.Student?.length > 0) {
        logData.WhoId = objectData?.Student[0]?.Id;
        if (objectData?.Account?.length > 0) {
          logData.WhatId = objectData?.Account[0]?.Id;
        }
        if (objectData?.Opportunity?.length > 0) {
          logData.WhatId = objectData?.Opportunity[0]?.Id;
        }
      }
    }
  }
  return logData;
}
