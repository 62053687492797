import React, { useEffect } from 'react';
import {ReactComponent as CallRing} from '../../icons/call-ringing.svg';
import {ReactComponent as CallEnd} from '../../icons/call-end.svg';
import {ReactComponent as CallIn} from "../../icons/callin.svg";
// @ts-ignore
import {useSwipeable} from 'react-swipeable';
import { translate } from '../../languages';
import { gConst } from '../../lib/gConst';
type Props = {
    name: string;
    phoneNumber: string,
    hangUp: any,
    hangDown: any,
    status: any,
    language: any,
    callButtonInfo: any
}
function InComing({name, phoneNumber, hangUp, hangDown, status,language, callButtonInfo}:Props) {
    return (
        <div id="inComing" className='h-5/6 w-full flex '>
            <div  className="grid grid-cols-1 justify-center items-center w-full">
             <div id="info" className=" items-center pt-12">
                <span className="block w-10/12 flex justify-center mx-auto">
                    <img src={`https://dummyimage.com/50x50/b39cdb/fffffd.jpg&text=${!name?"No name".charAt(0):name.charAt(0)}`} alt="" className="p-2 rounded-2xl" />
                </span>
                <span className="text-2xl w-10/12 font-bold mx-auto text-center text-gray-300 block my-4">{!name? (callButtonInfo.buttonName && callButtonInfo.isButtonClick2Call ? callButtonInfo.buttonName : "No name") :name}</span>
                <span className="text-2xl w-10/12 font-bold mx-auto text-center text-gray-300 block my-4">{phoneNumber !=='' ?phoneNumber:"0342200770"}</span>
            </div>
            <span className="text-xl w-10/12 font-bold mx-auto text-center text-gray-300 block my-12">{translate(language, status)}</span>
            {localStorage.getItem(gConst.STORAGE_HOTLINE) ?
                <span id="hotline" className="text-xl w-10/12 font-bold mx-auto text-center text-gray-300 block my-0">
                    <CallIn fill="white" width="1.4rem" height="1.4rem"/>
                    {localStorage.getItem(gConst.STORAGE_HOTLINE) ? " " + localStorage.getItem(gConst.STORAGE_HOTLINE) : ' 0XXXXXXXX'}
                </span> : null
            }            
            {localStorage.getItem(gConst.STORAGE_PRESS) ?
                <span id="press" className="text-xl w-10/12 font-bold mx-auto text-center text-gray-300 block mt-4">
                    <span>{localStorage.getItem(gConst.STORAGE_PRESS)}</span>
                </span>: null
            }
            <div className="w-full bottom-10 flex justify-around items-center">
            <button id="cancel" className=" bg-green-600 my-2  square rounded-full flex justify-center items-center active:bg-green-800 focus:bg-green-800"  onClick={hangUp}>
            <CallRing fill="white" width="4rem" height="4rem" className="ringing"  />
            </button>
            <button id="accepted" className=" bg-red-600 my-2  square rounded-full flex justify-center items-center active:bg-red-800 focus:bg-red-800" onClick={hangDown} >
            <CallEnd fill="white" width="4rem" height="4rem" className="ringing"/>
            </button>
            </div>
        </div>
        {/* <audio id="ringing"></audio> */}
        </div>
    );
}

export default InComing;