import axios from 'axios';
const currentDomain = window.location.hostname;
const defaultTemplate = 'default';

let cdnDomain = process.env.REACT_APP_CDN_DOMAIN; // Use the correct environment variable

const getBrandTemplate = async () => {
  try {
    let result = await getJson("config.json");
    if (result.success && result.data[currentDomain]) {

      return result.data[currentDomain];
    }

  } catch (error) {
    return defaultTemplate;
  }
  return defaultTemplate;
};

const setBgColor = async (pColor) => {
  document.documentElement.style.setProperty('--background', pColor);
};

const setBgImage = async (pImage) => {
  document.documentElement.style.setProperty('--background-img', `url(${pImage})`);
};

const brandText = async () => {
  let defaultBrand = '';
  try {
    let template = await getBrandTemplate();
    let result = await loadAndExecuteScript(`${cdnDomain}/${template}/text.js`);

    if (result?.BRAND) {
      return result.BRAND;
    }
    return defaultBrand;
  } catch (error) {
    return defaultBrand;
  }
};


const getMobileDomain = async () => {

  let defaultDomain = 'https://mobile.gcalls.co';
  try {
    let template = await getBrandTemplate();
    let result = await loadAndExecuteScript(`${cdnDomain}/${template}/text.js`);
    if (result?.REACT_APP_MOBILE_DOMAIN) {
      return result.REACT_APP_MOBILE_DOMAIN;
    }
    return defaultDomain;
  } catch (error) {
    return defaultDomain;
  }
};


const brandSupportLink = async () => {
  let defaultBrand = '';
  try {
    let template = await getBrandTemplate();
    let result = await loadAndExecuteScript(`${cdnDomain}/${template}/text.js`);
    if (result?.SUPPORTLINK) {
      return result.SUPPORTLINK;
    }
    return defaultBrand;
  } catch (error) {
    return defaultBrand;
  }
};



const brandPhone = async () => {
  let defaultPhone = '';
  // 08985 870 99'
  try {
    let template = await getBrandTemplate();
   
    let result = await loadAndExecuteScript(`${cdnDomain}/${template}/text.js`);

    if (result?.PHONE) {
      return result.PHONE;
    }
    return defaultPhone;
  } catch (error) {
    return defaultPhone;
  }
};

const brandLogo = async () => {
  let defaultLogo = '';
  try {
    let template = await getBrandTemplate();
    let dynamicLogo = await loadImage(`${cdnDomain}/${template}/images/logo.png`);
    return dynamicLogo;
  } catch (error) {
    console.log(error);
  }
  return defaultLogo;
};




const brandFavicon = async () => {
  let defaultLogo = '';
  try {
    let template = await getBrandTemplate();
    let dynamicLogo = await loadImage(`${cdnDomain}/${template}/images/favicon.png`);
    return dynamicLogo;
  } catch (error) {
    console.log(error);
  }
  return defaultLogo;
};

const fetchScriptContent = async (scriptUrl) => {
  try {
    const response = await axios.get(scriptUrl);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch script: ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching script: ${error.message}`);
  }
};

const loadAndExecuteScript = async (scriptUrl) => {
  try {
    const jsCode = await fetchScriptContent(scriptUrl);
    const moduleFunction = new Function('module', jsCode);
    const moduleObject = {};
    moduleFunction(moduleObject);
    return moduleObject.exports || {};
  } catch (error) {
    throw new Error(`Error loading and executing script: ${error.message}`);
  }
};

const loadImage = async (imageUrl) => {
  try {
    const response = await axios.get(imageUrl, { responseType: 'blob' });
    if (response.status !== 200) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const blobUrl = URL.createObjectURL(response.data);
    return blobUrl;
  } catch (error) {
    throw new Error(`Error fetching image: ${error.message}`);
  }
};

const getJson = async (pJsonPath) => {
  try {
    let cdnUrl = `${cdnDomain}/${pJsonPath}`;
    let result = await axios.get(cdnUrl).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error('Error fetching JSON data:', error);
      return { success: false };
    });

    return {success: true, data : result};
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};


const CDN = {
  getBrandTemplate,
  load: async () => {
    try {
      let template = await getBrandTemplate();
      let resultBgColor = await loadAndExecuteScript(`${cdnDomain}/${template}/color.js`);
      if (resultBgColor.bgColor) {
        setBgColor(resultBgColor.bgColor);
      }

      let resultBgImage = await loadImage(`${cdnDomain}/${template}/images/background.png`);
      setBgImage(resultBgImage);
    } catch (error) {
      // Handle errors as needed
    }
  },
  brandLogo,
  brandFavicon,
  brandText,
  brandPhone,
  brandSupportLink,
  getMobileDomain,
};

export { CDN };
