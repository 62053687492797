import {getConfig} from '../config/configEncrypt';
import { gConst } from './gConst';

require('dotenv').config()

export interface ProcessEnv {
  [key: string]: string | undefined
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_LOG_MISSED_USER_ID_GLOBISH?: string;
      REACT_APP_RECORDINGS_DOMAIN?: string;
      REACT_APP_COUCHDB_USERNAME?: string;
      REACT_APP_COUCHDB_PASSWORD?: string;
      REACT_APP_COUCHDB_HOST?: string;
    }
  }
}

let mobileUrl: any = process.env["REACT_APP_GCALLS_MOBILE"];

//const config: any = require('../config.json');
const config = getConfig();

let serverName: any = localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) ? localStorage.getItem(gConst.STORAGE_GCALLS_SERVER) : mobileUrl;
const couchdbConfig: any = config[serverName]?.couchdb;

const options: any = {
  eventHandlers: {
    progress: function () {
      // console.log("call is in progress from user");
    },
    failed: function (e: any) {
      // console.log("call failed with cause from user: " + e.cause);
    },
    ended: (e: any) => {
      // console.log("call ended with cause from user: " + e.cause);
    },
    confirmed: function () {
      // console.log("call confirmed from user");
    },
    incoming: () => { },
    outgoing: () => { },
    onTrack: (e: any) => {

      var audio = document.createElement("audio");
      audio.srcObject = e.streams[0];
      audio.play();
    },
  },
  mediaConstraints: { audio: true, video: false },
  databaseConfigurations: {
    db: gConst.COUCHDB_NAME,
    username: couchdbConfig?.couchdbUser,
    password: couchdbConfig?.couchdbPassword,
    host: couchdbConfig?.couchdbHost,
  }

}
export { options };

