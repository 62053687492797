import { apiIntegration, apiIntegration2, createContact, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from "../../services/gcalls.service";
import { gConst } from '../../lib/gConst';
let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData)
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatTime(createdOn) {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let time = "";
    var firstDate = new Date();
    var secondDate = new Date(createdOn);
    var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)))
    if (diffDays <= 30) {
        time = diffDays + " ngày"
    } else
        if (diffDays > 30 && diffDays <= 30 * 12) {
            time = (diffDays / 30).toFixed(1) + " tháng"
        } else {
            time = ((diffDays / 30) / 12).toFixed(1) + " năm"
        }
    return time;
}

export async function getDataIframe(display_name, setIframeData, setName, direction) {
    // Hubspot
    let iframeDatas = {}
    iframeDatas.phone = display_name;
    let dataSearch = {
        "query": display_name
    }

    await apiIntegration(dataSearch, gConst.SAPO, gConst.SAPO_GET_CUSTOMERS, async function (res, err) {
        // res = {
        //     "success": true,
        //     "data": {
        //         "metadata": {
        //             "total": 1,
        //             "page": 1,
        //             "limit": 20
        //         },
        //         "customers": [
        //             {
        //                 "id": 140676856,
        //                 "tenant_id": 16969,
        //                 "default_location_id": null,
        //                 "created_on": "2022-06-21T03:39:20Z",
        //                 "modified_on": "2022-06-21T03:39:20Z",
        //                 "code": "CUZN69915",
        //                 "name": "Hoàng Kỳ Anh",
        //                 "dob": null,
        //                 "sex": null,
        //                 "description": null,
        //                 "email": null,
        //                 "fax": null,
        //                 "phone_number": "0917016743",
        //                 "tax_number": null,
        //                 "website": null,
        //                 "customer_group_id": 64310,
        //                 "group_id": 64310,
        //                 "group_ids": [
        //                     64310
        //                 ],
        //                 "group_name": "Bán lẻ",
        //                 "assignee_id": 815337,
        //                 "default_payment_term_id": null,
        //                 "default_payment_method_id": null,
        //                 "default_tax_type_id": null,
        //                 "default_discount_rate": null,
        //                 "default_price_list_id": null,
        //                 "tags": [],
        //                 "addresses": [
        //                     {
        //                         "id": 118367524,
        //                         "created_on": "2022-06-21T03:39:20Z",
        //                         "modified_on": "2022-06-21T03:39:20Z",
        //                         "country": "Việt Nam",
        //                         "city": "Đồng Nai",
        //                         "district": "Huyện Nhơn Trạch",
        //                         "ward": "Thị trấn Hiệp Phước",
        //                         "address1": "Hẻm Gạo Gấm, Ấp 4",
        //                         "address2": null,
        //                         "zip_code": null,
        //                         "email": null,
        //                         "first_name": null,
        //                         "last_name": null,
        //                         "full_name": "Thanh Tú",
        //                         "label": null,
        //                         "phone_number": "0917016743",
        //                         "status": "active"
        //                     }
        //                 ],
        //                 "contacts": [],
        //                 "notes": [{content:"evnwevoj Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước"},
        //                 {content:"hven vjbwqekj bvwe bvqwke bvwje bvdbn bwkedbvn bakdbv"}],
        //                 "customer_group": {
        //                     "id": 64310,
        //                     "tenant_id": 16969,
        //                     "created_on": "2017-04-03T08:18:37Z",
        //                     "modified_on": "2022-05-14T04:15:32Z",
        //                     "name": "RETAIL",
        //                     "name_translate": "Bán lẻ",
        //                     "status": "active",
        //                     "is_default": true,
        //                     "default_payment_term_id": null,
        //                     "default_payment_method_id": null,
        //                     "default_tax_type_id": null,
        //                     "default_discount_rate": null,
        //                     "default_price_list_id": null,
        //                     "note": null,
        //                     "code": "BANLE",
        //                     "count_customer": null,
        //                     "type": "customer",
        //                     "group_type": null,
        //                     "condition_type": null,
        //                     "conditions": null
        //                 },
        //                 "status": "active",
        //                 "is_default": false,
        //                 "debt": -680000,
        //                 "apply_incentives": "group",
        //                 "total_expense": null,
        //                 "loyalty_customer": null,
        //                 "sale_order": null,
        //                 "social_customers": []
        //             },
        //             {
        //                 "id": 140488397,
        //                 "tenant_id": 16969,
        //                 "default_location_id": null,
        //                 "created_on": "2022-06-21T03:39:20Z",
        //                 "modified_on": "2022-06-21T03:39:20Z",
        //                 "code": "CUZN69915",
        //                 "name": "Thanh Tú",
        //                 "dob": null,
        //                 "sex": null,
        //                 "description": null,
        //                 "email": null,
        //                 "fax": null,
        //                 "phone_number": "0917016743",
        //                 "tax_number": null,
        //                 "website": null,
        //                 "customer_group_id": 64310,
        //                 "group_id": 64310,
        //                 "group_ids": [
        //                     64310
        //                 ],
        //                 "group_name": "Bán lẻ",
        //                 "assignee_id": 815337,
        //                 "default_payment_term_id": null,
        //                 "default_payment_method_id": null,
        //                 "default_tax_type_id": null,
        //                 "default_discount_rate": null,
        //                 "default_price_list_id": null,
        //                 "tags": [],
        //                 "addresses": [
        //                     {
        //                         "id": 118367524,
        //                         "created_on": "2022-06-21T03:39:20Z",
        //                         "modified_on": "2022-06-21T03:39:20Z",
        //                         "country": "Việt Nam",
        //                         "city": "Đồng Nai",
        //                         "district": "Huyện Nhơn Trạch",
        //                         "ward": "Thị trấn Hiệp Phước",
        //                         "address1": "Hẻm Gạo Gấm, Ấp 4",
        //                         "address2": null,
        //                         "zip_code": null,
        //                         "email": null,
        //                         "first_name": null,
        //                         "last_name": null,
        //                         "full_name": "Thanh Tú",
        //                         "label": null,
        //                         "phone_number": "0917016743",
        //                         "status": "active"
        //                     }
        //                 ],
        //                 "contacts": [],
        //                 "notes": [{content:"evnwevoj Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước Thị trấn Hiệp Phước"}],
        //                 "customer_group": {
        //                     "id": 64310,
        //                     "tenant_id": 16969,
        //                     "created_on": "2017-04-03T08:18:37Z",
        //                     "modified_on": "2022-05-14T04:15:32Z",
        //                     "name": "RETAIL",
        //                     "name_translate": "Bán lẻ",
        //                     "status": "active",
        //                     "is_default": true,
        //                     "default_payment_term_id": null,
        //                     "default_payment_method_id": null,
        //                     "default_tax_type_id": null,
        //                     "default_discount_rate": null,
        //                     "default_price_list_id": null,
        //                     "note": null,
        //                     "code": "BANLE",
        //                     "count_customer": null,
        //                     "type": "customer",
        //                     "group_type": null,
        //                     "condition_type": null,
        //                     "conditions": null
        //                 },
        //                 "status": "active",
        //                 "is_default": false,
        //                 "debt": -680000,
        //                 "apply_incentives": "group",
        //                 "total_expense": null,
        //                 "loyalty_customer": null,
        //                 "sale_order": null,
        //                 "social_customers": []
        //             }
        //         ]
        //     }
        // }
        if (!err) {
            let list = res?.data?.customers;
            console.log(list)
            if (!!list && !!list.length) {
                list.map(async (item, index) => {
                    if(index > 0) return;
                    let dataSearch2 = {
                        "customer_id": item.id,
                        "limit": 250,
                        "status": "completed"
                    }
                    iframeDatas.customers = [[{}]]
                    iframeDatas.customers[0][0].name = item.name;
                    iframeDatas.customers[0][0].group_name = item.group_name;
                    iframeDatas.customers[0][0].created_on = formatTime(item.created_on);
                    iframeDatas.customers[0][0].lasted_note = item.notes.length > 0 ? item.notes[item.notes.length - 1].content : "---";
                    iframeDatas.orders = [];
                    if (index < 3) {
                        let orders = await apiIntegration2(dataSearch2, gConst.SAPO, gConst.SAPO_GET_ORDERS);
                        let orderData = orders?.data?.orders;
                        iframeDatas.customers[0][0].total_orders = orders?.data?.metadata?.total || '---';
                        if(orderData){
                            // orderData.push({
                            //     "id": 351079237,
                            //     "tenant_id": 16969,
                            //     "location_id": 17798,
                            //     "code": "SON102294",
                            //     "created_on": "2022-06-21T14:31:20Z",
                            //     "modified_on": "2022-06-21T14:31:20Z",
                            //     "issued_on": "2022-06-21T14:31:20Z",
                            //     "ship_on": null,
                            //     "ship_on_min": null,
                            //     "ship_on_max": null,
                            //     "account_id": 19697,
                            //     "assignee_id": 19697,
                            //     "customer_id": 140614524,
                            //     "customer_data": {
                            //         "id": 140614524,
                            //         "tenant_id": 16969,
                            //         "default_location_id": null,
                            //         "created_on": "2022-06-21T14:31:01Z",
                            //         "modified_on": "2022-06-21T14:31:01Z",
                            //         "code": "CUZN69958",
                            //         "name": "Tuấn",
                            //         "dob": null,
                            //         "sex": "other",
                            //         "description": null,
                            //         "email": null,
                            //         "fax": null,
                            //         "phone_number": "0982925844",
                            //         "tax_number": null,
                            //         "website": null,
                            //         "customer_group_id": 64310,
                            //         "group_name": "Bán lẻ",
                            //         "assignee_id": 19697,
                            //         "default_payment_term_id": null,
                            //         "default_payment_method_id": null,
                            //         "default_tax_type_id": null,
                            //         "default_discount_rate": null,
                            //         "default_price_list_id": null,
                            //         "tags": [],
                            //         "addresses": [],
                            //         "contacts": [],
                            //         "notes": [],
                            //         "customer_group": {
                            //             "id": 64310,
                            //             "tenant_id": 16969,
                            //             "created_on": "2017-04-03T08:18:37Z",
                            //             "modified_on": "2022-05-14T04:15:32Z",
                            //             "name": "RETAIL",
                            //             "name_translate": "Bán lẻ",
                            //             "status": "active",
                            //             "is_default": true,
                            //             "default_payment_term_id": null,
                            //             "default_payment_method_id": null,
                            //             "default_tax_type_id": null,
                            //             "default_discount_rate": null,
                            //             "default_price_list_id": null,
                            //             "note": null,
                            //             "code": "BANLE"
                            //         },
                            //         "status": "active",
                            //         "is_default": false,
                            //         "debt": 0,
                            //         "apply_incentives": "group",
                            //         "total_expense": null,
                            //         "loyalty_customer": null,
                            //         "sale_order": null,
                            //         "social_customers": []
                            //     },
                            //     "contact_id": null,
                            //     "billing_address": null,
                            //     "shipping_address": null,
                            //     "email": null,
                            //     "phone_number": "0982925844",
                            //     "reference_number": null,
                            //     "price_list_id": 47433,
                            //     "tax_treatment": "inclusive",
                            //     "status": "completed",
                            //     "print_status": false,
                            //     "packed_status": "packed",
                            //     "fulfillment_status": "shipped",
                            //     "received_status": "received",
                            //     "payment_status": "paid",
                            //     "return_status": "unreturned",
                            //     "source_id": 65804,
                            //     "total": 500000,
                            //     "order_discount_rate": 0,
                            //     "order_discount_value": 0,
                            //     "order_discount_amount": 0,
                            //     "discount_reason": null,
                            //     "total_discount": 0,
                            //     "total_tax": 0,
                            //     "note": "",
                            //     "tags": [],
                            //     "delivery_fee": null,
                            //     "discount_items": [],
                            //     "order_line_items": [
                            //         {
                            //             "id": 824658589,
                            //             "created_on": "2022-06-21T14:31:20Z",
                            //             "modified_on": "2022-06-21T14:31:20Z",
                            //             "variant_id": 137306465,
                            //             "product_id": 91512794,
                            //             "product_name": "Giày Bóng Đá Nike Mercurial Superfly 8 Academy EURO Xanh Ngọc Cổ Cao V2 TF",
                            //             "variant_name": "Giày Bóng Đá Nike Mercurial Superfly 8 Academy EURO Xanh Ngọc Cổ Cao V2 TF - 39",
                            //             "tax_type_id": null,
                            //             "tax_included": false,
                            //             "tax_rate_override": 0,
                            //             "tax_rate": 0,
                            //             "tax_amount": 0,
                            //             "discount_rate": 0,
                            //             "discount_value": 0,
                            //             "discount_reason": null,
                            //             "discount_amount": 0,
                            //             "note": "",
                            //             "price": 500000,
                            //             "quantity": 1,
                            //             "is_freeform": false,
                            //             "is_composite": false,
                            //             "line_amount": 500000,
                            //             "discount_items": [],
                            //             "composite_item_domains": [],
                            //             "sku": "22061804.39",
                            //             "barcode": "22061804.39",
                            //             "unit": null,
                            //             "variant_options": "39",
                            //             "serials": null,
                            //             "lots_dates": [],
                            //             "product_type": "normal",
                            //             "lots_number_code1": null,
                            //             "lots_number_code2": null,
                            //             "lots_number_code3": null,
                            //             "lots_number_code4": null,
                            //             "warranty": null,
                            //             "distributed_discount_amount": 0
                            //         }
                            //     ],
                            //     "prepayments": [
                            //         {
                            //             "id": 308193656,
                            //             "payment_method_id": 62070,
                            //             "paid_on": "2022-06-21T14:31:20Z",
                            //             "amount": 500000,
                            //             "account_id": 19697,
                            //             "note": null,
                            //             "created_on": "2022-06-21T14:31:20Z",
                            //             "modified_on": "2022-06-21T14:31:20Z",
                            //             "reference": null,
                            //             "source": "customer_paid",
                            //             "paid_amount": 500000,
                            //             "returned_amount": 0,
                            //             "status": "active",
                            //             "integrated": false,
                            //             "status_before_cancelled": null,
                            //             "bin": null,
                            //             "card_type": null,
                            //             "order_id": 0,
                            //             "tenant_id": 0,
                            //             "type": "normal",
                            //             "from_voucher_id": null
                            //         }
                            //     ],
                            //     "fulfillments": [
                            //         {
                            //             "id": 1079928006,
                            //             "tenant_id": 16969,
                            //             "stock_location_id": 17798,
                            //             "code": "FUN108736",
                            //             "order_id": 351079237,
                            //             "account_id": 19697,
                            //             "assignee_id": 19697,
                            //             "partner_id": 140614524,
                            //             "billing_address": null,
                            //             "shipping_address": null,
                            //             "delivery_type": "pickup",
                            //             "tax_treatment": "inclusive",
                            //             "discount_rate": 0,
                            //             "discount_value": 0,
                            //             "discount_amount": 0,
                            //             "total": 500000,
                            //             "total_tax": 0,
                            //             "total_discount": 0,
                            //             "notes": null,
                            //             "packed_on": "2022-06-21T14:31:20Z",
                            //             "received_on": "2022-06-21T14:31:20Z",
                            //             "shipped_on": "2022-06-21T14:31:20Z",
                            //             "cancel_date": null,
                            //             "cancel_account_id": null,
                            //             "created_on": "2022-06-21T14:31:20Z",
                            //             "modified_on": "2022-06-21T14:31:20Z",
                            //             "status": "received",
                            //             "print_status": false,
                            //             "composite_fulfillment_status": "received",
                            //             "payment_status": "paid",
                            //             "stock_out_account_id": 19697,
                            //             "receive_account_id": 19697,
                            //             "receive_cancellation_account_id": null,
                            //             "receive_cancellation_on": null,
                            //             "fulfillment_line_items": [
                            //                 {
                            //                     "id": 1079928007,
                            //                     "created_on": "2022-06-21T14:31:20Z",
                            //                     "modified_on": "2022-06-21T14:31:20Z",
                            //                     "order_line_item_id": 824658589,
                            //                     "product_id": 91512794,
                            //                     "product_name": "Giày Bóng Đá Nike Mercurial Superfly 8 Academy EURO Xanh Ngọc Cổ Cao V2 TF",
                            //                     "variant_id": 137306465,
                            //                     "variant_name": "Giày Bóng Đá Nike Mercurial Superfly 8 Academy EURO Xanh Ngọc Cổ Cao V2 TF - 39",
                            //                     "order_line_item_note": "",
                            //                     "is_freeform": false,
                            //                     "is_composite": false,
                            //                     "is_packsize": false,
                            //                     "base_price": 500000,
                            //                     "quantity": 1,
                            //                     "tax_type_id": null,
                            //                     "tax_rate_override": 0,
                            //                     "tax_rate": 0,
                            //                     "line_amount": 500000,
                            //                     "line_tax_amount": 0,
                            //                     "line_discount_amount": 0,
                            //                     "discount_value": 0,
                            //                     "discount_rate": 0,
                            //                     "variant": null,
                            //                     "sku": "22061804.39",
                            //                     "barcode": "22061804.39",
                            //                     "unit": null,
                            //                     "variant_options": "39",
                            //                     "serials": null,
                            //                     "lots_dates": null,
                            //                     "product_type": "normal",
                            //                     "distributed_discount_value": 0,
                            //                     "lots_number_code1": null,
                            //                     "lots_number_code2": null,
                            //                     "lots_number_code3": null,
                            //                     "lots_number_code4": null,
                            //                     "sub_variants": []
                            //                 }
                            //             ],
                            //             "shipment": null,
                            //             "payments": [
                            //                 {
                            //                     "id": 377940881,
                            //                     "created_on": "2022-06-21T14:31:20Z",
                            //                     "modified_on": "2022-06-21T14:31:20Z",
                            //                     "order_id": 351079237,
                            //                     "fulfillment_id": 1079928006,
                            //                     "payment_method_id": 62070,
                            //                     "amount": 500000,
                            //                     "reference": null,
                            //                     "paid_on": "2022-06-21T14:31:20Z",
                            //                     "status": "active",
                            //                     "paid_amount": 0,
                            //                     "returned_amount": 0,
                            //                     "prepayment_id": 308193656,
                            //                     "type": "normal",
                            //                     "from_voucher_id": null
                            //                 }
                            //             ],
                            //             "total_quantity": 1,
                            //             "reason_cancel_id": null,
                            //             "pushing_status": "not_push",
                            //             "bill_of_lading_on": "2022-06-21T14:31:20Z",
                            //             "packed_processing_account_id": null,
                            //             "bill_of_lading_account_id": null,
                            //             "late_pickup_date": null,
                            //             "late_delivery_date": null
                            //         }
                            //     ],
                            //     "order_returns": [],
                            //     "business_version": 3,
                            //     "expected_payment_method_id": null,
                            //     "expected_delivery_type": null,
                            //     "expected_delivery_provider_id": null,
                            //     "process_status_id": null,
                            //     "reason_cancel_id": null,
                            //     "finalized_on": "2022-06-21T14:31:20Z",
                            //     "finished_on": null,
                            //     "completed_on": "2022-06-21T14:31:20Z",
                            //     "channel": "POS",
                            //     "cancelled_on": null,
                            //     "promotion_redemptions": [],
                            //     "create_invoice": false,
                            //     "reference_url": null,
                            //     "from_order_return_id": null,
                            //     "order_return_exchange": null,
                            //     "total_order_exchange_amount": null,
                            //     "allow_no_refund_order_exchange_amount": false,
                            //     "order_coupon_code": null,
                            //     "interconnection_status": null,
                            //     "einvoice_status": "uncreated"
                            // })
                            let account_name = "";
                            let sum = 0;
                            let id_account = ""
                            let id_order = ""
                            let latest_orders = ''
                            if(orderData.length >0){
                                id_account = orderData[0].account_id
                                id_order = orderData[0].id;
                                if (id_account) {
                                    let dataSearch3 = {
                                        id: id_account
                                    }
                                    let accountsResponse = await apiIntegration2(dataSearch3, gConst.SAPO, gConst.SAPO_GET_ACCOUNT_ID);
                                    account_name = accountsResponse?.data?.account?.full_name;
                                    iframeDatas.customers[0][0].account_name = account_name;
                                    orderData.map(i => {
                                        sum += i.total
                                    })
                                    orderData.slice(0,3).forEach(item=>{
                                        let orderItem = [{}];
                                        orderItem[0].id = item.id;
                                        orderItem[0].status = item.status;
                                        orderItem[0].total = item.total;
                                        orderItem[0].created_on = item.created_on;
                                        iframeDatas.orders.push(orderItem);
                                        console.log(iframeDatas)
                                    })
                                    iframeDatas.customers[0][0].total_value = sum;
                                }
                                setIframeData(null)
                                setIframeData(iframeDatas)
                            }
                        }
                    }
                })

                setIframeData(null)
                setIframeData(iframeDatas)
                console.log(iframeDatas)
            }
        }
    })


    let gcallsContact = await searchContact(display_name);
    if (!!gcallsContact.length) {
        let name = gcallsContact[0].firstName + " " + gcallsContact[0].lastName;
        setName(name);
        // searchGroup(gcallsContact[0]._id, async function (res, err) {
        //     if (!err) {
        //         console.log(res)
        //     }
        // })
        // await getCalllogs(gcallsContact[0]._id, async function (res, err) {
        //     if (!err) {
        //         // console.log(res)
        //         iframeDatas.latestCall = res[0]
        //     }
        // })
    }
    //Get Calllog By Phone
    await getCalllogs(display_name, async function (res, err) {
        if (!err) {
        iframeDatas.latestCall = res[0];
        }
    })

    setIframeData(null)
    setIframeData(iframeDatas)
}

export async function saveLogCRM(calllog,iframeData) {
    const disposition = {
        connected: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
        success: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
        busy: '9d9162e7-6cf3-4944-bf63-4dff82258764',
        'no-answer': '73a0d17f-1163-4015-bdd5-ec830791da20',
        rejected: '73a0d17f-1163-4015-bdd5-ec830791da20',
        missed: '73a0d17f-1163-4015-bdd5-ec830791da20',
        canceled: '73a0d17f-1163-4015-bdd5-ec830791da20'
    };

    let dataSearch = {
        "q": calllog.phone
    }

    let contact_vids = [];

    // await apiIntegration(dataSearch, gConst.HUBSPOT, 'search_contact', async function (res, err) {
    //     if (!err) {
    //         if (!!res.data && !!res.data.contacts) {
    //             for (const contact of res.data.contacts) {
    //                 contact_vids.push(contact.vid)
    //             }
    //             // console.log(contact_vids)

    //             if (!!contact_vids.length && !!gUserData) {
    //                 contact_vids.forEach(async (vid, i) => {
    //                     const info = `<p>Direction: ${calllog.direction} - `
    //                         + `Caller: ${calllog.direction === gConst.INCOMING ? calllog.destination : gUserData.email} - `
    //                         + `Callee: ${calllog.direction === gConst.INCOMING ? gUserData.email : calllog.destination} - `
    //                         + `Status: ${calllog.status} - `
    //                         + `Duration: ${calllog.duration} - `
    //                         + `Record URL: ${calllog.recordings_url}</p>`;
    //                     const data = {
    //                         engagement: { type: "CALL", timestamp: calllog.start },
    //                         associations: { contactIds: [vid] },
    //                         metadata: {
    //                             toNumber: calllog.destination,
    //                             fromNumber: calllog.destination,
    //                             status: 'COMPLETED',
    //                             durationMilliseconds: calllog.duration * 1000,
    //                             body: info,
    //                             disposition: disposition[calllog.status]
    //                         }
    //                     };
    //                     await apiIntegration2(data, gConst.HUBSPOT, 'post_engagement');

    //                     if (!!localStorage.getItem("auto_create_ticket_hubspot")) {
    //                         let subjectTicket = calllog.direction === gConst.INCOMING ? `Call from ${res.data.contacts[0].properties.firstname ? res.data.contacts[0].properties.firstname.value : calllog.phone}` : `Call to ${res.data.contacts[0].properties.firstname ? res.data.contacts[0].properties.firstname.value : calllog.phone}`;

    //                         let newTicket = {
    //                             properties: {
    //                                 content: '',
    //                                 hs_pipeline_stage: "1",
    //                                 hs_pipeline: "0",
    //                                 //hubspot_owner_id: 100399741,
    //                                 subject: subjectTicket
    //                             }
    //                         }

    //                         let searchOwnerData = {
    //                             email: gUserData.email
    //                         }

    //                         let ownerData = await apiIntegration2(searchOwnerData, gConst.HUBSPOT, 'search_owner');

    //                         if (!!ownerData && !!ownerData.data && !!ownerData.data.length) {
    //                             newTicket.properties.hubspot_owner_id = ownerData.data[0].ownerId;
    //                         }

    //                         apiIntegration(newTicket, gConst.HUBSPOT, 'post_ticket', async function (res, err) {
    //                             if (!err) {
    //                                 const fromObjectId = res.data.id;
    //                                 let dataUpdate = {
    //                                     fromObjectId,
    //                                     toObjectId: vid,
    //                                     category: 'HUBSPOT_DEFINED',
    //                                     definitionId: '16'
    //                                 }
    //                                 apiIntegration2(dataUpdate, gConst.HUBSPOT, 'post_association');
    //                             } else {
    //                                 console.log("Create ticket fail!")
    //                             }
    //                         })
    //                     }
    //                 })
    //             }
    //         }
    //     }
    // })

}

export async function createSMSNote(data) {
    // let contact_vids = [];
    // let dataSearch = {
    //     "q": data.phone
    // }
    // await apiIntegration(dataSearch, gConst.HUBSPOT, 'search_contact', async function (res, err) {
    //     if (!err) {
    //         if (!!res.data && !!res.data.contacts) {
    //             for (const contact of res.data.contacts) {
    //                 contact_vids.push(contact.vid)
    //             }

    //             if (!!contact_vids.length && !!gUserData) {
    //                 contact_vids.forEach(async (vid, i) => {
    //                     let noteData = {
    //                         "engagement": {
    //                             "active": true,
    //                             "type": "NOTE"
    //                         },
    //                         "associations": {
    //                             "contactIds": [vid]
    //                         },
    //                         "metadata": {
    //                             "body": `<div><p>You sent an SMS to ${res.data.contacts[0].properties.firstname ? res.data.contacts[0].properties.firstname.value : data.phone}</p><p><b>Status:</b> ${data.status_sms}</p><p><b>Content:</b> ${data.sms}</p></div>`
    //                         }
    //                     }
    //                     apiIntegration2(noteData, gConst.HUBSPOT, 'post_engagement');
    //                 })
    //             }
    //         }
    //     }
    // })
    
    
}