import React, { useState, useEffect } from 'react';
import LoginPage from './components/loginPage';
import InfoPage from './components/infoPage'
import {getSession} from '../../services/gcalls.service'
import {useToasts} from "react-toast-notifications"
import { LoginStatus } from '../../types';
import { isMobile } from '../../lib/gUtils';
import { gConst } from '../../lib/gConst';

type Props = {
    syncSip: any,
    closeLogin: any,
    updateUserInfo: any
    loginStatus: LoginStatus,
    setLoginStatus: any,
    signOut: any,
    keycloak: any,
    handleChangeCallbox: any;
}

declare const window: Window &
  typeof globalThis & {
    sforce: any;
    ReactNativeWebView : any
  }


function IntegrationGcalls ({syncSip, closeLogin, updateUserInfo, loginStatus, setLoginStatus, signOut, keycloak, handleChangeCallbox }:Props){
    const {addToast} = useToasts();

    // const [isLogin, setIsLogin] = useState<boolean>(false)
    const [user, setUser] = useState<any>({
        idAgent: "",
        idCallcenter: "",
        sip: {},
        email: "",
        fullName: ""        
    })

    // useEffect( () => {
    //     if(localStorage.getItem(gConst.STORAGE_GCALLS_TOKEN)) {
    //         getUserData()
    //     }            
    // }, [])
    
    const getUserData = async ()=>{
        let userData = await getSession();
        if(!userData.err){
            setUser({
                idAgent: userData._id,
                idCallcenter: userData.idCallcenter,
                sip: userData.sip,
                email: userData.email,
                fullName: userData.fullName
            })
            updateUserInfo({
                fullName: userData.fullName,
                extension: userData.sip ? userData.sip.extension : "", 
                proxy: userData.sip ? userData.sip.proxy : "", 
                socket: userData.sip ? userData.sip.websocket : "", 
                password: userData.sip ? userData.sip.password : "",
            })           
            setLoginStatus({
                isLogin: true,
                username: userData.email,
                fullname: userData.fullName
            })
        }else{
            // catch when session expired or session invalid
            setLoginStatus({
                isLogin: false,
                username: "",
                fullname: ""
            })
            if(!isMobile(window)){
                addToast('Session expired! Please login again.', {
                  appearance: 'error',
                  autoDismiss: true,
                });
              }else{
                let data = {        
                  type : gConst.REACT_NATIVE_LOCAL_PUSH,
                  title : `Gcalls`,
                  message: 'Session expired! Please login again.'
                }
                window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
              }
            
        }
    }    

    return (
        <div>
            {loginStatus.isLogin ? 
                <InfoPage
                    isLogin={loginStatus.isLogin}
                    setIsLogin={setLoginStatus}
                    userData={loginStatus}
                    setUserData={setUser}
                    syncSip={syncSip}
                    updateUserInfo={updateUserInfo}
                    closeLogin={closeLogin}
                    signOut={signOut}
                    keycloak={keycloak}
                ></InfoPage>:
                <LoginPage
                    isLogin={loginStatus.isLogin}
                    setIsLogin={setLoginStatus}
                    setUserData={getUserData}
                    closeLogin={closeLogin}
                    handleChangeCallbox={handleChangeCallbox}
                ></LoginPage>
            }             
        </div>
    )
}

export default IntegrationGcalls
