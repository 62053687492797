
import { getCallboxConfigEncrypt } from "@gcalls/gcallsconfig"
const CryptoJS = require("crypto-js");

require('dotenv').config()


function getConfig() {

    // var config = require('../config.json');
    // return config;

    let configEncrypt = getCallboxConfigEncrypt();
    let bytes = CryptoJS.AES.decrypt(configEncrypt, process.env["REACT_APP_CONFIG_KEY"]);

    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (decryptedData) {
        decryptedData = JSON.parse(decryptedData);
    }
    return decryptedData;
}

export { getConfig };