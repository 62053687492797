import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select"; // Import react-select

interface CustomSelectConfirmProps {
  title: string;
  message: string;
  options: any[];
  defaultOption: any;
  onConfirm: (selectedOption: any) => void;
  onCancel: () => void; // Add onCancel callback
}

const CustomSelectConfirm: React.FC<CustomSelectConfirmProps> = ({
  title,
  message,
  options,
  defaultOption,
  onConfirm,
  onCancel,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption); // Update the selected option
  };

  const confirm = () => {
    if (selectedOption) {
      onConfirm(selectedOption.value); // Pass the selected option's value to the onConfirm callback
    }
  };

  const cancel = () => {
    onCancel(); 
  };

  return (
    <div className="customConfirmBox">
      <h1 className="customConfirmBox_title">{title}</h1>
      <p className="customConfirmBox_message">{message}</p>
      <Select
        className="text-black text-2xl"
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
      <div className="customConfirmBox_btnDiv">
        <button
          className="customConfirmBox_btn1"
          onClick={() => cancel()}
        >
          Cancel
        </button>
        <button className="customConfirmBox_btn2" onClick={confirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export const showCustomSelectConfirm = (
  title: string,
  message: string,
  options: any[],
  defaultOption: any
) => {
  return new Promise<string | null>((resolve, reject) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomSelectConfirm
          title={title}
          message={message}
          options={options}
          defaultOption={defaultOption}
          onConfirm={(selectedOption) => {
            resolve(selectedOption);
            onClose(); // Close the confirmation dialog
          }}
          onCancel={() => {
            reject(); // Reject the promise when the user cancels
            onClose(); // Close the confirmation dialog
          }}
        />
      ),
    });
  });
};
