import { gConst } from "../../lib/gConst";

declare const window: Window &
    typeof globalThis & {
        sforce: any;
    };

const salesforce = window.sforce;

export const vinuniDefaultObject: any = {
    application: [
        {
            Application_No: "",
            Full_Name: "",
            Mobile: "",
            Status: "",
            Fist_Choice_Program: "",
            High_School_Name: "",
            Id: "",
            type: "",
            Owner_Name: ""
        }
    ],
    lead: [
        {
            Name: "",
            Status: "",
            Mobile: "",
            Source: "",
            OwnerId: "",
            Id: "",
            type: "",
            Owner: ""
        }
    ],
    contact: [
        {
            Name: "",
            Phone: "",
            Email: "",
            OwnerId: "",
            Id: "",
            type: "",
            oke: "",
            Owner: ""
        }
    ],
    cases: [
        {
            CaseNumber: "",
            Subject: "",
            Status: "",
            Priority: "",
            Id: "",
            Owner_Name: ""
        }
    ],
}

export const vinuniDefaultApplication: any = {
    Application_No: "",
    Full_Name: "",
    Mobile: "",
    Status: "",
    Fist_Choice_Program: "",
    High_School_Name: "",
    Id: "",
    type: "",
    Owner_Name: ""
}

export const vinuniDefaultContact: any = {
    Name: "",
    Phone: "",
    Email: "",
    OwnerId: "",
    Id: "",
    type: "",
    oke: "",
    Owner: ""
}

export const vinuniDefaultLead: any = {
    Name: "",
    Status: "",
    Mobile: "",
    Source: "",
    OwnerId: "",
    Id: "",
    type: "",
    Owner: ""
}

export const vinuniDefaultCases: any = {
    CaseNumber: "",
    Subject: "",
    Status: "",
    Priority: "",
    Id: "",
    Owner_Name: ""
}

const vinuniDefaultSMSVariable: any = {
    Applicant_Full_Name_Formula__c: "",
    Name: "",
    Current_Address__c: "",
    LOR_1_Fullname__c: "",
    LOR_2_Fullname__c: "",
    collegeName: ""
}

const defaultObject = vinuniDefaultObject;
const defaultApplication = vinuniDefaultApplication;
const defaultContact = vinuniDefaultContact;
const defaultLead = vinuniDefaultLead;
const defaultCases = vinuniDefaultCases;
const defaultSMSVariable = vinuniDefaultSMSVariable;

// export async function vinuniGetSMSInfo(sdt: string, setSMSInfo: any,) {
//     let getSMSVariable = "select Applicant_Full_Name_Formula__c,Name,Current_Address__c,LOR_2_Fullname__c,College__c,LOR_1_Fullname__c from Application__c where Mobile__c like\'%" + sdt + "%\' order by CreatedDate desc"
//     const param: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getSMSVariable}`,
//     };
//     param.callback = async function (response: any) {
//         if (response.success) {
//             console.log(JSON.parse(response.returnValue.runApex));
//             const x = JSON.parse(response.returnValue.runApex);
//             try {
//                 if (x.length === 0) {
//                     setSMSInfo(
//                         defaultSMSVariable
//                     );
//                 } else {
//                     if (x[0].College__c) {
//                         console.log('sms 1')
//                         let getCollegeName = "select Name from College__c where Id = \'" + x[0].College__c + "\'";
//                         let param1: any = {
//                             apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                             methodName: 'getObjectData',
//                             methodParams: `Params=${getCollegeName}`,
//                             callback: (response: any) => {
//                                 if (response.success) {
//                                     console.log(JSON.parse(response.returnValue.runApex));
//                                     const x1 = JSON.parse(response.returnValue.runApex);
//                                     try {
//                                         if (x1.length === 0) {
//                                             let xTemp = {
//                                                 Applicant_Full_Name_Formula__c: x[0].Applicant_Full_Name_Formula__c,
//                                                 Name: x[0].Name,
//                                                 Current_Address__c: x[0].Current_Address__c,
//                                                 LOR_1_Fullname__c: x[0].LOR_1_Fullname__c,
//                                                 LOR_2_Fullname__c: x[0].LOR_2_Fullname__c,
//                                                 collegeName: ""
//                                             }
//                                             setSMSInfo(
//                                                 xTemp
//                                             );
//                                         } else {
//                                             let xTemp = {
//                                                 Applicant_Full_Name_Formula__c: x[0].Applicant_Full_Name_Formula__c,
//                                                 Name: x[0].Name,
//                                                 Current_Address__c: x[0].Current_Address__c,
//                                                 LOR_1_Fullname__c: x[0].LOR_1_Fullname__c,
//                                                 LOR_2_Fullname__c: x[0].LOR_2_Fullname__c,
//                                                 collegeName: x1[0].Name
//                                             }
//                                             setSMSInfo(xTemp)
//                                         }
//                                     } catch (error) {
//                                         console.log(error);
//                                         setSMSInfo(
//                                             defaultSMSVariable
//                                         );
//                                     }
//                                 } else {
//                                     console.error('Something went wrong! Errors:', response);
//                                     setSMSInfo(
//                                         defaultSMSVariable
//                                     );
//                                 }
//                             }
//                         };
//                         await salesforce.opencti.runApex(param1);
//                     } else {
//                         console.log('sms 2')
//                         let xTemp = {
//                             Applicant_Full_Name_Formula__c: x[0].Applicant_Full_Name_Formula__c,
//                             Name: x[0].Name,
//                             Current_Address__c: x[0].Current_Address__c,
//                             LOR_1_Fullname__c: x[0].LOR_1_Fullname__c,
//                             LOR_2_Fullname__c: x[0].LOR_2_Fullname__c,
//                             collegeName: ""
//                         }
//                         setSMSInfo(xTemp)
//                     }
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setSMSInfo(
//                     defaultSMSVariable
//                 );
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setSMSInfo(
//                 defaultSMSVariable
//             );
//         }
//     };
//     await salesforce.opencti.runApex(param);
// }

// export async function runApexVinuni(sdt: string, setApplication: any, setLead: any, setStudent: any, setCases: any, student: any, currentObject?: any) {
//     console.log('runApexxx', sdt, currentObject);
//     let getApplicationFields = "select Name,Applicant_Full_Name__c,Mobile__c,Application_Status__c,F1st_Choice__c,High_School_Name__c,Id,OwnerId,CreatedDate from Application__c where Mobile__c like\'%" + sdt + "%\' order by CreatedDate desc"
//     const param: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getApplicationFields}`,
//     };
//     param.callback = async function (response: any) {
//         if (response.success) {
//             console.log(JSON.parse(response.returnValue.runApex));
//             const x = JSON.parse(response.returnValue.runApex);
//             try {
//                 if (x.length === 0) {
//                     setApplication([
//                         defaultApplication
//                     ]);
//                 } else {
//                     let submitObject: any = [];
//                     let draftObject: any = [];
//                     for (let i = 0; i < x.length; i++) {
//                         let param: any = {
//                             apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                             methodName: 'searchUser',
//                             methodParams: `userId=${x[i].OwnerId}`,
//                             callback: (response: any) => {
//                                 if (response.success) {
//                                     const userIf = JSON.parse(response.returnValue.runApex);
//                                     let ownerName = x[i].OwnerId;
//                                     if(!!userIf.length){
//                                         console.log('get Owner', response, userIf, userIf[0].Name);
//                                         ownerName = userIf[0].Name || x[i].OwnerId;
//                                     }
//                                     let xTemp = {
//                                         Application_No: x[i].Name,
//                                         Full_Name: x[i].Applicant_Full_Name__c,
//                                         Mobile: x[i].Mobile__c,
//                                         Status: x[i].Application_Status__c,
//                                         Fist_Choice_Program: x[i].F1st_Choice__c,
//                                         High_School_Name: x[i].High_School_Name__c,
//                                         Id: x[i].Id,
//                                         type: "",
//                                         Owner_Name: ownerName
//                                     }
//                                     if (x[i].Application_Status__c === 'Draft') {
//                                         draftObject.push(xTemp)
//                                     } else {
//                                         submitObject.push(xTemp)
//                                     }
//                                     if (submitObject.length > 0) {
//                                         setApplication([
//                                             submitObject[0]
//                                         ]);
//                                     } else if (draftObject.length > 0) {
//                                         setApplication([
//                                             draftObject[0]
//                                         ]);
//                                     }
//                                 } else {
//                                     let xTemp = {
//                                         Application_No: x[i].Name,
//                                         Full_Name: x[i].Applicant_Full_Name__c,
//                                         Mobile: x[i].Mobile__c,
//                                         Status: x[i].Application_Status__c,
//                                         Fist_Choice_Program: x[i].F1st_Choice__c,
//                                         High_School_Name: x[i].High_School_Name__c,
//                                         Id: x[i].Id,
//                                         type: "",
//                                         Owner_Name: ""
//                                     }
//                                     if (x[i].Application_Status__c === 'Draft') {
//                                         draftObject.push(xTemp)
//                                     } else {
//                                         submitObject.push(xTemp)
//                                     }
//                                     if (submitObject.length > 0) {
//                                         setApplication([
//                                             submitObject[0]
//                                         ]);
//                                     } else if (draftObject.length > 0) {
//                                         setApplication([
//                                             draftObject[0]
//                                         ]);
//                                     }
//                                 }
//                             },
//                         };
//                         await salesforce.opencti.runApex(param);
//                     }
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setApplication([
//                     defaultApplication
//                 ]);
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setApplication([
//                 defaultApplication
//             ]);
//         }
//     };
//     await salesforce.opencti.runApex(param);
//     let getLeadFields = "select Name, Status, MobilePhone, LeadSource, OwnerId, Id from Lead where MobilePhone like\'%" + sdt + "%\' order by CreatedDate desc";
//     const param1: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getLeadFields}`,
//     };
//     param1.callback = async function (response: any) {
//         if (response.success) {
//             const x = JSON.parse(response.returnValue.runApex);
//             console.log(x);
//             try {
//                 if (x.length === 0) {
//                     setLead([
//                         defaultLead
//                     ]);
//                 } else {
//                     const param: any = {
//                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                         methodName: 'searchUser',
//                         methodParams: `userId=${x[0].OwnerId}`,
//                         callback: (response: any) => {
//                             if (response.success) {
//                                 const userIf = JSON.parse(response.returnValue.runApex);
//                                 let ownerName = x[0].OwnerId;
//                                 if(!!userIf.length){
//                                     console.log('get Owner', response, userIf, userIf[0].Name);
//                                     ownerName = userIf[0].Name || x[0].OwnerId;
//                                 }
//                                 setLead([
//                                     {
//                                         Name: x[0].Name,
//                                         Status: x[0].Status,
//                                         Mobile: x[0].MobilePhone,
//                                         Source: x[0].LeadSource,
//                                         OwnerId: x[0].OwnerId,
//                                         Id: x[0].Id,
//                                         type: "",
//                                         Owner: ownerName
//                                     }
//                                 ]);
//                             } else {
//                                 setLead([
//                                     {
//                                         Name: x[0].Name,
//                                         Status: x[0].Status,
//                                         Mobile: x[0].MobilePhone,
//                                         Source: x[0].LeadSource,
//                                         OwnerId: x[0].OwnerId,
//                                         Id: x[0].Id,
//                                         type: "",
//                                         Owner: ""
//                                     }
//                                 ]);
//                             }
//                         },
//                     };
//                     await salesforce.opencti.runApex(param);
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setLead([
//                     defaultLead
//                 ]);
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setLead([
//                 defaultLead
//             ]);
//         }
//     };
//     await salesforce.opencti.runApex(param1);
//     let getContactFields = "select Name, MobilePhone, Email, OwnerId, Id from Contact where MobilePhone like\'%" + sdt + "%\' order by CreatedDate desc";
//     const param2: any = {
//         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//         methodName: 'getObjectData',
//         methodParams: `Params=${getContactFields}`,
//     };
//     param2.callback = async function (response: any) {
//         if (response.success) {
//             localStorage.setItem('contactInfo', response.returnValue.runApex);
//             const x = JSON.parse(response.returnValue.runApex);
//             console.log(x);
//             try {
//                 if (x.length === 0) {
//                     setStudent([
//                         defaultContact
//                     ]);
//                 } else {
//                     const param: any = {
//                         apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                         methodName: 'searchUser',
//                         methodParams: `userId=${x[0].OwnerId}`,
//                         callback: (response: any) => {
//                             if (response.success) {
//                                 const userIf = JSON.parse(response.returnValue.runApex);
//                                 let ownerName = x[0].OwnerId;
//                                 if(!!userIf.length){
//                                     console.log('get Owner', response, userIf, userIf[0].Name);
//                                     ownerName = userIf[0].Name || x[0].OwnerId;
//                                 }
//                                 setStudent([
//                                     {
//                                         Name: x[0].Name,
//                                         Phone: x[0].MobilePhone,
//                                         Email: x[0].Email,
//                                         OwnerId: x[0].OwnerId,
//                                         Id: x[0].Id,
//                                         type: "",
//                                         oke: "",
//                                         Owner: ownerName
//                                     }
//                                 ]);
//                             } else {
//                                 setStudent([
//                                     {
//                                         Name: x[0].Name,
//                                         Phone: x[0].MobilePhone,
//                                         Email: x[0].Email,
//                                         OwnerId: x[0].OwnerId,
//                                         Id: x[0].Id,
//                                         type: "",
//                                         oke: "",
//                                         Owner: ""
//                                     }
//                                 ]);
//                             }
//                         },
//                     };
//                     await salesforce.opencti.runApex(param);
//                 }
//             } catch (error) {
//                 console.log(error);
//                 setStudent([
//                     defaultContact
//                 ]);
//             }
//         } else {
//             console.error('Something went wrong! Errors:', response);
//             setStudent([
//                 defaultContact
//             ]);
//         }
//     };
//     await salesforce.opencti.runApex(param2);
//     setTimeout(async () => {
//         let ctID = null;
//         let c2: any = localStorage.getItem('contactInfo');
//         c2 = JSON.parse(c2);
//         console.log('contactInfo', c2);
//         localStorage.removeItem('contactInfo');
//         ctID = c2 && c2.length > 0 ? c2[0].Id : null;
//         let getCaseFields = "select CaseNumber, Subject, Status, Priority, Id, OwnerId from Case where ContactId = \'" + ctID + "\' order by CreatedDate desc";
//         const param3: any = {
//             apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//             methodName: 'getObjectData',
//             methodParams: `Params=${getCaseFields}`,
//         };
//         param3.callback = async function (response: any) {
//             if (response.success) {
//                 const x = JSON.parse(response.returnValue.runApex);
//                 console.log(x);
//                 try {
//                     if (x.length === 0) {
//                         setCases([
//                             defaultCases
//                         ]);
//                     } else {
//                         let x1: any = [];
//                         for (let i = 0; i < x.length; i++) {
//                             const param: any = {
//                                 apexClass: gConst.SF_CLASS_RC_PHONE_HELPER,
//                                 methodName: 'searchUser',
//                                 methodParams: `userId=${x[i].OwnerId}`,
//                                 callback: (response: any) => {
//                                     if (response.success) {
//                                         const userIf = JSON.parse(response.returnValue.runApex);
//                                         let ownerName = x[i].OwnerId;
//                                         if(!!userIf.length){
//                                             console.log('get Owner', response, userIf, userIf[0].Name);
//                                             ownerName = userIf[0].Name || x[i].OwnerId;
//                                         }
//                                         let x2 = {
//                                             CaseNumber: x[i].CaseNumber,
//                                             Subject: x[i].Subject,
//                                             Status: x[i].Status,
//                                             Priority: x[i].Priority,
//                                             Id: x[i].Id,
//                                             Owner_Name: ownerName
//                                         }
//                                         x1.push(x2)
//                                         if (x1.length > 0) {
//                                             setCases(x1);
//                                         } else {
//                                             setCases([
//                                                 defaultCases
//                                             ]);
//                                         }
//                                     } else {
//                                         let x2 = {
//                                             CaseNumber: x[i].CaseNumber,
//                                             Subject: x[i].Subject,
//                                             Status: x[i].Status,
//                                             Priority: x[i].Priority,
//                                             Id: x[i].Id,
//                                             Owner_Name: ""
//                                         }
//                                         x1.push(x2)
//                                         if (x1.length > 0) {
//                                             setCases(x1);
//                                         } else {
//                                             setCases([
//                                                 defaultCases
//                                             ]);
//                                         }
//                                     }
//                                 },
//                             };
//                             await salesforce.opencti.runApex(param);
//                         }
//                     }
//                 } catch (error) {
//                     console.log(error);
//                     setCases([
//                         defaultCases
//                     ]);
//                 }
//             } else {
//                 console.error('Something went wrong! Errors:', response);
//                 setCases([
//                     defaultCases
//                 ]);
//             }
//         };
//         console.log('contact: ', c2);
//         await salesforce.opencti.runApex(param3);
//     }, 2000);

// }
export async function setIframeVinuni(setIframeData: any, userInfo: any, objectData: any, currentObject: any) {
    console.log(objectData)
    let a: any = defaultObject;
    a.application = [objectData.application[0]];
    a.lead = [objectData.lead[0]];
    a.contact = [objectData.contact[0]];
    a.cases = objectData.cases;
    setIframeData(a);
}
export async function setIdVinuni(currentObject: any, number: any, logData: any, objectData: any, userInfo: any) {
    if (
        currentObject &&
        currentObject.idObject &&
        (currentObject.typeObject === gConst.CONTACT ||
            currentObject.typeObject === gConst.LEAD ||
            currentObject.typeObject === gConst.APPLICATION ||
            currentObject.typeObject === gConst.CASE) &&
        number === currentObject.number
    ) {
        if (currentObject.typeObject === gConst.LEAD) {
            logData.whoId = currentObject.idObject;
            logData.whatId = '';
        } else {
            if (currentObject.typeObject === gConst.CONTACT) {
                logData.whoId = currentObject.idObject;
                if (objectData.application.length > 0 && objectData.application[0].Id) {
                    logData.whatId = objectData.application[0].Id;
                }
            } else {
                logData.whatId = currentObject.idObject;
                if (objectData.contact.length > 0 && objectData.contact[0].Id) {
                    logData.whoId = objectData.contact[0].Id;
                }
            }
        }
    } else {
        if (objectData.application.length > 0 && objectData.application[0].Id) {
            logData.whatId = objectData.application[0].Id;
            if (objectData.contact.length > 0 && objectData.contact[0].Id) {
                logData.whoId = objectData.contact[0].Id;
            }
        }else if (objectData.lead.length > 0 && objectData.lead[0].Id) {
            logData.whoId = objectData.lead[0].Id;
            logData.whatId = '';
        } else {
            if (objectData.contact.length > 0 && objectData.contact[0].Id) {
                logData.whoId = objectData.contact[0].Id;
            }
            if (objectData.cases.length > 0 && objectData.cases[0].Id) {
                logData.whatId = objectData.cases[0].Id;
            }
        }
    }
    return logData;
}

export async function setNameVinuni(currentObject: any, number: any, setName: any, userInfo: any, application: any, lead: any, student: any, cases: any) {
    if (currentObject && currentObject.name && number === currentObject.number) {
        setName(currentObject.name);
    } else {
        if (lead.length > 0 && lead[0].Id) {
            setName(lead[0].Name);
        } else {
            if (student.length > 0 && student[0].Id) {
                setName(student[0].Name);
            } else if (application.length > 0 && application[0].Id) {
                setName(application[0].Full_Name);
            } else if (cases.length > 0 && cases[0].Id) {
                setName(cases[0].CaseNumber);
            }
        }
    }
}

export async function setObjectDataVinuni(setObjectData: any, application: any, lead: any, student: any, cases: any) {
    setObjectData({
        application:
            application !== undefined
                ? application
                : [
                    defaultApplication
                ],
        lead:
            lead !== undefined
                ? lead
                : [
                    defaultLead
                ],
        contact:
            student !== undefined
                ? student
                : [
                    defaultContact
                ],
        cases:
            cases !== undefined
                ? cases
                : [
                    defaultCases
                ],
    });
}