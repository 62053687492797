import React from 'react';
import {useStopwatch} from 'react-timer-hook';

export default function Stopwatch() {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
      } = useStopwatch({ autoStart: true });
      return(
        <>
            <span>{`0${minutes}`.slice(-2)}</span>:<span>{`0${seconds}`.slice(-2)}</span>
        </>
    )
}