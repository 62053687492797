import React, { useEffect, useState } from "react";
import { LoginStatus, UserInfo } from '../../../types';
import phoneHangOff from '../../../icons/call.png';
import pause from '../../../icons/pause-small.png';
import mic from '../../../icons/mic.svg';
import mute from '../../../icons/mute.svg'
import info from '../../../icons/info.png';
import Stopwatch from "../../../customHooks/myStopwatch";
// import { ReactComponent as Up } from '../../../icons/up-arrow.svg';
import { ReactComponent as Up } from '../../../icons/forward.svg';


declare const window: Window

interface Props {

  onMuteClick: any;
  name: string;
  phoneNumber: string;
  status: string;
  onEndCallClick: any;
  onTransfer: any;
  handleSendDTMF: any;
  isMute: boolean;
  handleChangeCallBoxMode: any,
}

function MinInPhoning({
  onMuteClick,
  name,
  status,
  onEndCallClick,
  phoneNumber,
  onTransfer,
  handleSendDTMF,
  handleChangeCallBoxMode,
  isMute,
}: Props) {

  // const [isMute, setIsMute] = useState<boolean>(false);

  const handleMuteClick = () => {
    onMuteClick();
  }

  useEffect(() => {
  }, []);



  return (

    <>

      <div className="rounded-lg grid grid-cols-2" style={{ backgroundColor: "#673ab7", width: "100%", height: "100px", zIndex: 100 }}>
        <div className="text-2xl grid grid-cols-1  text-center items-center justify-center" style={{height: "100px"}}>

          <div>
          <p className="pl-3 text-white ">  {phoneNumber !== '' ? phoneNumber : '0XXXXXXXX'}</p>
          <p className="pl-3 text-white "> {status === 'Confirm' ? <Stopwatch /> : status}</p>
          </div>

        </div>

        <div className="text-2xl flex text-center items-center justify-center">
          <button
            id="contact"
            style={{ width: '3rem', height: '3rem' }}
            className="flex justify-center items-center  mx-2 "
            onClick={handleChangeCallBoxMode}
          >
          < div className='transform -rotate-90'> <Up width="25" height="25" fill="white" /> </div>

          </button>
          <div >
            {isMute ?
              <button
                id="mute"
                className="rounded-full  flex justify-center items-center  mx-2"
                style={{ width: '3rem', height: '3rem' }}
                onClick={handleMuteClick}
              >
                <img className="bg-white rounded-full " style={{ width: '2.5rem', height: '2.5rem' }} src={mute} alt="mute" />
              </button> :

              <button
                id="mic"
                className="rounded-full  flex justify-center items-center  mx-2"
                onClick={handleMuteClick}
                style={{ width: '3rem', height: '3rem' }}
              >
                <img className="bg-white rounded-full " style={{width: '2.5rem', height: '2.5rem' }} src={mic} alt="mic" />
              </button>
            }
          </div>


          <div className="rounded-lg" onClick={onEndCallClick}>
            <button
              id="hangoff"
              style={{ width: '2.5rem', height: '2.5rem' }}
              className="rounded-full bg-red-500  flex justify-center items-center  mx-2 bg-transparent"
            >

              <img style={{ width: '1.5rem', height: '1.5rem' }} src={phoneHangOff} alt="hangoff" />

            </button>
          </div>
        </div>
      </div>
    </>

  );
}

export default MinInPhoning;
