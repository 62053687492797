import { gdebug } from "../../lib/gdebug";
import { apiIntegration, apiIntegration2, createContact, getCalllogs, searchContact, searchContact2, searchGroup, updateContact } from "../../services/gcalls.service";
import host from '../../config/host';
import { gConst } from '../../lib/gConst';
const { enablePouchDbLog } = host;


let gUserData = localStorage.getItem(gConst.STORAGE_GCALLS_USER);
gUserData = JSON.parse(gUserData)
let parrentHostname = localStorage.getItem(gConst.STORAGE_PARRENT_HOST_NAME) || '';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const searchContactWithOtherFormat =  async (phoneNumber) => {
    let tmpPhone = '';
    let resp = false;
    // Search with VN number format. Ex: 84933182497, 84 933182497
    var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    if (vnf_regex.test(phoneNumber)) {
        tmpPhone = '+84' + phoneNumber.substring(1);
        resp = await apiIntegration2({q: tmpPhone}, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_CONTACT);
        console.log("searchContactWithOtherFormat resp", resp);
    }
    return resp;
}

export function getAudioDuration(uri) {
    return new Promise((resolve, reject) => {
        if (!uri) {
            reject(0);
        }
        const audio = document.createElement("audio")
        audio.src = uri;
        audio.addEventListener("loadedmetadata", () => {
            if (audio.duration) {
                resolve(audio.duration)
            } else {
                reject(0)
            }
        })
        audio.addEventListener("error", (e) => {
            reject(0)
        });
    })
}

export async function getDataIframe(display_name, setIframeData, setName, direction) {
    // Hubspot
    let iframeDatas = {}
    iframeDatas.phone = display_name;
    let dataSearch = {
        "q": display_name
    }
    var companySearch = await apiIntegration(dataSearch, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_COMPANY, async function (res, err) {
            if (!err) {
                let list = []
                let currentCompany = ""
                let continued = true
                if (!!res.data && !!res.data.companies)
                    for (const company of res.data.companies) {
                        console.log("dataSearch.q", dataSearch.q)
                        console.log("company.properties.phone.value", company?.properties?.phone?.value)
                        if(company?.properties?.phone?.value && company.properties.phone.value.replace(" ","").replace("+", "").replace("-", "")===dataSearch.q)
                        {
                            currentCompany = company
                            console.log("currentCompany", company)
                            console.log("waiting for currentCompany")
                            console.log("this is currentCompany", company)
                            const owner_id = company?.properties?.hubspot_owner_id ? company?.properties?.hubspot_owner_id?.value : '';
                            let owner = {}
                            if (owner_id) {
                                let dataSearch2 = {
                                    "id": owner_id
                                }
                                let resp = await apiIntegration2(dataSearch2, gConst.HUBSPOT, gConst.HUBSPOT_GET_OWNER);
                                
                                owner = resp?.data?.firstName ? Object.assign({}, {
                                    firstname: resp?.data?.firstName || '',
                                    lastname: resp?.data?.lastName || '',
                                    email: resp.data.email
                                }) : undefined;
                            }
                            let item = Object.assign({}, {
                                type: "company",
                                vid: company.companyId,
                                email: company?.properties?.email ? company?.properties?.email?.value : '',
                                phone: company?.properties?.phone ? company?.properties?.phone?.value : '',
                                company: company?.properties?.name ? company?.properties?.name?.value : '',
                                website: company?.properties?.website ? company?.properties?.website?.value : '',
                                createdate: company?.properties?.createdate ? company?.properties?.createdate?.value : '',
                                industry: company?.properties?.industry ? company?.properties?.industry?.value : '',
                                domain: company?.properties?.domain ? company?.properties?.domain?.value : '',
                                owner
                            });
                            await list.push(item);
                        }
                    }
                    
                    
                        
                    
                if (!!list && !!list.length) {
                    iframeDatas.hubspotContact = list;
                    setName(list[0].name)
                    if (!!localStorage.getItem(gConst.STORAGE_AUTO_COPY_HUBSPOT_GCALLS)) {
                        searchContact2(display_name, async function (res, err) {
                            if (!err) {
                                if (res.length) {
                                    let contactData = {
                                        name: list[0].name,
                                        website: list[0].website || '',
                                        email: list[0].email,
                                        firstName: "",
                                        lastName: "",
                                        createdate: list[0].createdate,
                                        owner: list[0].owner,
                                    }
                                    updateContact(res[0]._id, contactData)
                                }else {
                                    let contactData = {
                                        name: list[0].name,
                                        phone: display_name,
                                        website: list[0].website,
                                        email: list[0].email,
                                        avatar: '',
                                        firstName: "",
                                        lastName: "",
                                        createdate: list[0].createdate,
                                        owner: list[0].owner,
                                        
                                    }
                                    createContact(contactData)
                                }
                            }
                        })
                    }
                    let getAssociationData = {
                        objectId: list[0].vid
                    }
                    await apiIntegration(getAssociationData, gConst.HUBSPOT, gConst.HUBSPOT_GET_ASSOCIATIONS_DEALS, async function (res, err) {
                        if (!err) {
                            let getDealData = {
                                offset: res?.data?.offset
                            }
                            await apiIntegration(getDealData, gConst.HUBSPOT, gConst.HUBSPOT_GET_RECENT_DEALS, async function (res, err) {
                                if (!err) {
                                    let dealResponse = res;
                                    if (dealResponse.success && dealResponse.data) {
                                        if (dealResponse.data.properties.amount) {
                                            let amount = dealResponse.data.properties.amount.value;
                                            let value = amount ? numberWithCommas(amount) : '0'
                                            iframeDatas.hubspotContact[0].deal = value;
                                        } else {
                                            iframeDatas.hubspotContact[0].deal = '0';
                                        }
                                    }
                                }
                            })
                        }
                    })
                    let filterTickets = {
                        "filters": [
                            {
                                "propertyName": "associations.contact",
                                "operator": "EQ",
                                "value": list[0].vid
                            }
                        ]
                    }
                    await apiIntegration(filterTickets, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_TICKETS, async function (res, err) {
                        if (!err) {
                            let ticketsResponse = res;
                            if (ticketsResponse.success && ticketsResponse.data.results.length > 0) {
                                iframeDatas.hubspotTicket = ticketsResponse.data.results;
                                iframeDatas.hubspotTicket.forEach(async (e, i) => {
                                    if (!e.properties.hubspot_owner_name) {
                                        let idData = {
                                            id: e.properties.hubspot_owner_id
                                        }
                                        if (idData.id) {
                                            await apiIntegration(idData, gConst.HUBSPOT, gConst.HUBSPOT_GET_OWNER, async function (res, err) {
                                                if (!err) {
                                                    e.properties.hubspot_owner_name = res.data?.firstName + ' ' + res.data?.lastName;
                                                }
                                            })
                                            setIframeData(null)
                                            setIframeData(iframeDatas)
                                        }
                                    }
                                })
                            }
    
                        }
                    })
    
                    setIframeData(null)
                    setIframeData(iframeDatas)
                }
            }
        }).then(async()=>{
            if(!companySearch || companySearch && !companySearch.companies){
                await apiIntegration(dataSearch, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_CONTACT, async function (res, err) {
                    console.log("It's contact time")
                    if (!err) {

                        if (!!res.data && !!res.data.contacts && res.data.contacts.length == 0 ) {
                            res = await searchContactWithOtherFormat(display_name);
                        }
                        
                        let list = []
                        if (!!res.data && !!res.data.contacts)
                            for (const contact of res.data.contacts) {
                                const owner_id = contact.properties.hubspot_owner_id ? contact.properties.hubspot_owner_id.value : '';
                                let owner = {}
                                if (owner_id) {
                                    let dataSearch2 = {
                                        "id": owner_id
                                    }
                                    let resp = await apiIntegration2(dataSearch2, gConst.HUBSPOT, gConst.HUBSPOT_GET_OWNER);
                                    owner = resp?.data?.firstName ? Object.assign({}, {
                                        firstname: resp?.data?.firstName,
                                        lastname: resp?.data?.lastName,
                                        email: resp?.data?.email
                                    }) : undefined;
                                }
                                let item = Object.assign({}, {
                                    vid: contact.vid,
                                    firstname: contact.properties.firstname ? contact.properties.firstname.value : '',
                                    lastname: contact.properties.lastname ? contact.properties.lastname.value : '',
                                    email: contact.properties.email ? contact.properties.email.value : '',
                                    phone: contact.properties.phone ? contact.properties.phone.value : '',
                                    company: contact.properties.company ? contact.properties.company.value : '',
                                    mobilephone: contact.properties.mobilephone ? contact.properties.mobilephone.value : '',
                                    lifecyclestage: contact.properties.lifecyclestage ? contact.properties.lifecyclestage.value : '',
                                    website: contact.properties.website ? contact.properties.website.value : '',
                                    createdate: contact.properties.createdate ? contact.properties.createdate.value : '',
                                    owner
                                });
                                await list.push(item);
                            }
                        if (!!list && !!list.length) {
                            iframeDatas.hubspotContact = list;
                            setName(list[0].firstname + " " + list[0].lastname)
                            if (!!localStorage.getItem(gConst.STORAGE_AUTO_COPY_HUBSPOT_GCALLS)) {
                                searchContact2(display_name, async function (res, err) {
                                    if (!err) {
                                        if (res.length) {
                                            let contactData = {
                                                firstName: list[0].firstname,
                                                lastName: list[0].lastname || '',
                                                email: list[0].email
                                            }
                                            updateContact(res[0]._id, contactData)
                                        }else {
                                            let contactData = {
                                                firstName: list[0].firstname,
                                                lastName: list[0].lastname || '',
                                                phone: display_name,
                                                email: list[0].email,
                                                gender: 'unknown',
                                                avatar: ''
                                            }
                                            createContact(contactData)
                                        }
                                    }
                                })
                            }
                            let getAssociationData = {
                                objectId: list[0].vid
                            }
                            await apiIntegration(getAssociationData, gConst.HUBSPOT, gConst.HUBSPOT_GET_ASSOCIATIONS_DEALS, async function (res, err) {
                                if (!err) {
                                    let getDealData = {
                                        offset: res.data.offset
                                    }
                                    await apiIntegration(getDealData, gConst.HUBSPOT, gConst.HUBSPOT_GET_RECENT_DEALS, async function (res, err) {
                                        if (!err) {
                                            let dealResponse = res;
                                            if (dealResponse.success && dealResponse.data) {
                                                if (dealResponse.data.properties.amount) {
                                                    let amount = dealResponse.data.properties.amount.value;
                                                    let value = amount ? numberWithCommas(amount) : '0'
                                                    iframeDatas.hubspotContact[0].deal = value;
                                                } else {
                                                    iframeDatas.hubspotContact[0].deal = '0';
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                            let filterTickets = {
                                "filters": [
                                    {
                                        "propertyName": "associations.contact",
                                        "operator": "EQ",
                                        "value": list[0].vid
                                    }
                                ]
                            }
                            await apiIntegration(filterTickets, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_TICKETS, async function (res, err) {
                                if (!err) {
                                    let ticketsResponse = res;
                                    if (ticketsResponse.success && ticketsResponse.data.results.length > 0) {
                                        iframeDatas.hubspotTicket = ticketsResponse.data.results;
                                        iframeDatas.hubspotTicket.forEach(async (e, i) => {
                                            if (!e.properties.hubspot_owner_name) {
                                                let idData = {
                                                    id: e.properties.hubspot_owner_id
                                                }
                                                if (idData.id) {
                                                    await apiIntegration(idData, gConst.HUBSPOT, gConst.HUBSPOT_GET_OWNER, async function (res, err) {
                                                        if (!err) {
                                                            e.properties.hubspot_owner_name = res.data?.firstName + ' ' + res.data?.lastName;
                                                        }
                                                    })
                                                    setIframeData(null)
                                                    setIframeData(iframeDatas)
                                                }
                                            }
                                        })
                                    }
            
                                }
                            })
            
                            setIframeData(null)
                            setIframeData(iframeDatas)
                        }
                    }
                })
            }
            
        }   
    )
    


    let gcallsContact = await searchContact(display_name);
    if (!!gcallsContact.length) {
        // searchGroup(gcallsContact[0]._id, async function (res, err) {
        //     if (!err) {
        //         console.log(res)
        //     }
        // })
        // await getCalllogs(gcallsContact[0]._id, async function (res, err) {
        //     if (!err) {
        //         iframeDatas.latestCall = res[0]
        //     }
        // })
    }
     //Get Calllog By Phone
    await getCalllogs(display_name, async function (res, err) {
        if (!err) {
        iframeDatas.latestCall = res[0];
        }
    })

    setIframeData(null)
    setIframeData(iframeDatas)
}

export async function saveLogCRM(calllog,iframeData) {

    let crmLogs = []
    
    const disposition = {
        connected: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
        success: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
        busy: '9d9162e7-6cf3-4944-bf63-4dff82258764',
        'no-answer': '73a0d17f-1163-4015-bdd5-ec830791da20',
        rejected: '73a0d17f-1163-4015-bdd5-ec830791da20',
        missed: '73a0d17f-1163-4015-bdd5-ec830791da20',
        canceled: '73a0d17f-1163-4015-bdd5-ec830791da20'
    };

    let dataSearch = {
        "q": calllog.phone
    }
    

    let contact_vids = [];
    let company_ids = [];

    var contactSearch = await apiIntegration(dataSearch, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_CONTACT, async function (res, err) {

        if (!err) {

            if (!!res.data && !!res.data.contacts && res.data.contacts.length == 0 ) {
                res = await searchContactWithOtherFormat(calllog.phone);
            }

            if (!!res.data && !!res.data.contacts) {
                for (const contact of res.data.contacts) {
                    contact_vids.push(contact.vid)
                }

                if (!!contact_vids.length && !!gUserData) {
                    contact_vids.forEach(async (vid, i) => {

                        let audioDuration = 0;
                        try {
                            audioDuration = await getAudioDuration(calllog.playing_recordings_url);
                        } catch (e) {
                            audioDuration = 0;
                        }

                        const info = `<p>Direction: ${calllog.direction} - `
                            + `Caller: ${calllog.direction === gConst.INCOMING ? calllog.destination : gUserData.email} - `
                            + `Callee: ${calllog.direction === gConst.INCOMING ? gUserData.email : calllog.destination} - `
                            + `Status: ${calllog.status} - `
                            + `Duration: ${calllog.duration} - `
                            + `Record URL: ${calllog.playing_recordings_url}</p>`; //update playing record url
                        const data = {
                            engagement: { type: "CALL", timestamp: calllog.start },
                            associations: { contactIds: [vid] },
                            metadata: {
                                toNumber: calllog.destination,
                                fromNumber: calllog.destination,
                                status: 'COMPLETED',
                                durationMilliseconds: audioDuration * 1000,
                                body: info,
                                disposition: disposition[calllog.status],
                                recordingUrl: calllog.playing_recordings_url
                            }
                        };
                        let ownerData = null;
                        let currentCrmUser = localStorage.getItem(gConst.STORAGE_CURRENT_CRM_USER);
                        if(currentCrmUser){
                            currentCrmUser = JSON.parse(currentCrmUser)
                            if(currentCrmUser?.hubspotEmail?.length) {
                                let searchOwnerData = {
                                    email: currentCrmUser?.hubspotEmail[0]
                                }
                                gdebug(1,'search user ',searchOwnerData)
                                ownerData = await apiIntegration2(searchOwnerData, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_OWNER);
                            }
                        }
                        if (!!ownerData && !!ownerData.data && !!ownerData.data.length) {
                            data.engagement.ownerId = ownerData.data[0].ownerId;
                        }

                        if(enablePouchDbLog){
                            let logObj = {
                                data: data,
                                templateName: gConst.HUBSPOT,
                                endpointName: gConst.HUBSPOT_POST_ENGAGEMENT 
                            }
                            crmLogs.push(logObj);
                        }else{
                            await apiIntegration2(data, gConst.HUBSPOT, gConst.HUBSPOT_POST_ENGAGEMENT);
                        }
           

                        if (!!localStorage.getItem(gConst.STORAGE_AUTO_CREATE_TICKET_HUBSPOT)) {
                            let subjectTicket = calllog.direction === gConst.INCOMING ? `Call from ${res.data.contacts[0].properties?.firstname ? res.data.contacts[0].properties.firstname.value : calllog.phone}` : `Call to ${res.data.contacts[0].properties.firstname ? res.data.contacts[0].properties.firstname.value : calllog.phone}`;

                            let newTicket = {
                                properties: {
                                    content: '',
                                    hs_pipeline_stage: "1",
                                    hs_pipeline: "0",
                                    //hubspot_owner_id: 100399741,
                                    subject: subjectTicket
                                }
                            }

                            // let searchOwnerData = {
                            //     email: gUserData.email
                            // }

                            // let ownerData = await apiIntegration2(searchOwnerData, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_OWNER);

                            if (!!ownerData && !!ownerData.data && !!ownerData.data.length) {
                                newTicket.properties.hubspot_owner_id = ownerData.data[0].ownerId;
                            }

                            apiIntegration(newTicket, gConst.HUBSPOT, gConst.HUBSPOT_POST_TICKET, async function (res, err) {
                                if (!err) {
                                    const fromObjectId = res.data.id;
                                    let dataUpdate = {
                                        fromObjectId,
                                        toObjectId: vid,
                                        category: 'HUBSPOT_DEFINED',
                                        definitionId: '16'
                                    }
                                    // apiIntegration2(dataUpdate, gConst.HUBSPOT, gConst.HUBSPOT_POST_ASSOCIATION);
                  
                                    if(enablePouchDbLog){
                                        let logObj = {
                                            data: dataUpdate,
                                            templateName: gConst.HUBSPOT,
                                            endpointName: gConst.HUBSPOT_POST_ASSOCIATION
                                        }
                                        crmLogs.push(logObj)
                                    }else{
                                        apiIntegration2(dataUpdate, gConst.HUBSPOT, gConst.HUBSPOT_POST_ASSOCIATION);
                                    }
                                } else {
                                    gdebug(3,"Create ticket fail! ")
                                }
                            })
                        }
                    })
                }
                return res.data
            }
        }
    })
    .then(async()=>{
        try{
            if(!contactSearch || (contactSearch && !contactSearch.contacts)){
                console.log("It's company time")
                await apiIntegration(dataSearch, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_COMPANY, async function (res, err) {
                    if (!err) {
                        let currentCompany = ''
                        let continued = true
                        if (!!res.data && !!res.data.companies) {
                            console.log("companies", res.data)
                            for(const company of res.data.companies){
                                console.log("dataSearch.q", dataSearch.q)
                                console.log("company.properties.phone.value", company?.properties?.phone?.value)
                                if(company?.properties?.phone?.value && company.properties.phone.value.replace(" ","").replace("+", "").replace("-", "")===dataSearch.q)
                                {
                                    currentCompany = company
                                    console.log("currentCompany", currentCompany)
                                    company_ids.push(company.companyId)
                                    setTimeout(()=>{
                                        if(!!currentCompany && !!currentCompany.properties.phone.value){
                                            continued = false
                                            console.log("currentCompany", currentCompany)
                                            console.log("save currentCompany", currentCompany)
                
                                            if (!!company_ids.length && !!gUserData) {
                                                company_ids.forEach(async (companyId, i) => {

                                                    let audioDuration = 0;
                                                    try {
                                                        audioDuration = await getAudioDuration(calllog.playing_recordings_url);
                                                    } catch (e) {
                                                        audioDuration = 0;
                                                    }

                                                    const info = `<p>Direction: ${calllog.direction} - `
                                                        + `Caller: ${calllog.direction === gConst.INCOMING ? calllog.destination : gUserData.email} - `
                                                        + `Callee: ${calllog.direction === gConst.INCOMING ? gUserData.email : calllog.destination} - `
                                                        + `Status: ${calllog.status} - `
                                                        + `Duration: ${calllog.duration} - `
                                                        + `Record URL: ${calllog.playing_recordings_url}</p>`; //update playing record url
                                                    const data = {
                                                        engagement: { type: "CALL", timestamp: calllog.start },
                                                        associations: { companyIds: [companyId] },
                                                        metadata: {
                                                            toNumber: calllog.destination,
                                                            fromNumber: calllog.destination,
                                                            status: 'COMPLETED',
                                                            durationMilliseconds: audioDuration * 1000,
                                                            body: info,
                                                            disposition: disposition[calllog.status],
                                                            recordingUrl: calllog.playing_recordings_url
                                                        }
                                                    };


                                                    if(enablePouchDbLog){
                                                        let logObj = {
                                                            data: data,
                                                            templateName: gConst.HUBSPOT,
                                                            endpointName: gConst.HUBSPOT_POST_ENGAGEMENT
                                                        }
                                                        crmLogs.push(logObj);
                                                    }else{
                                                        await apiIntegration2(data, gConst.HUBSPOT, gConst.HUBSPOT_POST_ENGAGEMENT).then(res=>console.log(res))
                                                    }
        
                            
                                                    if (!!localStorage.getItem(gConst.STORAGE_AUTO_CREATE_TICKET_HUBSPOT)) {
                                                        let subjectTicket = calllog.direction === gConst.INCOMING ? `Call from ${currentCompany.properties.name.value ? currentCompany.properties.name.value : calllog.phone}` : `Call to ${currentCompany.properties.name.value ? currentCompany.properties.name.value : calllog.phone}`;
                            
                                                        let newTicket = {
                                                            properties: {
                                                                content: '',
                                                                hs_pipeline_stage: "1",
                                                                hs_pipeline: "0",
                                                                //hubspot_owner_id: 100399741,
                                                                subject: subjectTicket
                                                            }
                                                        }
                            
                                                        let searchOwnerData = {
                                                            email: gUserData.email
                                                        }
                            
                                                        let ownerData = await apiIntegration2(searchOwnerData, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_OWNER)
                                                        .then(res=>console.log(res))

        
                                                        if (!!ownerData && !!ownerData.data && !!ownerData.data.length) {
                                                            newTicket.properties.hubspot_owner_id = ownerData.data[0].ownerId;
                                                        }
                            
                                                        apiIntegration(newTicket, gConst.HUBSPOT, gConst.HUBSPOT_POST_TICKET, async function (res, err) {
                                                            if (!err) {
                                                                const toObjectId = res.data.id;
                                                                console.log(res.data)
                                                                let dataUpdate = {
                                                                    fromObjectId: companyId,
                                                                    toObjectId,
                                                                    category: 'HUBSPOT_DEFINED',
                                                                    definitionId: '25' //25 is id for "company to ticket"
                                                                }
                                                                // apiIntegration2(dataUpdate, gConst.HUBSPOT, gConst.HUBSPOT_POST_ASSOCIATION)
                                                                // .then(res=>console.log(res))

                                                                if(enablePouchDbLog){
                                                                    let logObj = {
                                                                        data: dataUpdate,
                                                                        templateName: gConst.HUBSPOT,
                                                                        endpointName: gConst.HUBSPOT_POST_ASSOCIATION
                                                                    }
                                                                    crmLogs.push(logObj);
                                                                }else{
                                                                    apiIntegration2(dataUpdate, gConst.HUBSPOT, gConst.HUBSPOT_POST_ASSOCIATION).then(res=>console.log(res))
                                                                }
                                                            } else {
                                                                gdebug(3,"Create ticket fail! ");
                                                            }
                                                        })
        
                                                    }
                                                })
                                            }
                                        }
                                        
                                    }, 1000)
                                }
                            }
                            return res.data
                        }
                    }
                })
        
            }
        }catch(e){console.log(e)}
    })


    return crmLogs;    

}

export async function createSMSNote(data) {
    let contact_vids = [];
    let dataSearch = {
        "q": data.phone
    }
    await apiIntegration(dataSearch, gConst.HUBSPOT, gConst.HUBSPOT_SEARCH_CONTACT, async function (res, err) {
        if (!err) {

            if (!!res.data && !!res.data.contacts && res.data.contacts.length == 0 ) {
                res = await searchContactWithOtherFormat(data.phone);
            }

            if (!!res.data && !!res.data.contacts) {
                for (const contact of res.data.contacts) {
                    contact_vids.push(contact.vid)
                }

                if (!!contact_vids.length && !!gUserData) {
                    contact_vids.forEach(async (vid, i) => {
                        let noteData = {
                            "engagement": {
                                "active": true,
                                "type": "NOTE"
                            },
                            "associations": {
                                "contactIds": [vid]
                            },
                            "metadata": {
                                "body": `<div><p>You sent an SMS to ${res.data.contacts[0].properties.firstname ? res.data.contacts[0].properties.firstname.value : data.phone}</p><p><b>Status:</b> ${data.status_sms}</p><p><b>Content:</b> ${data.sms}</p></div>`
                            }
                        }
                        apiIntegration2(noteData, gConst.HUBSPOT, gConst.HUBSPOT_POST_ENGAGEMENT);
                    })
                }
            }
        }
    })
    
    
}